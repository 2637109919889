@charset "UTF-8";

a {
  &:hover {
    text-decoration: unset;
  }
}
.developing,
.development {
  display: none;
}

/************************************************
 印刷用
************************************************ */
@media print {
  body {
    zoom: 75%;
  }
}

/************************************************
 共通設定
************************************************ */
html {
  font-size: unset;
}
html,
body {
  height: 100%;
}

.sp_none {
  display: none;
  @include media_desktop {
    display: revert;
  }
}

.pc_none {
  display: revert;
  @include media_desktop {
    display: none;
  }
}

#wrapper {
  position: relative;
  min-height: 820px;
  height: auto !important;
  height: 100%;
}

#container {
  padding-bottom: 50px;
}

#footer {
  bottom: 0;
  width: 100%;
}

body {
  color: #333;
  font-family: "Hiragino Sans", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Meiryo", "BIZ UDPGothic", "MS Gothic", sans-serif;
  font-size: 75.5%;
  line-height: 1.2;
  letter-spacing: 0.1em;
  background-color: #fafafa;
  -webkit-text-size-adjust: 100%;
}

img {
  -ms-interpolation-mode: bicubic;
}

select {
  border: solid 1px #ccc;
}

.input:-ms-input-placeholder {
  color: #A3A3A3;
}

/*写真*/
.picture {
  border: 1px solid #ccc;
}

.margintop {
  margin-top: 1rem;
}

.small {
  float: left;
}

.red {
  color: #ff0000;
  font-weight: bold;
}

.url {
  font-weight: bold;
}

.url a:hover {
  text-decoration: underline;
}

.additional-banner {
  margin: 20px 0 10px !important;
}

.boxshadow1 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.boxshadow2 {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.boxshadow3 {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.boxshadow4 {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.boxshadow5 {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

.list_disc li {
  list-style-type: disc;
  margin-left: 1.5rem;
}

.swing {
  animation-name: fuwa_p;
  animation-duration: .3s;
  animation-iteration-count: 2;
  animation-timing-function: ease;
}

#bank_check_area {
  margin-top: 15px;
}

.pointer {
  display: none;
  float: left;
  position: relative;
  top: -35px;
  left: 550px;
  animation-name: fuwa_l;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
}

.fa-fuwaru {
  margin-left: 0.5rem;
  animation-name: fuwa_l;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
}

@keyframes fuwa_p {
  0% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(0, -10px);
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes fuwa_l {
  0% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(-10px, 0);
  }

  100% {
    transform: translate(0, 0);
  }
}

/* FLOAT */

@-webkit-keyframes float {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes float {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.faa-float {
  -webkit-animation: float 2s linear infinite;
  animation: float 2s linear infinite;
}

.faa-float.faa-fast {
  -webkit-animation: float 1s linear infinite;
  animation: float 1s linear infinite;
}

.faa-float.faa-slow {
  -webkit-animation: float 3s linear infinite;
  animation: float 3s linear infinite;
}


.ibox_gray {
  background-color: #dddddd;
  display: inline-block;
  padding: 8px;
  border-radius: 4px;
}

/* 緑の長いボタン */
.link_sp {
  width: 17em;
  max-width: 150px;
  height: auto;
  margin: 0 auto;
  padding: 10px;
  background: #4caf50;
  text-align: center;
  border-radius: 2px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.link_sp:hover {
  background: #439a46;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.35);
}

.link_sp:active {
  background: #439a46;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.35);
}

.link_sp_a {
  color: #fff !important;
  font-weight: bold;
  text-decoration: none !important;
  font-size: 1.5em;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

/* オレンジ色の長いボタン */
.link_sp2 {
  width: 17em;
  max-width: 150px;
  height: auto;
  margin: 0 auto;
  padding: 10px;
  background: #FF9800;
  text-align: center;
  border-radius: 2px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.link_sp2:hover {
  background: #e08600;
}

.link_sp2:active {
  background: #e08600;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.35);
}

.link_sp2_a {
  color: #fff !important;
  font-weight: bold;
  text-decoration: none !important;
  font-size: 1.5em;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

/* 白い長いボタン */
.link_sp3 {
  width: 19em;
  max-width: 50%;
  height: auto;
  margin: 0 auto;
  padding: 10px;
  background: #F6F6F6;
  text-align: center;
  border-radius: 2px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.link_sp3:hover {
  background: #E6E6E6;
}

.link_sp3:active {
  background: #E6E6E6;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.35);
}

.link_sp3_a {
  color: #130f30 !important;
  font-weight: bold;
  text-decoration: none !important;
  font-size: 1.5em;
}

/* オレンジ色の細長いボタン */
.link_sp4 {
  width: 17em;
  max-width: 50%;
  height: auto;
  margin: 0 auto;
  padding: 10px;
  background: #FF9800;
  text-align: center;
  border-radius: 2px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.link_sp4:hover {
  background: #FF9E43;
}

.link_sp4:active {
  background: #FF9E43;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.35);
}

.link_sp4_a {
  color: #fff !important;
  text-decoration: none !important;
  font-size: 1.0em;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

.sp_red {
  background-color: #f44336;
  color: white;
}

.sp_red:hover {
  background-color: #ef5350;
}

.sp_red:active {
  background: #ef5350;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.35);
}

.sp_blue {
  background-color: #2196f3;
  color: white;
}

.sp_blue:hover {
  background-color: #3ea4f5;
}

.sp_blue:active {
  background: #3ea4f5;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.35);
}

.sp_ora {
  background-color: #FF9800;
  color: white;
}

.sp_ora:hover {
  background-color: #ffa41f;
}

.sp_ora:active {
  background: #ffa41f;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.35);
}

.orange {
  color: #FF8611;
}

.fontsize12em {
  font-size: 1.2em;
}

.summerholiday_table_scroll {
  width: 100%;
  overflow-x: auto;
  table {
    width: 752px;
  }
}

.top-warning {
  display: block;
  background-color: #ffebee;
  text-align: center;
  font-size: 1.3em;
  border: 1px solid #f44336;
  padding: 5px;
  margin-bottom: 5px;
  border-radius: 3px;
  text-decoration: none;
  line-height: 1.3;
  margin-top: 15px;
}

.top-warning:hover {
  cursor: pointer;
  opacity: 0.8;
}

.box-text-notice {
  position: relative;
  background: #FFEBEE;
  border: 3px double #E53935;
  border-radius: 5px;
  margin: 20px 0;
  padding: 10px 5px;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.6;
}

.btn-push {
  position: relative;
  top: 0px;
  font-family: "Font Awesome 5 Free", "Hiragino Sans", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Meiryo", "BIZ UDPGothic", "MS Gothic", sans-serif;
  text-decoration: none;
  font-size: 16px;
  background: #f7f7f7;
  padding: 10px 10px;
  margin: 0 10px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  color: #222;
  text-shadow: 1px 1px 1px #fff;
  transition: all .3s;
  text-decoration: none !important;
  @include media_desktop {
    padding: 10px 35px;
  }
}

/*==========  Active State  ==========*/
.btn-push:hover {
  opacity: 0.6;
  transition: all .3s;
}

/*==========  Navy  ==========*/
.btn-push.navy {
  background: #1b486d;
  border: 1px solid #07121b;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 5px 0px #07121b;
  -moz-box-shadow: 0px 5px 0px #07121b;
  -ms-box-shadow: 0px 5px 0px #07121b;
  -o-box-shadow: 0px 5px 0px #07121b;
  box-shadow: 0px 5px 0px #07121b;
  color: #fff;
  text-shadow: 1px 1px 1px #07121b;
}

/*==========  Green  ==========*/
.btn-push.green {
  background: #4caf50;
  color: #fff;
  border: 1px solid #439a46;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 5px 0px #46963f;
  -moz-box-shadow: 0px 5px 0px #46963f;
  -ms-box-shadow: 0px 5px 0px #46963f;
  -o-box-shadow: 0px 5px 0px #46963f;
  box-shadow: 0px 5px 0px #46963f;
  text-shadow: 1px 1px 1px #46963f;
  display: inline-block;
}

/*==========  Red  ==========*/
.btn-push.red-b {
  background: #f44336;
  color: #fff;
  border: 1px solid #f22819;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 5px 0px #a01b1b;
  -moz-box-shadow: 0px 5px 0px #a01b1b;
  -ms-box-shadow: 0px 5px 0px #a01b1b;
  -o-box-shadow: 0px 5px 0px #a01b1b;
  box-shadow: 0px 5px 0px #a01b1b;
  text-shadow: 1px 1px 1px #a01b1b;
}

/*==========  Blue  ==========*/
.btn-push.blue {
  background: #2196f3;
  color: #fff;
  border: 1px solid #0d87e9;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 5px 0px #177c8e;
  -moz-box-shadow: 0px 5px 0px #177c8e;
  -ms-box-shadow: 0px 5px 0px #177c8e;
  -o-box-shadow: 0px 5px 0px #177c8e;
  box-shadow: 0px 5px 0px #177c8e;
  text-shadow: 1px 1px 1px #177c8e;
  display: inline-block;
}

/*==========  Orange  ==========*/
.btn-push.orange {
  background: #FF9800;
  color: #fff;
  border: 1px solid #e08600;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 5px 0px #bc4809;
  -moz-box-shadow: 0px 5px 0px #bc4809;
  -ms-box-shadow: 0px 5px 0px #bc4809;
  -o-box-shadow: 0px 5px 0px #bc4809;
  box-shadow: 0px 5px 0px #bc4809;
  text-shadow: 1px 1px 1px #bc4809;
}

.btn-sm {
  padding: 10px 25px;
}

.btn-ssm {
  padding: 10px 5px;
}

.btn-sss {
  padding: 5px 2px;
  font-size: 1.2em;
  margin: 0px 5px;
  display: inline-block;
  color: #666666;
}

.fav_button {
  display: table-cell;
  height: 54px;
  vertical-align: middle;
}

.detail_button {
  display: inline-block;
  padding: 15px;
  font-size: 1.1rem;
  font-weight: bold;
  text-decoration: none !important;
  border-radius: 3px;
  background-color: #f5f5f5;
  transition: all 0.3s;
  border: none;
  @include media_desktop_sp {
    font-size: 0.9rem;
    padding: 15px 10px;
  }
}

.s_btn_list {
  margin: 10px;
}

.s_btn_list .detail_button {
  border: 4px solid #2196F3;
  background: #F5F5F5 !important;
  color: #333 !important;
  transition: all 0.3s;
}

.s_btn_list .detail_button:hover {
  background: #EEEEEE !important;
}

.cart_button {
  background-color: #4caf50;
  border: 0px;
  color: #fafafa !important;
}

.contact_button {
  border: 1px solid #0D47A1;
  background: #E3F2FD;
  color: #0D47A1 !important;
}

.contact_button:hover {
  background: #0D47A1;
  color: #fafafa !important;
}

.contact_quick_button {
  border: 1px solid #E65100;
  background: #FFCDD2;
  color: #212121 !important;
}

.contact_quick_button:hover {
  background: #F44336;
  color: #fafafa !important;
}

.cart_x10_button {
  background-color: #43a047;
  color: #fafafa !important;
  width: 40%;
  text-align: center;
  margin: 10px 0px;
}

.gray_ripple {
  display: inline;
  a {
    text-decoration: none !important;
  }
}

.gray_ripple .legitRipple-ripple {
  background: #ddd;
}

.detail_etc_button {
  width: 45%;
  text-align: center;
  margin: 10px 0px;
  background-color: #f5f5f5;
  text-decoration: none !important;
  @include media_desktop_sp {
    width: calc(50% - 5px);
  }
}

.detail_etc_one_button {
  text-align: center;
  margin: 10px 0px;
  background-color: #f5f5f5;
}

.sidebar_button {
  width: 100%;
  padding: 15px 10px;
  text-align: center;
  margin: 5px 0px;
  border: 0px;
  background-color: #f5f5f5;
}

/* トップ画像の二列表示 */
.topimage-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: center;
  margin-bottom: 20px;
  width: 100%;
  .topimage-cell {
    width: 50%;
  }
}

.topimage-container a img {
  padding: 3px;
  transition: 0.3s;
  border: 1px solid #ccc;
  width: 100%;
  height: auto;
}

.topimage-container a:hover img {
  border: 1px solid #1E88E5;
  background: #1E88E5;
  opacity: 1.0;
  filter: alpha(opacity=100);
}

/* ==============================================
 フレーム
=============================================== */
/* 全体を包括 */
/*.frame_outer {
    margin: 0 auto;
    width: 100%;
    text-align: center;
}*/
.frame_outer {
  width: 100%;
  position: relative;
  height: auto !important;
  height: 100%;
  min-height: 100%;
  text-align: center;
  margin: 0 auto;
}

/* ラッパー */
#wrapper {
  margin: 0 auto;
  padding: 0;
}

/* コンテンツ */
#container {
}

/* ==============================================
 カラム指定
=============================================== */
/* ヘッダーとフッターの上下
[注意]ブロック移動時はbloc.css内で調整が必要 */

#bottomcolumn,
#footerbottomcolumn {
  margin: 0 auto;
  /*padding: 15px;*/
  width: 950px;
  max-width: 100%;
  /*background: #fff;*/
  text-align: left;
}
#topcolumn {
  margin: 10px auto 0;
  width: 980px;
  max-width: 100%;
  text-align: left;
  img {
    max-width: 100%;
  }
}

/* 中央カラム */
.main_column {
  padding: 10px 0 20px;
}

/* サイドカラム */
#leftcolumn {
  padding-right: 20px;
}

#rightcolumn {
  float: right;
  width: 20%;
}

/* 1カラム設定 */
#one_maincolumn {
  margin: 0 auto;
  width: 80%;
}

/* 2カラム設定 (メイン部が左) */
#two_maincolumn_left {
  padding-left: 1.5%;
  float: left;
  width: 70%;
}

/* 2カラム設定 (メイン部が右) */
#two_maincolumn_right {
  /*padding-right: 1.5%;
  width: 75.5%;
  float: right;*/
  padding-top: 0;
}

/* 3カラム設定 */
#three_maincolumn {
  padding-left: 0.5%;
  width: 59%;
  float: left;
}

/* 下層コンテンツ */
#undercolumn {
  width: 100%;
}

/* ==============================================
 ユーティリティ
=============================================== */
/* フロート回り込み解除
----------------------------------------------- */
.clearfix {
  display: block;
  /* for IE8 */
}

.clearfix:after {
  display: block;
  clear: both;
  height: 0px;
  line-height: 0px;
  visibility: hidden;
  content: ".";
}

.clear {
  clear: both;
}

/* リンク指定
----------------------------------------------- */
a:link,
a:visited {
  color: #666666;
}

a img:hover {
  opacity: 0.8;
  filter: alpha(opacity=80);
}

h2 img:hover {
  opacity: 0.8;
  filter: alpha(opacity=80);
}

/* フォント
----------------------------------------------- */
h1,
h2,
h3,
h4,
h5 {
  font-size: 100%;
  line-height: 150%;
}

.sale_price {
  color: #f00;
}

.normal_price {
  font-size: 90%;
}

.point {
  color: #f00;
  font-weight: bold;
}

.user_name {
  font-weight: bold;
}

.recommend_level {
  color: #ecbd00;
}

.attention {
  font-size: 1.2em;
  color: #f00;
}

.attention2 {
  font-size: 1.2em;
  color: #f00;
}

.attentionB {
  font-size: 1.2em;
  color: #2196f3;
}

.attentionSt {
  color: #f00;
  font-weight: bold;
}

.st,
.bold {
  font-weight: bold;
}

.mini {
  font-size: 90%;
}

/* 行揃え
----------------------------------------------- */
.alignC,
.alignC td {
  text-align: center;
}

.alignR {
  text-align: right;
}

.alignL {
  text-align: left;
}

.pricetd em {
  font-weight: bold;
}

/* フォーム
----------------------------------------------- */
.send-btn {
  margin: 0 auto;
  width: 27%;
}

.send-btn input {
  padding: 10px;
}

.box40 {
  width: 40px;
}

.box60 {
  width: 60px;
}

.box100 {
  width: 100px;
}

.box120 {
  width: 120px;
}

.box140 {
  width: 140px;
}

.box140s {
  width: 140px;
  height: 20px;
  float: left;
}

.box145 {
  width: 145px;
}

.box150 {
  width: 150px;
}

.box160 {
  width: 160px;
}

.box240 {
  width: 240px;
}

.box300 {
  width: 300px;
}

.box320 {
  width: 320px;
}

.box350 {
  width: 350px;
}

.box380 {
  width: 380px;
}

/* フォームが縦に重なり合う場合に併用する余白 */
.top {
  margin-bottom: 5px;
}

.top5 {
  margin-top: 0.5rem;
}

.ng_top {
  margin-bottom: 0 !important;
}

.box300,
.box120,
.box380 {
  border: 1px solid #777 !important;
  padding: 5px;
  color: #333;
  background: #eee;
  border-radius: 5px;
}

/* タイトル
----------------------------------------------- */
.h1_title {
  margin-bottom: 10px;
  color: #666;
  font-size: 16px;
  background: #fafafa !important;
  border: 1px solid #ccc !important;
  border-left: 15px solid #FF9800 !important;
  padding: 5px 10px !important;
  font-weight: bold;
}

h2.title {
  margin-bottom: 10px;
  padding: 8px;
  border-top: solid 1px #ebeced;
  color: #f60;
  background: #fafafa !important;
  border: 1px solid #ccc !important;
  border-left: 15px solid #FF9800 !important;
  padding: 5px 10px !important;
  font-size: 170%;
}

.h2_title {
  margin-bottom: 10px;
  color: #fff;
  font-size: 16px;
  background: #009688;
  border: 1px solid #ccc;
  border-left: 15px solid #FF9800;
  padding: 5px 10px;
  position: relative;
  margin-top: 10px;
  .rss_icon {
    position: absolute;
    top: 6px;
    right: 10px;
  }
}

h2.h2_title .category_h2text {
  font-size: 12px;
  text-align: right;
  float: right;
  margin-right: 10px;
}

.h2_subtitle {
  margin-bottom: 10px;
  /*padding: 4px 0 5px 20px;*/
  padding: 5px 10px;
  color: #333;
  border-left: 16px solid #FF9800;
  background: none repeat scroll 0% 0% #FFEDA9;
  font-size: 14px;
  font-weight: bold;
  width: 100%;
}

.h2_subtitle .category_h2text {
  font-size: 12px;
  text-align: right;
  float: right;
  margin-right: 10px;
}

.h3_subtitle {
  border-bottom: solid 2px #1E88E5;
  display: inline-block;
  padding-right: 1rem;
  margin: 0.5rem !important;
}

/*h2.h2_title span.page_title{
float:left;
}*/
#one_maincolumn .sub_area h3,
#two_maincolumn_right .sub_area h3,
#two_maincolumn_left .sub_area h3,
#three_maincolumn .sub_area h3,
#undercolumn_login .login_area h3,
#undercolumn_shopping h3,
#mypagecolumn h3,
#undercolumn_cart h3 {
  margin: 0 0 10px 0;
  padding: 5px 0 10px;
  color: #f60;
  background: url("../img/background/line_01.gif") repeat-x left bottom;
  font-size: 120%;
}

div#undercolumn_login .login_area h4 {
  padding-left: 15px;
  background: url("../img/icon/ico_arrow_05.gif") no-repeat left;
}


/* ==============================================
表示モード
=============================================== */
#displaymode {
  font-weight: bold;
  font-size: 18px;
  color: #FF9E3A;
}

#displaymode a {
  color: #FF9E3A;
}

#displaymode a:hover {
  color: #49B9E4;
}

input#submit_button {
  width: 95%;
  height: 40px;
  font-size: 20px;
  font-weight: bold;
}

/* ==============================================
 ヘッダー
=============================================== */
/* レイアウト
----------------------------------------------- */
#header_wrap {
  border-top: solid 3px #f90;
  min-height: 150px;
  background: url("../img/common/bg_header.gif") repeat-x bottom #fffaf0;
}

#header {
  clear: both;
  max-width: 980px;
  margin: 0 auto;
  @include media_desktop {
    display: flex;
    justify-content: space-between;
  }
  img {
    max-width: 100%;
    height: auto;
  }
}

#header #logo {
  max-width: 115px;
  margin-bottom: 20px;
  @include media_desktop {
    max-width: 200px;
    position: relative;
    padding: 0 0 10px 0;
    margin-bottom: 0;
  }
  h1 {
    margin-top: 0;
  }
  a {
    display: inline-block;
    @include media_desktop {
      width: 200px;
    }
    img {
      @include media_desktop {
        width: 200px;
        height: auto;
      }
    }
  }
}

#header_right {
  @include media_desktop {
    width: 770px;
  }
  h1 {
    font-weight: bold;
  }
}

#header p.description {
  margin: 0px;
  padding: 5px 0px 0px 0px;
  text-align: right;
  color: #333;
  font-family: "Hiragino Sans", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Meiryo", "BIZ UDPGothic", "MS Gothic", sans-serif;
  font-size: 11px;
  line-height: 16px;
}

#header .contact {

  @include media_desktop_max {
    display: none;
  }
  margin: 2px 0px 5px 0px;
  display: flex;
  &__item {
    padding: 10px;
    margin:14px 5px 8px 5px;border-radius:3px;box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.26);
    background-color:#f9f9f9;
    font-size: 99%;
    a {
      text-decoration: none;
      display: block;
      padding: 0 10px;
    }
  }
}

#header ul#header_system {
  list-style-type: none;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  float: left;
  height: 33px;
}

#header ul#header_system li {
  float: left;
  padding: 0px 0px 0px 10px;
}

#header ul#header_link {
  float: left;
  padding-top: 8px;
  list-style-type: none;
  margin: 0px;
  height: 51px;
}

#header ul#header_link li {
  float: left;
  padding: 0px 0px 0px 0px;
}

#header ul#header_guide {
  margin: 0px;
  padding: 0px 0px 0px 10px;
  height: 17px;
  float: left;
  list-style-type: none;
}

#header ul#header_guide li {
  /*background-image:url("../img/header/point01.jpg");*/
  color: #f90;
  background-repeat: no-repeat;
  background-position: left center;
  list-style-type: none;
  text-decoration: none;
  margin: 5px 6px 0px 0px;
  padding: 0px 0px 0px 17px;
  float: left;
  font-size: 12px;
  white-space: nowrap;
}

#header div#menu_area {

  @include media_desktop_max {
    display: none;
  }
  max-width: 770px;
  margin-top: 5px;
  display: flex;
  justify-content: flex-end;
}

#header div#list {
  width: 346px;
}

ul#global_navi {
  clear: both;
  margin: 0px;
  padding: 5px 0px 0px 0px;
  list-style-type: none;
}

ul#global_navi li {
  float: left;
}

ul#global_navi li img {
  vertical-align: bottom;
}

ul#navigation {
  clear: both;
  margin: 0px;
  padding: 0px 0px 0px 0px;
  list-style-type: none;
  width: 100%;
}

ul#navigation li {
  float: left;
}

ul#navigation li a:hover {
  border: none;
}

ul#navigation li img {
  vertical-align: bottom;
}

.head_nav2014 {
  font-size: 16px;
  font-weight: bold;
  font-family: "Hiragino Sans", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Meiryo", "BIZ UDPGothic", "MS Gothic", sans-serif;
}

#logo_area {
  padding-left: 10px;
  float: left;
  width: 390px;
  text-align: left;
}

#top_logo_area h1 {
  padding: 10px 10px 20px 10px;
  float: left;
}

.header_text {
  float: right;
  padding-top: 20px;
}

#header_utility {
  float: right;
  width: 580px;
}

#errorHeader {
  color: #F00;
  font-weight: bold;
  font-size: 12px;
  background-color: #FEB;
  text-align: center;
  padding: 5px;
}

#header_right2 {
  .top_text {
    font-size: 100%;
    line-height: 150%;
    font-weight: bold;
    padding: 5px 63px 0px 98px;
    margin: 5px 0;
    @include media_desktop {
      padding: 0 0 0 5px;
    }
  }
}



/* 追尾型の電話番号表示
----------------------------------------------- */
@media print {
  #header-fixed {
    display: none;
  }
}

#header-fixed {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100000;
  display: none;
  @include media_desktop_sp {
    display: none !important;
  }
}

/* ロゴ
----------------------------------------------- */
#site_description {
  font-size: 90%;
}

#logo_area h1 {
  width: 378px;
  height: 33px;
}

#logo_area h1 span {
  display: none;
  /* テキストロゴ非表示 */
}

/* ヘッダーナビ
----------------------------------------------- */
div#header_navi {
  float: right;
  width: 409px;
  height: 38px;
}

div#header_navi ul li {
  display: block;
  float: left;
}

div#header_navi ul li.mypage,
div#header_navi ul li.entry {
  margin-top: 6px;
}

/* ==============================================
 フッター
=============================================== */
#footer_wrap {
  width: 100%;
  clear: both;
  /*background-image:url(../img/footer/f_bg.jpg);*/
  background-color: #7B6F5F;
  border-top: 2px solid #333;
}

#footer {
  color: #fff;
  /*font-size:95%;
  width: 970px;
  margin: 0px auto;
  clear: both;*/
}

#footer ul {
  margin: 0px auto;
  padding: 10px;
  list-style-type: none;

  @include media_desktop {
    display: flex;
    flex-wrap: wrap;
  }
}

#footer ul li {
  padding-right: 10px;
}

#footer ul li a {
  color: #fff;
  font-weight: bold;
}

#footer ul li a:hover {
  color: #fff;
  font-weight: bold;
}

.go_pagetop {
  clear: both;
  text-align: right;
  padding-right: 15px;
  margin-bottom: -8px;
}

.link_pt {
  display: inline-block;
  width: 200px;
  height: 54px;
  text-align: center;
  text-decoration: none;
  line-height: 54px;
  outline: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all .3s;
  transition: all .3s;
  width: 12em;
  height: auto;
  margin: 0 auto;
  text-align: center;
  border-radius: 5px 5px 0px 0px;
  font-size: 12px;
}

.link_pt::before,
.link_pt::after {
  position: absolute;
  z-index: -1;
  display: block;
  content: '';
}

.link_pt::before,
.link_pt::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.link_pt a {
  color: #fff !important;
  font-weight: bold;
  text-decoration: none;
}

.link_pt_a {
  color: #fff !important;
  font-weight: bold;
  text-decoration: none;
}

.link_pt {
  position: relative;
  z-index: 2;
  background-color: #7B6F5F;
  border-left: 2px solid #333;
  border-right: 2px solid #333;
  border-top: 2px solid #333;
  color: #fff;
  line-height: 50px;
  overflow: hidden;
}

.link_pt:hover {
  color: #fff;
}

.link_pt::after {
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
}

.link_pt:hover::after {
  left: 0;
  background-color: #333;
}

.copyright {
  /*clear:both;*/
  margin: 0;
  padding: 0 0 15px 0;
  text-align: right;
}

/* ==============================================
 パーツ
=============================================== */
/* ボタン
----------------------------------------------- */
.btn_area {
  margin-top: 10px;
  width: 100%;
  text-align: center;
  ul {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      a {
        display: inline-block;
      }
    }
  }
}

.cart_btn_area {
  margin-top: 10px;
  width: 100%;
  text-align: center;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    @include media_desktop {
      display: flex;
      justify-content: center;
      flex-flow: row-reverse;
    }
    li {
      margin: 0 0 10px;
      @include media_desktop {
        margin: 0 10px;
      }
      a {
        display: inline-block;
      }
    }
  }
}

.btn_area_one {
  margin-top: 10px;
  width: 100%;
  text-align: center;
  li {
    display: inline-block;
  }
}

/* 完了メッセージ
----------------------------------------------- */
div#complete_area {
  margin-bottom: 20px;
}

div#complete_area .message {
  margin-bottom: 20px;
  line-height: 150%;
  font-weight: bold;
  font-size: 120%;
}

div#undercolumn_entry .message {
  margin-bottom: 20px;
  line-height: 150%;
  font-weight: bold;
  font-size: 120%;
}

div#complete_area .shop_information {
  margin-top: 20px;
  padding: 20px 0 0 0;
  border-top: solid 1px #ccc;
}

div#complete_area .shop_information .name {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 140%;
}

/* Tipsy
----------------------------------------------- */
.tipsy {
  padding: 5px;
  font-size: 10px;
  position: absolute;
  z-index: 100000;
}

.tipsy-inner {
  padding: 5px 8px 4px 8px;
  background-color: black;
  color: white;
  max-width: 200px;
  text-align: center;
  border-radius: 3px;
}

.tipsy-arrow {
  position: absolute;
  background: url("../img/ajax/tipsy.gif") no-repeat top left;
  width: 9px;
  height: 5px;
}

.tipsy-n .tipsy-arrow {
  top: 0;
  left: 50%;
  margin-left: -4px;
}

.tipsy-nw .tipsy-arrow {
  top: 0;
  left: 10px;
}

.tipsy-ne .tipsy-arrow {
  top: 0;
  right: 10px;
}

.tipsy-s .tipsy-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -4px;
  background-position: bottom left;
}

.tipsy-sw .tipsy-arrow {
  bottom: 0;
  left: 10px;
  background-position: bottom left;
}

.tipsy-se .tipsy-arrow {
  bottom: 0;
  right: 10px;
  background-position: bottom left;
}

.tipsy-e .tipsy-arrow {
  top: 50%;
  margin-top: -4px;
  right: 0;
  width: 5px;
  height: 9px;
  background-position: top right;
}

.tipsy-w .tipsy-arrow {
  top: 50%;
  margin-top: -4px;
  left: 0;
  width: 5px;
  height: 9px;
}

/* テーブル */
ul.ul_table {
  display: table;
  table-layout: fixed;
  width: 350px;
  padding: 3px;
}

ul.ul_table li {
  display: table-cell;
  vertical-align: middle;
  text-align: left;
  font-weight: bold;
}

ul.ul_table li:nth-of-type(odd) {
  width: 90px;
}

ul.ul_table li:nth-of-type(even) {
  width: 150px;
}

/*for IE6 */
* html ul.ul_table li,
*:first-child+html ul.ul_table li {
  display: inline;
  zoom: 1;
}

/*for IE7 */
/* ログイン */
.login_head {
  padding: 2px 5px 5px;
  margin: 0 auto;
  text-align: center;
  background-color: #FF9800;
  color: #fff;
  font-family: "Hiragino Sans", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Meiryo", "BIZ UDPGothic", "MS Gothic", sans-serif;
  font-size: 14px;
}

.login_b {
  width: 100px;
  margin: 0 auto;
  text-align: center;
  margin-top: 1px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #FF9800;
  color: #fff !important;
}

.login_b a:visited,
.login_b a:hover {
  color: #fff !important;
}

.ranking_num {
  background-color: #FF9800;
  color: #fff;
  vertical-align: middle;
  padding-left: 5px;
  vertical-align: middle;
  font-size: 25px;
  font-weight: bold;
  padding-right: 5px;
}

.nav_bg {
  background-color: #FF9800;
  width: 158px;
  border: 1px solid #ccc;
}

.nav_bg:hover {
  background-color: #FF9800;
  border: 1px solid #607d8b;
}

.nav_bg1 {
  border-radius: 2px 0px 0px 2px;
}

.nav_bgl {
  border-radius: 0px 2px 2px 0px;
}

/* ドロップダウンメニュー */
#dropmenu {
  list-style-type: none;
  width: 970px;
  height: 40px;
  margin: 30px auto 0px;
  padding: 0;
  background: #9e9e9e;
  border-bottom: 5px solid #bdbdbd;
  border-radius: 3px 3px 0 0;
  clear: both;
}

#dropmenu li {
  position: relative;
  width: 158px;
  float: left;
  margin: 0;
  padding: 0;
  text-align: center;
  border-right: 1px solid #bdbdbd;
}

#dropmenu li:last-child {
  border-right: 0px solid #bdbdbd;
}

#dropmenu li a {
  display: block;
  margin: 0;
  padding: 15px 0 11px;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  text-decoration: none;
}

#dropmenu li ul {
  list-style: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 999;
  margin: 0;
  padding: 0;
  border-radius: 0 0 3px 3px;
}

#dropmenu li:last-child ul {
  left: -100%;
  width: 100%;
}

#dropmenu li ul.nav_br_r {
  float: right;
}

#dropmenu li ul.nav_br_l {
  float: left;
  margin-left: 200px;
}

#dropmenu li ul li {
  overflow: hidden;
  width: 220px;
  height: 0;
  color: #fff;
  -moz-transition: .2s;
  -webkit-transition: .2s;
  -o-transition: .2s;
  -ms-transition: .2s;
  transition: .2s;
}

#dropmenu li ul.nav_br_r li,
#dropmenu li ul.nav_br_l li {
  width: 200px;
}

#dropmenu li ul li a {
  padding: 13px 15px;
  background: #5E5A5A;
  text-align: left;
  font-size: 12px;
  font-weight: normal;
}

#dropmenu li:hover>a {
  background: #fb8c00;
  color: #fff;
}

#dropmenu li:hover ul li {
  overflow: visible;
  height: 38px;
  border-top: 1px solid #767474;
  border-bottom: 1px solid #767474;
}

#dropmenu li:hover ul li:first-child {
  border-top: 0;
}

#dropmenu li:hover ul li:last-child {
  border-bottom: 0;
}

#dropmenu li:hover ul li:last-child a {
  border-radius: 0 0 3px 3px;
}

#dropmenu li:hover ul.nav_br_r li:last-child a {
  border-radius: 0 0 0 3px;
}

#dropmenu li:hover ul.nav_br_l li:last-child a {
  border-radius: 0 0 3px 0;
}

.dropmenu_abt {
  background: #FF9800 !important;
  border-bottom: 5px solid #e08600 !important;
}

.dropmenu_abt li {
  border-right: 1px solid #bdbdbd;
}

.dropmenu_abt li:last-child {
  border-right: 0px solid #bdbdbd;
}

.dropmenu_abt li a {
  color: #fff;
}

.dropmenu_abt li ul li {
  color: #fff;
}

.dropmenu_abt li ul li a {
  background: #5E5A5A;
}

.dropmenu_abt li:hover>a {
  background: #ffa41f !important;
  color: #fff;
}

.dropmenu_abt li:hover ul li {
  border-top: 1px solid #767474;
  border-bottom: 1px solid #767474;
}

#srchBox {
  width: 200px;
  _width: 146px;
  margin-bottom: 10px;
  background-color: #FFFFFF;
  border-style: solid;
  border-width: 1px;
  border-color: #EEEEEE;
  color: #000000;
  text-align: center;
}

#srchBox * {
  margin: 0;
  padding: 0;
  font-size: 13px;
  *font-size: small;
  *font: x-small;
}

#srchBox a img {
  border: none;
}

#srchBox #srch {
  padding: 6px;
}

#srchBox #srchInput {
  width: 98%;
  _width: 130px;
}

#srchBox #srchBtn {
  width: 115px;
}

*:first-child+html #srchBox #srchBtn {
  padding-top: 2px;
}

#srchBox ul {
  margin-top: 6px;
  margin-bottom: 6px;
  text-align: left;
}

#srchBox li {
  list-style-type: none;
  display: block;
}

#srchBox li input {
  zoom: 1;
  margin-right: 2px;
  _margin: -4px 0 -4px -4px;
  vertical-align: middle;
  border: 0;
}

#srchBox #srchLogo {
  margin: 6px 6px 6px 0;
  text-align: center;
}

#srchBox #srchLogo a {
  color: #666666;
  text-decoration: none;
  font-size: 85%;
}

#srchBox #srchLogo a:hover {
  text-decoration: underline;
}

.items_cartblock {
  margin: 0 auto;
  margin-bottom: 3px;
}

.items_cartblock li:nth-child(odd) {
  width: 50px;
  display: inline-block;
  vertical-align: middle;
}

.items_cartblock li:nth-child(even) {
  width: 130px;
  display: inline-block;
  vertical-align: middle;
}

.items_cartblockin {
  border-bottom: 1px solid #9e9e9e;
}

/* fort.js */
.top_fort {
  /*background: #009dff;*/
  background: linear-gradient(to right, #009dff 0, #00c8ff 100%);
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  height: 18px;
  transition: all 1s;
  width: 0;
  overflow: hidden;
}

.colors {
  width: 100%;
  height: 4px;
}

.prog_tr {
  position: fixed;
  top: 0px;
  right: 0px;
  color: #FAFAFA;
  z-index: 1032;
  display: none;
}

.prog_tm1 {
  position: fixed;
  top: 0px;
  right: 75%;
  color: #FAFAFA;
  z-index: 1032;
  display: none;
}

.prog_tm {
  position: fixed;
  top: 0px;
  right: 50%;
  color: #FAFAFA;
  z-index: 1032;
  display: none;
}

.prog_tm2 {
  position: fixed;
  top: 0px;
  right: 25%;
  color: #FAFAFA;
  z-index: 1032;
  display: none;
}

.prog_tl {
  position: fixed;
  top: 0px;
  left: 0px;
  color: #FAFAFA;
  z-index: 1032;
  display: none;
}

#mauticform_input__submit {
  display: none;
}

/************************************************
 ブロック用
************************************************ */
/*** 目次 ***

▼ブロック共通
リスト
タイトル
ヘッダー上、フッター下のブロックエリア

▼各機能ブロックの指定
-新着情報
-現在のカゴの中
-カテゴリー
-ガイドリンク
-ログイン（サイド用）
-検索
-カレンダー
-おすすめ商品
    *メインカラム用 1カラム時 [one_maincolumn]
    *メインカラム用 2カラム時 [two_maincolumn_left]
                              [two_maincolumn_right]
    *メインカラム用 3カラム時 [three_maincolumn]
    *サイドカラム用           [side_column]
    *商品詳細のオススメ商品   [whobought_area]
 */
/* ==============================================
ブロック共通
/* ============================================= */
#container .bloc_body {
  background-color: #fff;
}

#container .login_body {
  background-color: #ccc;
}

.side_column {
  /*overflow-x: hidden;*/
  /* IE6 表示乱れ防止 */
}

.ec-layoutRole__left {
  margin-top: 10px;
  .bloc_outer {
    margin: 10px 0 10px 0;
    clear: both;
    img {
      max-width: 100%;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }
  .bloc_body {
    border: solid 1px #ccc;
    border-top: none;
    .box {
      border: solid 1px #ccc;
      width: 145px;
    }
  }
}
.ec-layoutRole__mainWithColumn {
  .bloc_body {
    border: solid 1px #ccc;
    border-top: none;
  }
  .bloc_outer {
    margin-bottom: 20px;
  }
}

/* リスト
----------------------------------------------- */
/* ログイン 検索条件 */
#container .bloc_outer .bloc_body dl.formlist {
  margin-bottom: 8px;
}

#container .bloc_outer .bloc_body dl.formlist dd {
  margin-bottom: 5px;
}

#container .bloc_outer .bloc_body dl.formlist dt {
  margin-bottom: 3px;
}

#container .bloc_outer .bloc_body dl.formlist span {
  vertical-align: top;
}

/* タイトル
----------------------------------------------- */
/* タイトルの背景 白 */
#login_area h2,
#search_area h2,
#calender_area h2,
#cart_area h2,
#cart h2 {
  padding: 5px 0 8px 10px;
  border-style: solid;
  /*border-color: $orange #ccc #ccc;
  border-width: 1px 1px 0;*/
  border-top: 1px solid #FF9800;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 2px solid #eee;
  /*background: url('../img/background/bg_tit_bloc_01.jpg') repeat-x left bottom;*/
  background-color: #f5f5f5;
  font-size: 14px;
}

.cart_head {
  border: 2px solid #FF8D21;
  border-left: 15px solid #FF8D21;
  font-size: 16px;
  color: #FF8D21;
  padding: 5px;
}

#category_area h2 {
  border-top: solid 1px #FF9800;
}

/* ==============================================
▼ヘッダー上、フッター下のブロックエリア
=============================================== */
div#bottomcolumn {
  margin: 0px;
  clear: both;
}

/* ***********************************************
▼各機能ブロックの指定
/*********************************************** */
/* ===============================================
▼新着情報
=============================================== */
#news_area {
  .bloc_body {
    padding: 10px;
  }
  .news_list {
    padding: 10px 10px 10px 10px;
    max-height: 12rem;
    height: auto;
    border: 2px solid #EEEEEE;
    border-radius: 0.5rem;
    font-size: 1rem;
    overflow: auto;
    overflow-y: scroll;
  }
  dl.news {
    margin-bottom: 0;
    dt {
      margin-bottom: 5px;
      padding: 0.5rem;
      border: 1px solid #ccc;
      border-radius: 0.5rem;
      font-weight: normal;
    }
    dd {
      margin-bottom: 10px;
      padding-bottom: 10px;
    }
    a {
      color: #1E88E5;
      &:visited {
        color: #c58af9 !important;
      }
      &:hover {
        color: #1976D2;
      }
    }
  }
}

/* ===============================================
▼現在のカゴの中
=============================================== */
#cart_area .information,
#cart_area2 .information {
  padding: 10px;
}

#cart_area .postage,
#cart_area2 .postage {
  margin-top: 10px;
  padding-top: 10px;
  /*background: url("../img/background/line_dot_01.gif") repeat-x top;*/
}

#cart_area .postage .point_announce,
#cart_area2 .postage .point_announce {
  padding: 2px 0 2px 20px;
  background: url("../img/icon/ico_price.gif") no-repeat left top;
}

#cart_area .btn,
#cart_area2 .btn {
  /*padding: 10px 0;
    background: url("../img/background/line_dot_01.gif") repeat-x top #f7f7e6;
    height: 2.5em;*/
  text-align: center;
  background-color: #F6CD74;
  border-top: 2px dotted #eee;
  white-space: normal;
}

/* ===============================================
▼カテゴリー
=============================================== */
#category_area .bloc_body {
  background-color: #fffaf0;
}

#category_area li {
  padding-left: 5px;
}

#category_area li.level1 {
  border-bottom: solid 1px #ccc;
}

#category_area li.level1 p {
  padding-left: 20px;
  margin: 7px 3px;
  background: url("../img/icon/ico_arrow_01.gif") 2px 3px no-repeat;
}

#category_area li.level1 li p {
  background: url("../img/icon/ico_level.gif") 7px 7px no-repeat;
}

#category_area li a {
  display: block;
  padding: 0;
}

a.onlink:link {
  color: #f00;
  text-decoration: underline;
}

a.onlink:visited,
a.onlink:hover {
  color: #f00;
}

#category_title {
  padding-top: 10px;
}

#category_title ul#login_guide li {
  /*background-image:url("../img/header/point01.jpg");*/
  font-size: 12px;
  color: #FF9800;
  background-repeat: no-repeat;
  background-position: left center;
  list-style-type: none;
  text-decoration: underline;
  float: left;
}

#category_title .categorytext {
  font-size: 12px;
  padding-left: 20px;
}

#category_title .cate {
  /*background-image:url("../img/header/point01.jpg");
    background-repeat: no-repeat;
    background-position: left center;*/
  margin-left: 20px;
  padding-left: 15px;
  font-size: 14px;
  color: #FF9800;
}

#category_title .cate:before {
  content: "\f144";
  font-family: "Font Awesome 5 Free";
}

#category_title .cate a {
  color: #FF9800;
  text-decoration: underline;
}

#category_title .cate_li {
  margin: 5px 0 5px 5px;
  font-size: 13px;
}

#category_title .cate_li a {
  font-family: "Hiragino Sans", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Meiryo", "BIZ UDPGothic", "MS Gothic", sans-serif;
  color: #333;
}

#category_title .cate_li a:link {
  text-decoration: none;
}

#category_title .cate_li a:hover {
  color: #FF9800;
}

.forex_blk {
  position: relative;
  margin: 1em 0px 0.5em;
  width: 100%;
  min-height: 5.5em;
  overflow: hidden;
  padding: 0.5em;
  font-size: 1rem;
  color: #000;
  border-radius: 3px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.26);
  z-index: 1;
}

.forex_blk span {
  display: block;
  position: absolute;
  bottom: 0.1em;
  right: 0;
  z-index: -1;
  font-size: 250%;
  color: #ffecb3;
}

/* ===============================================
▼カテゴリ
=============================================== */
div#category_title .categorypadding {
  padding-top: 10px;
  padding-bottom: 10px;
  ul.sub {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

div#category_title .categorypadding p {
  padding-bottom: 10px;
}

div#category_title .categorypadding input {
  padding-bottom: 0px;
}

/* ===============================================
▼ガイドリンク
=============================================== */
#guide_area {
  margin-top: 1em;
  border: none;
}

#guide_area li {
  margin-bottom: 5px;
  letter-spacing: -0.05em;
}

ul.button_like li {
  margin: 0;
  padding: 0 0 1px 0;
  background: url("../img/background/bg_btn_list.jpg") bottom repeat-x;
}

ul.button_like li a {
  margin: 0;
  padding: 10px 15px 10px 10px;
  border: 1px solid;
  border-bottom: none;
  border-color: #ccc;
  display: block;
  background: url("../img/icon/ico_arrow_02.gif") no-repeat right;
  text-decoration: none;
  outline: none;
}

/* ===============================================
▼ログイン（サイド用）
※ヘッダー用はbloc_alpha.css内に記述
=============================================== */
.bloc_outer div#login_area .bloc_body {
  background-color: #FDFDBD;
  /*padding: 1px 1px 10px 1px;
    border: solid 1px $orange;*/
}

.bloc_outer div#login_area .bloc_body p {
  margin-bottom: 5px;
}

.bloc_outer div#login_area .bloc_body .btn {
  text-align: center;
}

.bloc_outer #login_area .text {
  padding: 10px 10px 0 10px;
}

.bloc_outer .login_area dl.formlist {
  margin-bottom: 10px;
  margin: 0 auto;
  width: 500px;
}

.bloc_outer .login_area dl.formlist dt {
  margin-bottom: 3px;
  padding-left: 15px;
  color: #333;
  background: url("../img/icon/ico_arrow_03.gif") no-repeat left;
  width: 120px;
  float: left;
  font-size: 90%;
}

.bloc_outer .login_area dl.formlist dd {
  margin-bottom: 5px;
  float: right;
  width: 360px;
  vertical-align: bottom;
  text-align: left;
}

.bloc_outer div#login_area .bloc_body .mini {
  margin-top: 5px;
  letter-spacing: -0.01em;
}

.bloc_outer ul#login_guide {
  clear: both;
}

.bloc_outer ul#login_guide li {
  /*background-image:url("../img/header/point01.jpg");
      background-repeat:no-repeat;
      background-position:left center;*/
  font-size: 12px;
  color: #FF9800;
  list-style-type: none;
  text-decoration: underline;
  float: left;
  clear: both;
  /* IE7用 */
  white-space: nowrap;
}

.bloc_outerul #login_guide li a {
  padding-left: 5px;
  /* IE7用 */
  display: inline-block;
}

.mb-40 {
  margin-bottom: 40px;
}

/* ===============================================
▼検索
=============================================== */
div#search_area .bloc_body {
  padding: 10px;
}

div#search_area .bloc_body .btn {
  text-align: center;
}

/* ===============================================
▼お買い上げランキング
=============================================== */
#ranking {
  width: 740px;
  height: 155px;
  padding: 0 0 0 0;
  border-style: none;
}

#ranking td {
  border-style: none;
}

#ranking .rank {
  padding: 1px 0px 0px 1px;
  border-top: solid 1px #ccc;
  border-left: solid 1px #ccc;
  width: 30px;
}

#ranking .name {
  font-weight: bold;
  border-top: solid 1px #ccc;
  border-right: solid 1px #ccc;
  overflow: hidden;
  padding: 5px;
  width: 95px;
}

#ranking .space {
  width: 10px;
  padding: 0px;
}

#ranking .rankimage {
  border-right: solid 1px #ccc;
  border-left: solid 1px #ccc;
  text-align: center;
  vertical-align: middle;
  overflow: hidden;
  padding: 5px;
  width: 125px;
}

#ranking .rankimage img {
  width: 100px;
}

#ranking .detail {
  border-bottom: solid 1px #ccc;
  border-right: solid 1px #ccc;
  border-left: solid 1px #ccc;
  text-align: center;
}

#ranking .detail a {
  color: #FF9800;
  font-size: 14px;
}

#ranking .detail a:before {
  content: "\f144";
  font-family: "Font Awesome 5 Free";
}

.product_rcheck_area {
  .rcheck {
    display: flex;
    .rcheck_box {
      width: 140px;
      margin: 0px 5px 10px 0px;
      padding: 0 0 0 0;
      transition: all 250ms;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      &:hover {
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
        transform: translateY(-5px);
        cursor: pointer;
      }
      .name {
        color: #fff;
        font-weight: bold;
        padding: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        a {
          color: #fff;
        }
      }
      .rankimage {
        text-align: center;
        vertical-align: middle;
        overflow: hidden;
        padding: 5px;
        width: 128px;
        min-height: 114px;
        margin: auto;
        img {
          width: 100px;
          height: 100px;
        }
      }
      .detail {
        text-align: center;
        a {
          color: #FF9800;
          font-size: 14px;
          text-decoration: underline;
          &:before {
            font-family: "Font Awesome 5 Free";
            content: "\f144";
          }
        }
      }
    }
  }
}

#product_rcome_area {
  .rcome {
    display: flex;
    .rcome_box {
      width: 140px;
      margin: 0px 5px 10px 0px;
      padding: 0 0 0 0;
      transition: all 250ms;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      &:hover {
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
        transform: translateY(-5px);
        cursor: pointer;
      }
      .name {
        color: #fff;
        font-weight: bold;
        padding: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        a {
          color: #fff;
        }
      }
      .rankimage {
        text-align: center;
        vertical-align: middle;
        overflow: hidden;
        padding: 5px;
        width: 128px;
        min-height: 114px;
        margin: auto;
        img {
          height: 100px;
        }
      }
      .detail {
        text-align: center;
        a {
          color: #FF9800;
          font-size: 14px;
          &:before {
            content: "\f144";
            font-family: "Font Awesome 5 Free";
          }
        }
      }
    }
  }
}

/* ===============================================
▼バナー
=============================================== */
.bana_area {
  text-align: center;
}

.bana_area2 {
  text-align: center;
  padding-bottom: 10px;
}

.bana_area2nd img {
  max-width: 200px;
  text-align: center;
  padding-bottom: 10px;
}

.bana_area3 {
  text-align: center;
  border: solid 1px #ccc;
}

.bana_area3 p {
  font-size: 12px;
  color: #666666;
  text-align: left;
  padding: 5px 5px 5px 5px;
  line-height: 105%;
}

/* ===============================================
▼カレンダー
=============================================== */
#calender_area {
  background-color: transparent;
  border: none;
}

#calender_area .bloc_body {
  padding: 10px 0;
  background-color: #f1f9fc;
}

#calender_area table {
  background: #fff;
  border: none;
  width: 150px;
  margin: 0 auto 5px;
  font-size: 90%;
}

#calender_area table td {
  padding: 1px 3px;
  border-top: 1px solid #ccc;
  border-right: none;
  text-align: center;
}

#calender_area th {
  padding: 1px 3px;
  background: #fff;
  border: none;
  text-align: center;
}

#calender_area table .month {
  margin-bottom: 5px;
  padding-left: 12px;
  background: url("../img/icon/ico_arrow_04.gif") no-repeat left;
  font-size: 120%;
}

#calender_area .off {
  color: #f00;
}

#calender_area .information {
  margin-left: 10px;
  font-size: 90%;
}

/* ===============================================
▼カテゴリヘッダ
=============================================== */
.y_headerlist {
  clear: both;
  height: 190px;
}

.y_headerlist a {
  float: left;
  margin: 10px 10px 0 0;
}

.y_headerlist a img:hover {
  opacity: 0.8;
  filter: alpha(opacity=80);
}

.y_headerlist2 {
  clear: both;
}

.y_headerlist2 a {
  float: left;
  margin: 10px 10px 0 0;
}

.y_headerlist2 a img:hover {
  opacity: 0.8;
  filter: alpha(opacity=80);
}

.b_headerlist {
  clear: both;
  height: 120px;
}

.b_headerlist a {
  float: left;
  margin: 8px 8px 0 0;
}

.b_headerlist a img:hover {
  opacity: 0.8;
  filter: alpha(opacity=80);
}

.t_headerlist {
  clear: both;
  height: 190px;
}

.t_headerlist a {
  float: left;
  margin: 10px 12px 0 0;
}

.t_headerlist a img:hover {
  opacity: 0.8;
  filter: alpha(opacity=80);
}

.t_headerlist2 {
  clear: both;
}

.t_headerlist2 a {
  float: left;
  margin: 10px 12px 0 0;
}

.t_headerlist2 a img:hover {
  opacity: 0.8;
  filter: alpha(opacity=80);
}

.i_headerlist {
  clear: both;
  height: 310px;
}

.i_headerlist a {
  float: left;
  margin: 10px 12px 0 0;
}

.i_headerlist a img:hover {
  opacity: 0.8;
  filter: alpha(opacity=80);
}

.i_headerlist2 {
  clear: both;
}

.i_headerlist2 a {
  float: left;
  margin: 10px 12px 0 0;
}

.i_headerlist2 a img:hover {
  opacity: 0.8;
  filter: alpha(opacity=80);
}

.f_headerlist {
  clear: both;
  height: 20px;
}

.mr-5 {
  margin-right: 5px;
}

/* ===============================================
▼おすすめ商品
=============================================== */
/*
   tplファイルのマークアップが同じ項目
   メインカラム用 2カラム時 [two_maincolumn_left]
                            [two_maincolumn_right]
   メインカラム用 3カラム時 [three_maincolumn]
   サイドカラム用           [side_column]
   商品詳細のオススメ商品   [whobought_area]
=============================================== */
/* 共通
----------------------------------------------- */
#recommend_area .bloc_body,
#whobought_area .whobought_bloc {
  margin-bottom: 10px;
  padding: 10px 0 10px;
  border: none;
  /*background: url("../img/background/line_dot_01.gif") repeat-x bottom;*/
  background: url(data:image/gif;base64,R0lGODlhBAABAIAAAP///8zMzCH5BAAHAP8ALAAAAAAEAAEAAAIDBBIFADs=) repeat-x bottom;
}

#recommend_area .bloc_body p,
#whobought_area .whobought_bloc p {
  margin: 0 0 5px 0;
}

#recommend_area .bloc_body img,
#whobought .whobought_bloc img {
  margin: 0 5px 0 0;
}

#recommend_area .bloc_body h3,
#whobought_area .whobought_bloc h3 {
  font-size: 100%;
  font-weight: normal;
}

/* サイドカラム用 */
.side_column #recommend_area .product_item {
  margin-bottom: 10px;
}

/* 画像
----------------------------------------------- */
/* メインカラム用 */
.main_column #recommend_area .bloc_body .productImage,
#whobought_area .whobought_bloc .productImage {
  margin-bottom: 10px;
  float: left;
  width: 90px;
}

/* サイドカラム用 */
.side_column #recommend_area .bloc_body .productImage {
  float: none;
  text-align: center;
  width: auto;
}

/* 左右の振り分け
----------------------------------------------- */
.main_column #recommend_area .product_item,
#whobought_area .whobought_bloc .whobought_left {
  float: left;
  width: 47.5%;
}

.product_outer {
  margin: 15px 0 20px 0;
}

.product_outer #product_area .product_list {
  float: left;
  width: 19%;
  border: solid 1px #cccccc;
  margin: 10px 5px 0 0;
  height: 155px;
  overflow: hidden;
}

.product_outer #product_area .product_list img {
  float: left;
}

.product_outer #product_area .product_list .list a {
  /*background-image:url("../img/header/point01.jpg");
      background-repeat:no-repeat;
      background-position:left center;*/
  font-size: 10px;
  color: #FF9800;
  list-style-type: none;
  float: left;
  margin-left: 10px;
  padding: 5px 0 5px 15px;
}

.product_outer #product_area .product_list .list a:before {
  content: "\f144";
  font-family: "Font Awesome 5 Free";
}

.product_outer #product_area .product_list .product_image {
  margin: 0px 10px 0px 20px;
}

.product_outer #product_area .product_list .product_name {
  font-weight: bold;
  padding: 5px 10px 10px 35px;
  color: #FF9800;
}

#whobought_area .whobought_bloc .whobought_right {
  float: right;
  width: 47.5%;
}

/* 商品説明テキスト
----------------------------------------------- */
/* 前回購入*/
.bought_box {
  margin: 5px;
  padding: 10px;
  border: 1px solid #bbd3de;
  border-radius: 4px;
  background-color: #f6f6f6;
}

.bought_box_icon {
  float: left;
  width: 30px;
  height: 30px;
}

.bought_box_date {
  margin-left: 50px;
  font-size: 1.2em;
}

/* メインカラム用 1カラム時*/
#one_maincolumn #recommend_area .bloc_body .productContents,
#two_maincolumn_right #recommend_area .bloc_body .productContents,
#two_maincolumn_left #recommend_area .bloc_body .productContents,
#two_maincolumn_right #whobought_area .whobought_bloc .productContents,
#two_maincolumn_left #whobought_area .whobought_bloc .productContents {
  float: right;
  width: 200px;
}

/* メインカラム用 2カラム時*/
/* メインカラム用 3カラム時*/
#three_maincolumn #recommend_area .bloc_body .productContents,
#three_maincolumn #whobought_area .whobought_bloc .productContents {
  float: right;
  width: 67%;
}

/* サイドカラム用 */
.side_column #recommend_area .bloc_body .productContents {
  clear: both;
}

.mente {
  border: 4px solid #FF0000;
  padding: 10px;
  background-color: #FDFDBD;
}

.mente_title {
  font-weight: bold;
  font-size: 16px;
}

/* トップページ　お問い合わせ*/
.topimage_2 {
  padding-top: 10px;
  font-size: 1.4em;
  line-height: 1.3;
  color: #333;
  img {
    max-width: 100%;
  }
}

.contact_infop {
  border-left: 10px solid #FF9800;
  border-bottom: 2px solid #FF9800;
  padding: 5px;
  margin-bottom: 5px;
}

.contact_infop strong {
  margin-left: 5px;
  font-size: 1.3em;
}

/* 初めての方へ */
.newcomer {
  border: 1px solid #333;
  font-size: 1.5em;
  padding: 25px;
  -webkit-background-size: 30px 30px;
  -moz-background-size: 30px 30px;
  background-size: 30px 30px;
  background-color: #fff;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 158, 67, 0.5)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 158, 67, 0.5)), color-stop(0.75, rgba(255, 158, 67, 0.5)), color-stop(0.75, transparent), to(transparent)), -webkit-gradient(linear, 0 0, 100% 100%, color-stop(0.25, rgba(255, 158, 67, 0.5)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 158, 67, 0.5)), color-stop(0.75, rgba(255, 158, 67, 0.5)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 158, 67, 0.5) 25%, transparent 25%, transparent 50%, rgba(255, 158, 67, 0.5) 50%, rgba(255, 158, 67, 0.5) 75%, transparent 75%, transparent), -webkit-linear-gradient(-45deg, rgba(255, 158, 67, 0.5) 25%, transparent 25%, transparent 50%, rgba(255, 158, 67, 0.5) 50%, rgba(255, 158, 67, 0.5) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 158, 67, 0.5) 25%, transparent 25%, transparent 50%, rgba(255, 158, 67, 0.5) 50%, rgba(255, 158, 67, 0.5) 75%, transparent 75%, transparent), -moz-linear-gradient(-45deg, rgba(255, 158, 67, 0.5) 25%, transparent 25%, transparent 50%, rgba(255, 158, 67, 0.5) 50%, rgba(255, 158, 67, 0.5) 75%, transparent 75%, transparent);
  background-image: -ms-linear-gradient(45deg, rgba(255, 158, 67, 0.5) 25%, transparent 25%, transparent 50%, rgba(255, 158, 67, 0.5) 50%, rgba(255, 158, 67, 0.5) 75%, transparent 75%, transparent), -ms-linear-gradient(-45deg, rgba(255, 158, 67, 0.5) 25%, transparent 25%, transparent 50%, rgba(255, 158, 67, 0.5) 50%, rgba(255, 158, 67, 0.5) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 158, 67, 0.5) 25%, transparent 25%, transparent 50%, rgba(255, 158, 67, 0.5) 50%, rgba(255, 158, 67, 0.5) 75%, transparent 75%, transparent), -o-linear-gradient(-45deg, rgba(255, 158, 67, 0.5) 25%, transparent 25%, transparent 50%, rgba(255, 158, 67, 0.5) 50%, rgba(255, 158, 67, 0.5) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 158, 67, 0.5) 25%, transparent 25%, transparent 50%, rgba(255, 158, 67, 0.5) 50%, rgba(255, 158, 67, 0.5) 75%, transparent 75%, transparent), linear-gradient(-45deg, rgba(255, 158, 67, 0.5) 25%, transparent 25%, transparent 50%, rgba(255, 158, 67, 0.5) 50%, rgba(255, 158, 67, 0.5) 75%, transparent 75%, transparent);
}

.newc_text {
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  border-radius: 10px;
}

/* ヘッダーお問い合わせ */
.contact_n {
  margin-bottom: 5px;
  height: 20px;
}

.header_mailc {
  height: 20px;
  float: left;
  padding: 10px;
  border: 1px solid #333;
  font-size: 14px;
  background-color: #FF9800;
}

.header_mailc a {
  color: #fff;
  line-height: 20px;
}

.header_phonec {
  height: 20px;
  width: 730px;
  padding: 10px;
  border: 1px solid #333;
  line-height: 20px;
}

.phonec_text {
  font-size: 14px;
  float: left;
  margin-top: -7px;
  padding-left: 5px;
}

/************************************************
 インヘッダーブロック
************************************************ */
#headerInternalColumn {
  margin-top: 5px;
  float: right;
  width: 520px;
  height: 35px;
}

/* ログイン（ヘッダー用）
----------------------------------------------- */
#header_login_area {
  padding: 0 10px;
  border: solid 1px #ffc979;
  height: 30px;
  background: #fef3d3;
  letter-spacing: -0.075em;
}

#header_login_area ul.formlist {
  margin-top: 5px;
}

#header_login_area ul.formlist li {
  float: left;
}

#header_login_area ul.formlist li.mail {
  padding-left: 28px;
  width: 155px;
  background: url("../img/common/ico_arrow_login.gif") no-repeat left;
  font-size: 90%;
}

#header_login_area ul.formlist li.password {
  padding-right: 5px;
}

#header_login_area ul.formlist li.login_memory {
  padding-right: 5px;
  font-size: 90%;
}

#header_login_area ul.formlist li.forgot {
  margin-top: 3px;
  padding-right: 5px;
  font-size: 90%;
}

#header_login_area ul.formlist li.btn {
  padding-right: 5px;
  width: 53px;
}

#header_login_area p.btn {
  height: 20px;
  padding: 5px 0;
  vertical-align: middle;
}

#header_login_area p.btn input[type=image] {
  vertical-align: middle;
}

/* ***********************************************
追加ブロック
************************************************ */
/* 共通
----------------------------------------------- */
#container .bloc_outer #banner_area .bloc_body {
  border: none;
}

/* 【メイン】バナーエリア_02
----------------------------------------------- */
.main_column .bloc_outer #banner_area .bloc_body ul {
  width: 100%;
}

.main_column .bloc_outer #banner_area .bloc_body ul li {
  float: left;
}

.main_column .bloc_outer #banner_area .bloc_body ul li.sub_01 {
  padding-right: 8px;
}

/* 【サイド】バナーエリア_01
----------------------------------------------- */
/* 【サイド】バナーエリア_02
----------------------------------------------- */
#leftcolumn .bloc_outer #banner_area .bloc_body ul li,
#rightcolumn .bloc_outer #banner_area .bloc_body ul li {
  margin-bottom: 8px;
}

/************************************************
 ポップアップウィンドウ
************************************************ */
/* 共通
----------------------------------------------- */
div#windowcolumn {
  border-top: solid 3px #FF9800;
  width: 560px;
  height: 100%;
  margin: 15px 15px 0 15px;
  background: #fff;
}

div#windowcolumn h2 {
  margin-bottom: 10px;
  padding: 8px;
  border-top: solid 1px #ebeced;
  color: #f60;
  background: url("../img/background/bg_tit_sub_01.jpg") repeat-x left bottom;
  background-color: #fef3d8;
  font-size: 170%;
}

div#window_area {
  margin: 15px auto 0 auto;
  padding-bottom: 20px;
  width: 540px;
  min-height: 300px;
  height: auto !important;
}

div#window_area p.information {
  margin-bottom: 20px;
}

div#window_area .message {
  margin-bottom: 20px;
  color: #f60;
  line-height: 150%;
  font-weight: bold;
  font-size: 140%;
}

div#window_area table {
  width: 540px;
}

div#window_area #forgot {
  margin: 0 auto;
  padding: 20px;
  width: 440px;
  border: 1px solid #ccc;
  text-align: left;
}

div#window_area #forgot .mailaddres {
  margin-bottom: 10px;
}

div#window_area #forgot p {
  text-align: center;
}

div#bigimage,
div#cartimage {
  margin-top: 15px;
  background-color: #fff;
  text-align: center;
}

div#bigimage img,
div#cartimage img {
  padding: 10px;
  background-color: #fff;
}

div#zipsearchcolumn {
  margin: 15px auto 0 auto;
  border-top: 5px solid #ffa85c;
  border-bottom: 5px solid #ffa85c;
  width: 460px;
  background-color: #fff;
}

div#zipsearchcolumn h2 {
  margin: 0 0 15px 0;
  width: 460px;
}

div#zipsearch_area {
  margin: 15px auto 0 auto;
  width: 460px;
}

div#zipsearchcolumn .btn {
  margin: 15px 0 30px 0;
  text-align: center;
}

div#zipsearch_area #completebox p {
  padding: 60px 5px;
  text-align: center;
}

/* お客様の声の書き込み、新しいお届け先の追加・変更
----------------------------------------------- */
/* 商品詳細拡大写真、カート拡大写真
----------------------------------------------- */
/* 郵便番号検索
----------------------------------------------- */
/************************************************
 各ページコンテンツ用
************************************************ */
/* ==============================================
▼TOP
=============================================== */
/* メインイメージ
----------------------------------------------- */
#main_image {
  margin-bottom: 10px;
  text-align: center;
}

/* ==============================================
▼下層
=============================================== */
/* ==============================================
▼ガイド
=============================================== */
/* ◎◎について
----------------------------------------------- */
div#undercolumn_contact {
  margin: 0 auto;
  width: 100%;
}

/* 特定商取引法
----------------------------------------------- */
/* お問い合わせ
----------------------------------------------- */
.zipimg img {
  vertical-align: middle;
}

/* ==============================================
▼MYページ
=============================================== */
/* 共通設定
----------------------------------------------- */
div#mypagecolumn,
div#mynavi_area,
div#mycontents_area {
  width: 100%;
}

div#mynavi_area .mynavi_list {
  margin-bottom: 20px;
  width: 100%;
}

div#mynavi_area .mynavi_list li {
  margin: 0 15px 5px 0;
  padding-left: 15px;
  float: left;
  background: url("../img/icon/ico_arrow_01.gif") no-repeat left;
  font-size: 120%;
}

div#mynavi_area div.point_announce {
  margin-bottom: 30px;
  padding: 10px;
  border: solid 1px #ffcc62;
  background-color: #fffaf0;
}

div#mynavi_area div.point_announce p {
  padding-left: 20px;
  background: url("../img/icon/ico_point.gif") no-repeat left;
}

div#mycontents_area p.inforamtion {
  margin-bottom: 20px;
}

div#mypagecolumn h4 {
  margin: 10px auto;
  border-bottom: 1px solid #999;
  text-align: left;
  font-size: 120%;
}

div#mypagecolumn .flow_title {
  margin: 20px 0px 20px 10px;
  width: 730px;
}

div#mypagecolumn .flow_title .flow_img {
  float: left;
  width: 220px;
  padding-top: 10px;
}

div#mypagecolumn .flow_title .flow_detail {
  float: left;
  width: 500px;
  line-height: 150%;
  font-weight: bold;
  font-size: 120%;
  margin: 10px 0px 5px 10px;
}

div#mypagecolumn .flow_title .flow_detail .minnanokai {
  width: 450px;
  padding: 10px 5px 10px 5px;
  border: 1px dashed #cccccc;
}

div#mypagecolumn .ticket_attention {
  width: 730px;
  background-color: #ffdd88;
  font-weight: bold;
  font-size: 110%;
  padding: 10px 10px 10px 10px;
}

div#mycontents_area div.mycondition_area {
  margin: 0 auto 20px 0;
  padding: 10px;
  border: solid 1px #ccc;
  width: 97%;
  background: #f9f9f9;
}

div#mycontents_area div.mycondition_area p {
  float: left;
}

div#mycontents_area div.mycondition_area .btn {
  width: 160px;
  margin-top: 15px;
  float: right;
}

/* 購入履歴一覧/詳細
----------------------------------------------- */
.add_address {
  margin-bottom: 20px;
}

/* 会員登録内容変更/退会
----------------------------------------------- */
div#mycontents_area .message_area {
  margin: 30px auto;
  padding: 30px;
  border: 1px solid #ccc;
  text-align: center;
}

div#mycontents_area .message_area p {
  margin-bottom: 20px;
}

div#undercolumn_entry {
  width: 100%;
  @include media_desktop_sp {
    padding: 0 16px;
  }
}

div#undercolumn_entry .kiyaku_text {
  margin: 20px auto;
  padding: 10px;
  border: solid 1px #ccc;
  width: 94%;
  background: #fff;
}

div#undercolumn_login {
  margin: 0 auto;
  width: 100%;
}

div#undercolumn_login .login_area {
  margin-bottom: 30px;
}

div#undercolumn_login .login_area .inputbox {
  margin: 15px auto 15px auto;
  padding: 15px 20px 10px 20px;
  background: #f0f0f0;
}

div#undercolumn_login .login_area .inputbox .btn_area {
  margin-top: 0;
}

div#undercolumn_error .message_area {
  margin: 15px auto;
  padding: 15px;
  border: 1px solid #ccc;
  text-align: center;
  background: rgba(255, 255, 255, 0.8) url("/info/wp-content/uploads/bag_bg.png") no-repeat left center;
  background-size: 80px auto;
  min-height: 185px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  @include media_desktop {
    margin: 30px auto;
    background: rgba(255, 255, 255, 0.8) url("/info/wp-content/uploads/bag_bg.png") no-repeat left center;
    background-size: 150px auto;
    padding: 60px 15px;
    min-height: 350px;
  }
  .error {
    padding: 10px 0;
    font-size: 14px;
    background-color: rgba(255, 255, 255, 0.8);
    @include media_desktop {
      background-color: transparent;
    }
  }
}

div .topimage {
  clear: both;
  /*margin-bottom: 20px;*/
  padding-top: 10px;
  text-align: center;
}

/* ==============================================
▼会員登録
=============================================== */
/* ==============================================
▼ログイン
=============================================== */
/* ==============================================
▼エラー
=============================================== */
/* ==============================================
▼トップ画面
=============================================== */
#spr_bana_top li {
  display: inline-block;
}

#spr_bana_top a {
  width: 720px;
  height: 275px;
  float: left;
  overflow: hidden;
}

#spr_bana_top a img {
  border: none;
  width: 720px;
}

.sprite-z {
  margin-top: 0px;
}

.sprite-s {
  margin-top: -325px;
}

.sprite-t {
  margin-top: -650px;
}

div .topimage_left {
  float: left;
  width: 55%;
  padding: 0px 10px 0px 0px;
}

div .topimage_left .textarea {
  padding: 20px 0px 0px 10px;
}

div .topimage_left .imagearea {
  float: left;
  margin-top: 20px;
}

div .topimage_left .imagearea table {
  clear: both;
  border-style: none;
}

div .topimage_left .imagearea table td {
  border-style: none;
  padding: 0 15px 0 0;
}

div .topimage_right {
  float: left;
  width: 40%;
}

div .topimage_left2 img {
  clear: both;
  float: left;
  padding: 0px 5px 0px 0;
}

div .topimage_right2 img {
  float: left;
  padding: 0px 0 0px 0;
}

/* ==============================================
▼商品一覧
=============================================== */
/* ページ送り
----------------------------------------------- */
.pagenumber_area {
  padding-bottom: 10px;
  /*background: url("../img/background/line_dot_01.gif") repeat-x bottom ;*/
  background: url(data:image/gif;base64,R0lGODlhBAABAIAAAP///8zMzCH5BAAHAP8ALAAAAAAEAAEAAAIDBBIFADs=) repeat-x bottom;
}

.pagecond_area {
  margin-bottom: 20px;
  padding: 10px;
}

.pagenumber_area {
  margin: 20px 0;
}

.pagenumber_area li {
  float: left;
  padding-right: 10px;
}

.pagecond_area {
  border: 1px solid #ccc;
}

.pagenumber_area .navi {
  width: 100%;
  text-align: center;
}

.pagenumber_area .navi a {
  color: #FF9800;
}

.pagenumber_area .navi li {
  display: inline;
}

.pagenumber_area .change {
  float: right;
  text-align: right;
  white-space: nowrap;
}

/* レイアウト
----------------------------------------------- */
div.list_area {
  padding: 0 0 30px 0;
  width: 100%;
  overflow: auto;
}

.product_list {
  @include media_desktop() {
    display: flex;
    flex-wrap: wrap;
  }
  .list_wrap {
    margin-bottom: 10px;
    @include media_desktop() {
      width: calc(50% - 5px);
      margin: 0 10px 10px 0;
      &--even {
        margin-right: 0;
      }
    }
  }
  .additional-banner {
    margin: 5px 0 15px !important;
  }
}

div.list_area_left,
div.list_area_right {
  padding: 0 0 30px 0;
  width: 100%;
  overflow: auto;
  float: left;
}

div.listphoto {
  float: left;
}

/* 商品一覧用ブロック */
#bloc_title {
  background-color: #FDDFA1;
}

.list_products {
  background-color: #FDDFA1;
  border: solid 1px #E89B3F;
}

td.bloc_t1 {
  text-align: center;
  width: 20px;
  border: solid 1px #E89B3F;
  padding: 0px;
}

td.bloc_t2 {
  text-align: center;
  border: solid 1px #E89B3F;
  width: 80px;
  padding: 0px;
}

td.bloc_t3 {
  text-align: center;
  border: solid 1px #E89B3F;
  padding: 0px;
  width: 80px;
}

td.bloc_t4 {
  text-align: center;
  border: solid 1px #E89B3F;
  padding: 0px;
  width: 50px;
}

td.bloc_t5 {
  text-align: center;
  border: solid 1px #E89B3F;
  padding: 0px;
  width: 480px;
}

td.bloc_l1 {
  text-align: center;
  border: solid 1px #E89B3F;
  padding: 0px;
}

td.bloc_l2 {
  text-align: center;
  word-wrap: break-word;
  border: solid 1px #E89B3F;
  width: 80px;
  padding: 0px;
  overflow: hidden;
}

td.bloc_l2 a {
  color: #666666;
  font-weight: bold;
}

td.bloc_l2 a:hover {
  text-decoration: underline;
}

td.bloc_l2 .link_title {
  width: 80px;
}

td.bloc_l3 {
  text-align: center;
  border: solid 1px #E89B3F;
  padding: 0px;
  word-wrap: break-word;
  overflow: hidden;
}

td.bloc_l4 {
  text-align: center;
  border: solid 1px #E89B3F;
  word-wrap: break-word;
  padding: 0px;
  overflow: hidden;
}

td.bloc_l5 {
  text-align: left;
  border: solid 1px #E89B3F;
  padding: 0px 0px 0px 1px;
  padding: 0 1px 1px 1px;
  overflow: hidden;
}

td.bloc_l5 img {
  margin: 1px 1px 1px 0;
}

.list_products .bloc_list1 {
  background-color: #FDFDBD;
  height: 82px;
}

.list_products .bloc_list2 {
  background-color: #FDDFA1;
  height: 82px;
}

/* お買い上げランキング */
#ranking_area {
  clear: both;
  border: solid 1px #cccccc;
}

#ranking_area .bloc_body {
  clear: both;
  border: medium none;
}

/* トップページアイコン */
.icontop_flex {
  @include media_desktop {
    display: flex;
    justify-content: space-between;
  }
  &__right,
  &__left {
    @include media_desktop {
      width: 49%;
    }
  }
  .h2_title {
    margin-bottom: 20px;
  }
  ul {
    list-style: none;
    margin: 0 10px;
    padding: 0;
    @include media_desktop {
      margin: 0;
    }
    > li {
      margin-bottom: 20px;
    }
  }
  .icontop_contents_flex {
    display: flex;
    &__left {
      width: 59px;
    }
    &__right {
      padding-left: 5px;
      width: calc(100% - 59px);
    }
    .title a {
      font-size: 16px;
      color: #FF9800;
      font-weight: bold;
    }
  }
}
table#icontop {
  border-style: none;
  margin-top: 0;
}

table#icontop td {
  padding: 0 0 10px 0;
  border-style: none;
}

table#icontop td img {
  float: left;
}

table#icontop .text {
  height: 50px;
  margin: 5px 8px 0 65px;
}

table#icontop .text a {
  font-size: 12px;
  color: #333;
  line-height: 150%;
  font-weight: normal;
}

table#icontop .title a {
  padding: 0px 10px 0px 0px;
  margin: 0 0 5px 5px;
  font-size: 16px;
  color: #FF9800;
  font-weight: bold;
}

table#iconlist {
  border-style: none;
  padding: 0px;
}

table#iconlist th {
  background-color: #ffffff;
  border-style: none;
  padding: 5px 5px 0 0;
}

table#iconlist td {
  background-color: #ffffff;
  border-style: none;
  padding: 5px 5px 0px 0px;
}

/* 商品一覧アイコン */
/* メインカラム用 1カラム時*/
#one_maincolumn div.listrightbloc {
  float: right;
  width: 40%;
}

/* メインカラム用 2カラム時*/
#two_maincolumn_right div.listrightbloc,
#two_maincolumn_left div.listrightbloc {
  float: left;
  /*width: 45%;*/
  padding: 10px 0 0 0px;
}

/* メインカラム用 3カラム時*/
#three_maincolumn div.listrightbloc {
  float: right;
  width: 74%;
}

.text a,
.rank a {
  text-decoration: none;
}

/* 商品情報 各種設定
----------------------------------------------- */
/* 商品ステータス */
div.listrightbloc ul.status_icon {
  margin-bottom: 10px;
  width: 100%;
}

div.listrightbloc ul.status_icon li {
  margin-right: 5px;
  float: left;
}

div.listrightbloc h3 {
  font-weight: bold;
  font-size: 120%;
}

div.listrightbloc .listcomment {
  margin: 0 0 10px 0;
  text-align: left;
}

div.listrightbloc .detail_btn {
  margin-bottom: 20px;
}

div.listrightbloc .pricebox {
  margin: 0 0 10px 0;
}

div.listrightbloc .cart_area {
  padding: 10px;
  border: 1px solid #cef0f4;
  background-color: #ecf5ff;
  width: 94%;
}

/* 商品名 */
/* コメント */
/* 商品詳細を見る */
/* 価格 */
/* 買い物かご */
.order_number {
  margin: 0px 0 0 0px;
}

.order_number .quantity {
  width: 380px;
}

.order_number .quantity .set {
  margin: 10px 10px 10px 0px;
  float: left;
}

.order_number .quantity .set2 {
  margin: 10px 10px 10px 0px;
  font-size: 1.2rem;
  font-weight: bold;
}

.order_kind {
  margin: 10px 0 0 50px;
}

.set_order {
  margin: 10px 0 0 0px;
  clear: both;
  text-align: center;
}

.set_order2 {
  margin: 5px 0px 0px 50px;
  clear: both;
}

.set_orderlist {
  margin: 0 0 10px 0;
}

.set_orderlist li a {
  /*background-image:url("../img/header/point01.jpg");*/
  font-size: 12px;
  color: #FF9800;
  background-repeat: no-repeat;
  background-position: left center;
  list-style-type: none;
  text-decoration: underline;
  padding: 0px 0 0px 20px;
}

.set_orderlist li a:before {
  content: "\f144";
  font-family: "Font Awesome 5 Free";
}

.set_present {
  margin: 5px 0 10px 10px;
}

/* 規格 */
div.listrightbloc .classlist {
  margin-bottom: 10px;
  padding-bottom: 10px;
  background: url("../img/background/line_dot_02.gif") repeat-x bottom;
}

div.listrightbloc dl {
  width: 100%;
}

div.listrightbloc dt {
  display: inline-block;
  vertical-align: top;
}

div.listrightbloc dd {
  padding-bottom: 10px;
  display: inline-block;
}

div.listrightbloc dd p.attention {
  margin-top: 5px;
}

div.listrightbloc .cartin {
  margin: 0;
  float: right;
}

div.listrightbloc .cartin .quantity {
  padding: 3px 10px 0 0;
  width: 150px;
  float: left;
  text-align: right;
}

div.listrightbloc .cartin .quantity .box {
  width: 70px;
}

div.listrightbloc .cartin_btn {
  width: 160px;
  float: left;
}

/* カゴに入れる */
/* ==============================================
▼商品詳細
=============================================== */
/* レイアウト

    tplファイルのマークアップが同じ項目
    *1カラム時 [one_maincolumn]
    *2カラム時 [two_maincolumn_left]
               [two_maincolumn_right]
    *3カラム時 [three_maincolumn]

----------------------------------------------- */
#detailarea,
.sub_area {
  margin-bottom: 20px;
  width: 100%;
}

/* レイアウト
----------------------------------------------- */
/* 1カラム用 */
#one_maincolumn div#detailphotobloc {
  width: 37%;
  float: left;
}

#one_maincolumn #detailrightbloc {
  width: 63%;
  float: right;
}

#one_maincolumn div.subtext {
  margin-bottom: 20px;
  float: left;
  width: 69%;
}

#one_maincolumn div.subphotoimg {
  float: right;
  width: 25%;
  text-align: right;
}

#one_maincolumn p.subtext {
  margin-bottom: 20px;
}

/* 2カラム用 */
#two_maincolumn_left div#detailphotobloc {
  width: 50%;
  float: left;
}

#two_maincolumn_right div#detailphotobloc {
  width: 50%;
  float: left;
}

#two_maincolumn_right div#detailphotobloc .detail_menu {
  /*height: 45px;*/
  text-align: center;
}

.detail_photo {
  font-size: 12px;
  .mainphoto {
    text-align: center;
  }
  .photolist {
    display: flex;
    flex-wrap: wrap;
    img {
      padding: 0px 0px 0px 0px;
      margin: 10px 10px 0 0;
      border: 1px solid #cccccc;
    }
  }
}

#two_maincolumn_left #detailrightbloc,
#two_maincolumn_right #detailrightbloc {
  float: right;
  width: 50%;
}

#two_maincolumn_left div.subtext,
#two_maincolumn_right div.subtext {
  margin-bottom: 20px;
  float: left;
  width: 73%;
}

#two_maincolumn_left p.subtext,
#two_maincolumn_right p.subtext {
  margin-bottom: 20px;
}

#two_maincolumn_left div.subphotoimg,
#two_maincolumn_right div.subphotoimg {
  float: right;
  width: 25%;
  text-align: right;
}

/* 3カラム用 */
#three_maincolumn div#detailphotobloc {
  float: left;
  width: 49%;
}

#three_maincolumn #detailrightbloc {
  float: right;
  width: 50%;
}

#three_maincolumn div.subtext {
  margin-bottom: 20px;
  float: left;
  width: 63%;
}

#three_maincolumn p.subtext {
  margin-bottom: 20px;
}

#three_maincolumn div.subphotoimg {
  float: right;
  width: 35%;
  text-align: right;
}

/* 商品情報 各種設定
----------------------------------------------- */
.product_hint {
  margin-bottom: 5px;
  padding: 3px;
  background: #FFF176;
  display: inline-block;
  border-radius: 4px;
  font-size: 14px;
}

#detailrightbloc h2 {
  margin: 0 0 10px 0;
  padding: 0 0 15px 0;
  color: #666;
  /*background: url("../img/background/line_dot_01.gif") repeat-x bottom ;*/
  background: url(data:image/gif;base64,R0lGODlhBAABAIAAAP///8zMzCH5BAAHAP8ALAAAAAAEAAEAAAIDBBIFADs=) repeat-x bottom;
  font-weight: bold;
  font-size: 160%;
}

#detailrightbloc .point,
#detailrightbloc .relative_cat {
  margin: 0 0 10px 0;
  padding: 0 0 10px 0;
  /*background: url("../img/background/line_dot_01.gif") repeat-x bottom ;*/
  background: url(data:image/gif;base64,R0lGODlhBAABAIAAAP///8zMzCH5BAAHAP8ALAAAAAAEAAEAAAIDBBIFADs=) repeat-x bottom;
}

#detailrightbloc .main_comment {
  margin: 10px 10px 10px 20px;
  width: 350px;
}

#detailrightbloc .main_comment ul {
  list-style: disc inside none;
}

#detailrightbloc .main_comment ul li {
  list-style: disc inside none;
}

#detailrightbloc .main_comment a {
  color: #FF9800;
  /*&:not(.nobr):after {
        content: "\A";
        white-space: pre;
      }*/
}

#detailrightbloc .main_comment a:hover {
  text-decoration: underline;
}

.iconlist {
  margin: 5px 0 10px;
  width: 100%;
  max-width: 100%;
  /*height: 180px;*/
  height: auto;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  &__item {
    margin-right: 10px;
    margin-bottom: 10px;
    width: calc(100% / 4 - 8px);
    &:nth-of-type(4n) {
      margin-right: 0;
    }
  }
  a {
    text-decoration: none;
  }
  img {
    width: 100%;
    height: auto;
  }
}

#other_area {
  clear: both;
  margin-top: 20px;
  margin-bottom: 90px;
}

#order {
  float: left;
  padding: 10px 0 10px 0px;
}

/* 商品コード */
#detailrightbloc .product_code dt,
#detailrightbloc .product_code dd {
  display: inline;
}

#detailrightbloc ul.status_icon {
  margin-bottom: 10px;
  width: 100%;
}

#detailrightbloc ul.status_icon li {
  margin-right: 5px;
  margin-bottom: 3px;
  float: left;
}

#detailrightbloc .normal_price dt,
#detailrightbloc .normal_price dd {
  display: inline;
}

#detailrightbloc .sale_price dt,
#detailrightbloc .sale_price dd {
  display: inline;
}

#detailrightbloc .point dt,
#detailrightbloc .point dd {
  display: inline;
}

#detailrightbloc div.classlist {
  margin-bottom: 10px;
  padding-bottom: 10px;
  width: 100%;
  background: url("../img/background/line_dot_02.gif") repeat-x bottom;
}

#detailrightbloc .classlist {
  margin-bottom: 5px;
}

#detailrightbloc ul {
  margin-bottom: 10px;
  width: 100%;
}

#detailrightbloc ul li {
  vertical-align: top;
  float: left;
}

#detailrightbloc .maker dt,
#detailrightbloc .maker dd {
  display: inline;
}

#detailrightbloc .comment1 dt,
#detailrightbloc .comment1 dd {
  display: inline;
}

#detailrightbloc .relative_cat dd {
  margin-left: 1em;
}

#detailrightbloc .cart_area {
  padding: 10px;
  background-color: #ecf5ff;
  border: 1px solid #cef0f4;
}

#detailrightbloc .quantity dt,
#detailrightbloc .quantity dd {
  display: inline;
}

#detailrightbloc .cartin,
#detailrightbloc .cartin_btn {
  text-align: center;
}

#detailrightbloc .favorite_btn {
  text-align: center;
  margin-top: 10px;
}

/* 商品ステータス */
/* 通常価格 */
/* 販売価格 */
/* ポイント */
/* 規格 */
/* メーカー */
/* メーカーURL */
/* 関連カテゴリ */
/* 買い物かご */
/* 詳細ページ支払い方法など */
#footguide {
  margin: 0px auto 15px;
  width: 100%;
  text-align: left;
  font-size: 12px;
  a {
    text-decoration: underline;
  }
}

.foot_flex {
  @include media_desktop {
    display: flex;
    justify-content: space-between;
  }
  &__right,
  &__left {
    @include media_desktop {
      width: 49%;
    }
  }
}

#footguide dt {
  font-size: 14px;
  border-left: 16px solid #FF9800;
  background: #FFEDA9;
  clear: both;
  font-weight: bold;
  padding: 5px 10px;
}

#footguide dd {
  padding: 10px 10px 0px;
  @include media_desktop {
    padding: 3px 0px 0px 0px;
  }
}

#footguide .kwsk {
  margin: 0px 0px 15px;
  padding: 3px 0px 0px 10px;
  text-align: right;
}

#footguide .kwsk a {
  padding: 0px 0px 0px 15px;
}

/* お客様の声
----------------------------------------------- */
/* 商品情報にそのまま流用したせいで面倒なことに */
div#itemdetail_area_wrap {
  margin-bottom: 10px;
  clear: both;
  font-size: 16px;
}

div#itemdetail_area {
  clear: both;
  padding: 20px 5px 0px;
  /*h2 {
      margin-bottom: 20px;
      padding: 6px 0 8px 10px;
      border-top: solid 1px $orange;
      background: url('../img/background/bg_tit_sub_01.jpg') repeat-x left bottom;
    }*/
}

div#itemdetail_area .customer_left {
  width: 50%;
  float: left;
  margin-bottom: 20px;
}

div#itemdetail_area ul {
  list-style-type: disc;
  padding-left: 15px;
}

div#itemdetail_area ul li {
  list-style-type: disc;
}

div#itemdetail_area .customer_right {
  width: 50%;
  float: left;
  /*padding-left: 15px;*/
}

div#itemdetail_area .customer_bottom {
  clear: both;
  height: 60px;
}

div#itemdetail_area .customer_bottom img {
  float: left;
  padding-right: 5px;
}

div#itemdetail_area .review_bloc {
  margin-bottom: 20px;
  padding: 10px;
  font-size: 1rem;
  background-color: #f6f6f6;
}

div#itemdetail_area .review_bloc p {
  padding-top: 3px;
  margin-right: 10px;
}

div#itemdetail_area review_bloc .review_btn {
  float: right;
  width: 160px;
}

div#itemdetail_area .voicetitle {
  margin-bottom: 5px;
  color: #333;
  font-weight: bold;
}

div#itemdetail_area .voicedate {
  margin-bottom: 10px;
}

div#whobought_area {
  clear: both;
  /*padding: 35px 0 0 0;*/
}

div#whobought_area a:hover {
  text-decoration: underline;
}

div#customervoice_area_wrap {
  margin-bottom: 10px;
  clear: both;
  font-size: 14px;
}

div#customervoice_area {
  clear: both;
  padding: 20px 5px 0px;
  /*h2 {
      margin-bottom: 20px;
      padding: 6px 0 8px 10px;
      border-top: solid 1px $orange;
      background: url('../img/background/bg_tit_sub_01.jpg') repeat-x left bottom;
    }*/
}

div#customervoice_area .customer_left {
  width: 50%;
  float: left;
  margin-bottom: 20px;
}

div#customervoice_area ul {
  list-style-type: disc;
}

div#customervoice_area ul li {
  list-style-type: disc;
  margin: 10px 0px 10px 0px;
}

div#customervoice_area .customer_right {
  width: 50%;
  float: left;
  /*padding-left: 15px;*/
}

div#customervoice_area .customer_bottom {
  clear: both;
  height: 60px;
}

div#customervoice_area .customer_bottom img {
  float: left;
  padding-right: 5px;
}

div#customervoice_area .review_bloc {
  /*margin-bottom: 20px;*/
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f6f6f6;
  font-size: 12px;
}

div#customervoice_area .review_bloc p {
  padding-top: 3px;
  margin-right: 10px;
  float: left;
}

div#customervoice_area review_bloc .review_btn {
  float: right;
  width: 160px;
}

div#customervoice_area .voicetitle {
  margin-bottom: 5px;
  color: #333;
  font-weight: bold;
}

div#customervoice_area .voicedate {
  margin-bottom: 10px;
}

div#customervoice_area .voicecomment {
  position: relative;
  display: inline-block;
  margin-top: 18px;
  padding: 15px;
  width: auto;
  min-width: 115px;
  /*height: 50px;*/
  color: #333;
  line-height: 20px;
  text-align: left;
  background: #e0e0e0;
  border-radius: 20px;
  z-index: 0;
}

div#customervoice_area .voicecomment:before {
  content: "";
  position: absolute;
  top: -17px;
  left: 15px;
  margin-right: 0;
  display: block;
  width: 15px;
  height: 15px;
  background: #e0e0e0;
  border-radius: 50%;
  z-index: -1;
}

div#customervoice_area .voicecomment:after {
  content: "";
  position: absolute;
  top: -25px;
  left: 25px;
  margin-right: 0;
  display: block;
  width: 8px;
  height: 8px;
  background: #e0e0e0;
  border-radius: 50%;
  z-index: -1;
}

div#whobought_area {
  clear: both;
  /*padding: 35px 0 0 0;*/
}

div#whobought_area a:hover {
  text-decoration: underline;
}

div#customervoice_area #titulos_waku {
  border: #999 1px solid;
  border-left: #999 10px solid;
  padding: 5px;
  background-color: #F7F7F7;
  margin-bottom: 15px;
  min-height: 28px;
}

div#customervoice_area #titulos_waku .titulos {
  float: left;
  padding: 5px;
}

div#customervoice_area .titulos li {
  display: inline;
  background: none !important;
}

div#customervoice_area .titulos a {
  padding: 5px;
  border: #999 1px solid;
  background-color: #FFF;
  cursor: pointer;
  color: #69C;
}

div#customervoice_area .titulos a:hover {
  color: #F30;
}

div#customervoice_area .titulos a.activo {
  background-color: #eee;
  color: #000;
}

/* 関連商品（商品部分はbloc.cssのおすすめ商品と共通）
----------------------------------------------- */
/*div#whobought_area h2 {
    border-top: solid 1px $orange;
    background: url('../img/background/bg_tit_sub_01.jpg') repeat-x left bottom;
    padding: 5px 0 8px 10px;
    font-size: 14px;
}*/
.photo img {
  border: 2px solid #eee;
}

#whobought_area .areabloc {
  float: left;
  width: 200px;
  height: 260px;
  margin: 0 auto;
  text-align: center;
  padding: 5px;
}

/* ***********************************************
▼カートの中
/*********************************************** */
/* 現在のカゴの中
----------------------------------------------- */
div#undercolumn_cart .point_announce {
  padding: 20px;
  margin-bottom: 20px;
  border: solid 1px #ffcc62;
  background: #fffaf0;
  font-size: 120%;
  text-align: center;
  line-height: 140%;
}

div#undercolumn_cart .totalmoney_area {
  margin-bottom: 20px;
}

div#undercolumn_cart p {
  margin: 10px 5px;
}

div#undercolumn ul#quantity_level li {
  padding: 3px;
  display: inline;
}

div#undercolumn .empty {
  text-align: left;
}

div.form_area {
  margin-bottom: 30px;
}

/* お客様情報入力
----------------------------------------------- */
#undercolumn_shopping {
  font-size: 1rem;
}

.white-block {
  width: 98%;
  padding: 1rem 0.8rem;
  margin: 1rem 0;
  border-radius: 0.8rem;
}

.payment-block {
  width: 98%;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 0.8rem;
  padding: 0.5rem;
  margin-bottom: 1rem !important;
  box-sizing: border-box;
}

.payment-block p {
  margin: 1rem;
  line-height: 1.5;
}

.payment-block img {
  max-width: 95%;
}

.payment_announce {
  background: #fafafa;
  border: 1px solid #ccc;
  border-radius: 0.4rem;
  margin: 1rem 0.2rem;
}

.payment-alert {
  background: #FFEBEE;
}

.confirm_after_pay {
  text-align: left;
  width: 30rem;
  margin: 0 auto;
}

.flow_area {
  margin: 0 0 20px 0;
}

div#undercolumn_customer th em {
  color: #000;
  font-weight: bold;
}

div#undercolumn_shopping .pay_area02 .txtarea {
  margin: 5px 0 0 0;
  padding: 2px;
  border: 1px solid #ccc;
  border-radius: 0.4rem;
  width: 99%;
  height: 150px;
}

div#after_pay {
  border: 4px solid #999999;
  background-color: #FDFDBD;
  padding: 10px;
  margin: 30px auto 30px auto;
}

div#after_pay .title {
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  margin: 10px 0 20px 0;
}

div#after_pay .accept_title {
  font-weight: bold;
  font-size: 22px;
  color: #ff0000;
}

div#after_pay .accept_area {
  text-align: center;
}

div#undercolumn_shopping .pay_area02 .select-msg {
  margin-bottom: 10px;
}

div#undercolumn_shopping .point_area .point_announce {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 0.4rem;
}

div#undercolumn_shopping .point_area p {
  margin-bottom: 20px;
}

div#undercolumn_shopping .point_area .point_announce li {
  margin-bottom: 5px;
}

#show-recept {
  display: none;
}

#clk-recept:hover {
  cursor: pointer;
}

/* 住所自動入力 */
.zipimg {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  padding: 5px;
}

.zipimg a {
  text-decoration: none;
  color: #333;
}

.zipimg:active {
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.35);
}

.pay_area td,
.pay_area label {
  cursor: pointer;
}

label,
input[type='checkbox'],
input[type='radio'] {
  cursor: pointer;
}

.table_shop td {
  background: transparent;
}

.table_shop tr,
.table_shop tr td {
  transition: all 0.3s;
}

.table_shop tr:hover,
.table_shop tr:hover td {
  background-color: #E0F2F1;
}

.card {
  position: relative;
}

.card_link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: transparent;
}

/* お支払い方法・お届け時間等の指定
----------------------------------------------- */
/* お届け先の指定
----------------------------------------------- */
#address_area {
  margin-bottom: 10px;
  width: 100%;
}

#address_area .information {
  width: 65%;
  float: left;
}

#address_area .information2 {
  width: 90%;
  float: left;
}

#undercolumn_shopping .information {
  margin-bottom: 15px;
}

#address_area .add_multiple {
  padding: 15px 10px;
  border: 1px solid #ffcc62;
  float: right;
  width: 30%;
  color: #555;
  background: #fffaf0;
  text-align: center;
  font-weight: bold;
}

#address_area .add_multiple p {
  margin-bottom: 10px;
}

#address_area p.addbtn {
  font-weight: bold;
  font-size: 10px;
}

.otheraddr {
  display: none;
}

#payblock_3 label:before,
#payblock_5 label:before,
#payblock_12 label:before,
#payblock_13 label:before,
#payblock_4 label:before,
#payblock_6 label:before,
#payblock_7 label:before,
#payblock_8 label:before,
#payblock_9 label:before,
#payblock_10 label:before,
#payblock_11 label:before {
  font-family: "Font Awesome 5 Free";
  font-size: 1.4rem;
  color: #2196F3;
  vertical-align: middle;
  display: inline-block;
  min-width: 2rem;
  text-align: center;
}

/* クレジットカード */
#payblock_5 label:before {
  content: "\f283";
}

/* コンビニ */
#payblock_12 label:before {
  content: "\f0f7";
}

/* 銀行前払い */
#payblock_3 label:before {
  content: "\f19c";
}

/* 後払い */
#payblock_13 label:before {
  content: "\f0d1";
}

/* 代引き */
#payblock_4 label:before,
#payblock_6 label:before,
#payblock_7 label:before,
#payblock_8 label:before,
#payblock_9 label:before,
#payblock_10 label:before,
#payblock_11 label:before {
  content: "\f157";
}

/* ==============================================
▼検索結果
=============================================== */
p.condition_area {
  margin: 0 auto;
  padding: 5px;
  border: solid 1px #333;
  width: 566px;
}

/************************************************
 tables
************************************************ */
/* デフォルトテーブル
----------------------------------------------- */
table {
  margin: 15px auto 20px auto;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

table th {
  padding: 8px;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  color: #333;
  background-color: #f0f0f0;
  font-weight: normal;
}

table td {
  padding: 8px;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  background: #fff;
}

table .alignC img {
  width: 110px;
}

table .alignC .expansion img {
  max-width: 110px;
  max-height: 160px;
}

.search_table  {
  margin: 15px auto 20px auto;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  @include media_desktop_sp {
    display: block;
  }
  tr {
    @include media_desktop_sp {
      display: block;
    }
    clear: both;
  }
  th {
    padding: 8px;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    background-color: #FDDFA1;
    border: solid 1px #FF9900;
    @include media_desktop_sp {
      display: block;
    }
    @include media_desktop {
      min-width: 125px;
    }
  }
  td {
    border: solid 1px #EDD398;
  }
}

.tooltip_img {
  float: left;
  margin-right: 5px;
}

.twoc_dl dt {
  float: left;
  width: 17rem;
}

.threec_dl dt {
  float: left;
  width: 14rem;
}

#search_form_lite .threec_dl label {
  display: block;
}
#search_lite_button_area {
  margin-bottom: 10px;
}

.btn_area-search {
  > ul {
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  a {
    display: inline-block;
  }
}

.tooltipster-icon {
  font-family: "Font Awesome 5 Free";
  font-size: 16px;
  vertical-align: middle;
}

.list_wrap table.category_table {
  border-collapse: collapse;
  text-align: center;
  margin: 0 0 0 0;
  border: solid 1px #FF9900;
  width: 100%;
  /*overflow: visible;*/
}

.list_wrap table.category_table td div.category_list {
  width: 150px;
  /*height: 240px;
    overflow:hidden;*/
  padding: 0;
  margin: 0;
  text-align: center;
}

.category_table td {
  padding: 0px 0px 0 0px;
  border: solid 1px #FF9900;
  position: relative;
  background: none;
  background-clip: padding-box;
}

.category_table th {
  background-color: #FDDFA1;
  padding: 0 0 0 0px;
  border: solid 1px #FF9900;
  width: 19%;
}

.category_table .cno_title {
  /*height: 25px;
    width: 70px;*/
  text-align: center;
  vertical-align: middle;
  padding-top: 5px;
  font-size: 1rem;
}

.category_table .cno_text {
  /*height: 25px;
    width: 110px;
    overflow:hidden;*/
  text-align: center;
  font-weight: bold;
  vertical-align: middle;
  padding-top: 5px;
  font-size: 1rem;
}

.category_table .h25_title {
  /*width: 70px;*/
  text-align: center;
  vertical-align: middle;
  padding-top: 5px;
  font-size: 1rem;
}

.category_table .h25_text {
  /*width: 110px;*/
  overflow: hidden;
  text-align: center;
  font-weight: bold;
  vertical-align: middle;
  padding-top: 5px;
  word-wrap: break-word;
  font-size: 1rem;
  padding: 3px;
}

.category_table .comment_text {
  /*width: 175px;
    height: 70px;*/
  padding: 5px 5px 0px 5px;
  overflow: hidden;
  font-size: 1rem;
  text-align: left;
}

.category_table .s_btn {
  text-align: center;
  padding: 10px 0 10px 0;
  a {
    text-decoration: none;
  }
}

/* 商品詳細
----------------------------------------------- */
.detail_table {
  border-collapse: collapse;
  text-align: center;
  font-size: 1rem;
  margin: 20px 0 10px 0;
  border: solid 1px #FF9900;
  width: 355px;
  word-wrap: break-word;
  table-layout: fixed;
}

.detail_table td {
  padding: 5px 0 0 10px;
  border: solid 1px #FF9900;
  text-align: center;
}

.detail_table th {
  background-color: #FDDFA1;
  padding: 0 0 0 5px;
  border: solid 1px #FF9900;
  text-align: center;
  width: 100px;
}

.detail_table2 {
  border-collapse: collapse;
  text-align: center;
  margin: 0px 0 0px 0;
  border: solid 1px #FF9900;
  width: 355px;
}

.detail_table2 td {
  padding: 5px 0 0 5px;
  border: solid 1px #FF9900;
  text-align: center;
}

.detail_table2 th {
  background-color: #FDDFA1;
  padding: 0 0 0 5px;
  border: solid 1px #FF9900;
  text-align: center;
  width: 100px;
}

/* 見出し
----------------------------------------------- */
div#undercolumn_shopping table th[scope=col] {
  text-align: center;
}

div#undercolumn_shopping table.delivname th {
  width: 155px;
}

div#mycontents_area table th {
  text-align: left;
}

div#mycontents_area table th.alignR {
  text-align: right;
}

div#mycontents_area table th.alignL {
  text-align: left;
}

div#mycontents_area table th.alignC {
  text-align: center;
}

div#mycontents_area table th.resulttd {
  text-align: right;
}

div#mycontents_area table caption {
  padding: 8px;
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  color: #000;
  background-color: #f0f0f0;
  text-align: left;
  font-weight: bold;
}

/* MYページ */
/* その他
----------------------------------------------- */
table select {
  margin-bottom: 7px;
  border: solid 1px #ccc;
}

table .change select {
  margin-bottom: 0px;
  border: solid 1px #ccc;
}

.change_table {
  margin-top: 3px;
}

.change_text {
  font-size: 14px;
}

.change_bold {
  font-weight: bold;
  color: #ff0000;
}

/* 買い物カゴ内送料表
----------------------------------------------- */
#pay-table {
  width: 60%;
  background: none repeat scroll 0 0 #F6D288;
  border: 1px solid #E89B3F;
  border-collapse: collapse;
  margin: 10px 18px 20px 0px;
}

#pay-table th {
  border: 1px solid #E89B3F;
  background-color: #F6D288;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.7;
  padding: 7px;
  text-align: left;
}

#pay-table td {
  background: none repeat scroll 0 0 #FFFFFF;
  border: 1px solid #E89B3F;
  font-size: 13px;
  line-height: 1.7;
  padding: 7px;
}

/*! legitRipple.js v1.1.0: ripple.min.css by Matthias Vogt (ISC license) */
.legitRipple,
.legitRipple-custom {
  overflow: hidden;
}

.legitRipple {
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 0;
}

.legitRipple-ripple {
  position: absolute;
  z-index: -1;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  pointer-events: none;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.4);
  will-change: transform, width, opacity;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  width: 0;
  opacity: 1;
  -webkit-transition: width .15s linear, opacity .9s ease-out;
  transition: width .15s linear, opacity .9s ease-out;
}

.legitRipple-ripple:before {
  content: "";
  padding-top: 100%;
  display: block;
}

img~.legitRipple-ripple {
  z-index: auto;
}

.legitRipple-template {
  display: none;
}

.legitRipple-custom>* {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/* 20190322 ヘッダーカテゴリ+サイト内検索 */
#dropmenu,
#srchBox_side {
  display: none !important;
}


#serach-bar {
  background: #009688;
}

/*サイト内検索ボックス*/
#serach-bar-searchform-block {
  max-width: 970px;
  width: 90%;
  margin: 0 auto;
  padding: 5px 0;
}

#serach-bar-searchform-block-text {
  width: 100%;
  @include media_desktop {
    width: 80%;
    float: left;
  }
}

#serach-bar-searchform-block-text:before {
  content: none;
}

#serach-bar-searchform-block-icon {
  width: 10%;
  background: #EEEEEE;
  height: 35px;
  display: table-cell;
  vertical-align: middle;
  font-size: 1.1rem;
  padding: 2px 5px;
  border: 0px;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  text-align: center;
}

#serach-bar-searchform-block-text input {
  width: calc(100% - 80px);
  height: 35px;
  float: left;
  font-size: 1.1rem;
  padding: 2px 5px;
  border: 0px;
  border-radius: 4px 0 0 4px;
  transition: .3s;
  @include media_desktop {
    width: 85%;
  }
}

#serach-bar-searchform-block-text input:hover {
  background: #E1F5FE;
  transition: .3s;
}

#serach-bar-searchform-detail {
  display: none;
}

#serach-bar-searchform-detail {
  position: fixed;
  z-index: 7777;
  display: none;
  width: 80%;
  max-width: 840px;
  margin: 0;
  padding: 60px 2vw 80px;
  border: 2px solid #aaa;
  text-align: center;
  background: #fff;
  box-sizing: border-box;
}

#serach-bar-searchform-block-detail {
  color: #fff;
  text-decoration: underline;
  line-height: 1.5;
  cursor: pointer;
  #search-popup-trigger {
    display: none;
    @include media_desktop {
      display: inline-block;
      margin-left: 20px;
      text-decoration: underline;
    }
  }
}

.detail-modal-search {
  background: #009688;
  color: #fff !important;
  text-decoration: none;
  font-size: 1.2rem;
  padding: 10px 25px;
  border-radius: 4px;
}

.detail-modal-close {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 62px;
  font-size: 46px;
  color: #000;
  line-height: 62px;
  text-align: center;
  background: #e6e6e6;
  border-radius: 0 0 0 20px;
  cursor: pointer;
}

.detail-modal-close i {
  line-height: 62px;
  vertical-align: bottom;
}

.modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 6666;
  display: none;
  width: 100%;
  height: 120%;
  background-color: rgba(0, 0, 0, 0.7);
}

.youtube_area {
  max-width: 560px;
  &__inner {
    position: relative;
    width: 100%;
    padding-top: calc(315 / 560 * 100%);
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 769px) {

  /*  ウィンドウサイズ769px以下の時のスタイル  */
  #serach-bar-searchform-detail {
    padding: 50px 2vw 40px;
  }

  .detail-modal-close {
    width: 40px;
    line-height: 40px;
    font-size: 30px;
  }

  .detail-modal-close i {
    line-height: 44px;
  }

  #serach-bar-menu-dropdown {
    height: 55px;
  }
}

#serach-bar-searchform-block-submit {
  width: 150px;
  float: left;
}

#serach-bar-searchform-block-submit a {
  background: #fff;
  width: 150px;
  height: 35px;
  padding: 2px 5px;
  border-radius: 4px;
  display: table-cell;
  vertical-align: middle;
  font-size: 1.1rem;
  text-decoration: none;
  transition: .3s;
}

#serach-bar-searchform-block-submit a:hover {
  background: #E8F5E9;
  transition: .3s;
}

/* ドロップダウンメニュー */
.fa-color-bk {
  color: #000;
}

.fa-color-wh {
  color: #fff;
}

.fa-color-bl {
  color: #2196F3;
}

.fa-color-gr {
  color: #8BC34A;
}

.fa-color-ye {
  color: #FDD835;
}

.fa-color-or {
  color: #FF7043;
}

#serach-bar-menu {
  display: none;
  @include media_desktop {
    display: block;
  }
  .header_button_cartitem {
    background-color: red;
    color: white;
    border-radius: 5px;
    padding: 3px;
  }
}
#serach-bar-menu-dropdown {
  list-style-type: none;
  width: 90%;
  max-width: 970px;
  height: 40px;
  margin: 0 auto;
  padding: 0;
  background: #009688;
  border-bottom: 5px solid #00897B;
  border-radius: 3px 3px 0 0;
  clear: both;
}

#serach-bar-menu-dropdown li {
  position: relative;
  width: 15.5%;
  float: left;
  margin: 0;
  padding: 0;
  text-align: center;
  border-right: 1px solid #bdbdbd;
}

#serach-bar-menu-dropdown li:last-child {
  border-right: 0px solid #bdbdbd;
}

#serach-bar-menu-dropdown li a {
  display: block;
  margin: 0;
  padding: 15px 0 11px;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  text-decoration: none;
}

#serach-bar-menu-dropdown li ul {
  list-style: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 999;
  margin: 0;
  padding: 0;
  border-radius: 0 0 3px 3px;
}

#serach-bar-menu-dropdown li:last-child ul {
  left: -100%;
  width: 100%;
}

#serach-bar-menu-dropdown li ul.nav_br_r {
  float: right;
}

#serach-bar-menu-dropdown li ul.nav_br_l {
  float: left;
  margin-left: 200px;
}

#serach-bar-menu-dropdown li ul li {
  overflow: hidden;
  width: 220px;
  height: 0;
  color: #fff;
  -moz-transition: .2s;
  -webkit-transition: .2s;
  -o-transition: .2s;
  -ms-transition: .2s;
  transition: .2s;
}

#serach-bar-menu-dropdown li ul.nav_br_r li,
#serach-bar-menu-dropdown li ul.nav_br_l li {
  width: 200px;
}

#serach-bar-menu-dropdown li ul li a {
  padding: 13px 15px;
  background: #5E5A5A;
  border-top: 2px solid #5E5A5A;
  border-bottom: 2px solid #5E5A5A;
  text-align: left;
  font-size: 12px;
  font-weight: normal;
}

#serach-bar-menu-dropdown li:hover>a {
  background: #fb8c00;
  color: #fff;
}

#serach-bar-menu-dropdown li:hover ul li {
  overflow: visible;
  height: 38px;
  border-top: 1px solid #767474;
  border-bottom: 1px solid #767474;
}

#serach-bar-menu-dropdown li:hover ul li:first-child {
  border-top: 0;
}

#serach-bar-menu-dropdown li:hover ul li:last-child {
  border-bottom: 0;
}

#serach-bar-menu-dropdown li:hover ul li:last-child a {
  border-radius: 0 0 3px 3px;
}

#serach-bar-menu-dropdown li:hover ul.nav_br_r li:last-child a {
  border-radius: 0 0 0 3px;
}

#serach-bar-menu-dropdown li:hover ul.nav_br_l li:last-child a {
  border-radius: 0 0 3px 0;
}

.serach-bar-menu-dropdown_abt {
  background: #FF9800 !important;
  border-bottom: 5px solid #e08600 !important;
}

.serach-bar-menu-dropdown_abt li {
  border-right: 1px solid #bdbdbd;
}

.serach-bar-menu-dropdown_abt li:last-child {
  border-right: 0px solid #bdbdbd;
}

.serach-bar-menu-dropdown_abt li a {
  color: #fff;
}

.serach-bar-menu-dropdown_abt li ul li {
  color: #fff;
}

.serach-bar-menu-dropdown_abt li ul li a {
  background: #5E5A5A;
}

.serach-bar-menu-dropdown_abt li:hover>a {
  background: #ffa41f !important;
  color: #fff;
}

.serach-bar-menu-dropdown_abt li:hover ul li {
  border-top: 1px solid #767474;
  border-bottom: 1px solid #767474;
}

/* パンくずリスト */
.breadcrumbs {
  margin: 10px 0;
}

.breadcrumbs ol {
  display: table;
  font-size: 13px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.breadcrumbs ol li {
  margin: 0 5px 0 0;
  font-size: 13px;
  float: left;
}

// .breadcrumbs ol li:first-child::before {
//   padding: 0 3px 0 0;
//   content: "\f015";
//   font-family: "Font Awesome 5 Free";
//   font-size: 14px;
// }

// .breadcrumbs ol li::before {
//   padding: 0 5px 0 0;
//   content: "\f105";
//   font-family: "Font Awesome 5 Free";
//   font-size: 14px;
// }

.disabled {
  pointer-events: none;
  text-decoration: none !important;
}

.no-underline {
  text-decoration: none;
}

/* 商品ページ ボタン 2019/06/14～ */
.cover-link {
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  display: block;
}

.box-cta-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  list-style: none;
  padding: 0;
}

.box-cta-item {
  border: 1px solid #2196F3;
  border-radius: 4px;
  box-sizing: border-box;
  color: #2196F3;
  display: flex;
  flex: 1 0 140px;
  flex-direction: column;
  justify-content: center;
  margin: 10px 5px;
  padding: 0.5rem;
  max-width: 100%;
  text-align: center;
  font-size: 1.0rem;
  font-weight: bold;
  transition: all .3s;
  position: relative;
}

.box-cta-item:hover {
  background: #1565C0;
  color: #fff !important;
}

.box-cta-item-link:hover {
  color: #fff !important;
}

.box-cta-item .fa {
  margin-bottom: 10px;
}

.box-cta-cart {
  border: 3px solid #388E3C;
  color: #fff;
  background: #43A047;
  font-size: 1.4rem;
  flex-basis: 95%;
}

.box-cta-cart:hover {
  background: #388E3C;
  color: #fff !important;
}

.box-cta-sample {
  border: 1px solid #0D47A1;
  background: #E3F2FD;
  color: #0D47A1;
}

.box-cta-sample:hover {
  background: #0D47A1;
  color: #fff !important;
}

.box-cta-kaematen {
  border: 1px solid #8BC34A;
  background: #F1F8E9;
  color: #689F38;
}

.box-cta-kaematen:hover {
  background: #8BC34A;
  color: #fff !important;
}

.box-cta-contact {
  border: 1px solid #0097A7;
  background: #E0F7FA;
  color: #0097A7;
}

.box-cta-contact:hover {
  background: #0097A7;
  color: #fff !important;
}

.box-cta-print {
  border: 1px solid #8D6E63;
  background: #EFEBE9;
  color: #8D6E63;
}

.box-cta-print:hover {
  background: #8D6E63;
  color: #fff !important;
}

.box-cta-quick {
  border: 1px solid #E65100;
  background: #FFCDD2;
  color: #212121;
}

.box-cta-quick:hover {
  background: #F44336;
  color: #fafafa !important;
}

.box-cta-item-link {
  color: #2196F3;
  padding: 20px 30px;
  text-decoration: none;
}

.box-cta-block {
  background: #E3F2FD;
  padding: 20px;
}

.box-present-10,
.box-present-20 {
  font-size: 1.1rem;
}

.abtest-new {
  display: none;
}

/* 20191028 検索バーをスクロールに追従 */
.show-on-scroll {
  display: none;
}

@media screen and (min-width:970px) {
  .is-fixed #serach-bar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    width: 100%;
  }

  .is-fixed .show-on-scroll {
    display: block;
  }

  .is-fixed #serach-bar-menu-dropdown {
    max-width: 1080px;
  }

  .is-fixed #serach-bar-menu-dropdown>li {
    width: 13.5%;
  }
}

@keyframes show {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.is-fixed .side_banner {
  display: block;
  animation: show 0.3s linear 0s;
}

@media screen and (max-width: 1250px) {
  .is-fixed .side_banner {
    display: none !important;
  }
}

body.is-modal {
  overflow: hidden;
}

.side_banner {
  display: none;
  position: fixed;
  top: 100px;
  z-index: 10001;
  background: url("/info/wp-content/uploads/sample-sidebanner2.jpg") no-repeat scroll 0 0;
  width: 100px;
  height: 600px;
  cursor: pointer;
}

.side_banner_l {
  left: 0;
}

/* レビューの★ */
.evaluation {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
}

.evaluation input[type='radio'] {
  display: none;
}

.evaluation label {
  position: relative;
  padding: 10px 10px 0;
  color: #757575;
  cursor: pointer;
  font-size: 50px;
}

.evaluation label .text {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  text-align: center;
  font-size: 12px;
  color: #757575;
}

.evaluation label:hover,
.evaluation label:hover~label {
  color: #FFD54F !important;
}

.evaluation input[type='radio']:checked~label {
  color: #FFC107;
}

.product-price-block {
  margin: 1rem 0;
}

.product-price-detail {
  color: #e50000;
  font-weight: bold;
  font-size: 1.6em;
}

.product-price-notice {
  color: #e50000;
  font-weight: bold;
  font-size: 1.3rem;
  text-decoration: underline;
}

.product-price-list {
  font-size: 1.1rem;
}

.product-price-list,
.product-price-list>dt,
.product-price-list>dd {
  box-sizing: border-box;
}

.product-price-list>dt,
.product-price-list>dd {
  padding: 0.2rem;
  margin: 0.1rem 0;
}

.product-price-list>dt {
  text-align: right;
  font-weight: normal;
}

.product-price-list-del {
  text-decoration: line-through;
}

.product-price-list-big {
  font-size: 1.6rem;
  color: #E53935;
  font-weight: bold;
}

.product-price-list-little {
  font-size: 0.8rem;
}

.product-price-list-point {
  font-size: 1.2rem;
  color: #F57C00;
  font-weight: bold;
}

.product-price-list-promo {
  border-bottom: 4px double #e50000;
  line-height: 1.6;
  font-size: 1.5rem;
  font-weight: bold;
}

.product-price-list-stock-ok {
  color: #008a00;
  font-weight: bold;
}

.product-price-list-stock-pre {
  color: #008a00;
}

.product-price-list-stock-soso {
  color: #9D0A0A;
}

.product-price-list-stock-ng {
  color: #9D0A0A;
  font-size: 1.1rem;
}

.product-price-delivery {
  margin-top: 0.5rem;
  font-size: 0.9rem;
}

.product-price-delivery-remaining {
  font-weight: bold;
  font-size: 1.1rem;
  color: #008a00;
}

.product-price-delivery-date {
  font-weight: bold;
  font-size: 1.1rem;
  color: #2196f3;
}

.product-price-delivery-notice {
  font-size: 0.9rem;
}

.product-price-per {
  padding: 0.3rem;
  font-size: 1rem;
}

.product-free-shipping {
  font-size: 1.2rem;
  color: #fafafa;
  background: #F44336;
  display: inline-block;
  padding: 2px 5px;
  margin: 0 0.5rem;
  border-radius: 4px;
}

.product-exclude-point {
  display: inline-block;
  padding: 5px;
  margin: 0.5rem;
  border-radius: 4px;
  font-size: 0.9rem;
  border: solid 2px #5C6BC0;
}

.product-pointback {
  color: #e50000;
  font-weight: bold;
  font-size: 1.4em;
  margin-top: 0.5rem;
}

@media screen and (max-width: 767px) {

  .product-price-list>dt,
  .product-price-list>dd {
    text-align: center;
  }

  .product-price-list>dd>ul {
    text-align: left;
  }
  .tradelaw_table tbody,
  .tradelaw_table td,
  .tradelaw_table th,
  .tradelaw_table tr,
  .tradelaw_table {
    display: block;
    width: 100%;
  }
}

@media print,
(min-width: 768px) {
  .product-price-list {
    display: flex;
    flex-wrap: wrap;
  }

  .product-price-list>dt,
  .product-price-list>dd {
    display: flex;
    flex-direction: column;
    justify-content: start;
  }

  .product-price-list-center {
    justify-content: center !important;
  }

  .product-price-list>dt {
    width: 35%;
  }

  .product-price-list>dd {
    width: 65%;
  }
}

.confirm-mail-addr {
  font-size: 2.1rem;
  font-family: "UD Digi Kyokasho NP-R", "Hiragino Sans", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Meiryo", "MS Gothic", sans-serif;
  font-weight: bold;
  word-break: break-all;
  display: inline-block;
}

/* サンプルフォームの選択肢 */
.form-desc {
  font-size: 1.1rem;
  margin: 1rem 0.5rem;
  line-height: 1.6;
}

.form-button-lead {
  display: block;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  padding: 0.5rem;
  text-decoration: underline;
}

.list-button-two {
  border: solid 2px #FF9800;
  border-radius: 8px;
  border-collapse: separate;
  overflow: hidden;
}

.list-button-two td {
  vertical-align: top;
  padding: 0.2rem;
  width: 50%;
}

.list-button-two tr,
.list-button-two td {
  background: transparent;
  border: none;
}

.list-button-two tr td:nth-child(odd) {
  background: #F1F8E9;
}

.list-button-two-l a,
.list-button-two-r a {
  display: block;
  padding: 1rem;
  margin: 0.5rem;
  border-radius: 8px;
  font-size: 1.2rem;
  font-weight: bold;
  transition: all .3s;
}

.list-button-two span {
  font-size: 1.1rem;
  line-height: 1.5;
  padding: 0 1rem;
  display: block;
}

.list-button-two-l,
.list-button-two-r {
  text-align: center !important;
}

.list-button-two-l a {
  border: 1px solid #43A047;
  background: #4CAF50;
  color: #fafafa;
}

.list-button-two-l a:hover {
  background: #2E7D32;
  color: #fafafa !important;
}

.list-button-two-r a {
  border: 1px solid #0D47A1;
  background: #E3F2FD;
  color: #0D47A1;
}

.list-button-two-r a:hover {
  background: #0D47A1;
  color: #fff !important;
}

/* 支払い画面のポイント */
.payment-point {
  line-height: 1.5;
  ;
}

.payment-point-balance {
  font-size: 1.2rem;
  font-weight: bold;
  color: #E53935;
}

.point_check_use {
  margin-left: 1.5rem;
}

#use_point {
  font-size: 1.2rem;
  padding: 0 5px;
}

/* マイページのポイント表示 */
.mypage_point {
  width: 95%;
  max-width: 400px;
  line-height: 1.5;
}

.mypage_point_balance {
  font-size: 1.2rem;
  font-weight: bold;
  color: #E53935;
}

.is_used_point {
  background: #d7e9ff;
}

.is_got_point {
  background: #ffe2e2;
}

.is_used_point_status {
  color: #1E88E5;
}

.is_got_point_status {
  color: #E53935;
}

.mypage_point_num {
  font-size: 1.2rem;
  font-weight: bold;
}

/* iframeフォーム埋め込み */
.iframe-pd {
  border: 2px solid #ccc;
  border-radius: 1rem;
  margin: 1.5rem 0;
  overflow: hidden;
}

/* コラムランダム表示 */
.random-posts-banner {
  @include media_desktop_sp {
    display: block;
  }
  
}
.topimage-cell.random-posts {
  width: 45%;
  border-bottom: 2px solid #9E9E9E;
  padding: 0 1.5rem 1rem 0;
  margin: 10px 0;
  @include media_desktop_sp {
    width: 100%;
    padding: 0 10px 1rem;
  }
}

.random-posts a {
  display: inline-block;
  width: 90%;
  @include media_desktop_sp {
    width: 100%;
  }
}

.random-posts img {
  max-width: 95%;
  height: auto;
}

.random-posts .ttl03 {
  border-left: 4px solid #388E3C;
  padding-left: 0.5rem;
  font-size: 1rem !important;
  display: inline-block;
}
#other_area_n {
  @include media_desktop_sp {
    margin: 0 10px;
  }
}

.page-list p,
.page-list div {
  font-size: 16px;
  margin: 1rem 0;
}

.sample-items td {
  text-align: center;
}

/* サイドバー検索 */
.category_search_form h3 {
  clear: both;
  background: #E0E0E0;
  border-radius: 0.4rem;
  padding: 0.5rem;
  margin: 0.5rem 0 0.5rem;
  width: 100%;
}

#search_form_lite .threec_dl {
  width: 100%;
}

#search_form_lite dt {
  margin: 0.2rem;
  float: none;
  width: auto;
  font-weight: normal;
}

.side_input {
  padding: 0.3rem !important;
  font-size: 1rem;
  border-radius: 0.2rem;
  width: 100%;
}

.btn-search {
  text-align: center;
  width: 90%;
}

.btn-search input {
  width: 80%;
  margin: 1.5rem 0;
  padding: 0.5rem;
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 0.4rem;
  border: 1px solid #ccc;
  background: #EEEEEE;
}

.btn-search input:hover {
  cursor: pointer;
  opacity: 0.8;
}

.search_lite_button {
  padding: 0.5rem;
  margin-top: 0.5rem;
  margin-right: 0.5rem;
  background: #e6f4fa;
  border: 1px solid #99cde7;
  color: #003b65;
  border-radius: 0.5rem;
  font-size: 1rem;
}

.search_lite_button:after {
  content: "\f057";
  font-family: "Font Awesome 5 Free";
  vertical-align: middle;
  font-size: 1.2rem;
  margin-left: 0.2rem;
}

.search_lite_button:hover {
  cursor: pointer;
  opacity: 0.8;
}

.search_lite_button span {
  vertical-align: middle;
}

.span_bl {
  color: #333;
}

.product_brand_name {
  display: inline-block;
  background: #1E88E5;
  padding: 0.4rem;
  border-radius: 1rem;
  margin: 0.5rem 0;
  color: #fafafa;
}

.product_brand_name span {
  vertical-align: middle;
}
/* 右上メニュー */
.menu-box{
  max-width: 45rem;
  margin: 0 1vh;
  background: #fafafa;
  font-size:1.1rem;
  @include media_desktop_sp {
    margin: 0;
    padding: 0 1vh;
  }
  .item-0{
    text-align: center;
    padding: 0.5rem 1rem;
    font-size: 1.2rem;
    border-left: 2px solid #ccc;
    border-right: 2px solid #ccc;
    a {
      text-decoration: underline;
    }
    @include media_desktop_sp {
      border-top: 2px solid #ccc;
      border-radius: 0.4rem 0.4rem 0 0;
      font-size: 1.0rem;
    }
  }

  .menu-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 2vh;
    position: relative;
    border: 2px solid #ccc;
    border-radius: 0 0 0.4rem 0.4rem;
    list-style: none;
    @include media_desktop_sp {
      align-items: center;
      flex-flow: row wrap;
      margin-bottom: 0.5rem;
      font-size:1.0rem;
      padding: 0;
    }
    li {
      border-right: 2px solid #ccc;
      @include media_desktop {
        &:last-of-type {
          border-right: none;
        }
      }
      @include media_desktop_sp {
        width: 50%;
        &:nth-of-type(even) {
          border-right: none;
        }
        &:nth-of-type(-n+2) {
          border-bottom: 2px solid #ccc;
        }
      }
    }
    .ec-cartNavi,
    li > a {
      padding: 0 1rem;
      margin: 0 0.2rem;
      text-decoration: none;
      transition: all 0.3s;
      display: block;
      @include media_desktop_sp {
        padding: 0.5rem;
      }
      &:hover {
        color: #1E88E5;
      }
    }
  }
}

.detail_table_area {
  width: 100%;
  margin-top: 10px;
}
.detail_table {
  width: 100%;
}
.callme_blk {
    position: relative;
    padding: 0.5em;
    font-size: 100%;
    font-weight: bolder;
    color: #000;
    border-radius: 3px;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.26);
}
.description_list_text {
  font-weight:bold;
  font-size:19px;
}
.detail_bottom_area {
  clear:both;
  padding-top:10px;
  font-size: 12px;
  .common-table {
    width: 96%;
    @include media_desktop {
      width: 100%;
    }
  }
}
.indent {
  text-indent: -1em;
  padding-left: 1em;
}
table.entry_table {
  @include media_desktop_sp {
    display: block;
    width: 100%;
    tbody,
    tr,
    th,
    td {
      display: block;
      width: 100%;
    }
  }
}
table.estimate_table {
  a {
    img {
      max-width: 100px;
      display: block;
      margin: auto;
    }
  }
  .download_image {
    max-width: 100px;
    display: block;
    margin: auto;
  }
  @include media_desktop_sp {
    display: block;
    width: 100%;
    border: none;
    tbody,
    tr,
    td {
      display: block;
      width: 100%;
    }
    th {
      display: none;
    }
    tbody > tr {
      margin-bottom: 20px;
      border: 1px solid #ccc;
    }
    td {
      position: relative;
      text-align: right;
      word-break: break-all;
      padding-left: 8em;
      a {
        img {
          max-width: 150px;
        }
      }
      &:before {
        content: attr(aria-label);
        position: absolute;
        top: 0;
        left: 0;
        padding: 10px;
        text-align: left;
      }
      &.estimate_table__td_btn {
        padding: 10px;
      }
    }
  }
}
.ranking_ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    width: calc(100% / 2 - 10px);
    margin-right: 10px;
    margin-bottom: 10px;
    
    &:nth-of-type(2n) {
      margin-right: 0;
    }
    @include media_desktop {
      width: calc(100% / 5 - 8px);
      &:nth-of-type(2n) {
        margin-right: 8px;
      }
      &:nth-of-type(5n) {
        margin-right: 0;
      }
    }
    .ranking_ul_top {
      display: flex;
      &__rank {
        .ranking_num {
          display: inline-block;
          color: #fff;
          padding-left: 5px;
          padding-right: 5px;
          vertical-align: middle;
          font-size: 25px;
          font-weight: bold;
        }
      }
      &__name {
        word-break: break-all;
        padding: 4px;
      }
    }
    .ranking_ul_img {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1 auto;
      min-height: 0%;
      padding: 0 10px;
      img {
        width: 100%;
      }
    }
    .ranking_ul_bottom {
      padding: 10px;
      text-align: center;
      max-height: 50px;
      a {
        display: inline-block;
        margin-top: auto;
        color: #FF9800;
        font-size: 14px;
        &:before {
          content: "\f144";
          font-family: 'Font Awesome 5 Free';
      }
      }
    }
  }
}