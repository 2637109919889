@import "../mixins/media";
/*
お問い合わせ

お問い合わせ に関する Project コンポーネントを定義します。

ex [お問い合わせ](http://demo3.ec-cube.net/contact)

Markup:
include /assets/tmpl/elements/19.2.contact.pug
+ec-contactRole

Styleguide 19.2

*/
.ec-contactRole{
  @include container;
  & &__actions {
    padding-top:20px;
  }
  p {
    margin:16px 0;
  }

}
.ec-contactConfirmRole {
  @include container;
  & &__actions {
    padding-top:20px;
  }
  .ec-blockBtn--action {
    margin-bottom: 16px;
  }
}
.ec-contactCompleteRole {
  @include container;
}
.contact_top {
  & &__subtitel {
    font-size:1.5em;
    font-weight:bold;
    line-height: 1.1;
  }
  & &__teltext {
    margin-top:10px;
  }
  & &__tel {
    color:#D02428;
    font-size:1.6em;
    font-weight: bold;
  }
  & &__qa {
    @include media_desktop {
      padding-top: 45px;
    }
  }
  .topblk_contact_stepnum {
    color: #fff;
    background-color: #03a9f4;
    display: block;
    padding: 5px;
    @include media_desktop {
      display: inline-block;
    }
  }
  .topblk_contact_steptext {
    padding: 5px;
    display: inline-block;
    @include media_desktop {
      padding: 0;
    }
  }
  .topblk_contact_step_now {
    background-color: #ffc107;
  }
  .topblk_contact_step {
    width: 160px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid #333;
    border-radius: 3px;
  }
  .contact_top_flow {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .contact_top_ul {
    padding: 0;
    @include media_desktop {
      padding-left: 30px;
    }
  }
  .contact_top_flex {
    margin-bottom: 10px;
    @include media_desktop {
      display: flex;
    }
    .btn-push {
      display: inline-block;
    }
    &__right {
      text-align: center;
      margin-top: 10px;
      @include media_desktop {
        padding-left: 1rem;
        margin-top: 0;
      }
      img {
        max-width: 50%;
        @include media_desktop {
          max-width: 100%;
        }
      }
    }
  }
  .contact_qa_flex {
    @include media_desktop {
      display: flex;
    }
  }
}