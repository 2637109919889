@charset "UTF-8";
@import url("../../../../../node_modules/normalize.css/normalize.css");
body {
  font-family: Roboto, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
  color: #525263;
  transition: z-index 0ms 5.28455ms;
  background: #f6f6f6;
  margin: 0; }

a {
  text-decoration: none; }

pre {
  background-color: transparent;
  border: none;
  padding: 16px 0; }

p {
  -webkit-margin-before: 0;
  -webkit-margin-after: 0; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
見出し

ページ内で見出しとして機能する要素のスタイル群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 1.1
*/
/*
見出し

商品紹介等で利用される、一般的な見出しのスタイルです。

ex [商品詳細ページ　商品見出し部分](http://demo3.ec-cube.net/products/detail/27)

Markup:
.ec-headingTitle マトリョーシカ

Styleguide 1.1.1
*/
.ec-headingTitle {
  margin: 0 0 8px;
  font-size: 32px;
  font-weight: normal;
  color: #525263; }

/*
ページヘッダ

各種ページで用いられるページヘッダのデザインです。

ex [利用規約ページ　ページヘッダ部](http://demo3.ec-cube.net/help/agreement)

Markup:
.ec-pageHeader
  h1 利用規約

Styleguide 1.1.2
*/
.ec-pageHeader h1 {
  margin: 0 0 8px;
  border-bottom: 1px dotted #ccc;
  border-top: 1px solid #ccc;
  padding: 8px 0 12px;
  font-size: 16px;
  font-weight: bold; }
  @media only screen and (min-width: 768px) {
    .ec-pageHeader h1 {
      border-top: none;
      border-bottom: 1px solid #ccc;
      margin: 10px 16px 48px;
      padding: 8px;
      font-size: 32px;
      font-weight: bold; } }

/*
サブ見出し

利用規約など、文字主体のページで用いられるサブ見出しです。

ex [利用規約ページ サブ見出し部分](http://demo3.ec-cube.net/help/agreement)

Markup:
.ec-heading 第1条 (会員)

Styleguide 1.1.3
*/
.ec-heading {
  margin: 24px 0; }

/*
サブ見出し(太字)

文字主体のページで用いられるサブ見出しの太字のスタイルです。

ex [プライバシーポリシー サブ見出し部分](http://demo3.ec-cube.net/help/privacy)

Markup:
.ec-heading-bold 個人情報の定義

Styleguide 1.1.4
*/
.ec-heading-bold {
  margin: 16px 0;
  font-size: 16px;
  font-weight: bold; }
  @media only screen and (min-width: 768px) {
    .ec-heading-bold {
      font-size: 18px; } }

/*
背景付き見出し

マイページ注文履歴等で用いられる背景付きの見出しです。

ex [ご注文履歴詳細　背景付き見出し部分](http://demo3.ec-cube.net/mypage/history/1063)

Markup:
.ec-rectHeading
  h2 配送情報
.ec-rectHeading
  h2 お支払について

Styleguide 1.1.5
*/
.ec-rectHeading h1, .ec-rectHeading h2, .ec-rectHeading h3,
.ec-rectHeading h4, .ec-rectHeading h5, .ec-rectHeading h6 {
  background: #F3F3F3;
  padding: 8px 12px;
  font-size: 20px;
  font-weight: bold; }

/*
メッセージ見出し

ユーザが行った操作に対する、完了報告やエラー表示のページで使用される見出しのスタイルです。

ex [注文完了 ログイン後、カートに商品を入れ注文完了まで行う](http://demo3.ec-cube.net/shopping/)

Markup:
.ec-reportHeading
  h2 ご注文ありがとうございました

Styleguide 1.1.6
*/
.ec-reportHeading {
  width: 100%;
  border-top: 1px dotted #ccc;
  margin: 20px 0 30px;
  padding: 0;
  text-align: center;
  font-size: 24px;
  font-weight: bold; }
  @media only screen and (min-width: 768px) {
    .ec-reportHeading {
      border-top: 0;
      font-size: 32px; } }
  .ec-reportHeading h1, .ec-reportHeading h2, .ec-reportHeading h3,
  .ec-reportHeading h4, .ec-reportHeading h5, .ec-reportHeading h6, .ec-reportHeading p {
    font-weight: bold;
    font-size: 24px; }
    @media only screen and (min-width: 768px) {
      .ec-reportHeading h1, .ec-reportHeading h2, .ec-reportHeading h3,
      .ec-reportHeading h4, .ec-reportHeading h5, .ec-reportHeading h6, .ec-reportHeading p {
        font-size: 32px; } }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
文字装飾

文字装飾をするためのスタイル群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 1.2
*/
/*
テキストリンク

テキストリンクのスタイルです。

Markup:
a(href="#").ec-link さくらのクラウド

Styleguide 1.2.1
*/
.ec-link {
  color: #0092C4;
  text-decoration: none;
  cursor: pointer; }
  .ec-link:hover {
    color: #33A8D0;
    text-decoration: none; }

/*
テキスト（太字）

テキストを太くするためのスタイルです。

Markup:
p.ec-font-bold この季節にぴったりな商品をご用意しました

Styleguide 1.2.2
*/
.ec-font-bold {
  font-weight: bold; }

/*
テキスト（グレー）

テキストをグレーにするためのスタイルです。

Markup:
p.ec-color-grey 青色が美しい職人が仕上げた吹きガラス

Styleguide 1.2.3
*/
.ec-color-grey {
  color: #9a947e; }

/*
テキスト（赤）

テキストを赤にするためのスタイルです。

Markup:
p.ec-color-red ¥ 2,728 税込
p.ec-color-accent ¥ 2,728 税込

Styleguide 1.2.4
*/
.ec-color-red {
  color: #DE5D50; }

.ec-color-accent {
  color: #DE5D50; }

/*
フォントサイズ

フォントサイズを指定するためのスタイルです。

Markup:
.ec-font-size-1 さわやかな日差しが過ごしやすい季節
.ec-font-size-2 さわやかな日差しが過ごしやすい季節
.ec-font-size-3 さわやかな日差しが過ごしやすい季節
.ec-font-size-4 さわやかな日差しが過ごしやすい季節
.ec-font-size-5 さわやかな日差しが過ごしやすい季節
.ec-font-size-6 さわやかな日差しが過ごしやすい季節


Styleguide 1.2.5
*/
.ec-font-size-1 {
  font-size: 12px; }

.ec-font-size-2 {
  font-size: 14px; }

.ec-font-size-3 {
  font-size: 16px; }

.ec-font-size-4 {
  font-size: 20px; }

.ec-font-size-5 {
  font-size: 32px; }

.ec-font-size-6 {
  font-size: 40px; }

/*
テキスト水平位置

テキストをセンタリングするためのスタイルです。

Markup:
p.ec-text-ac さわやかな日差しが過ごしやすい季節

Styleguide 1.2.6
*/
.ec-text-ac {
  text-align: center; }

/*
価格テキスト

価格を表示するテキストです。

価格文字にスペースを取るほか、税込み等の表示を小さくする効果もあります。

spanを用いたインライン要素として利用します。

Markup:
div(style="color:#DE5D50;font-size:28px")
    span.ec-price
      span.ec-price__unit ¥
      span.ec-price__price 1,280
      span.ec-price__tax 税込

Styleguide 1.2.7
*/
.ec-price .ec-price__unit {
  font-size: 18px;
  font-weight: bold; }
  @media only screen and (min-width: 768px) {
    .ec-price .ec-price__unit {
      font-size: 1em; } }

.ec-price .ec-price__price {
  display: inline-block;
  padding: 0 .3em;
  font-size: 18px;
  font-weight: bold; }
  @media only screen and (min-width: 768px) {
    .ec-price .ec-price__price {
      font-size: 1em; } }

.ec-price .ec-price__tax {
  font-size: 12px; }
  @media only screen and (min-width: 768px) {
    .ec-price .ec-price__tax {
      font-size: 0.57em; } }

/*
テキストの位置

テキストや、入れ子にしたインライン要素を
「左揃え」「中央揃え」「右揃え」に設定することができます。

Markup:
h3 左揃え
p.text-left
  | Lorem ipsum dolor sit amet, consectetur adipisicing elit. Incidunt praesentium repellat sapiente suscipit, unde veniam! Doloribus error, expedita id impedit iusto qui sint totam? Aspernatur error facere possimus quam quos?
br
h3 中央揃え
p.text-center
  | Lorem ipsum dolor sit amet, consectetur adipisicing elit. Incidunt praesentium repellat sapiente suscipit, unde veniam! Doloribus error, expedita id impedit iusto qui sint totam? Aspernatur error facere possimus quam quos?
br
h3 右揃え
p.text-right
  | Lorem ipsum dolor sit amet, consectetur adipisicing elit. Incidunt praesentium repellat sapiente suscipit, unde veniam! Doloribus error, expedita id impedit iusto qui sint totam? Aspernatur error facere possimus quam quos?

Styleguide 1.2.8
*/
.text-left {
  text-align: left; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

/*
メッセージテキスト

ユーザが行った操作に対する、完了報告やエラー表示のページで使用されるテキストのスタイルです。

ex [注文完了 （ログイン後、カートに商品を入れ注文完了まで行う）](http://demo3.ec-cube.net/shopping/)

Markup:
.ec-reportHeading
  h2 ご注文ありがとうございました
p.ec-reportDescription
      | ただいま、ご注文の確認メールをお送りさせていただきました。
      br
      | 万一、ご確認メールが届かない場合は、トラブルの可能性もありますので大変お手数ではございますがもう一度お問い合わせいただくか、お電話にてお問い合わせくださいませ。
      br
      | 今後ともご愛顧賜りますようよろしくお願い申し上げます。


Styleguide 1.2.9
*/
.ec-reportDescription {
  margin-bottom: 32px;
  text-align: center;
  font-size: 16px;
  line-height: 1.4; }

/*
テキスト下部のスペース

テキストの下に余白を追加することができます。 .ec-para-normalで16pxの余白をつけることができます。

Markup:
p.ec-para-normal 万一、ご確認メールが届かない場合は、トラブルの可能性もありますので大変お手数ではございますがもう一度お問い合わせいただくか、お電話にてお問い合わせくださいませ。
p.ec-para-normal 万一、ご確認メールが届かない場合は、トラブルの可能性もありますので大変お手数ではございますがもう一度お問い合わせいただくか、お電話にてお問い合わせくださいませ。

Styleguide 1.2.10
*/
.ec-para-normal {
  margin-bottom: 16px; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
リスト

シンプルなリストを構成するためのスタイル群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 1.3
*/
/*
水平定義リスト

シンプルな定義リストのスタイルを定義します。

dl要素を用いてコーディングします。

ex [当サイトについて　水平定義リスト部分](http://demo3.ec-cube.net/help/about)

Markup:
dl.ec-definitions
    dt 店名
    dd EC-CUBE3 DEMO SHOP
dl.ec-definitions
    dt 会社名
    dd EC-CUBE3
dl.ec-definitions--soft
    dt 所在地
    dd 〒 550-0001

Styleguide 1.3.1
*/
.ec-definitions, .ec-definitions--soft {
  margin: 5px 0;
  display: block; }
  .ec-definitions dt, .ec-definitions--soft dt, .ec-definitions dd, .ec-definitions--soft dd {
    display: inline-block;
    margin: 0; }
  .ec-definitions dt, .ec-definitions--soft dt {
    font-weight: bold; }

.ec-definitions--soft dt {
  font-weight: normal; }

/*
下線つき定義リスト

線が添えられた定義リストのスタイルを定義します。

dl要素を用いてコーディングします。

ex [当サイトについて　下線つき定義リスト](http://demo3.ec-cube.net/help/about)

Markup:
.ec-borderedDefs
  dl
    dt 店名
    dd EC-CUBE3 DEMO SHOP
  dl
    dt 会社名
    dd EC-CUBE3
  dl
    dt 所在地
    dd 〒550 - 0001

Styleguide 1.3.2
*/
.ec-borderedDefs {
  width: 100%;
  border-top: 1px dotted #ccc;
  margin-bottom: 16px; }
  .ec-borderedDefs dl {
    display: flex;
    border-bottom: 1px dotted #ccc;
    margin: 0;
    padding: 10px 0 0;
    flex-wrap: wrap; }
    @media only screen and (min-width: 768px) {
      .ec-borderedDefs dl {
        flex-wrap: nowrap;
        padding: 15px 0 4px; } }
  .ec-borderedDefs dt, .ec-borderedDefs dd {
    padding: 0; }
  .ec-borderedDefs dt {
    font-weight: normal;
    width: 100%;
    padding-top: 0; }
    @media only screen and (min-width: 768px) {
      .ec-borderedDefs dt {
        padding-top: 14px;
        width: 30%; } }
  .ec-borderedDefs dd {
    padding: 0;
    width: 100%;
    line-height: 2.5; }
    @media only screen and (min-width: 768px) {
      .ec-borderedDefs dd {
        width: 70%;
        line-height: 3; } }
  .ec-borderedDefs p {
    line-height: 1.4; }
  .ec-borderedDefs .contents_top_list li {
    line-height: 1.4; }

.ec-list-chilled {
  display: table-row;
  border: 0 none;
  padding: 8px 0; }
  .ec-list-chilled dt, .ec-list-chilled dd {
    display: table-cell;
    border-bottom: 1px dotted #ccc;
    padding: 0; }
    @media only screen and (min-width: 768px) {
      .ec-list-chilled dt, .ec-list-chilled dd {
        padding: 16px 0; } }
  .ec-list-chilled dt {
    width: 30%; }
  .ec-list-chilled dd {
    padding: 0; }
    @media only screen and (min-width: 768px) {
      .ec-list-chilled dd {
        padding: 16px; } }

/*
ボーダーリスト

線が添えられたリストを表示します。

ex [当サイトについて　ボーダーリスト](http://demo3.ec-cube.net/help/about)

Markup:
ul.ec-borderedList
  li: p lorem
  li: p lorem
  li: p lorem


Styleguide 1.3.3
*/
.ec-borderedList {
  width: 100%;
  border-top: 0;
  list-style: none;
  padding: 0; }
  @media only screen and (min-width: 768px) {
    .ec-borderedList {
      border-top: 1px dotted #ccc; } }
  .ec-borderedList li {
    border-bottom: 1px dotted #ccc; }

.ec-list-chilled {
  display: table-row;
  border: 0 none;
  padding: 8px 0; }
  .ec-list-chilled dt, .ec-list-chilled dd {
    display: table-cell;
    border-bottom: 1px dotted #ccc;
    padding: 16px 0; }
  .ec-list-chilled dt {
    width: 30%; }
  .ec-list-chilled dd {
    padding: 16px; }

/*
ボタンサイズ

ボタンサイズを変更するスタイル群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 2.1
*/
/*
通常ボタン

インラインの要素としてボタンを定義出来ます。

ex [トップページ　ボタン部分](http://demo3.ec-cube.net/)

Markup:
.ec-inlineBtn 住所検索
.ec-inlineBtn--primary もっと見る
.ec-inlineBtn--action カートに入れる
.ec-inlineBtn--cancel キャンセル

Styleguide 2.1.1
*/
.ec-inlineBtn {
  text-decoration: none !important;
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  border-radius: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 16px;
  text-decoration: none;
  color: #525263;
  background-color: #F5F7F8;
  border-color: #ccc; }
  .ec-inlineBtn:focus, .ec-inlineBtn.focus, .ec-inlineBtn:active:focus, .ec-inlineBtn:active.focus, .ec-inlineBtn.active:focus, .ec-inlineBtn.active.focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }
  .ec-inlineBtn:hover, .ec-inlineBtn:focus, .ec-inlineBtn.focus {
    color: #525263;
    text-decoration: none; }
  .ec-inlineBtn:active, .ec-inlineBtn.active {
    outline: 0;
    background-image: none;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .ec-inlineBtn.disabled, .ec-inlineBtn[disabled],
  fieldset[disabled] .ec-inlineBtn {
    cursor: not-allowed;
    filter: alpha(opacity=65);
    opacity: 0.65;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .ec-inlineBtn:focus, .ec-inlineBtn.focus {
    color: #525263;
    background-color: #d7dfe3;
    border-color: #8c8c8c; }
  .ec-inlineBtn:hover {
    color: #525263;
    background-color: #d7dfe3;
    border-color: #adadad; }
  .ec-inlineBtn:active, .ec-inlineBtn.active,
  .open > .ec-inlineBtn.dropdown-toggle {
    color: #525263;
    background-color: #d7dfe3;
    background-image: none;
    border-color: #adadad; }
    .ec-inlineBtn:active:hover, .ec-inlineBtn:active:focus, .ec-inlineBtn:active.focus, .ec-inlineBtn.active:hover, .ec-inlineBtn.active:focus, .ec-inlineBtn.active.focus,
    .open > .ec-inlineBtn.dropdown-toggle:hover,
    .open > .ec-inlineBtn.dropdown-toggle:focus,
    .open > .ec-inlineBtn.dropdown-toggle.focus {
      color: #525263;
      background-color: #c2ced4;
      border-color: #8c8c8c; }
  .ec-inlineBtn.disabled:hover, .ec-inlineBtn.disabled:focus, .ec-inlineBtn.disabled.focus, .ec-inlineBtn[disabled]:hover, .ec-inlineBtn[disabled]:focus, .ec-inlineBtn[disabled].focus,
  fieldset[disabled] .ec-inlineBtn:hover,
  fieldset[disabled] .ec-inlineBtn:focus,
  fieldset[disabled] .ec-inlineBtn.focus {
    background-color: #F5F7F8;
    border-color: #ccc; }
  .ec-inlineBtn .badge {
    color: #F5F7F8;
    background-color: #525263; }
  .ec-inlineBtn .ec-icon img {
    width: 1em;
    vertical-align: text-bottom; }

.ec-inlineBtn--primary {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  border-radius: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 16px;
  text-decoration: none;
  color: #fff;
  background-color: #5CB1B1;
  border-color: #5CB1B1; }
  .ec-inlineBtn--primary:focus, .ec-inlineBtn--primary.focus, .ec-inlineBtn--primary:active:focus, .ec-inlineBtn--primary:active.focus, .ec-inlineBtn--primary.active:focus, .ec-inlineBtn--primary.active.focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }
  .ec-inlineBtn--primary:hover, .ec-inlineBtn--primary:focus, .ec-inlineBtn--primary.focus {
    color: #525263;
    text-decoration: none; }
  .ec-inlineBtn--primary:active, .ec-inlineBtn--primary.active {
    outline: 0;
    background-image: none;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .ec-inlineBtn--primary.disabled, .ec-inlineBtn--primary[disabled],
  fieldset[disabled] .ec-inlineBtn--primary {
    cursor: not-allowed;
    filter: alpha(opacity=65);
    opacity: 0.65;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .ec-inlineBtn--primary:focus, .ec-inlineBtn--primary.focus {
    color: #fff;
    background-color: #479393;
    border-color: #2e6060; }
  .ec-inlineBtn--primary:hover {
    color: #fff;
    background-color: #479393;
    border-color: #438d8d; }
  .ec-inlineBtn--primary:active, .ec-inlineBtn--primary.active,
  .open > .ec-inlineBtn--primary.dropdown-toggle {
    color: #fff;
    background-color: #479393;
    background-image: none;
    border-color: #438d8d; }
    .ec-inlineBtn--primary:active:hover, .ec-inlineBtn--primary:active:focus, .ec-inlineBtn--primary:active.focus, .ec-inlineBtn--primary.active:hover, .ec-inlineBtn--primary.active:focus, .ec-inlineBtn--primary.active.focus,
    .open > .ec-inlineBtn--primary.dropdown-toggle:hover,
    .open > .ec-inlineBtn--primary.dropdown-toggle:focus,
    .open > .ec-inlineBtn--primary.dropdown-toggle.focus {
      color: #fff;
      background-color: #3b7b7b;
      border-color: #2e6060; }
  .ec-inlineBtn--primary.disabled:hover, .ec-inlineBtn--primary.disabled:focus, .ec-inlineBtn--primary.disabled.focus, .ec-inlineBtn--primary[disabled]:hover, .ec-inlineBtn--primary[disabled]:focus, .ec-inlineBtn--primary[disabled].focus,
  fieldset[disabled] .ec-inlineBtn--primary:hover,
  fieldset[disabled] .ec-inlineBtn--primary:focus,
  fieldset[disabled] .ec-inlineBtn--primary.focus {
    background-color: #5CB1B1;
    border-color: #5CB1B1; }
  .ec-inlineBtn--primary .badge {
    color: #5CB1B1;
    background-color: #fff; }
  .ec-inlineBtn--primary .ec-icon img {
    width: 1em;
    vertical-align: text-bottom; }

.ec-inlineBtn--action {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  border-radius: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 16px;
  text-decoration: none;
  color: #fff;
  background-color: #43A047;
  border-color: #43A047;
  text-decoration: none !important; }
  .ec-inlineBtn--action:focus, .ec-inlineBtn--action.focus, .ec-inlineBtn--action:active:focus, .ec-inlineBtn--action:active.focus, .ec-inlineBtn--action.active:focus, .ec-inlineBtn--action.active.focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }
  .ec-inlineBtn--action:hover, .ec-inlineBtn--action:focus, .ec-inlineBtn--action.focus {
    color: #525263;
    text-decoration: none; }
  .ec-inlineBtn--action:active, .ec-inlineBtn--action.active {
    outline: 0;
    background-image: none;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .ec-inlineBtn--action.disabled, .ec-inlineBtn--action[disabled],
  fieldset[disabled] .ec-inlineBtn--action {
    cursor: not-allowed;
    filter: alpha(opacity=65);
    opacity: 0.65;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .ec-inlineBtn--action:focus, .ec-inlineBtn--action.focus {
    color: #fff;
    background-color: #347c37;
    border-color: #1d461f; }
  .ec-inlineBtn--action:hover {
    color: #fff;
    background-color: #347c37;
    border-color: #317534; }
  .ec-inlineBtn--action:active, .ec-inlineBtn--action.active,
  .open > .ec-inlineBtn--action.dropdown-toggle {
    color: #fff;
    background-color: #347c37;
    background-image: none;
    border-color: #317534; }
    .ec-inlineBtn--action:active:hover, .ec-inlineBtn--action:active:focus, .ec-inlineBtn--action:active.focus, .ec-inlineBtn--action.active:hover, .ec-inlineBtn--action.active:focus, .ec-inlineBtn--action.active.focus,
    .open > .ec-inlineBtn--action.dropdown-toggle:hover,
    .open > .ec-inlineBtn--action.dropdown-toggle:focus,
    .open > .ec-inlineBtn--action.dropdown-toggle.focus {
      color: #fff;
      background-color: #29632c;
      border-color: #1d461f; }
  .ec-inlineBtn--action.disabled:hover, .ec-inlineBtn--action.disabled:focus, .ec-inlineBtn--action.disabled.focus, .ec-inlineBtn--action[disabled]:hover, .ec-inlineBtn--action[disabled]:focus, .ec-inlineBtn--action[disabled].focus,
  fieldset[disabled] .ec-inlineBtn--action:hover,
  fieldset[disabled] .ec-inlineBtn--action:focus,
  fieldset[disabled] .ec-inlineBtn--action.focus {
    background-color: #43A047;
    border-color: #43A047; }
  .ec-inlineBtn--action .badge {
    color: #43A047;
    background-color: #fff; }
  .ec-inlineBtn--action .ec-icon img {
    width: 1em;
    vertical-align: text-bottom; }
  .ec-inlineBtn--action:link, .ec-inlineBtn--action:visited {
    color: #fff; }

.ec-inlineBtn--cancel {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  border-radius: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 16px;
  text-decoration: none;
  color: #fff;
  background-color: #525263;
  border-color: #525263; }
  .ec-inlineBtn--cancel:focus, .ec-inlineBtn--cancel.focus, .ec-inlineBtn--cancel:active:focus, .ec-inlineBtn--cancel:active.focus, .ec-inlineBtn--cancel.active:focus, .ec-inlineBtn--cancel.active.focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }
  .ec-inlineBtn--cancel:hover, .ec-inlineBtn--cancel:focus, .ec-inlineBtn--cancel.focus {
    color: #525263;
    text-decoration: none; }
  .ec-inlineBtn--cancel:active, .ec-inlineBtn--cancel.active {
    outline: 0;
    background-image: none;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .ec-inlineBtn--cancel.disabled, .ec-inlineBtn--cancel[disabled],
  fieldset[disabled] .ec-inlineBtn--cancel {
    cursor: not-allowed;
    filter: alpha(opacity=65);
    opacity: 0.65;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .ec-inlineBtn--cancel:focus, .ec-inlineBtn--cancel.focus {
    color: #fff;
    background-color: #3b3b47;
    border-color: #18181d; }
  .ec-inlineBtn--cancel:hover {
    color: #fff;
    background-color: #3b3b47;
    border-color: #363642; }
  .ec-inlineBtn--cancel:active, .ec-inlineBtn--cancel.active,
  .open > .ec-inlineBtn--cancel.dropdown-toggle {
    color: #fff;
    background-color: #3b3b47;
    background-image: none;
    border-color: #363642; }
    .ec-inlineBtn--cancel:active:hover, .ec-inlineBtn--cancel:active:focus, .ec-inlineBtn--cancel:active.focus, .ec-inlineBtn--cancel.active:hover, .ec-inlineBtn--cancel.active:focus, .ec-inlineBtn--cancel.active.focus,
    .open > .ec-inlineBtn--cancel.dropdown-toggle:hover,
    .open > .ec-inlineBtn--cancel.dropdown-toggle:focus,
    .open > .ec-inlineBtn--cancel.dropdown-toggle.focus {
      color: #fff;
      background-color: #2b2b34;
      border-color: #18181d; }
  .ec-inlineBtn--cancel.disabled:hover, .ec-inlineBtn--cancel.disabled:focus, .ec-inlineBtn--cancel.disabled.focus, .ec-inlineBtn--cancel[disabled]:hover, .ec-inlineBtn--cancel[disabled]:focus, .ec-inlineBtn--cancel[disabled].focus,
  fieldset[disabled] .ec-inlineBtn--cancel:hover,
  fieldset[disabled] .ec-inlineBtn--cancel:focus,
  fieldset[disabled] .ec-inlineBtn--cancel.focus {
    background-color: #525263;
    border-color: #525263; }
  .ec-inlineBtn--cancel .badge {
    color: #525263;
    background-color: #fff; }
  .ec-inlineBtn--cancel .ec-icon img {
    width: 1em;
    vertical-align: text-bottom; }

/*
ブロックボタン（全幅）

ボタンサイズは em で指定するため、テキストサイズの変更でボタンサイズを変更できます。

ex [商品詳細ページ　カートボタン部分](http://demo3.ec-cube.net/products/detail/30)

Markup:
p: .ec-blockBtn 住所検索
p: .ec-blockBtn--primary もっと見る
p: .ec-blockBtn--action カートに入れる
p: .ec-blockBtn--cancel キャンセル

Styleguide 2.1.2
*/
.ec-blockBtn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  border-radius: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 16px;
  text-decoration: none;
  color: #525263;
  background-color: #F5F7F8;
  border-color: #ccc;
  display: block;
  width: 100%;
  height: 56px;
  line-height: 56px;
  padding-top: 0;
  padding-bottom: 0; }
  .ec-blockBtn:focus, .ec-blockBtn.focus, .ec-blockBtn:active:focus, .ec-blockBtn:active.focus, .ec-blockBtn.active:focus, .ec-blockBtn.active.focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }
  .ec-blockBtn:hover, .ec-blockBtn:focus, .ec-blockBtn.focus {
    color: #525263;
    text-decoration: none; }
  .ec-blockBtn:active, .ec-blockBtn.active {
    outline: 0;
    background-image: none;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .ec-blockBtn.disabled, .ec-blockBtn[disabled],
  fieldset[disabled] .ec-blockBtn {
    cursor: not-allowed;
    filter: alpha(opacity=65);
    opacity: 0.65;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .ec-blockBtn:focus, .ec-blockBtn.focus {
    color: #525263;
    background-color: #d7dfe3;
    border-color: #8c8c8c; }
  .ec-blockBtn:hover {
    color: #525263;
    background-color: #d7dfe3;
    border-color: #adadad; }
  .ec-blockBtn:active, .ec-blockBtn.active,
  .open > .ec-blockBtn.dropdown-toggle {
    color: #525263;
    background-color: #d7dfe3;
    background-image: none;
    border-color: #adadad; }
    .ec-blockBtn:active:hover, .ec-blockBtn:active:focus, .ec-blockBtn:active.focus, .ec-blockBtn.active:hover, .ec-blockBtn.active:focus, .ec-blockBtn.active.focus,
    .open > .ec-blockBtn.dropdown-toggle:hover,
    .open > .ec-blockBtn.dropdown-toggle:focus,
    .open > .ec-blockBtn.dropdown-toggle.focus {
      color: #525263;
      background-color: #c2ced4;
      border-color: #8c8c8c; }
  .ec-blockBtn.disabled:hover, .ec-blockBtn.disabled:focus, .ec-blockBtn.disabled.focus, .ec-blockBtn[disabled]:hover, .ec-blockBtn[disabled]:focus, .ec-blockBtn[disabled].focus,
  fieldset[disabled] .ec-blockBtn:hover,
  fieldset[disabled] .ec-blockBtn:focus,
  fieldset[disabled] .ec-blockBtn.focus {
    background-color: #F5F7F8;
    border-color: #ccc; }
  .ec-blockBtn .badge {
    color: #F5F7F8;
    background-color: #525263; }
  .ec-blockBtn .ec-icon img {
    width: 1em;
    vertical-align: text-bottom; }

.ec-blockBtn--primary {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  border-radius: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 16px;
  text-decoration: none;
  color: #fff;
  background-color: #5CB1B1;
  border-color: #5CB1B1;
  display: block;
  width: 100%;
  height: 56px;
  line-height: 56px;
  padding-top: 0;
  padding-bottom: 0; }
  .ec-blockBtn--primary:focus, .ec-blockBtn--primary.focus, .ec-blockBtn--primary:active:focus, .ec-blockBtn--primary:active.focus, .ec-blockBtn--primary.active:focus, .ec-blockBtn--primary.active.focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }
  .ec-blockBtn--primary:hover, .ec-blockBtn--primary:focus, .ec-blockBtn--primary.focus {
    color: #525263;
    text-decoration: none; }
  .ec-blockBtn--primary:active, .ec-blockBtn--primary.active {
    outline: 0;
    background-image: none;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .ec-blockBtn--primary.disabled, .ec-blockBtn--primary[disabled],
  fieldset[disabled] .ec-blockBtn--primary {
    cursor: not-allowed;
    filter: alpha(opacity=65);
    opacity: 0.65;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .ec-blockBtn--primary:focus, .ec-blockBtn--primary.focus {
    color: #fff;
    background-color: #479393;
    border-color: #2e6060; }
  .ec-blockBtn--primary:hover {
    color: #fff;
    background-color: #479393;
    border-color: #438d8d; }
  .ec-blockBtn--primary:active, .ec-blockBtn--primary.active,
  .open > .ec-blockBtn--primary.dropdown-toggle {
    color: #fff;
    background-color: #479393;
    background-image: none;
    border-color: #438d8d; }
    .ec-blockBtn--primary:active:hover, .ec-blockBtn--primary:active:focus, .ec-blockBtn--primary:active.focus, .ec-blockBtn--primary.active:hover, .ec-blockBtn--primary.active:focus, .ec-blockBtn--primary.active.focus,
    .open > .ec-blockBtn--primary.dropdown-toggle:hover,
    .open > .ec-blockBtn--primary.dropdown-toggle:focus,
    .open > .ec-blockBtn--primary.dropdown-toggle.focus {
      color: #fff;
      background-color: #3b7b7b;
      border-color: #2e6060; }
  .ec-blockBtn--primary.disabled:hover, .ec-blockBtn--primary.disabled:focus, .ec-blockBtn--primary.disabled.focus, .ec-blockBtn--primary[disabled]:hover, .ec-blockBtn--primary[disabled]:focus, .ec-blockBtn--primary[disabled].focus,
  fieldset[disabled] .ec-blockBtn--primary:hover,
  fieldset[disabled] .ec-blockBtn--primary:focus,
  fieldset[disabled] .ec-blockBtn--primary.focus {
    background-color: #5CB1B1;
    border-color: #5CB1B1; }
  .ec-blockBtn--primary .badge {
    color: #5CB1B1;
    background-color: #fff; }
  .ec-blockBtn--primary .ec-icon img {
    width: 1em;
    vertical-align: text-bottom; }

.ec-blockBtn--action {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  border-radius: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 16px;
  text-decoration: none;
  color: #fff;
  background-color: #43A047;
  border-color: #43A047;
  display: block;
  width: 100%;
  padding: 15px 10px 15px;
  text-decoration: none !important; }
  .ec-blockBtn--action:focus, .ec-blockBtn--action.focus, .ec-blockBtn--action:active:focus, .ec-blockBtn--action:active.focus, .ec-blockBtn--action.active:focus, .ec-blockBtn--action.active.focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }
  .ec-blockBtn--action:hover, .ec-blockBtn--action:focus, .ec-blockBtn--action.focus {
    color: #525263;
    text-decoration: none; }
  .ec-blockBtn--action:active, .ec-blockBtn--action.active {
    outline: 0;
    background-image: none;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .ec-blockBtn--action.disabled, .ec-blockBtn--action[disabled],
  fieldset[disabled] .ec-blockBtn--action {
    cursor: not-allowed;
    filter: alpha(opacity=65);
    opacity: 0.65;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .ec-blockBtn--action:focus, .ec-blockBtn--action.focus {
    color: #fff;
    background-color: #347c37;
    border-color: #1d461f; }
  .ec-blockBtn--action:hover {
    color: #fff;
    background-color: #347c37;
    border-color: #317534; }
  .ec-blockBtn--action:active, .ec-blockBtn--action.active,
  .open > .ec-blockBtn--action.dropdown-toggle {
    color: #fff;
    background-color: #347c37;
    background-image: none;
    border-color: #317534; }
    .ec-blockBtn--action:active:hover, .ec-blockBtn--action:active:focus, .ec-blockBtn--action:active.focus, .ec-blockBtn--action.active:hover, .ec-blockBtn--action.active:focus, .ec-blockBtn--action.active.focus,
    .open > .ec-blockBtn--action.dropdown-toggle:hover,
    .open > .ec-blockBtn--action.dropdown-toggle:focus,
    .open > .ec-blockBtn--action.dropdown-toggle.focus {
      color: #fff;
      background-color: #29632c;
      border-color: #1d461f; }
  .ec-blockBtn--action.disabled:hover, .ec-blockBtn--action.disabled:focus, .ec-blockBtn--action.disabled.focus, .ec-blockBtn--action[disabled]:hover, .ec-blockBtn--action[disabled]:focus, .ec-blockBtn--action[disabled].focus,
  fieldset[disabled] .ec-blockBtn--action:hover,
  fieldset[disabled] .ec-blockBtn--action:focus,
  fieldset[disabled] .ec-blockBtn--action.focus {
    background-color: #43A047;
    border-color: #43A047; }
  .ec-blockBtn--action .badge {
    color: #43A047;
    background-color: #fff; }
  .ec-blockBtn--action .ec-icon img {
    width: 1em;
    vertical-align: text-bottom; }
  .ec-blockBtn--action:link, .ec-blockBtn--action:visited {
    color: #fff; }
  .ec-blockBtn--action.add-cart {
    line-height: 2;
    padding: 15px 10px 5px;
    font-size: 20px;
    border: 3px solid #388E3C;
    border-radius: 4px; }
    .ec-blockBtn--action.add-cart i {
      display: block; }

.ec-blockBtn--cancel {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  border-radius: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 16px;
  text-decoration: none;
  color: #fff;
  background-color: #525263;
  border-color: #525263;
  display: block;
  width: 100%;
  height: 56px;
  line-height: 56px;
  padding-top: 0;
  padding-bottom: 0;
  text-decoration: none !important; }
  .ec-blockBtn--cancel:focus, .ec-blockBtn--cancel.focus, .ec-blockBtn--cancel:active:focus, .ec-blockBtn--cancel:active.focus, .ec-blockBtn--cancel.active:focus, .ec-blockBtn--cancel.active.focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }
  .ec-blockBtn--cancel:hover, .ec-blockBtn--cancel:focus, .ec-blockBtn--cancel.focus {
    color: #525263;
    text-decoration: none; }
  .ec-blockBtn--cancel:active, .ec-blockBtn--cancel.active {
    outline: 0;
    background-image: none;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .ec-blockBtn--cancel.disabled, .ec-blockBtn--cancel[disabled],
  fieldset[disabled] .ec-blockBtn--cancel {
    cursor: not-allowed;
    filter: alpha(opacity=65);
    opacity: 0.65;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .ec-blockBtn--cancel:focus, .ec-blockBtn--cancel.focus {
    color: #fff;
    background-color: #3b3b47;
    border-color: #18181d; }
  .ec-blockBtn--cancel:hover {
    color: #fff;
    background-color: #3b3b47;
    border-color: #363642; }
  .ec-blockBtn--cancel:active, .ec-blockBtn--cancel.active,
  .open > .ec-blockBtn--cancel.dropdown-toggle {
    color: #fff;
    background-color: #3b3b47;
    background-image: none;
    border-color: #363642; }
    .ec-blockBtn--cancel:active:hover, .ec-blockBtn--cancel:active:focus, .ec-blockBtn--cancel:active.focus, .ec-blockBtn--cancel.active:hover, .ec-blockBtn--cancel.active:focus, .ec-blockBtn--cancel.active.focus,
    .open > .ec-blockBtn--cancel.dropdown-toggle:hover,
    .open > .ec-blockBtn--cancel.dropdown-toggle:focus,
    .open > .ec-blockBtn--cancel.dropdown-toggle.focus {
      color: #fff;
      background-color: #2b2b34;
      border-color: #18181d; }
  .ec-blockBtn--cancel.disabled:hover, .ec-blockBtn--cancel.disabled:focus, .ec-blockBtn--cancel.disabled.focus, .ec-blockBtn--cancel[disabled]:hover, .ec-blockBtn--cancel[disabled]:focus, .ec-blockBtn--cancel[disabled].focus,
  fieldset[disabled] .ec-blockBtn--cancel:hover,
  fieldset[disabled] .ec-blockBtn--cancel:focus,
  fieldset[disabled] .ec-blockBtn--cancel.focus {
    background-color: #525263;
    border-color: #525263; }
  .ec-blockBtn--cancel .badge {
    color: #525263;
    background-color: #fff; }
  .ec-blockBtn--cancel .ec-icon img {
    width: 1em;
    vertical-align: text-bottom; }
  .ec-blockBtn--cancel:link, .ec-blockBtn--cancel:visited {
    color: #fff; }

/*
アイコンボタン

SVGアイコンを用いたアイコンボタンです。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>

Styleguide 2.2
*/
/*
アイコンボタン

閉じるなどSVGアイコンを用いたボタン装飾で利用します。

ex [ログイン画面　☓ボタン部分](http://demo3.ec-cube.net/mypage/login)

Markup:
a.ec-closeBtn
  .ec-icon
    img(src='/moc/icon/cross.svg', alt='close')

Styleguide 2.2.1
*/
.ec-closeBtn {
  cursor: pointer; }
  .ec-closeBtn .ec-icon img {
    display: inline-block;
    margin-right: 5px;
    width: 1em;
    height: 1em;
    position: relative;
    top: -1px;
    vertical-align: middle; }

/*
アイコンボタン(○)

閉じるなどSVGアイコンを用いたボタン装飾で利用します。

ex [ログイン画面　☓ボタン部分](http://demo3.ec-cube.net/mypage/login)



ex [お届け先編集画面　☓ボタン部分](http://demo3.ec-cube.net/mypage/delivery)

Markup:
a.ec-closeBtn--circle
  .ec-icon
    img(src='/moc/icon/cross-white.svg', alt='close')

Styleguide 2.2.2
*/
.ec-closeBtn--circle {
  display: block;
  border: 0 none;
  padding: 0;
  margin: 0;
  text-shadow: none;
  box-shadow: none;
  border-radius: 50%;
  background: #B8BEC4;
  cursor: pointer;
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  line-height: 40px;
  vertical-align: middle;
  position: relative;
  text-align: center; }
  .ec-closeBtn--circle .ec-icon img {
    display: block;
    margin-top: -.5em;
    margin-left: -.5em;
    width: 1em;
    height: 1em;
    position: absolute;
    top: 50%;
    left: 50%; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
その他のボタン

通常のボタンや、アイコンボタン以外のボタンを定義します。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 2.3
*/
/*
ページトップボタン

ページトップボタンを表示します

ex [商品詳細ページ　カートボタン部分](http://demo3.ec-cube.net/products/detail/30)

Markup:
.ec-blockTopBtn

Styleguide 2.3.1
*/
.ec-blockTopBtn {
  display: none;
  position: fixed;
  width: 120px;
  height: 40px;
  right: 0;
  bottom: 10px;
  cursor: pointer;
  color: #FFFFFF;
  text-align: center;
  line-height: 40px;
  opacity: 0.8;
  background-color: #9da3a9; }
  @media only screen and (min-width: 768px) {
    .ec-blockTopBtn {
      right: 30px;
      bottom: 30px; } }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
フォーム部品(テキスト)

テキストや数値の入力項目に関する要素を定義します。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 3.1
*/
/*
フォーム

`.ec-input` 要素は全ての入力項目に関する標準的なコンポーネントクラスです。


ex [会員情報編集画面　フォーム部分](http://demo3.ec-cube.net/mypage/change)

Markup:
p.ec-input
  input(type="number")
p.ec-input
  textarea(rows="6")

Styleguide 3.1.1
*/
.ec-input input[type="search"], .ec-halfInput input[type="search"], .ec-numberInput input[type="search"], .ec-zipInput input[type="search"], .ec-telInput input[type="search"], .ec-select input[type="search"], .ec-birth input[type="search"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.ec-input input[type="radio"], .ec-halfInput input[type="radio"], .ec-numberInput input[type="radio"], .ec-zipInput input[type="radio"], .ec-telInput input[type="radio"], .ec-select input[type="radio"], .ec-birth input[type="radio"],
.ec-input input[type="checkbox"],
.ec-halfInput input[type="checkbox"],
.ec-numberInput input[type="checkbox"],
.ec-zipInput input[type="checkbox"],
.ec-telInput input[type="checkbox"],
.ec-select input[type="checkbox"],
.ec-birth input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9;
  line-height: normal; }

.ec-input input[type="file"], .ec-halfInput input[type="file"], .ec-numberInput input[type="file"], .ec-zipInput input[type="file"], .ec-telInput input[type="file"], .ec-select input[type="file"], .ec-birth input[type="file"] {
  display: block; }

.ec-input input[type="range"], .ec-halfInput input[type="range"], .ec-numberInput input[type="range"], .ec-zipInput input[type="range"], .ec-telInput input[type="range"], .ec-select input[type="range"], .ec-birth input[type="range"] {
  display: block;
  width: 100%; }

.ec-input select[multiple], .ec-halfInput select[multiple], .ec-numberInput select[multiple], .ec-zipInput select[multiple], .ec-telInput select[multiple], .ec-select select[multiple], .ec-birth select[multiple],
.ec-input select[size],
.ec-halfInput select[size],
.ec-numberInput select[size],
.ec-zipInput select[size],
.ec-telInput select[size],
.ec-select select[size],
.ec-birth select[size] {
  height: auto; }

.ec-input input[type="file"]:focus, .ec-halfInput input[type="file"]:focus, .ec-numberInput input[type="file"]:focus, .ec-zipInput input[type="file"]:focus, .ec-telInput input[type="file"]:focus, .ec-select input[type="file"]:focus, .ec-birth input[type="file"]:focus,
.ec-input input[type="radio"]:focus,
.ec-halfInput input[type="radio"]:focus,
.ec-numberInput input[type="radio"]:focus,
.ec-zipInput input[type="radio"]:focus,
.ec-telInput input[type="radio"]:focus,
.ec-select input[type="radio"]:focus,
.ec-birth input[type="radio"]:focus,
.ec-input input[type="checkbox"]:focus,
.ec-halfInput input[type="checkbox"]:focus,
.ec-numberInput input[type="checkbox"]:focus,
.ec-zipInput input[type="checkbox"]:focus,
.ec-telInput input[type="checkbox"]:focus,
.ec-select input[type="checkbox"]:focus,
.ec-birth input[type="checkbox"]:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px; }

.ec-input input, .ec-halfInput input, .ec-numberInput input, .ec-zipInput input, .ec-telInput input, .ec-select input, .ec-birth input {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.42857;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-appearance: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  border-radius: 3px; }
  .ec-input input:focus, .ec-halfInput input:focus, .ec-numberInput input:focus, .ec-zipInput input:focus, .ec-telInput input:focus, .ec-select input:focus, .ec-birth input:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }
  .ec-input input::-moz-placeholder, .ec-halfInput input::-moz-placeholder, .ec-numberInput input::-moz-placeholder, .ec-zipInput input::-moz-placeholder, .ec-telInput input::-moz-placeholder, .ec-select input::-moz-placeholder, .ec-birth input::-moz-placeholder {
    color: #999;
    opacity: 1; }
  .ec-input input:-ms-input-placeholder, .ec-halfInput input:-ms-input-placeholder, .ec-numberInput input:-ms-input-placeholder, .ec-zipInput input:-ms-input-placeholder, .ec-telInput input:-ms-input-placeholder, .ec-select input:-ms-input-placeholder, .ec-birth input:-ms-input-placeholder {
    color: #999; }
  .ec-input input::-webkit-input-placeholder, .ec-halfInput input::-webkit-input-placeholder, .ec-numberInput input::-webkit-input-placeholder, .ec-zipInput input::-webkit-input-placeholder, .ec-telInput input::-webkit-input-placeholder, .ec-select input::-webkit-input-placeholder, .ec-birth input::-webkit-input-placeholder {
    color: #999; }
  .ec-input input::-ms-expand, .ec-halfInput input::-ms-expand, .ec-numberInput input::-ms-expand, .ec-zipInput input::-ms-expand, .ec-telInput input::-ms-expand, .ec-select input::-ms-expand, .ec-birth input::-ms-expand {
    border: 0;
    background-color: transparent; }
  .ec-input input[disabled], .ec-halfInput input[disabled], .ec-numberInput input[disabled], .ec-zipInput input[disabled], .ec-telInput input[disabled], .ec-select input[disabled], .ec-birth input[disabled], .ec-input input[readonly], .ec-halfInput input[readonly], .ec-numberInput input[readonly], .ec-zipInput input[readonly], .ec-telInput input[readonly], .ec-select input[readonly], .ec-birth input[readonly],
  fieldset[disabled] .ec-input input,
  fieldset[disabled] .ec-halfInput input,
  fieldset[disabled] .ec-numberInput input,
  fieldset[disabled] .ec-zipInput input,
  fieldset[disabled] .ec-telInput input,
  fieldset[disabled] .ec-select input,
  fieldset[disabled] .ec-birth input {
    background-color: #eeeeee;
    opacity: 1; }
  .ec-input input[disabled], .ec-halfInput input[disabled], .ec-numberInput input[disabled], .ec-zipInput input[disabled], .ec-telInput input[disabled], .ec-select input[disabled], .ec-birth input[disabled],
  fieldset[disabled] .ec-input input,
  fieldset[disabled] .ec-halfInput input,
  fieldset[disabled] .ec-numberInput input,
  fieldset[disabled] .ec-zipInput input,
  fieldset[disabled] .ec-telInput input,
  fieldset[disabled] .ec-select input,
  fieldset[disabled] .ec-birth input {
    cursor: not-allowed; }
  @media only screen and (min-width: 768px) {
    .ec-input input, .ec-halfInput input, .ec-numberInput input, .ec-zipInput input, .ec-telInput input, .ec-select input, .ec-birth input {
      font-size: 14px; } }

.ec-input select, .ec-halfInput select, .ec-numberInput select, .ec-zipInput select, .ec-telInput select, .ec-select select, .ec-birth select {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.42857;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-appearance: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  border-radius: 3px; }
  .ec-input select:focus, .ec-halfInput select:focus, .ec-numberInput select:focus, .ec-zipInput select:focus, .ec-telInput select:focus, .ec-select select:focus, .ec-birth select:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }
  .ec-input select::-moz-placeholder, .ec-halfInput select::-moz-placeholder, .ec-numberInput select::-moz-placeholder, .ec-zipInput select::-moz-placeholder, .ec-telInput select::-moz-placeholder, .ec-select select::-moz-placeholder, .ec-birth select::-moz-placeholder {
    color: #999;
    opacity: 1; }
  .ec-input select:-ms-input-placeholder, .ec-halfInput select:-ms-input-placeholder, .ec-numberInput select:-ms-input-placeholder, .ec-zipInput select:-ms-input-placeholder, .ec-telInput select:-ms-input-placeholder, .ec-select select:-ms-input-placeholder, .ec-birth select:-ms-input-placeholder {
    color: #999; }
  .ec-input select::-webkit-input-placeholder, .ec-halfInput select::-webkit-input-placeholder, .ec-numberInput select::-webkit-input-placeholder, .ec-zipInput select::-webkit-input-placeholder, .ec-telInput select::-webkit-input-placeholder, .ec-select select::-webkit-input-placeholder, .ec-birth select::-webkit-input-placeholder {
    color: #999; }
  .ec-input select::-ms-expand, .ec-halfInput select::-ms-expand, .ec-numberInput select::-ms-expand, .ec-zipInput select::-ms-expand, .ec-telInput select::-ms-expand, .ec-select select::-ms-expand, .ec-birth select::-ms-expand {
    border: 0;
    background-color: transparent; }
  .ec-input select[disabled], .ec-halfInput select[disabled], .ec-numberInput select[disabled], .ec-zipInput select[disabled], .ec-telInput select[disabled], .ec-select select[disabled], .ec-birth select[disabled], .ec-input select[readonly], .ec-halfInput select[readonly], .ec-numberInput select[readonly], .ec-zipInput select[readonly], .ec-telInput select[readonly], .ec-select select[readonly], .ec-birth select[readonly],
  fieldset[disabled] .ec-input select,
  fieldset[disabled] .ec-halfInput select,
  fieldset[disabled] .ec-numberInput select,
  fieldset[disabled] .ec-zipInput select,
  fieldset[disabled] .ec-telInput select,
  fieldset[disabled] .ec-select select,
  fieldset[disabled] .ec-birth select {
    background-color: #eeeeee;
    opacity: 1; }
  .ec-input select[disabled], .ec-halfInput select[disabled], .ec-numberInput select[disabled], .ec-zipInput select[disabled], .ec-telInput select[disabled], .ec-select select[disabled], .ec-birth select[disabled],
  fieldset[disabled] .ec-input select,
  fieldset[disabled] .ec-halfInput select,
  fieldset[disabled] .ec-numberInput select,
  fieldset[disabled] .ec-zipInput select,
  fieldset[disabled] .ec-telInput select,
  fieldset[disabled] .ec-select select,
  fieldset[disabled] .ec-birth select {
    cursor: not-allowed; }
  @media only screen and (min-width: 768px) {
    .ec-input select, .ec-halfInput select, .ec-numberInput select, .ec-zipInput select, .ec-telInput select, .ec-select select, .ec-birth select {
      font-size: 14px; } }

.ec-input textarea, .ec-halfInput textarea, .ec-numberInput textarea, .ec-zipInput textarea, .ec-telInput textarea, .ec-select textarea, .ec-birth textarea {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.42857;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-appearance: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  border-radius: 3px; }
  .ec-input textarea:focus, .ec-halfInput textarea:focus, .ec-numberInput textarea:focus, .ec-zipInput textarea:focus, .ec-telInput textarea:focus, .ec-select textarea:focus, .ec-birth textarea:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }
  .ec-input textarea::-moz-placeholder, .ec-halfInput textarea::-moz-placeholder, .ec-numberInput textarea::-moz-placeholder, .ec-zipInput textarea::-moz-placeholder, .ec-telInput textarea::-moz-placeholder, .ec-select textarea::-moz-placeholder, .ec-birth textarea::-moz-placeholder {
    color: #999;
    opacity: 1; }
  .ec-input textarea:-ms-input-placeholder, .ec-halfInput textarea:-ms-input-placeholder, .ec-numberInput textarea:-ms-input-placeholder, .ec-zipInput textarea:-ms-input-placeholder, .ec-telInput textarea:-ms-input-placeholder, .ec-select textarea:-ms-input-placeholder, .ec-birth textarea:-ms-input-placeholder {
    color: #999; }
  .ec-input textarea::-webkit-input-placeholder, .ec-halfInput textarea::-webkit-input-placeholder, .ec-numberInput textarea::-webkit-input-placeholder, .ec-zipInput textarea::-webkit-input-placeholder, .ec-telInput textarea::-webkit-input-placeholder, .ec-select textarea::-webkit-input-placeholder, .ec-birth textarea::-webkit-input-placeholder {
    color: #999; }
  .ec-input textarea::-ms-expand, .ec-halfInput textarea::-ms-expand, .ec-numberInput textarea::-ms-expand, .ec-zipInput textarea::-ms-expand, .ec-telInput textarea::-ms-expand, .ec-select textarea::-ms-expand, .ec-birth textarea::-ms-expand {
    border: 0;
    background-color: transparent; }
  .ec-input textarea[disabled], .ec-halfInput textarea[disabled], .ec-numberInput textarea[disabled], .ec-zipInput textarea[disabled], .ec-telInput textarea[disabled], .ec-select textarea[disabled], .ec-birth textarea[disabled], .ec-input textarea[readonly], .ec-halfInput textarea[readonly], .ec-numberInput textarea[readonly], .ec-zipInput textarea[readonly], .ec-telInput textarea[readonly], .ec-select textarea[readonly], .ec-birth textarea[readonly],
  fieldset[disabled] .ec-input textarea,
  fieldset[disabled] .ec-halfInput textarea,
  fieldset[disabled] .ec-numberInput textarea,
  fieldset[disabled] .ec-zipInput textarea,
  fieldset[disabled] .ec-telInput textarea,
  fieldset[disabled] .ec-select textarea,
  fieldset[disabled] .ec-birth textarea {
    background-color: #eeeeee;
    opacity: 1; }
  .ec-input textarea[disabled], .ec-halfInput textarea[disabled], .ec-numberInput textarea[disabled], .ec-zipInput textarea[disabled], .ec-telInput textarea[disabled], .ec-select textarea[disabled], .ec-birth textarea[disabled],
  fieldset[disabled] .ec-input textarea,
  fieldset[disabled] .ec-halfInput textarea,
  fieldset[disabled] .ec-numberInput textarea,
  fieldset[disabled] .ec-zipInput textarea,
  fieldset[disabled] .ec-telInput textarea,
  fieldset[disabled] .ec-select textarea,
  fieldset[disabled] .ec-birth textarea {
    cursor: not-allowed; }
  @media only screen and (min-width: 768px) {
    .ec-input textarea, .ec-halfInput textarea, .ec-numberInput textarea, .ec-zipInput textarea, .ec-telInput textarea, .ec-select textarea, .ec-birth textarea {
      font-size: 14px; } }

.ec-input input:focus, .ec-halfInput input:focus, .ec-numberInput input:focus, .ec-zipInput input:focus, .ec-telInput input:focus, .ec-select input:focus, .ec-birth input:focus, .ec-input textarea:focus, .ec-halfInput textarea:focus, .ec-numberInput textarea:focus, .ec-zipInput textarea:focus, .ec-telInput textarea:focus, .ec-select textarea:focus, .ec-birth textarea:focus {
  box-shadow: none;
  border-color: #3c8dbc; }

.ec-input input, .ec-halfInput input, .ec-numberInput input, .ec-zipInput input, .ec-telInput input, .ec-select input, .ec-birth input {
  height: 40px;
  margin-bottom: 10px; }
  @media only screen and (min-width: 768px) {
    .ec-input input, .ec-halfInput input, .ec-numberInput input, .ec-zipInput input, .ec-telInput input, .ec-select input, .ec-birth input {
      margin-bottom: 16px; } }

.ec-input textarea, .ec-halfInput textarea, .ec-numberInput textarea, .ec-zipInput textarea, .ec-telInput textarea, .ec-select textarea, .ec-birth textarea {
  height: auto;
  min-height: 100px; }

.ec-input p, .ec-halfInput p, .ec-numberInput p, .ec-zipInput p, .ec-telInput p, .ec-select p, .ec-birth p {
  line-height: 1.4; }

.ec-input .ec-errorMessage, .ec-halfInput .ec-errorMessage, .ec-numberInput .ec-errorMessage, .ec-zipInput .ec-errorMessage, .ec-telInput .ec-errorMessage, .ec-select .ec-errorMessage, .ec-birth .ec-errorMessage {
  margin-bottom: 25px;
  font-size: 12px;
  font-weight: bold;
  color: #DE5D50; }

.error.ec-input input, .error.ec-halfInput input, .error.ec-numberInput input, .error.ec-zipInput input, .error.ec-telInput input, .error.ec-select input, .error.ec-birth input, .error.ec-input select, .error.ec-halfInput select, .error.ec-numberInput select, .error.ec-zipInput select, .error.ec-telInput select, .error.ec-select select, .error.ec-birth select, .error.ec-input textarea, .error.ec-halfInput textarea, .error.ec-numberInput textarea, .error.ec-zipInput textarea, .error.ec-telInput textarea, .error.ec-select textarea, .error.ec-birth textarea {
  margin-bottom: 5px;
  border-color: #CF3F34;
  background: #FDF1F0; }

.ec-checkbox .ec-errorMessage {
  margin-bottom: 25px;
  font-size: 12px;
  font-weight: bold;
  color: #DE5D50; }

.error.ec-checkbox input, .error.ec-checkbox label {
  border-color: #CF3F34;
  background: #FDF1F0; }

/*
フォーム（text２つ）

姓名など2つ入力させたい入力項目で使用します。

入力フォームを半分で用意したいときにも利用可能です。

ex [会員情報編集画面　フォーム部分](http://demo3.ec-cube.net/mypage/change)

Markup:
p.ec-halfInput
  input(type="text")
  input(type="text")
p.ec-halfInput
  input(type="text")

Styleguide 3.1.2
*/
.ec-halfInput input[type='text'] {
  display: inline-block;
  width: 100%;
  margin-left: 2%; }
  @media only screen and (min-width: 768px) {
    .ec-halfInput input[type='text'] {
      margin-left: 15px;
      width: 45%; } }

.ec-halfInput input[type='text']:first-child {
  margin-left: 0; }

/*
数量ボタン

数量を表示するための小さなコンポーネントです。

数値表示に最適化するため、数字は右端揃えで表示されます。

ex [商品詳細画面　数量ボタン部分](http://demo3.ec-cube.net/products/detail/27)

Markup:
.ec-numberInput
  span 数量
  input(type="number",value="0")

Styleguide 3.1.3
*/
.ec-numberInput input[type='number'] {
  display: inline-block;
  width: auto;
  max-width: 100px;
  text-align: right; }

/*
郵便番号フォーム

数量を表示するための小さなコンポーネントです。

内部に input 要素を配置してコーディングします。

ex [会員情報編集画面　郵便番号部分](http://demo3.ec-cube.net/mypage/change)

Markup:
.ec-zipInput
  span 〒
  input(type="text")
.ec-zipInputHelp
  a(href="http://www.post.japanpost.jp/zipcode/" target="_blank")
    .ec-zipInputHelp__icon
      .ec-icon
        img(src='/moc/icon/question-white.svg', alt='')
    span 郵便番号検索
.ec-zipAuto
  a.ec-inlineBtn 郵便番号から自動入力

Styleguide 3.1.4
*/
.ec-zipInput {
  display: inline-block; }
  .ec-zipInput input {
    display: inline-block;
    text-align: left;
    width: auto;
    max-width: 8em;
    font-size: 16px; }
  .ec-zipInput span {
    display: inline-block;
    padding: 0 5px 0 3px;
    margin-left: 5px; }

.ec-zipInputHelp {
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 16px;
  vertical-align: baseline;
  line-height: 0; }
  .ec-zipInputHelp .ec-zipInputHelp__icon {
    display: inline-block;
    margin-top: -10px;
    width: 20px;
    height: 20px;
    background: #525263;
    border-radius: 50%;
    font-size: 13px;
    position: relative;
    top: -6px; }
    .ec-zipInputHelp .ec-zipInputHelp__icon .ec-icon img {
      width: 1em;
      height: 1em;
      position: relative;
      left: 3px;
      top: 3px; }
  .ec-zipInputHelp span {
    margin-left: 8px;
    display: inline-block;
    color: #0092C4;
    vertical-align: 3px; }

.ec-zipAuto {
  margin-bottom: 16px; }
  .ec-zipAuto .ec-inlineBtn {
    font-weight: normal; }

/*
電話番号ボタン

数量を表示するための小さなコンポーネントです。

内部に input 要素を配置してコーディングします。

ex [会員情報編集画面　電話番号部分](http://demo3.ec-cube.net/mypage/change)

Markup:
.ec-telInput
  input(type="text")

Styleguide 3.1.5
*/
.ec-telInput input {
  max-width: 10em;
  text-align: left; }

/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
フォーム部品(テキスト)

テキストや数値の入力項目に関する要素を定義します。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 3.1
*/
/*
フォーム

`.ec-input` 要素は全ての入力項目に関する標準的なコンポーネントクラスです。


ex [会員情報編集画面　フォーム部分](http://demo3.ec-cube.net/mypage/change)

Markup:
p.ec-input
  input(type="number")
p.ec-input
  textarea(rows="6")

Styleguide 3.1.1
*/
.ec-input input[type="search"], .ec-halfInput input[type="search"], .ec-numberInput input[type="search"], .ec-zipInput input[type="search"], .ec-telInput input[type="search"], .ec-select input[type="search"], .ec-birth input[type="search"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.ec-input input[type="radio"], .ec-halfInput input[type="radio"], .ec-numberInput input[type="radio"], .ec-zipInput input[type="radio"], .ec-telInput input[type="radio"], .ec-select input[type="radio"], .ec-birth input[type="radio"],
.ec-input input[type="checkbox"],
.ec-halfInput input[type="checkbox"],
.ec-numberInput input[type="checkbox"],
.ec-zipInput input[type="checkbox"],
.ec-telInput input[type="checkbox"],
.ec-select input[type="checkbox"],
.ec-birth input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9;
  line-height: normal; }

.ec-input input[type="file"], .ec-halfInput input[type="file"], .ec-numberInput input[type="file"], .ec-zipInput input[type="file"], .ec-telInput input[type="file"], .ec-select input[type="file"], .ec-birth input[type="file"] {
  display: block; }

.ec-input input[type="range"], .ec-halfInput input[type="range"], .ec-numberInput input[type="range"], .ec-zipInput input[type="range"], .ec-telInput input[type="range"], .ec-select input[type="range"], .ec-birth input[type="range"] {
  display: block;
  width: 100%; }

.ec-input select[multiple], .ec-halfInput select[multiple], .ec-numberInput select[multiple], .ec-zipInput select[multiple], .ec-telInput select[multiple], .ec-select select[multiple], .ec-birth select[multiple],
.ec-input select[size],
.ec-halfInput select[size],
.ec-numberInput select[size],
.ec-zipInput select[size],
.ec-telInput select[size],
.ec-select select[size],
.ec-birth select[size] {
  height: auto; }

.ec-input input[type="file"]:focus, .ec-halfInput input[type="file"]:focus, .ec-numberInput input[type="file"]:focus, .ec-zipInput input[type="file"]:focus, .ec-telInput input[type="file"]:focus, .ec-select input[type="file"]:focus, .ec-birth input[type="file"]:focus,
.ec-input input[type="radio"]:focus,
.ec-halfInput input[type="radio"]:focus,
.ec-numberInput input[type="radio"]:focus,
.ec-zipInput input[type="radio"]:focus,
.ec-telInput input[type="radio"]:focus,
.ec-select input[type="radio"]:focus,
.ec-birth input[type="radio"]:focus,
.ec-input input[type="checkbox"]:focus,
.ec-halfInput input[type="checkbox"]:focus,
.ec-numberInput input[type="checkbox"]:focus,
.ec-zipInput input[type="checkbox"]:focus,
.ec-telInput input[type="checkbox"]:focus,
.ec-select input[type="checkbox"]:focus,
.ec-birth input[type="checkbox"]:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px; }

.ec-input input, .ec-halfInput input, .ec-numberInput input, .ec-zipInput input, .ec-telInput input, .ec-select input, .ec-birth input {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.42857;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-appearance: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  border-radius: 3px; }
  .ec-input input:focus, .ec-halfInput input:focus, .ec-numberInput input:focus, .ec-zipInput input:focus, .ec-telInput input:focus, .ec-select input:focus, .ec-birth input:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }
  .ec-input input::-moz-placeholder, .ec-halfInput input::-moz-placeholder, .ec-numberInput input::-moz-placeholder, .ec-zipInput input::-moz-placeholder, .ec-telInput input::-moz-placeholder, .ec-select input::-moz-placeholder, .ec-birth input::-moz-placeholder {
    color: #999;
    opacity: 1; }
  .ec-input input:-ms-input-placeholder, .ec-halfInput input:-ms-input-placeholder, .ec-numberInput input:-ms-input-placeholder, .ec-zipInput input:-ms-input-placeholder, .ec-telInput input:-ms-input-placeholder, .ec-select input:-ms-input-placeholder, .ec-birth input:-ms-input-placeholder {
    color: #999; }
  .ec-input input::-webkit-input-placeholder, .ec-halfInput input::-webkit-input-placeholder, .ec-numberInput input::-webkit-input-placeholder, .ec-zipInput input::-webkit-input-placeholder, .ec-telInput input::-webkit-input-placeholder, .ec-select input::-webkit-input-placeholder, .ec-birth input::-webkit-input-placeholder {
    color: #999; }
  .ec-input input::-ms-expand, .ec-halfInput input::-ms-expand, .ec-numberInput input::-ms-expand, .ec-zipInput input::-ms-expand, .ec-telInput input::-ms-expand, .ec-select input::-ms-expand, .ec-birth input::-ms-expand {
    border: 0;
    background-color: transparent; }
  .ec-input input[disabled], .ec-halfInput input[disabled], .ec-numberInput input[disabled], .ec-zipInput input[disabled], .ec-telInput input[disabled], .ec-select input[disabled], .ec-birth input[disabled], .ec-input input[readonly], .ec-halfInput input[readonly], .ec-numberInput input[readonly], .ec-zipInput input[readonly], .ec-telInput input[readonly], .ec-select input[readonly], .ec-birth input[readonly],
  fieldset[disabled] .ec-input input,
  fieldset[disabled] .ec-halfInput input,
  fieldset[disabled] .ec-numberInput input,
  fieldset[disabled] .ec-zipInput input,
  fieldset[disabled] .ec-telInput input,
  fieldset[disabled] .ec-select input,
  fieldset[disabled] .ec-birth input {
    background-color: #eeeeee;
    opacity: 1; }
  .ec-input input[disabled], .ec-halfInput input[disabled], .ec-numberInput input[disabled], .ec-zipInput input[disabled], .ec-telInput input[disabled], .ec-select input[disabled], .ec-birth input[disabled],
  fieldset[disabled] .ec-input input,
  fieldset[disabled] .ec-halfInput input,
  fieldset[disabled] .ec-numberInput input,
  fieldset[disabled] .ec-zipInput input,
  fieldset[disabled] .ec-telInput input,
  fieldset[disabled] .ec-select input,
  fieldset[disabled] .ec-birth input {
    cursor: not-allowed; }
  @media only screen and (min-width: 768px) {
    .ec-input input, .ec-halfInput input, .ec-numberInput input, .ec-zipInput input, .ec-telInput input, .ec-select input, .ec-birth input {
      font-size: 14px; } }

.ec-input select, .ec-halfInput select, .ec-numberInput select, .ec-zipInput select, .ec-telInput select, .ec-select select, .ec-birth select {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.42857;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-appearance: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  border-radius: 3px; }
  .ec-input select:focus, .ec-halfInput select:focus, .ec-numberInput select:focus, .ec-zipInput select:focus, .ec-telInput select:focus, .ec-select select:focus, .ec-birth select:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }
  .ec-input select::-moz-placeholder, .ec-halfInput select::-moz-placeholder, .ec-numberInput select::-moz-placeholder, .ec-zipInput select::-moz-placeholder, .ec-telInput select::-moz-placeholder, .ec-select select::-moz-placeholder, .ec-birth select::-moz-placeholder {
    color: #999;
    opacity: 1; }
  .ec-input select:-ms-input-placeholder, .ec-halfInput select:-ms-input-placeholder, .ec-numberInput select:-ms-input-placeholder, .ec-zipInput select:-ms-input-placeholder, .ec-telInput select:-ms-input-placeholder, .ec-select select:-ms-input-placeholder, .ec-birth select:-ms-input-placeholder {
    color: #999; }
  .ec-input select::-webkit-input-placeholder, .ec-halfInput select::-webkit-input-placeholder, .ec-numberInput select::-webkit-input-placeholder, .ec-zipInput select::-webkit-input-placeholder, .ec-telInput select::-webkit-input-placeholder, .ec-select select::-webkit-input-placeholder, .ec-birth select::-webkit-input-placeholder {
    color: #999; }
  .ec-input select::-ms-expand, .ec-halfInput select::-ms-expand, .ec-numberInput select::-ms-expand, .ec-zipInput select::-ms-expand, .ec-telInput select::-ms-expand, .ec-select select::-ms-expand, .ec-birth select::-ms-expand {
    border: 0;
    background-color: transparent; }
  .ec-input select[disabled], .ec-halfInput select[disabled], .ec-numberInput select[disabled], .ec-zipInput select[disabled], .ec-telInput select[disabled], .ec-select select[disabled], .ec-birth select[disabled], .ec-input select[readonly], .ec-halfInput select[readonly], .ec-numberInput select[readonly], .ec-zipInput select[readonly], .ec-telInput select[readonly], .ec-select select[readonly], .ec-birth select[readonly],
  fieldset[disabled] .ec-input select,
  fieldset[disabled] .ec-halfInput select,
  fieldset[disabled] .ec-numberInput select,
  fieldset[disabled] .ec-zipInput select,
  fieldset[disabled] .ec-telInput select,
  fieldset[disabled] .ec-select select,
  fieldset[disabled] .ec-birth select {
    background-color: #eeeeee;
    opacity: 1; }
  .ec-input select[disabled], .ec-halfInput select[disabled], .ec-numberInput select[disabled], .ec-zipInput select[disabled], .ec-telInput select[disabled], .ec-select select[disabled], .ec-birth select[disabled],
  fieldset[disabled] .ec-input select,
  fieldset[disabled] .ec-halfInput select,
  fieldset[disabled] .ec-numberInput select,
  fieldset[disabled] .ec-zipInput select,
  fieldset[disabled] .ec-telInput select,
  fieldset[disabled] .ec-select select,
  fieldset[disabled] .ec-birth select {
    cursor: not-allowed; }
  @media only screen and (min-width: 768px) {
    .ec-input select, .ec-halfInput select, .ec-numberInput select, .ec-zipInput select, .ec-telInput select, .ec-select select, .ec-birth select {
      font-size: 14px; } }

.ec-input textarea, .ec-halfInput textarea, .ec-numberInput textarea, .ec-zipInput textarea, .ec-telInput textarea, .ec-select textarea, .ec-birth textarea {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.42857;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-appearance: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  border-radius: 3px; }
  .ec-input textarea:focus, .ec-halfInput textarea:focus, .ec-numberInput textarea:focus, .ec-zipInput textarea:focus, .ec-telInput textarea:focus, .ec-select textarea:focus, .ec-birth textarea:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }
  .ec-input textarea::-moz-placeholder, .ec-halfInput textarea::-moz-placeholder, .ec-numberInput textarea::-moz-placeholder, .ec-zipInput textarea::-moz-placeholder, .ec-telInput textarea::-moz-placeholder, .ec-select textarea::-moz-placeholder, .ec-birth textarea::-moz-placeholder {
    color: #999;
    opacity: 1; }
  .ec-input textarea:-ms-input-placeholder, .ec-halfInput textarea:-ms-input-placeholder, .ec-numberInput textarea:-ms-input-placeholder, .ec-zipInput textarea:-ms-input-placeholder, .ec-telInput textarea:-ms-input-placeholder, .ec-select textarea:-ms-input-placeholder, .ec-birth textarea:-ms-input-placeholder {
    color: #999; }
  .ec-input textarea::-webkit-input-placeholder, .ec-halfInput textarea::-webkit-input-placeholder, .ec-numberInput textarea::-webkit-input-placeholder, .ec-zipInput textarea::-webkit-input-placeholder, .ec-telInput textarea::-webkit-input-placeholder, .ec-select textarea::-webkit-input-placeholder, .ec-birth textarea::-webkit-input-placeholder {
    color: #999; }
  .ec-input textarea::-ms-expand, .ec-halfInput textarea::-ms-expand, .ec-numberInput textarea::-ms-expand, .ec-zipInput textarea::-ms-expand, .ec-telInput textarea::-ms-expand, .ec-select textarea::-ms-expand, .ec-birth textarea::-ms-expand {
    border: 0;
    background-color: transparent; }
  .ec-input textarea[disabled], .ec-halfInput textarea[disabled], .ec-numberInput textarea[disabled], .ec-zipInput textarea[disabled], .ec-telInput textarea[disabled], .ec-select textarea[disabled], .ec-birth textarea[disabled], .ec-input textarea[readonly], .ec-halfInput textarea[readonly], .ec-numberInput textarea[readonly], .ec-zipInput textarea[readonly], .ec-telInput textarea[readonly], .ec-select textarea[readonly], .ec-birth textarea[readonly],
  fieldset[disabled] .ec-input textarea,
  fieldset[disabled] .ec-halfInput textarea,
  fieldset[disabled] .ec-numberInput textarea,
  fieldset[disabled] .ec-zipInput textarea,
  fieldset[disabled] .ec-telInput textarea,
  fieldset[disabled] .ec-select textarea,
  fieldset[disabled] .ec-birth textarea {
    background-color: #eeeeee;
    opacity: 1; }
  .ec-input textarea[disabled], .ec-halfInput textarea[disabled], .ec-numberInput textarea[disabled], .ec-zipInput textarea[disabled], .ec-telInput textarea[disabled], .ec-select textarea[disabled], .ec-birth textarea[disabled],
  fieldset[disabled] .ec-input textarea,
  fieldset[disabled] .ec-halfInput textarea,
  fieldset[disabled] .ec-numberInput textarea,
  fieldset[disabled] .ec-zipInput textarea,
  fieldset[disabled] .ec-telInput textarea,
  fieldset[disabled] .ec-select textarea,
  fieldset[disabled] .ec-birth textarea {
    cursor: not-allowed; }
  @media only screen and (min-width: 768px) {
    .ec-input textarea, .ec-halfInput textarea, .ec-numberInput textarea, .ec-zipInput textarea, .ec-telInput textarea, .ec-select textarea, .ec-birth textarea {
      font-size: 14px; } }

.ec-input input:focus, .ec-halfInput input:focus, .ec-numberInput input:focus, .ec-zipInput input:focus, .ec-telInput input:focus, .ec-select input:focus, .ec-birth input:focus, .ec-input textarea:focus, .ec-halfInput textarea:focus, .ec-numberInput textarea:focus, .ec-zipInput textarea:focus, .ec-telInput textarea:focus, .ec-select textarea:focus, .ec-birth textarea:focus {
  box-shadow: none;
  border-color: #3c8dbc; }

.ec-input input, .ec-halfInput input, .ec-numberInput input, .ec-zipInput input, .ec-telInput input, .ec-select input, .ec-birth input {
  height: 40px;
  margin-bottom: 10px; }
  @media only screen and (min-width: 768px) {
    .ec-input input, .ec-halfInput input, .ec-numberInput input, .ec-zipInput input, .ec-telInput input, .ec-select input, .ec-birth input {
      margin-bottom: 16px; } }

.ec-input textarea, .ec-halfInput textarea, .ec-numberInput textarea, .ec-zipInput textarea, .ec-telInput textarea, .ec-select textarea, .ec-birth textarea {
  height: auto;
  min-height: 100px; }

.ec-input p, .ec-halfInput p, .ec-numberInput p, .ec-zipInput p, .ec-telInput p, .ec-select p, .ec-birth p {
  line-height: 1.4; }

.ec-input .ec-errorMessage, .ec-halfInput .ec-errorMessage, .ec-numberInput .ec-errorMessage, .ec-zipInput .ec-errorMessage, .ec-telInput .ec-errorMessage, .ec-select .ec-errorMessage, .ec-birth .ec-errorMessage {
  margin-bottom: 25px;
  font-size: 12px;
  font-weight: bold;
  color: #DE5D50; }

.error.ec-input input, .error.ec-halfInput input, .error.ec-numberInput input, .error.ec-zipInput input, .error.ec-telInput input, .error.ec-select input, .error.ec-birth input, .error.ec-input select, .error.ec-halfInput select, .error.ec-numberInput select, .error.ec-zipInput select, .error.ec-telInput select, .error.ec-select select, .error.ec-birth select, .error.ec-input textarea, .error.ec-halfInput textarea, .error.ec-numberInput textarea, .error.ec-zipInput textarea, .error.ec-telInput textarea, .error.ec-select textarea, .error.ec-birth textarea {
  margin-bottom: 5px;
  border-color: #CF3F34;
  background: #FDF1F0; }

.ec-checkbox .ec-errorMessage {
  margin-bottom: 25px;
  font-size: 12px;
  font-weight: bold;
  color: #DE5D50; }

.error.ec-checkbox input, .error.ec-checkbox label {
  border-color: #CF3F34;
  background: #FDF1F0; }

/*
フォーム（text２つ）

姓名など2つ入力させたい入力項目で使用します。

入力フォームを半分で用意したいときにも利用可能です。

ex [会員情報編集画面　フォーム部分](http://demo3.ec-cube.net/mypage/change)

Markup:
p.ec-halfInput
  input(type="text")
  input(type="text")
p.ec-halfInput
  input(type="text")

Styleguide 3.1.2
*/
.ec-halfInput input[type='text'] {
  display: inline-block;
  width: 100%;
  margin-left: 2%; }
  @media only screen and (min-width: 768px) {
    .ec-halfInput input[type='text'] {
      margin-left: 15px;
      width: 45%; } }

.ec-halfInput input[type='text']:first-child {
  margin-left: 0; }

/*
数量ボタン

数量を表示するための小さなコンポーネントです。

数値表示に最適化するため、数字は右端揃えで表示されます。

ex [商品詳細画面　数量ボタン部分](http://demo3.ec-cube.net/products/detail/27)

Markup:
.ec-numberInput
  span 数量
  input(type="number",value="0")

Styleguide 3.1.3
*/
.ec-numberInput input[type='number'] {
  display: inline-block;
  width: auto;
  max-width: 100px;
  text-align: right; }

/*
郵便番号フォーム

数量を表示するための小さなコンポーネントです。

内部に input 要素を配置してコーディングします。

ex [会員情報編集画面　郵便番号部分](http://demo3.ec-cube.net/mypage/change)

Markup:
.ec-zipInput
  span 〒
  input(type="text")
.ec-zipInputHelp
  a(href="http://www.post.japanpost.jp/zipcode/" target="_blank")
    .ec-zipInputHelp__icon
      .ec-icon
        img(src='/moc/icon/question-white.svg', alt='')
    span 郵便番号検索
.ec-zipAuto
  a.ec-inlineBtn 郵便番号から自動入力

Styleguide 3.1.4
*/
.ec-zipInput {
  display: inline-block; }
  .ec-zipInput input {
    display: inline-block;
    text-align: left;
    width: auto;
    max-width: 8em;
    font-size: 16px; }
  .ec-zipInput span {
    display: inline-block;
    padding: 0 5px 0 3px;
    margin-left: 5px; }

.ec-zipInputHelp {
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 16px;
  vertical-align: baseline;
  line-height: 0; }
  .ec-zipInputHelp .ec-zipInputHelp__icon {
    display: inline-block;
    margin-top: -10px;
    width: 20px;
    height: 20px;
    background: #525263;
    border-radius: 50%;
    font-size: 13px;
    position: relative;
    top: -6px; }
    .ec-zipInputHelp .ec-zipInputHelp__icon .ec-icon img {
      width: 1em;
      height: 1em;
      position: relative;
      left: 3px;
      top: 3px; }
  .ec-zipInputHelp span {
    margin-left: 8px;
    display: inline-block;
    color: #0092C4;
    vertical-align: 3px; }

.ec-zipAuto {
  margin-bottom: 16px; }
  .ec-zipAuto .ec-inlineBtn {
    font-weight: normal; }

/*
電話番号ボタン

数量を表示するための小さなコンポーネントです。

内部に input 要素を配置してコーディングします。

ex [会員情報編集画面　電話番号部分](http://demo3.ec-cube.net/mypage/change)

Markup:
.ec-telInput
  input(type="text")

Styleguide 3.1.5
*/
.ec-telInput input {
  max-width: 10em;
  text-align: left; }

/*
フォーム部品(その他)

フォーム部品でテキストの入力以外の動作要素を定義します。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>

Styleguide 3.2
*/
/*
ラジオ（水平）

水平に並ぶラジオボタンフィールドです。

各要素をlabelでくくって、コーディングします。

ex [新規会員登録画面　性別選択部分](http://demo3.ec-cube.net/entry)

Markup:
.ec-radio
  label
    input(type="radio")
    span 男性
  label
    input(type="radio")
    span 女性

Styleguide 3.2.2
*/
.ec-radio label {
  margin-right: 20px; }

.ec-radio input {
  margin-right: 10px;
  margin-bottom: 10px; }

.ec-radio span {
  font-weight: normal; }

/*
ラジオ(垂直)

垂直に並ぶラジオボタンフィールドです。

各要素をlabelでくくって、コーディングします。

ex [購入画面 お支払方法](http://demo3.ec-cube.net/shopping)

Markup:
.ec-blockRadio
  label
    input(type="radio")
    span 郵便振替
  label
    input(type="radio")
    span 現金書留
  label
    input(type="radio")
    span 銀行振込
  label
    input(type="radio")
    span 代金引換

Styleguide 3.2.3
*/
.ec-blockRadio label {
  display: block; }

.ec-blockRadio span {
  padding-left: 10px;
  font-weight: normal; }

/*
セレクトボックス

数量を表示するための小さなコンポーネントです。

数値表示に最適化するため、数字は右端揃えで表示されます。

ex [新規会員登録画面　都道府県選択部分](http://demo3.ec-cube.net/entry)

Markup:
.ec-select
  select
    option 都道府県を選択
    option 北海道
    option 青森県
    option 岩手県
    option ...
.ec-select
  select
    option 選択して下さい
    option 公務員
    option コンサルタント
    option コンピュータ関連技術職
    option コンピュータ関連以外の技術職
    option ...

Styleguide 3.2.4
*/
.ec-selects {
  margin-bottom: 20px;
  border-bottom: 1px dotted #ccc; }

.ec-select {
  margin-bottom: 16px; }
  .ec-select select {
    display: inline-block;
    width: auto;
    max-width: 100%;
    background-color: #fff;
    -webkit-appearance: menulist;
    -moz-appearance: menulist; }
    .ec-select select:focus {
      box-shadow: none; }
  .ec-select label {
    margin-right: 10px;
    font-weight: bold; }
  .ec-select label:nth-child(3) {
    margin-left: 10px;
    font-weight: bold; }

.ec-select__delivery {
  display: block;
  margin-right: 16px; }
  @media only screen and (min-width: 768px) {
    .ec-select__delivery {
      display: inline-block; } }

.ec-select__time {
  display: block; }
  @media only screen and (min-width: 768px) {
    .ec-select__time {
      display: inline-block; } }

/*
生年月日選択

数量を表示するための小さなコンポーネントです。

数値表示に最適化するため、数字は右端揃えで表示されます。

ex [新規会員登録画面　生年月日選択部分](http://demo3.ec-cube.net/entry)

Markup:
.ec-birth
  select
    option ----
    option 1960
    option 1961
    option 1962
    option ...
  span /
  select
    option --
    option 01
    option 02
    option 03
    option ...
  span /
  select
    option --
    option 01
    option 02
    option 03
    option ...

Styleguide 3.2.5
*/
.ec-birth select {
  display: inline-block;
  width: auto;
  margin: 0 0 10px;
  background-color: #f8f8f8;
  -webkit-appearance: menulist;
  -moz-appearance: menulist; }
  .ec-birth select:focus {
    box-shadow: none; }
  @media only screen and (min-width: 768px) {
    .ec-birth select {
      margin: 0 8px 10px; } }

.ec-birth span {
  margin-left: 5px; }

/*
チェックボックス （水平）

水平に並ぶチェックボックス フィールドです。

各要素をlabelでくくって、コーディングします。

ex [新規会員登録画面　利用規約](http://demo3.ec-cube.net/entry)

Markup:
.ec-checkbox
  label
    input(type="checkbox")
    span 利用規約に同意する

Styleguide 3.2.6
*/
.ec-checkbox label {
  display: inline-block; }

.ec-checkbox input {
  margin-bottom: 10px; }

.ec-checkbox span {
  font-weight: normal; }

/*
チェックボックス (垂直)

垂直に並ぶチェックボックス フィールドです。

各要素をlabelでくくって、コーディングします。

Markup:
.ec-blockCheckbox
  label
    input(type="checkbox")
    span 利用規約に同意する

Styleguide 3.2.7
*/
.ec-blockCheckbox label {
  display: block; }

.ec-blockCheckbox span {
  font-weight: normal; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
フォームラベル

フォームのラベルに関する要素を定義します。

sg-wrapper:
<div class="ec-registerRole">
  <div class="ec-off1Grid">
    <div class="ec-off1Grid__cell">
      <div class="ec-borderedDefs">
        <sg-wrapper-content/>
      </div>
    </div>
  </div>
</div>

Styleguide 3.3
*/
/*
ラベル

フォーム要素で利用するラベル要素です。

ex [お問い合わせページ　ラベル部分](http://demo3.ec-cube.net/contact)

Markup:
.ec-borderedDefs
  dl
    dt
      label.ec-label お名前
    dd
      .ec-input
        input(type="text")

Styleguide 3.3.1
*/
.ec-label {
  display: inline-block;
  font-weight: bold;
  margin-bottom: 5px; }

/*
必須ラベル

必須文字を表示するラベル要素です。

ex [お問い合わせページ　必須ラベル部分](http://demo3.ec-cube.net/contact)


Markup:
.ec-borderedDefs
  dl
    dt
      label.ec-label お名前
        span.ec-required 必須
    dd
      .ec-input
        input(type="text")

Styleguide 3.3.2
*/
.ec-required {
  display: inline-block;
  margin-left: .8em;
  vertical-align: 2px;
  color: #f00;
  font-size: 1.2em;
  font-weight: normal; }
  @media only screen and (min-width: 768px) {
    .ec-required {
      margin-left: 1em; } }

/*
アイコン

デフォルトテンプレートのアイコンは`.ec-icon`>`img`タグで使用することができます

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>

Markup:
include /assets/tmpl/elements/4.1.icon.pug
div(style="background-color: rgba(130,130,130,.15); padding: 20px;")
  +icon-all

Styleguide 4.1
*/
.ec-icon img {
  max-width: 80px;
  max-height: 80px; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
グリッド

画面を12分割し、グリッドレイアウトに対応するためのスタイルです。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 5.1
*/
/*
2分割グリッド

画面 ２分割の　グリッドです。
Bootstrap の col-sm-6 相当のグリッドを提供します。

Markup:
.ec-grid2
  .ec-grid2__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid2__cell
  .ec-grid2__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid2__cell

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 5.1.1
*/
.ec-grid2 {
  display: block;
  margin: 0; }
  @media only screen and (min-width: 768px) {
    .ec-grid2 {
      display: flex; } }
  .ec-grid2 .ec-grid2__cell {
    position: relative;
    min-height: 1px; }
    @media (min-width: 768px) {
      .ec-grid2 .ec-grid2__cell {
        width: 50%; } }
  .ec-grid2 .ec-grid2__cell2 {
    position: relative;
    min-height: 1px; }
    @media (min-width: 768px) {
      .ec-grid2 .ec-grid2__cell2 {
        width: 100%; } }

/*
3分割グリッド

画面　３分割の　グリッドです。


Markup:
.ec-grid3
  .ec-grid3__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid3__cell
  .ec-grid3__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid3__cell
  .ec-grid3__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid3__cell

Styleguide 5.1.2
*/
.ec-grid3 {
  display: block;
  margin: 0; }
  @media only screen and (min-width: 768px) {
    .ec-grid3 {
      display: flex; } }
  .ec-grid3 .ec-grid3__cell {
    position: relative;
    min-height: 1px; }
    @media (min-width: 768px) {
      .ec-grid3 .ec-grid3__cell {
        width: 33.33333%; } }
  .ec-grid3 .ec-grid3__cell2 {
    position: relative;
    min-height: 1px; }
    @media (min-width: 768px) {
      .ec-grid3 .ec-grid3__cell2 {
        width: 66.66667%; } }
  .ec-grid3 .ec-grid3__cell3 {
    position: relative;
    min-height: 1px; }
    @media (min-width: 768px) {
      .ec-grid3 .ec-grid3__cell3 {
        width: 100%; } }

/*
4分割グリッド

画面　４分割の　グリッドです。


Markup:
.ec-grid4
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid4__cell

Styleguide 5.1.3
*/
.ec-grid4 {
  display: block;
  margin: 0; }
  @media only screen and (min-width: 768px) {
    .ec-grid4 {
      display: flex; } }
  .ec-grid4 .ec-grid4__cell {
    position: relative;
    min-height: 1px; }
    @media (min-width: 768px) {
      .ec-grid4 .ec-grid4__cell {
        width: 25%; } }

/*
6分割グリッド

2つにまとめた cell2 や 3つをまとめた cell3 タグも使用可能です。


Markup:
.ec-grid6
  .ec-grid6__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell
  .ec-grid6__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell
  .ec-grid6__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell
  .ec-grid6__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell
  .ec-grid6__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell
  .ec-grid6__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell
.ec-grid6
  .ec-grid6__cell2(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell2
  .ec-grid6__cell2(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell2
  .ec-grid6__cell2(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell2
.ec-grid6
  .ec-grid6__cell3(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell3
  .ec-grid6__cell3(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell3

Styleguide 5.1.4
*/
.ec-grid6 {
  display: block;
  margin: 0; }
  @media only screen and (min-width: 768px) {
    .ec-grid6 {
      display: flex; } }
  .ec-grid6 .ec-grid6__cell {
    position: relative;
    min-height: 1px; }
    @media (min-width: 768px) {
      .ec-grid6 .ec-grid6__cell {
        width: 16.66667%; } }
  .ec-grid6 .ec-grid6__cell2 {
    position: relative;
    min-height: 1px; }
    @media (min-width: 768px) {
      .ec-grid6 .ec-grid6__cell2 {
        width: 33.33333%; } }
  .ec-grid6 .ec-grid6__cell3 {
    position: relative;
    min-height: 1px; }
    @media (min-width: 768px) {
      .ec-grid6 .ec-grid6__cell3 {
        width: 50%; } }

/*
中央寄せグリッド 10/12

左右にマージンを持つ、中央寄せグリッドを提供します。１２分の１０グリッドです

ex [ご利用規約ページ　本文](http://demo3.ec-cube.net/help/agreement)

Markup:
.ec-off1Grid
  .ec-off1Grid__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod

Styleguide 5.1.5
*/
.ec-off1Grid {
  margin: 0; }
  @media only screen and (min-width: 768px) {
    .ec-off1Grid {
      display: block;
      margin: 0; } }
  @media only screen and (min-width: 768px) and (min-width: 768px) {
    .ec-off1Grid {
      display: flex; } }
  .ec-off1Grid .ec-off1Grid__cell {
    margin: 0; }
    @media only screen and (min-width: 768px) {
      .ec-off1Grid .ec-off1Grid__cell {
        position: relative;
        min-height: 1px;
        margin-left: 8.33333%; } }
  @media only screen and (min-width: 768px) and (min-width: 768px) {
    .ec-off1Grid .ec-off1Grid__cell {
      width: 83.33333%; } }

/*
中央寄せグリッド 8/12

左右にマージンを持つ、中央寄せグリッドを提供します。１２分の８グリッドです


Markup:
.ec-off2Grid
  .ec-off2Grid__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod

Styleguide 5.1.6
*/
.ec-off2Grid {
  display: block;
  margin: 0; }
  @media only screen and (min-width: 768px) {
    .ec-off2Grid {
      display: flex; } }
  .ec-off2Grid .ec-off2Grid__cell {
    margin: 0; }
    @media only screen and (min-width: 768px) {
      .ec-off2Grid .ec-off2Grid__cell {
        position: relative;
        min-height: 1px;
        margin-left: 16.66667%; } }
  @media only screen and (min-width: 768px) and (min-width: 768px) {
    .ec-off2Grid .ec-off2Grid__cell {
      width: 66.66667%; } }

/*
中央寄せグリッド 6/12

左右にマージンを持つ、中央寄せグリッドを提供します。１２分の６グリッドです


Markup:
.ec-off3Grid
  .ec-off3Grid__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod

Styleguide 5.1.7
*/
.ec-off3Grid {
  display: block;
  margin: 0; }
  @media only screen and (min-width: 768px) {
    .ec-off3Grid {
      display: flex; } }
  .ec-off3Grid .ec-off3Grid__cell {
    margin: 0; }
    @media only screen and (min-width: 768px) {
      .ec-off3Grid .ec-off3Grid__cell {
        position: relative;
        min-height: 1px;
        margin-left: 25%; } }
  @media only screen and (min-width: 768px) and (min-width: 768px) {
    .ec-off3Grid .ec-off3Grid__cell {
      width: 50%; } }

/*
中央寄せグリッド 4/12

左右にマージンを持つ、中央寄せグリッドを提供します。１２分の４グリッドです


Markup:
.ec-off4Grid
  .ec-off4Grid__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod


Styleguide 5.1.8
*/
.ec-off4Grid {
  display: block;
  margin: 0; }
  @media only screen and (min-width: 768px) {
    .ec-off4Grid {
      display: flex; } }
  .ec-off4Grid .ec-off4Grid__cell {
    margin: 0; }
    @media only screen and (min-width: 768px) {
      .ec-off4Grid .ec-off4Grid__cell {
        position: relative;
        min-height: 1px;
        margin-left: 33.33333%; } }
  @media only screen and (min-width: 768px) and (min-width: 768px) {
    .ec-off4Grid .ec-off4Grid__cell {
      width: 33.33333%; } }

/*
グリッドオプション

グリッドのセルに対して「左寄せ」「中央寄せ」「右寄せ」のオプションを付与することができます。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>

Styleguide 5.1.9
*/
/*
グリッドセルの左寄せ

.ec-gridに.ec-grid--leftを付与すると内包してるセルを左寄せにすることができます。

Markup:
.ec-grid4.ec-grid--left
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell

Styleguide 5.1.10
*/
.ec-grid--left {
  justify-content: flex-start; }

/*
グリッドセルの右寄せ

.ec-gridに.ec-grid--leftを付与すると内包してるセルを左寄せにすることができます。

Markup:
.ec-grid4.ec-grid--right
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell

Styleguide 5.1.11
*/
.ec-grid--right {
  justify-content: flex-end; }

/*
グリッドセルの中央寄せ

.ec-gridに.ec-grid--leftを付与すると内包してるセルを左寄せにすることができます。

Markup:
.ec-grid4.ec-grid--center
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell

Styleguide 5.1.12
*/
.ec-grid--center {
  justify-content: center; }

/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
レイアウト

様々なレイアウトを変更する為のスタイル群です。

Styleguide 5.2
*/
/*
画像レイアウト

画像とテキストを水平に並べるレイアウトです。

画像は20%で表示されます。

ex [注文履歴 ログイン後→注文履歴ボタンを押下](http://demo3.ec-cube.net/mypage)

Markup:
.ec-imageGrid
  .ec-imageGrid__img: img(src="http://demo3.ec-cube.net/upload/save_image/0701113537_559351f959620.jpeg")
  .ec-imageGrid__content
    p.ec-font-bold ホーローマグ
    p ¥ 1,728 x 1

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 5.2.1
*/
.ec-imageGrid {
  display: table;
  border-top: 1px dotted #ccc;
  width: 100%; }
  .ec-imageGrid .ec-imageGrid__img {
    display: table-cell;
    padding: 10px;
    width: 100px; }
    @media only screen and (min-width: 768px) {
      .ec-imageGrid .ec-imageGrid__img {
        padding: 10px;
        width: 130px; } }
    .ec-imageGrid .ec-imageGrid__img img {
      width: 100%; }
  .ec-imageGrid .ec-imageGrid__content {
    vertical-align: middle;
    display: table-cell; }
    .ec-imageGrid .ec-imageGrid__content span {
      margin-left: 10px; }
    .ec-imageGrid .ec-imageGrid__content p {
      margin-bottom: 0; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
ログイン

主にログインフォームのスタイルを表示します。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 6.1
*/
/*
ログインフォーム

ログインフォームを表示します。

ex [ログイン画面](http://demo3.ec-cube.net/mypage/login)

Markup:
include /assets/tmpl/elements/6.3.login.pug
+ec-login


Styleguide 6.1.1
*/
.ec-login {
  margin: 0 0 20px;
  padding: 30px 13% 20px;
  height: auto;
  background: #F3F4F4;
  box-sizing: border-box; }
  @media only screen and (min-width: 768px) {
    .ec-login {
      margin: 0 16px;
      padding: 30px 13% 60px; } }
  .ec-login .ec-login__icon {
    text-align: center; }
  .ec-login .ec-icon {
    margin-bottom: 10px; }
    .ec-login .ec-icon img {
      width: 90px;
      height: 90px;
      display: inline-block; }
  .ec-login .ec-login__input {
    margin-bottom: 40px; }
    .ec-login .ec-login__input .ec-checkbox span {
      margin-left: 5px;
      font-weight: normal; }
  .ec-login .ec-login__actions {
    color: #fff; }
    .ec-login .ec-login__actions a {
      color: inherit;
      text-decoration: none; }
    .ec-login .ec-login__actions a:hover {
      text-decoration: none; }
  .ec-login .ec-login__link {
    margin-top: 5px;
    margin-left: 0; }
    @media only screen and (min-width: 768px) {
      .ec-login .ec-login__link {
        margin-left: 20px; } }
  .ec-login .ec-errorMessage {
    color: #DE5D50;
    margin-bottom: 20px; }

/*
ゲスト購入

ゲスト購入ボタンとそのフォームを表示します。

ex [ゲスト購入画面](http://demo3.ec-cube.net/shopping/login)

Markup:
include /assets/tmpl/elements/6.3.login.pug
+ec-guest
hoge

Styleguide 6.1.2
*/
.ec-guest {
  display: table;
  margin: 0;
  padding: 13%;
  height: auto;
  box-sizing: border-box;
  background: #F3F4F4; }
  @media only screen and (min-width: 768px) {
    .ec-guest {
      height: 100%;
      margin: 0 16px; } }
  .ec-guest .ec-guest__inner {
    display: table-cell;
    vertical-align: middle;
    text-align: center; }
    .ec-guest .ec-guest__inner p {
      margin-bottom: 16px; }
  .ec-guest .ec-guest__actions {
    display: block;
    vertical-align: middle;
    text-align: center;
    color: #fff; }
    .ec-guest .ec-guest__actions a {
      color: inherit;
      text-decoration: none; }
    .ec-guest .ec-guest__actions a:hover {
      text-decoration: none; }
  .ec-guest .ec-guest__icon {
    font-size: 70px;
    text-align: center; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
商品掲載

トップページに商品掲載するスタイルガイド群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 7.1
*/
/*
商品アイテム（商品紹介B）

３項目横並びの商品アイテムを表示します。
必要に応じて商品詳細や、キャッチコピーなどを添えることが出来ます。

ex [トップページ　商品紹介部分](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/7.1.itembanner.pug
+ec-displayB

Styleguide 7.1.1
*/
.ec-displayB {
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  flex-direction: column; }
  @media only screen and (min-width: 768px) {
    .ec-displayB {
      flex-direction: row; } }
  .ec-displayB .ec-displayB__cell {
    width: 100%;
    margin-bottom: 16px; }
    .ec-displayB .ec-displayB__cell a {
      color: inherit;
      text-decoration: none; }
    .ec-displayB .ec-displayB__cell a:hover {
      text-decoration: none; }
    @media only screen and (min-width: 768px) {
      .ec-displayB .ec-displayB__cell {
        width: 31.4466%;
        margin-bottom: 0; } }
    .ec-displayB .ec-displayB__cell:hover {
      text-decoration: none; }
      .ec-displayB .ec-displayB__cell:hover img {
        opacity: .8; }
      .ec-displayB .ec-displayB__cell:hover a {
        text-decoration: none; }
  .ec-displayB .ec-displayB__img {
    margin-bottom: 15px; }
  .ec-displayB .ec-displayB__catch {
    margin-bottom: 15px;
    text-decoration: none;
    font-weight: bold;
    color: #9a947e; }
  .ec-displayB .ec-displayB__comment {
    margin-bottom: 14px;
    text-decoration: none;
    color: #525263;
    font-size: 14px; }
  .ec-displayB .ec-displayB__link {
    text-decoration: none;
    font-weight: bold;
    color: #9a947e; }

/*
商品アイテム（商品紹介C）

４項目横並びの商品アイテムを表示します。

ex [トップページ　商品紹介部分](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/7.1.itembanner.pug
+ec-displayC
p hoge

Styleguide 7.1.2
*/
.ec-displayC {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 24px; }
  .ec-displayC .ec-displayC__cell {
    width: 47%; }
    .ec-displayC .ec-displayC__cell a {
      color: inherit;
      text-decoration: none; }
    .ec-displayC .ec-displayC__cell a:hover {
      text-decoration: none; }
    @media only screen and (min-width: 768px) {
      .ec-displayC .ec-displayC__cell {
        width: 22.8775%; } }
    .ec-displayC .ec-displayC__cell:hover a {
      text-decoration: none; }
    .ec-displayC .ec-displayC__cell:hover img {
      opacity: .8; }
  .ec-displayC .ec-displayC__img {
    display: block;
    width: 100%;
    margin-bottom: 15px; }
  .ec-displayC .ec-displayC__catch {
    display: block;
    width: 100%;
    font-weight: bold;
    color: #9a947e; }
  .ec-displayC .ec-displayC__title {
    display: block;
    width: 100%;
    color: #525263; }
  .ec-displayC .ec-displayC__price {
    display: block;
    width: 100%;
    font-weight: bold;
    color: #525263; }
  .ec-displayC .ec-displayC__price--sp {
    display: block;
    width: 100%;
    font-weight: bold;
    color: #DE5D50; }

/*
商品アイテム（商品紹介D）

６項目横並びの商品アイテムを表示します。

ex [トップページ　商品紹介部分](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/7.1.itembanner.pug
+ec-displayD

Styleguide 7.1.3
*/
.ec-displayD {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap-reverse; }
  @media only screen and (min-width: 768px) {
    .ec-displayD {
      box-sizing: border-box;
      flex-wrap: nowrap; } }
  .ec-displayD .ec-displayD__cell {
    width: 30%;
    margin-bottom: 8px; }
    .ec-displayD .ec-displayD__cell a {
      color: inherit;
      text-decoration: none; }
    .ec-displayD .ec-displayD__cell a:hover {
      text-decoration: none; }
    @media only screen and (min-width: 768px) {
      .ec-displayD .ec-displayD__cell {
        width: 14.3083%;
        margin-bottom: 16px; } }
    .ec-displayD .ec-displayD__cell:hover {
      text-decoration: none; }
      .ec-displayD .ec-displayD__cell:hover img {
        opacity: .8; }
  .ec-displayD .ec-displayD__img {
    display: block;
    width: 100%; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
検索・一覧表示

検索欄や、一覧表示に使用するスタイル群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 7.2
*/
/*
トピックパス

検索結果で表示されるトピックパスのスタイルです。

ex [商品一覧ページ　横並びリスト部分](http://demo3.ec-cube.net/products/list?category_id=&name=)

Markup:
include /assets/tmpl/elements/7.2.search.pug
+ec-topicpath

Styleguide 7.2.1
*/
.ec-topicpath {
  letter-spacing: -.4em;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  -webkit-padding-start: 0;
  border-top: 1px solid #ccc;
  border-bottom: 1px dotted #ccc;
  padding: 10px;
  list-style: none;
  overflow: hidden;
  font-size: 12px;
  color: #0092C4; }
  @media only screen and (min-width: 768px) {
    .ec-topicpath {
      padding: 30px 0 10px;
      border: 0;
      font-size: 16px; } }
  .ec-topicpath .ec-topicpath__item a {
    color: inherit;
    text-decoration: none; }
  .ec-topicpath .ec-topicpath__item a:hover {
    text-decoration: none; }
  .ec-topicpath .ec-topicpath__divider {
    color: #000; }
  .ec-topicpath .ec-topicpath__item,
  .ec-topicpath .ec-topicpath__divider,
  .ec-topicpath .ec-topicpath__item--active {
    display: inline-block;
    min-width: 16px;
    text-align: center;
    position: relative;
    letter-spacing: normal; }
  .ec-topicpath .ec-topicpath__item--active {
    font-weight: bold; }
    .ec-topicpath .ec-topicpath__item--active a {
      color: inherit;
      text-decoration: none; }
    .ec-topicpath .ec-topicpath__item--active a:hover {
      text-decoration: none; }

/*
ページャ

検索結果で表示される商品一覧のスタイルです。

ex [商品一覧ページ　ページャ部分](http://demo3.ec-cube.net/products/list?category_id=&name=)

Markup:
include /assets/tmpl/elements/7.2.search.pug
+ec-pager

Styleguide 7.2.2
*/
.ec-pager {
  list-style: none;
  list-style-type: none;
  margin: 0 auto;
  padding: 1em 0;
  text-align: center; }
  .ec-pager .ec-pager__item,
  .ec-pager .ec-pager__item--active {
    display: inline-block;
    min-width: 29px;
    padding: 0 3px 0 2px;
    text-align: center;
    position: relative; }
    .ec-pager .ec-pager__item a,
    .ec-pager .ec-pager__item--active a {
      color: inherit;
      text-decoration: none; }
    .ec-pager .ec-pager__item a:hover,
    .ec-pager .ec-pager__item--active a:hover {
      text-decoration: none; }
    .ec-pager .ec-pager__item a,
    .ec-pager .ec-pager__item--active a {
      color: inherit;
      display: block;
      line-height: 1.8;
      padding: 5px 1em;
      text-decoration: none !important; }
    .ec-pager .ec-pager__item a:hover,
    .ec-pager .ec-pager__item--active a:hover {
      color: inherit; }
  .ec-pager .ec-pager__item--active {
    background: #F3F3F3; }
  .ec-pager .ec-pager__item:hover {
    background: #F3F3F3; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden; }
  100% {
    opacity: 1;
    visibility: visible; } }

@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible; }
  100% {
    opacity: 0;
    visibility: hidden; } }

.bg-load-overlay {
  background: rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  position: fixed;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-around;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2147483647;
  opacity: 1; }

/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
カート

ショッピングカートに関するスタイルです。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 7.3
*/
/*
カートヘッダ

購入完了までの手順や、現在の状態を表示します。

ul 要素を用いたリスト要素としてマークアップします。

ex [カートページ　ヘッダ部分](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/7.3.cart.pug
+ec-progress

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 7.3.1
*/
.ec-progress {
  margin: 0 auto;
  padding: 8px 0 16px;
  display: table;
  table-layout: fixed;
  width: 100%;
  max-width: 600px;
  list-style: none; }
  @media only screen and (min-width: 768px) {
    .ec-progress {
      margin-bottom: 30px;
      padding: 0; } }
  .ec-progress .ec-progress__item {
    display: table-cell;
    position: relative;
    font-size: 14px;
    text-align: center;
    font-weight: bold;
    z-index: 10; }
    .ec-progress .ec-progress__item:after {
      content: '';
      position: absolute;
      display: block;
      background: #525263;
      width: 100%;
      height: 0.25em;
      top: 1.25em;
      left: 50%;
      margin-left: 1.5em\9;
      z-index: -1; }
    .ec-progress .ec-progress__item:last-child:after {
      display: none; }
  .ec-progress .ec-progress__number {
    line-height: 30px;
    width: 30px;
    height: 30px;
    margin-bottom: 5px;
    font-size: 12px;
    background: #525263;
    color: #fff;
    top: 0;
    left: 18px;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    border-radius: 50%; }
    @media only screen and (min-width: 768px) {
      .ec-progress .ec-progress__number {
        line-height: 42px;
        width: 42px;
        height: 42px;
        font-size: 20px; } }
  .ec-progress .ec-progress__label {
    font-size: 12px; }
  .ec-progress .is-complete .ec-progress__number {
    background: #5CB1B1; }
  .ec-progress .is-complete .ec-progress__label {
    color: #5CB1B1; }

/*
カートナビゲーション

カートナビゲーションを表示します。　カートに追加された商品の個数も表示します。

ex [カートページ　ナビゲーション部分](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/11.2.header.pug
+ec-headerCart

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>


Styleguide 7.3.5
*/
@media only screen and (min-width: 768px) {
  .ec-cartNaviWrap {
    position: relative; } }

.ec-cartNavi {
  display: inline-block;
  padding: 10px 0 0 20px;
  width: auto;
  background: transparent; }
  @media only screen and (min-width: 768px) {
    .ec-cartNavi {
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 5px 2px;
      width: auto;
      min-width: 140px;
      white-space: nowrap;
      cursor: pointer;
      background: #F8F8F8; } }
  .ec-cartNavi .ec-cartNavi__icon {
    color: #666666;
    display: inline-block;
    font-size: 20px;
    display: inline-block;
    opacity: 1;
    visibility: visible;
    animation: fadeIn 200ms linear 0s;
    position: relative; }
  .ec-cartNavi .ec-cartNavi__text {
    color: #666666; }
  .ec-cartNavi .ec-cartNavi__badge {
    display: inline-block;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 5px;
    height: 21px;
    font-size: 10px;
    vertical-align: top;
    color: #fff;
    text-align: left;
    white-space: nowrap;
    background-color: red; }
  .ec-cartNavi .ec-cartNavi__price {
    display: none; }
    @media only screen and (min-width: 768px) {
      .ec-cartNavi .ec-cartNavi__price {
        display: inline-block;
        font-size: 14px;
        font-weight: normal;
        vertical-align: middle; } }

.ec-cartNavi.is-active .ec-cartNavi__icon:before {
  content: "\f00d";
  font-family: "Font Awesome 5 Free";
  font-weight: 900; }

.ec-cartNavi.is-active .ec-cartNavi__badge {
  display: none; }
  @media only screen and (min-width: 768px) {
    .ec-cartNavi.is-active .ec-cartNavi__badge {
      display: none; } }

/*
カートナビゲーションのポップアップ(商品詳細)

カートナビゲーションのポップアップを表示します。カートに追加された商品の詳細が表示されます。

ex [カートページ　ナビゲーション部分](http://demo3.ec-cube.net/cart)

Markup:
div(style="height:350px;")
  // 上記のdivはスタイルガイド都合上、高さをもたせるため設置(mocでは不要)
  .is_active
    .ec-cartNavi
      .ec-cartNavi__icon
        img(src='/moc/icon/cart-dark.svg', alt='close')
      .ec-cartNavi__iconClose
        img(src='/moc/icon/cross-dark.svg', alt='close')
      .ec-cartNavi__badge 1
      .ec-cartNavi__label
        | 合計
        .ec-cartNavi__price ¥1920
    +b.ec-cartNaviIsset
      +e.cart
        +e.cartImage
          img(src='http://demo3.ec-cube.net/upload/save_image/0701104933_5593472d8d179.jpeg')
        +e.cartContent
          +e.cartContentTitle ミニテーブル
          +e.cartContentPrice ¥ 12,960
            +e.cartContentTax 税込
          +e.cartContentNumber 数量：1
      +e.action
        a.ec-blockBtn--action(href="/moc/guest/cart1") カートへ進む
        a.ec-blockBtn.ec-cartNavi--cancel キャンセル

Styleguide 7.3.6
*/
.ec-cartNaviIsset {
  display: none;
  width: 100%;
  text-align: center;
  background: #f8f8f8;
  box-sizing: border-box;
  padding: 16px;
  z-index: 20;
  position: absolute;
  right: 0; }
  @media only screen and (min-width: 768px) {
    .ec-cartNaviIsset {
      margin-top: 10px;
      min-width: 256px;
      max-width: 256px; }
      .ec-cartNaviIsset::before {
        display: inline-block;
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 8.5px 10px 8.5px;
        border-color: transparent transparent #f8f8f8 transparent;
        position: absolute;
        top: -9px; } }
  .ec-cartNaviIsset .ec-cartNaviIsset__cart {
    border-bottom: 1px solid #E8E8E8;
    margin-bottom: 16px;
    padding-bottom: 32px; }
    .ec-cartNaviIsset .ec-cartNaviIsset__cart:after {
      content: " ";
      display: table; }
    .ec-cartNaviIsset .ec-cartNaviIsset__cart:after {
      clear: both; }
  .ec-cartNaviIsset .ec-cartNaviIsset__cartImage {
    float: left;
    width: 45%; }
    .ec-cartNaviIsset .ec-cartNaviIsset__cartImage img {
      width: 100%; }
  .ec-cartNaviIsset .ec-cartNaviIsset__cartContent {
    float: right;
    width: 55%;
    padding-left: 16px;
    text-align: left;
    box-sizing: border-box;
    word-break: break-word; }
  .ec-cartNaviIsset .ec-cartNaviIsset__action .ec-blockBtn--action {
    color: #fff;
    margin-bottom: 8px; }
  .ec-cartNaviIsset .ec-cartNaviIsset__cartContentTitle {
    margin-bottom: 8px; }
  .ec-cartNaviIsset .ec-cartNaviIsset__cartContentPrice {
    font-weight: bold; }
  .ec-cartNaviIsset .ec-cartNaviIsset__cartContentTax {
    display: inline-block;
    font-size: 12px;
    font-weight: normal;
    margin-left: 2px; }
  .ec-cartNaviIsset .ec-cartNaviIsset__cartContentNumber {
    font-size: 14px; }

.ec-cartNaviIsset.is-active {
  display: block; }

/*
カートナビゲーションのポップアップ(商品なし)

カートナビゲーションのポップアップを表示します。商品が登録されていない場合の表示です。

ex [カートページ　ナビゲーション部分](http://demo3.ec-cube.net/cart)

Markup:
div(style="height:170px;")
  // 上記のdivはスタイルガイド都合上、高さをもたせるため設置(mocでは不要)
  .is_active
    .ec-cartNavi
      .ec-cartNavi__icon
        img(src='/moc/icon/cart-dark.svg', alt='cart')
      .ec-cartNavi__iconClose
        img(src='/moc/icon/cross-dark.svg', alt='close')
      .ec-cartNavi__badge 1
      .ec-cartNavi__label
        | 合計
        .ec-cartNavi__price ¥1920
    .ec-cartNaviNull
      .ec-cartNaviNull__message
        p 現在カート内に
          br
          | 商品がございません。
    //+b.ec-cartNaviIsset
    //  +e.cart
    //    +e.cartImage
    //      img(src='http://demo3.ec-cube.net/upload/save_image/0701104933_5593472d8d179.jpeg')
    //    +e.cartContent
    //      +e.cartContentTitle ミニテーブル
    //      +e.cartContentPrice ¥ 12,960
    //        +e.cartContentTax 税込
    //      +e.cartContentNumber 数量：1
    //  +e.action
    //    a.ec-blockBtn--action(href="/moc/guest/cart1") カートへ進む
    //    a.ec-blockBtn キャンセル

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>


Styleguide 7.3.7
*/
.ec-cartNaviNull {
  display: none;
  width: 100%;
  text-align: center;
  background: #f8f8f8;
  box-sizing: border-box;
  padding: 16px;
  z-index: 3;
  position: absolute;
  right: 0; }
  @media only screen and (min-width: 768px) {
    .ec-cartNaviNull {
      margin-top: 10px;
      min-width: 256px;
      max-width: 256px; }
      .ec-cartNaviNull::before {
        display: inline-block;
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 8.5px 10px 8.5px;
        border-color: transparent transparent #f8f8f8 transparent;
        position: absolute;
        top: -9px; } }
  .ec-cartNaviNull .ec-cartNaviNull__message {
    border: 1px solid #D9D9D9;
    padding: 16px 0;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: #F99; }
    .ec-cartNaviNull .ec-cartNaviNull__message p {
      margin: 0; }

.ec-cartNaviNull.is-active {
  display: block; }

/*
総計

会計時の合計金額、総計を表示します。

ex [カートページ　統計部分](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/7.3.cart.pug
+ec-totalBox

Styleguide 7.3.8
*/
.ec-totalBox {
  background: #F3F3F3;
  padding: 16px;
  margin-bottom: 16px; }
  .ec-totalBox .ec-totalBox__spec {
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    margin-bottom: 8px; }
    .ec-totalBox .ec-totalBox__spec dt {
      font-weight: normal;
      text-align: left;
      font-size: 13px; }
    .ec-totalBox .ec-totalBox__spec dd {
      text-align: right;
      font-size: 13px; }
    .ec-totalBox .ec-totalBox__spec .ec-totalBox .ec-totalBox__spec__specTotal {
      color: #DE5D50; }
  .ec-totalBox .ec-totalBox__total {
    border-top: 1px dotted #ccc;
    padding: 8px 0;
    text-align: right;
    font-size: 14px;
    font-weight: bold; }
  .ec-totalBox .ec-totalBox__paymentTotal {
    padding: 8px 0;
    text-align: right;
    font-size: 14px;
    font-weight: bold; }
    .ec-totalBox .ec-totalBox__paymentTotal .ec-totalBox__price,
    .ec-totalBox .ec-totalBox__paymentTotal .ec-totalBox__taxLabel {
      color: #DE5D50; }
  .ec-totalBox .ec-totalBox__price {
    margin-left: 16px;
    font-size: 16px;
    font-weight: bold; }
    @media only screen and (min-width: 768px) {
      .ec-totalBox .ec-totalBox__price {
        font-size: 24px; } }
  .ec-totalBox .ec-totalBox__taxLabel {
    margin-left: 8px;
    font-size: 12px; }
    @media only screen and (min-width: 768px) {
      .ec-totalBox .ec-totalBox__taxLabel {
        font-size: 14px; } }
  .ec-totalBox .ec-totalBox__taxRate {
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-bottom: 8px;
    font-size: 10px; }
    @media only screen and (min-width: 768px) {
      .ec-totalBox .ec-totalBox__taxRate {
        font-size: 12px; } }
    .ec-totalBox .ec-totalBox__taxRate dt {
      font-weight: normal;
      text-align: left;
      margin-right: 8px; }
      .ec-totalBox .ec-totalBox__taxRate dt::before {
        content: "[ "; }
    .ec-totalBox .ec-totalBox__taxRate dd {
      text-align: right; }
      .ec-totalBox .ec-totalBox__taxRate dd::after {
        content: " ]"; }
  .ec-totalBox .ec-totalBox__pointBlock {
    padding: 18px 20px 10px;
    margin-bottom: 10px;
    background: #fff; }
  .ec-totalBox .ec-totalBox__btn {
    color: #fff; }
    .ec-totalBox .ec-totalBox__btn a {
      color: inherit;
      text-decoration: none; }
    .ec-totalBox .ec-totalBox__btn a:hover {
      text-decoration: none; }
    .ec-totalBox .ec-totalBox__btn .ec-blockBtn--action {
      font-size: 16px;
      font-weight: bold; }
    .ec-totalBox .ec-totalBox__btn .ec-blockBtn--cancel {
      margin-top: 8px;
      text-decoration: none; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
お知らせ

新着情報やバナーなどの掲載項目を紹介していきます。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 8.1
*/
/*
新着情報

新着情報の掲載をします。

ex [トップページ　新着情報部分](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/8.1.info.pug
+ec-news

Styleguide 8.1.1
*/
.ec-news {
  margin-bottom: 16px;
  background: #F8F8F8; }
  @media only screen and (min-width: 768px) {
    .ec-news {
      margin-right: 3%; } }
  @media only screen and (min-width: 768px) {
    .ec-news {
      margin-bottom: 32px; } }
  .ec-news .ec-news__title {
    font-weight: bold;
    padding: 8px;
    font-size: 16px;
    text-align: center; }
    @media only screen and (min-width: 768px) {
      .ec-news .ec-news__title {
        padding: 16px;
        text-align: left;
        font-size: 24px; } }
  .ec-news .ec-news__items {
    padding: 0;
    list-style: none;
    border-top: 1px dotted #ccc; }

/*
折りたたみ項目

折りたたみ項目を掲載します。

ex [トップページ　折りたたみ項目部分](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/8.1.info.pug
+b.ec-news
        +e.title 新着情報
        +e.UL.items
            +e.LI.item
                +b.ec-newsline.is_active
                    +e.info
                        +e.date 2016/09/29
                        +e.comment サイトオープンしました
                        +e.close
                            a.ec-closeBtn--circle
                                span.ec-closeBtn--circle__icon
                                    .ec-icon
                                        img(src='/moc/icon/angle-down-white.svg', alt='')
                    +e.description 一人暮らしからオフィスなどさまざまなシーンで あなたの生活をサポートするグッズをご家庭へお届けします！

Styleguide 8.1.2
*/
.ec-newsline {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  padding: 0 16px; }
  .ec-newsline .ec-newsline__info {
    width: 100%;
    padding: 16px 0; }
    .ec-newsline .ec-newsline__info:after {
      content: " ";
      display: table; }
    .ec-newsline .ec-newsline__info:after {
      clear: both; }
  .ec-newsline .ec-newsline__date {
    display: inline-block;
    margin-right: 10px;
    float: left; }
  .ec-newsline .ec-newsline__comment {
    display: inline-block;
    float: left; }
  .ec-newsline .ec-newsline__close {
    float: right;
    display: inline-block;
    text-align: right; }
    .ec-newsline .ec-newsline__close .ec-closeBtn--circle {
      display: inline-block;
      width: 25px;
      height: 25px;
      min-width: 25px;
      min-height: 25px; }
  .ec-newsline .ec-newsline__description {
    width: 100%;
    height: 0;
    transition: all .2s ease-out; }
  .ec-newsline.is_active .ec-newsline__description {
    height: auto;
    transition: all .2s ease-out;
    padding-bottom: 16px; }
  .ec-newsline.is_active .ec-icon img {
    transform: rotateX(180deg); }

/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
マイページ

マイページで利用するためのスタイルガイド群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 9.1
*/
/*
マイページ

マイページで表示するメニューリストです。

ul を利用したリスト要素で記述します。

ex [マイページ　メニューリスト部分](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-navlist

Styleguide 9.1.1
*/
.ec-navlistRole .ec-navlistRole__navlist {
  display: flex;
  flex-wrap: wrap;
  border-color: #D0D0D0;
  border-style: solid;
  border-width: 1px 0 0 1px;
  margin-bottom: 32px;
  padding: 0;
  list-style: none; }
  .ec-navlistRole .ec-navlistRole__navlist a {
    color: inherit;
    text-decoration: none; }
  .ec-navlistRole .ec-navlistRole__navlist a:hover {
    text-decoration: none; }
  @media only screen and (min-width: 768px) {
    .ec-navlistRole .ec-navlistRole__navlist {
      flex-wrap: nowrap; } }

.ec-navlistRole .ec-navlistRole__item {
  display: flex;
  width: 50%;
  border-color: #D0D0D0;
  border-style: solid;
  border-width: 0 1px 1px 0;
  text-align: center;
  font-weight: bold; }
  .ec-navlistRole .ec-navlistRole__item:hover {
    background: #f5f7f8; }
  .ec-navlistRole .ec-navlistRole__item a {
    margin: auto;
    padding: 16px 10px;
    width: 100%;
    display: inline-block;
    text-decoration: none !important; }

.ec-navlistRole .active a {
  color: #DE5D50; }

/*
マイページ（お気に入り機能無効）

マイページで表示するメニューリストです。

ul を利用したリスト要素で記述します。

ex [マイページ　メニューリスト部分](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-navlist_noFavorite

Styleguide 9.1.2
*/
/*
Welcome メッセージ

マイページで表示するログイン名の表示コンポーネントです。

ex [マイページ　メニューリスト下部分](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-welcomeMsg

Styleguide 9.1.3
*/
.ec-welcomeMsg {
  margin-right: auto;
  margin-left: auto;
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  margin: 1em 0;
  padding-bottom: 32px;
  text-align: center;
  border-bottom: 1px dotted #ccc; }
  .ec-welcomeMsg:after {
    content: " ";
    display: table; }
  .ec-welcomeMsg:after {
    clear: both; }
  @media only screen and (min-width: 768px) {
    .ec-welcomeMsg {
      padding-left: 26px;
      padding-right: 26px; } }

/*
お気に入り一覧

お気に入り一覧で表示するアイテムの表示コンポーネントです。

ex [マイページ　お気に入り一覧](http://demo3.ec-cube.net/mypage/favorite)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-favorite

Styleguide 9.1.4
*/
.ec-favoriteRole .ec-favoriteRole__header {
  margin-bottom: 16px; }

.ec-favoriteRole .ec-favoriteRole__itemList {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  list-style: none; }
  .ec-favoriteRole .ec-favoriteRole__itemList a {
    color: inherit;
    text-decoration: none; }
  .ec-favoriteRole .ec-favoriteRole__itemList a:hover {
    text-decoration: none; }

.ec-favoriteRole .ec-favoriteRole__item {
  margin-bottom: 8px;
  width: 47.5%;
  position: relative;
  box-sizing: border-box;
  padding: 10px; }
  .ec-favoriteRole .ec-favoriteRole__item-image {
    margin-bottom: 10px;
    text-align: center; }
  .ec-favoriteRole .ec-favoriteRole__item img {
    width: auto;
    max-height: 100%; }
  @media only screen and (min-width: 768px) {
    .ec-favoriteRole .ec-favoriteRole__item {
      width: 25%; } }
  .ec-favoriteRole .ec-favoriteRole__item .ec-closeBtn--circle {
    position: absolute;
    right: 10px;
    top: 10px; }
    .ec-favoriteRole .ec-favoriteRole__item .ec-closeBtn--circle .ec-icon img {
      width: 1em;
      height: 1em; }

.ec-favoriteRole .ec-favoriteRole__itemThumb {
  display: block;
  height: auto;
  margin-bottom: 8px; }

.ec-favoriteRole .ec-favoriteRole__itemTitle {
  margin-bottom: 2px; }

.ec-favoriteRole .ec-favoriteRole__itemPrice {
  font-weight: bold;
  margin-bottom: 0; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
標準セクション

通常のコンテナブロックです。

ex [商品詳細ページ　コンテナ](http://demo3.ec-cube.net/products/detail/33)

Markup:
include /assets/tmpl/elements/11.1.role.pug
+ec-roleRole

Styleguide 11.1
*/
.ec-role {
  margin: 0 auto;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 980px; }
  .ec-role:after {
    content: " ";
    display: table; }
  .ec-role:after {
    clear: both; }
  .ec-role textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-role img {
    max-width: 100%; }
  .ec-role html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-role *,
  .ec-role *::before,
  .ec-role *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }

/*
マイページセクション

マイページ専用のコンテナブロックです。

ex [マイページ　コンテナ](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/11.1.role.pug
+ec-mypageRole

Styleguide 11.1.2
*/
.ec-mypageRole {
  margin-right: auto;
  margin-left: auto;
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%; }
  .ec-mypageRole:after {
    content: " ";
    display: table; }
  .ec-mypageRole:after {
    clear: both; }
  @media only screen and (min-width: 768px) {
    .ec-mypageRole {
      padding-left: 26px;
      padding-right: 26px; } }
  @media only screen and (min-width: 768px) {
    .ec-mypageRole .ec-pageHeader h1 {
      margin: 10px 0 48px;
      padding: 8px 0 18px; } }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden; }
  100% {
    opacity: 1;
    visibility: visible; } }

@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible; }
  100% {
    opacity: 0;
    visibility: hidden; } }

.bg-load-overlay {
  background: rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  position: fixed;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-around;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2147483647;
  opacity: 1; }

/*
ヘッダー

ヘッダー用のプロジェクトコンポーネントを提供します。

ex [トップページ　ヘッダー](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/11.2.header.pug
include /assets/tmpl/elements/11.3.headerNavi.pug
include /assets/tmpl/elements/11.4.categoryNavi.pug
+b.ec-layoutRole
  +e.header
    +ec-headerRole
    +ec-headerNaviRole
    +ec-categoryNaviRole

Styleguide 11.2
*/
.ec-layoutRole {
  width: 100%;
  transition: transform 0.3s; }
  .ec-layoutRole .ec-layoutRole__contentTop {
    padding: 0 15px; }
    @media only screen and (min-width: 768px) {
      .ec-layoutRole .ec-layoutRole__contentTop {
        padding: 0; } }
  .ec-layoutRole .ec-layoutRole__contents {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    max-width: 980px;
    display: flex;
    flex-wrap: nowrap;
    padding: 0 15px 20px; }
    @media only screen and (min-width: 768px) {
      .ec-layoutRole .ec-layoutRole__contents {
        padding: 0 0 30px 0; } }
  .ec-layoutRole .ec-layoutRole__main {
    width: 100%; }
  .ec-layoutRole .ec-layoutRole__mainWithColumn {
    width: 100%; }
    .ec-layoutRole .ec-layoutRole__mainWithColumn a {
      text-decoration: underline; }
    @media only screen and (min-width: 768px) {
      .ec-layoutRole .ec-layoutRole__mainWithColumn {
        width: 78%; } }
  .ec-layoutRole .ec-layoutRole__mainBetweenColumn {
    width: 100%; }
    @media only screen and (min-width: 768px) {
      .ec-layoutRole .ec-layoutRole__mainBetweenColumn {
        width: 50%; } }
  .ec-layoutRole .ec-layoutRole__left,
  .ec-layoutRole .ec-layoutRole__right {
    display: none; }
    @media only screen and (min-width: 768px) {
      .ec-layoutRole .ec-layoutRole__left,
      .ec-layoutRole .ec-layoutRole__right {
        display: block;
        width: 22%;
        padding-right: 35px; } }

.ec-headerRole {
  margin: 0 auto;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 980px;
  padding-top: 15px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: auto; }
  .ec-headerRole:after {
    content: " ";
    display: table; }
  .ec-headerRole:after {
    clear: both; }
  .ec-headerRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-headerRole img {
    max-width: 100%; }
  .ec-headerRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-headerRole *,
  .ec-headerRole *::before,
  .ec-headerRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-headerRole:after {
    display: none; }
  @media only screen and (min-width: 768px) {
    .ec-headerRole:after {
      content: " ";
      display: table; }
    .ec-headerRole:after {
      clear: both; } }
  .ec-headerRole::before {
    display: none; }
  @media only screen and (min-width: 768px) {
    .ec-headerRole {
      width: 100%; }
      .ec-headerRole:after {
        content: " ";
        display: table; }
      .ec-headerRole:after {
        clear: both; } }
  .ec-headerRole .ec-headerRole__title {
    width: 100%; }
  .ec-headerRole .ec-headerRole__navSP {
    display: block;
    position: absolute;
    top: 15px;
    width: 27%;
    right: 0;
    text-align: right; }
    @media only screen and (min-width: 768px) {
      .ec-headerRole .ec-headerRole__navSP {
        display: none; } }

.ec-headerNaviRole {
  margin: 0 auto;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 980px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px; }
  .ec-headerNaviRole:after {
    content: " ";
    display: table; }
  .ec-headerNaviRole:after {
    clear: both; }
  .ec-headerNaviRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-headerNaviRole img {
    max-width: 100%; }
  .ec-headerNaviRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-headerNaviRole *,
  .ec-headerNaviRole *::before,
  .ec-headerNaviRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  @media only screen and (max-width: 769px) {
    .ec-headerNaviRole {
      position: fixed;
      top: 0;
      height: 63px;
      z-index: 999; } }
  .ec-headerNaviRole .fa-bars {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .ec-headerNaviRole .ec-headerNaviRole__left {
    width: calc(100% / 3); }
  .ec-headerNaviRole .ec-headerNaviRole__search {
    display: none; }
    @media only screen and (min-width: 768px) {
      .ec-headerNaviRole .ec-headerNaviRole__search {
        display: inline-block;
        margin-top: 10px; }
        .ec-headerNaviRole .ec-headerNaviRole__search a {
          color: inherit;
          text-decoration: none; }
        .ec-headerNaviRole .ec-headerNaviRole__search a:hover {
          text-decoration: none; } }
  .ec-headerNaviRole .ec-headerNaviRole__navSP {
    display: block; }
    @media only screen and (min-width: 768px) {
      .ec-headerNaviRole .ec-headerNaviRole__navSP {
        display: none; }
        .ec-headerNaviRole .ec-headerNaviRole__navSP a {
          color: inherit;
          text-decoration: none; }
        .ec-headerNaviRole .ec-headerNaviRole__navSP a:hover {
          text-decoration: none; } }
  .ec-headerNaviRole .ec-headerNaviRole__right {
    width: calc(100% * 2 / 3);
    display: flex;
    justify-content: flex-end;
    align-items: center; }
  .ec-headerNaviRole .ec-headerNaviRole__nav {
    display: inline-block; }
    .ec-headerNaviRole .ec-headerNaviRole__nav a {
      color: inherit;
      text-decoration: none; }
    .ec-headerNaviRole .ec-headerNaviRole__nav a:hover {
      text-decoration: none; }
  .ec-headerNaviRole .ec-headerNaviRole__cart {
    display: inline-block; }
    .ec-headerNaviRole .ec-headerNaviRole__cart a {
      color: inherit;
      text-decoration: none; }
    .ec-headerNaviRole .ec-headerNaviRole__cart a:hover {
      text-decoration: none; }

.ec-headerNavSP {
  display: block;
  cursor: pointer;
  border-radius: 50%;
  box-sizing: border-box;
  padding: 10px;
  width: 40px;
  height: 40px;
  font-size: 18px;
  text-align: center;
  color: black;
  background: white;
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1000; }
  .ec-headerNavSP .fas {
    vertical-align: top; }
  @media only screen and (min-width: 768px) {
    .ec-headerNavSP {
      display: none; } }

.ec-headerNavSP.is-active {
  display: none; }

/*
ヘッダー：タイトル

ヘッダー内で使用されるタイトルコンポーネントです。

Markup:
include /assets/tmpl/elements/11.2.header.pug
+ec-headerTitle

Styleguide 11.2.1
*/
.ec-headerTitle {
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%; }
  .ec-headerTitle textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-headerTitle img {
    max-width: 100%; }
  .ec-headerTitle html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-headerTitle *,
  .ec-headerTitle *::before,
  .ec-headerTitle *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-headerTitle .ec-headerTitle__title {
    text-align: center; }
    .ec-headerTitle .ec-headerTitle__title h1 {
      margin: 0;
      padding: 0; }
    .ec-headerTitle .ec-headerTitle__title a {
      display: inline-block;
      margin-bottom: 30px;
      text-decoration: none;
      font-size: 20px;
      font-weight: bold;
      color: black; }
      @media only screen and (min-width: 768px) {
        .ec-headerTitle .ec-headerTitle__title a {
          font-size: 40px; } }
      .ec-headerTitle .ec-headerTitle__title a:hover {
        opacity: .8; }
  .ec-headerTitle .ec-headerTitle__subtitle {
    font-size: 10px;
    text-align: center; }
    @media only screen and (min-width: 768px) {
      .ec-headerTitle .ec-headerTitle__subtitle {
        font-size: 16px;
        margin-bottom: 10px; } }
    .ec-headerTitle .ec-headerTitle__subtitle a {
      display: inline-block;
      color: #0092C4;
      text-decoration: none;
      cursor: pointer; }

/*
ヘッダー：ユーザナビゲーション

ヘッダー内でユーザに関与するナビゲーションコンポーネントです。
<br><br>
`.ec-headerNaviRole`>`.ec-headerNaviRole__nav`内に記述すると２カラム上の右側に配置することができます。

Markup:
include /assets/tmpl/elements/11.3.headerNavi.pug
+ec-headerNav

sg-wrapper:
<div class="ec-headerNaviRole">
  <div class="ec-headerNaviRole__nav">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.2.3
*/
.ec-headerNav {
  text-align: right; }
  .ec-headerNav .ec-headerNav__item {
    margin-left: 0;
    display: inline-block;
    font-size: 28px; }
  .ec-headerNav .ec-headerNav__itemIcon {
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
    font-size: 18px;
    color: black; }
    @media only screen and (min-width: 768px) {
      .ec-headerNav .ec-headerNav__itemIcon {
        margin-right: 0;
        font-size: 20px; } }
  .ec-headerNav .ec-headerNav__itemLink {
    display: none;
    margin-right: 5px;
    font-size: 14px;
    vertical-align: middle;
    color: black; }
    @media only screen and (min-width: 768px) {
      .ec-headerNav .ec-headerNav__itemLink {
        display: inline-block; } }

/*
ヘッダー：検索ボックス

ヘッダー内で使用される商品検索コンポーネントです。
<br><br>
`.ec-headerNaviRole`>`.ec-headerNaviRole__search`内に記述すると２カラム上の右側に配置することができます。

Markup:
include /assets/tmpl/elements/11.3.headerNavi.pug
+ec-headerSearch

sg-wrapper:
<div class="ec-headerNaviRole">
  <div class="ec-headerNaviRole__search">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.2.4
*/
.ec-headerSearch:after {
  content: " ";
  display: table; }

.ec-headerSearch:after {
  clear: both; }

.ec-headerSearch .ec-headerSearch__category {
  float: none; }
  @media only screen and (min-width: 768px) {
    .ec-headerSearch .ec-headerSearch__category {
      float: left;
      width: 43%; } }
  .ec-headerSearch .ec-headerSearch__category .ec-select {
    overflow: hidden;
    width: 100%;
    margin: 0;
    text-align: center; }
    .ec-headerSearch .ec-headerSearch__category .ec-select select {
      width: 100%;
      cursor: pointer;
      padding: 8px 24px 8px 8px;
      text-indent: 0.01px;
      text-overflow: ellipsis;
      border: none;
      outline: none;
      background: transparent;
      background-image: none;
      box-shadow: none;
      appearance: none;
      color: #fff; }
      @media only screen and (min-width: 768px) {
        .ec-headerSearch .ec-headerSearch__category .ec-select select {
          max-width: 165px;
          height: 36px; } }
      .ec-headerSearch .ec-headerSearch__category .ec-select select option {
        color: #000; }
      .ec-headerSearch .ec-headerSearch__category .ec-select select::-ms-expand {
        display: none; }
    .ec-headerSearch .ec-headerSearch__category .ec-select.ec-select_search {
      position: relative;
      border: 0;
      background: #000;
      color: #fff;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px; }
      @media only screen and (min-width: 768px) {
        .ec-headerSearch .ec-headerSearch__category .ec-select.ec-select_search {
          border-top-right-radius: inherit;
          border-top-left-radius: 50px;
          border-bottom-left-radius: 50px; } }
      .ec-headerSearch .ec-headerSearch__category .ec-select.ec-select_search::before {
        position: absolute;
        top: 0.8em;
        right: 0.4em;
        width: 0;
        height: 0;
        padding: 0;
        content: '';
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid #fff;
        pointer-events: none; }

.ec-headerSearch .ec-headerSearch__keyword {
  position: relative;
  color: #525263;
  border: 1px solid #ccc;
  background-color: #f6f6f6;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px; }
  @media only screen and (min-width: 768px) {
    .ec-headerSearch .ec-headerSearch__keyword {
      float: right;
      width: 57%;
      border-bottom-left-radius: inherit;
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px; } }
  .ec-headerSearch .ec-headerSearch__keyword input[type="search"] {
    width: 100%;
    height: 34px;
    font-size: 16px;
    border: 0 none;
    padding: 0.5em 50px 0.5em 1em;
    box-shadow: none;
    background: none;
    box-sizing: border-box;
    margin-bottom: 0; }
    @media only screen and (min-width: 768px) {
      .ec-headerSearch .ec-headerSearch__keyword input[type="search"] {
        font-size: 12px; } }
  .ec-headerSearch .ec-headerSearch__keyword .ec-icon {
    width: 22px;
    height: 22px; }

.ec-headerSearch .ec-headerSearch__keywordBtn {
  border: 0;
  background: none;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-55%);
  display: block;
  white-space: nowrap;
  z-index: 1; }

/*
ヘッダー：カテゴリナビ

ヘッダー内で使用されている商品のカテゴリ一覧として使用します。
`li`の中に`ul > li`要素を入れることで、階層を深くする事ができます。

Markup:
include /assets/tmpl/elements/11.4.categoryNavi.pug
+ec-itemNav

sg-wrapper:
<div class="ec-categoryNaviRole" style="padding-bottom:150px;">
  <sg-wrapper-content/>
</div>

Styleguide 11.2.5
*/
.ec-categoryNaviRole {
  margin: 0 auto;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 980px;
  display: none; }
  .ec-categoryNaviRole:after {
    content: " ";
    display: table; }
  .ec-categoryNaviRole:after {
    clear: both; }
  .ec-categoryNaviRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-categoryNaviRole img {
    max-width: 100%; }
  .ec-categoryNaviRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-categoryNaviRole *,
  .ec-categoryNaviRole *::before,
  .ec-categoryNaviRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  @media only screen and (min-width: 768px) {
    .ec-categoryNaviRole {
      display: block;
      width: 100%; }
      .ec-categoryNaviRole a {
        color: inherit;
        text-decoration: none; }
      .ec-categoryNaviRole a:hover {
        text-decoration: none; } }

.ec-itemNav {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  text-align: center; }

.ec-itemNav__nav {
  display: block;
  margin: 0 auto;
  padding: 0;
  width: auto;
  height: auto;
  list-style-type: none;
  text-align: center;
  vertical-align: bottom; }
  @media only screen and (min-width: 768px) {
    .ec-itemNav__nav {
      display: inline-block; } }

.ec-itemNav__nav li {
  float: none;
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: center;
  position: relative; }
  @media only screen and (min-width: 768px) {
    .ec-itemNav__nav li {
      float: left;
      width: auto; } }

.ec-itemNav__nav li a {
  display: block;
  border-bottom: 1px solid #E8E8E8;
  margin: 0;
  padding: 16px;
  height: auto;
  color: #2e3233;
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  text-decoration: none;
  text-align: left;
  color: #fff;
  border-bottom: 1px solid #E8E8E8; }
  @media only screen and (min-width: 768px) {
    .ec-itemNav__nav li a {
      text-align: center;
      border-bottom: none; } }

.ec-itemNav__nav li ul {
  display: none;
  z-index: 0;
  margin: 0;
  padding: 0;
  min-width: 200px;
  list-style: none;
  position: static;
  top: 100%;
  left: 0; }
  @media only screen and (min-width: 768px) {
    .ec-itemNav__nav li ul {
      display: block;
      z-index: 100;
      position: absolute; } }

.ec-itemNav__nav li ul li {
  overflow: hidden;
  width: 100%;
  height: auto;
  transition: .3s; }
  @media only screen and (min-width: 768px) {
    .ec-itemNav__nav li ul li {
      overflow: hidden;
      height: 0; } }

.ec-itemNav__nav li ul li a {
  border-bottom: 1px solid #E8E8E8;
  padding: 16px 22px 16px 16px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  text-align: left;
  background: black; }

.ec-itemNav__nav > li:hover > a {
  background: #fb8c00; }

.ec-itemNav__nav > li:hover li:hover > a {
  background: #333; }

@media only screen and (min-width: 768px) {
  .ec-itemNav__nav > li:hover > ul > li {
    overflow: visible;
    height: auto; } }

.ec-itemNav__nav li ul li ul {
  top: 0;
  left: 100%;
  width: auto; }

@media only screen and (min-width: 768px) {
  .ec-itemNav__nav li ul li ul:before {
    content: "\f054";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 12px;
    color: white;
    position: absolute;
    top: 19px;
    right: auto;
    left: -20px; } }

@media only screen and (min-width: 768px) {
  .ec-itemNav__nav li ul li:hover > ul > li {
    overflow: visible;
    height: auto;
    width: auto; } }

.ec-itemNav__nav li ul li ul li a {
  background: #7D7D7D; }

.ec-itemNav__nav li:hover ul li ul li a:hover {
  background: #333; }

/*
ヘッダー：SPヘッダー

SP時のみ出現するヘッダーに関係するコンポーネントです。<br>
ex [トップページ](http://demo3.ec-cube.net/)画面サイズが768px以下に該当。<br>
<br>
`.ec-drawerRole`：SPのドロワー内の要素をwrapするコンポーネントです。<br>
`.ec-headerSearch`、`.ec-headerNav`、`.ec-itemNav`は`.ec-drawerRole`の子要素にある場合、ドロワーに適したスタイルに変化します。<br><br>
`.ec-overlayRole`：SPのドロワー出現時にz-indexがドロワー以下の要素に半透明の黒背景をかぶせるコンポーネントです。<br>

Styleguide 11.2.6
*/
.ec-drawerRole {
  overflow-y: scroll;
  background: black;
  width: 260px;
  height: 100vh;
  transform: translateX(300px);
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1;
  transition: z-index 0ms 1ms; }
  @media only screen and (min-width: 768px) {
    .ec-drawerRole {
      display: none; } }
  .ec-drawerRole .ec-headerSearchArea {
    padding: 20px 10px;
    width: 100%;
    background: #F8F8F8; }
  .ec-drawerRole .ec-headerSearch {
    padding: 16px 8px 26px;
    background: #EBEBEB;
    color: #636378; }
    .ec-drawerRole .ec-headerSearch select {
      width: 100% !important; }
  .ec-drawerRole .ec-headerCategoryArea .ec-headerCategoryArea__heading {
    border-top: 1px solid #CCCCCC;
    border-bottom: 1px solid #CCCCCC;
    padding: 1em 10px;
    font-size: 16px;
    font-weight: bold;
    color: black;
    background: #F8F8F8; }
  .ec-drawerRole .ec-headerCategoryArea p {
    margin-top: 0;
    margin-bottom: 0; }
  .ec-drawerRole .ec-headerCategoryArea .ec-itemNav__nav li a {
    border-bottom: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    color: black;
    font-weight: normal;
    background: #f8f8f8; }
  .ec-drawerRole .ec-headerCategoryArea .ec-itemNav__nav li ul li a {
    border-bottom: 1px solid #ccc;
    padding-left: 20px;
    font-weight: normal;
    background: white; }
  .ec-drawerRole .ec-headerCategoryArea .ec-itemNav__nav > li:hover > a {
    background: #f8f8f8; }
  .ec-drawerRole .ec-headerCategoryArea .ec-itemNav__nav > li:hover li:hover > a {
    background: white; }
  .ec-drawerRole .ec-headerCategoryArea .ec-itemNav__nav li ul li ul li a {
    padding-left: 40px;
    color: black;
    background: white; }
  .ec-drawerRole .ec-headerCategoryArea .ec-itemNav__nav li:hover ul li ul li a:hover {
    background: white; }
  .ec-drawerRole .ec-headerCategoryArea .ec-itemNav__nav li ul li ul li ul li a {
    padding-left: 60px;
    font-weight: normal; }
  .ec-drawerRole .ec-headerLinkArea {
    background: black; }
    .ec-drawerRole .ec-headerLinkArea .ec-headerLink__list {
      border-top: 1px solid #ccc; }
    .ec-drawerRole .ec-headerLinkArea .ec-headerLink__item {
      display: block;
      border-bottom: 1px solid #ccc;
      padding: 15px 20px;
      font-size: 16px;
      font-weight: bold;
      color: white; }
    .ec-drawerRole .ec-headerLinkArea .ec-headerLink__icon {
      display: inline-block;
      width: 28px;
      font-size: 17px; }

.ec-drawerRoleClose {
  display: none;
  cursor: pointer;
  border-radius: 50%;
  box-sizing: border-box;
  padding: 10px;
  width: 40px;
  height: 40px;
  font-size: 18px;
  text-align: center;
  color: black;
  background: white;
  position: fixed;
  top: 10px;
  right: 270px;
  z-index: 1000; }
  .ec-drawerRoleClose .fas {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  @media only screen and (min-width: 768px) {
    .ec-drawerRoleClose {
      display: none; } }

.ec-drawerRole.is_active {
  display: block;
  transform: translateX(0);
  transition: all .3s;
  z-index: 100000; }
  @media only screen and (min-width: 768px) {
    .ec-drawerRole.is_active {
      display: none; } }

.ec-drawerRoleClose.is_active {
  display: inline-block;
  transition: all .3s; }
  @media only screen and (min-width: 768px) {
    .ec-drawerRoleClose.is_active {
      display: none; } }

.ec-overlayRole {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  opacity: 0;
  background: transparent;
  transform: translateX(0);
  transition: all .3s;
  visibility: hidden;
  z-index: 999; }
  @media only screen and (min-width: 768px) {
    .ec-overlayRole {
      display: none; } }

.have_curtain .ec-overlayRole {
  display: block;
  opacity: 1;
  background: rgba(0, 0, 0, 0.5);
  visibility: visible; }
  @media only screen and (min-width: 768px) {
    .have_curtain .ec-overlayRole {
      display: none; } }

/*
ヘッダー：test

test

Markup:
span.ec-itemAccordionParent test1
ul.ec-itemNavAccordion
  li.ec-itemNavAccordion__item
    a(href='') test2
    ul.ec-itemNavAccordion
      li.ec-itemNavAccordion__item
        a(href='') test3
        ul.ec-itemNavAccordion
          li.ec-itemNavAccordion__item
            a(href='') test4

Styleguide 11.2.7
*/
.ec-itemNavAccordion {
  display: none; }

.ec-maintenanceAlert {
  background: steelblue;
  height: 5rem;
  position: fixed;
  top: 0;
  width: 100%;
  color: white;
  z-index: 9999;
  display: flex;
  font-weight: bold; }
  .ec-maintenanceAlert > * {
    margin: auto; }
  .ec-maintenanceAlert .ec-maintenanceAlert__icon {
    display: inline-block;
    margin-right: 1rem;
    width: 20px;
    height: 20px;
    color: #fff;
    fill: #fff;
    vertical-align: top; }
  .ec-maintenanceAlert + * {
    margin-top: 5rem; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
フッター

全ページで使用されるフッターのプロジェクトコンポーネントです。

ex [トップページ　フッター](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerRole

Styleguide 11.3
*/
.ec-footerRole {
  border-top: 1px solid #7d7d7d;
  margin-top: 30px;
  background: black; }
  @media only screen and (min-width: 768px) {
    .ec-footerRole {
      padding-top: 40px;
      margin-top: 100px; } }
  @media only screen and (min-width: 768px) {
    .ec-footerRole .ec-footerRole__inner {
      margin: 0 auto;
      box-sizing: border-box;
      font-size: 16px;
      line-height: 1.4;
      color: #525263;
      -webkit-text-size-adjust: 100%;
      width: 100%;
      max-width: 980px; }
      .ec-footerRole .ec-footerRole__inner:after {
        content: " ";
        display: table; }
      .ec-footerRole .ec-footerRole__inner:after {
        clear: both; }
      .ec-footerRole .ec-footerRole__inner textarea {
        /* for chrome fontsize bug */
        font-family: sans-serif; }
      .ec-footerRole .ec-footerRole__inner img {
        max-width: 100%; }
      .ec-footerRole .ec-footerRole__inner html {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box; }
      .ec-footerRole .ec-footerRole__inner *,
      .ec-footerRole .ec-footerRole__inner *::before,
      .ec-footerRole .ec-footerRole__inner *::after {
        -webkit-box-sizing: inherit;
        -moz-box-sizing: inherit;
        box-sizing: inherit; } }

/*
フッターナビ

フッタープロジェクトで使用するナビゲーション用のコンポーネントです。

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerNav

sg-wrapper:
<div class="ec-footerRole">
  <div class="ec-footerRole__inner">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.3.1
*/
.ec-footerNavi {
  padding: 0;
  color: white;
  list-style: none; }
  .ec-footerNavi .ec-footerNavi__link {
    display: block; }
    @media only screen and (min-width: 768px) {
      .ec-footerNavi .ec-footerNavi__link {
        display: inline-block; } }
    .ec-footerNavi .ec-footerNavi__link a {
      display: block;
      border-bottom: 1px solid #7d7d7d;
      padding: 15px 0;
      color: inherit;
      text-decoration: none; }
      @media only screen and (min-width: 768px) {
        .ec-footerNavi .ec-footerNavi__link a {
          display: inline-block;
          border-bottom: none;
          padding: 0;
          text-decoration: underline; } }
    .ec-footerNavi .ec-footerNavi__link:hover a {
      opacity: .8;
      text-decoration: none; }

/*
フッタータイトル

フッタープロジェクトで使用するタイトル用のコンポーネントです。

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerTitle

sg-wrapper:
<div class="ec-footerRole">
  <div class="ec-footerRole__inner">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.3.2
*/
.ec-footerTitle {
  padding: 40px 0 60px;
  text-align: center;
  color: white; }
  @media only screen and (min-width: 768px) {
    .ec-footerTitle {
      padding: 50px 0 80px; } }
  .ec-footerTitle .ec-footerTitle__logo {
    display: block;
    margin-bottom: 10px;
    font-weight: bold; }
    .ec-footerTitle .ec-footerTitle__logo a {
      color: inherit;
      text-decoration: none; }
    .ec-footerTitle .ec-footerTitle__logo a:hover {
      text-decoration: none; }
    .ec-footerTitle .ec-footerTitle__logo a {
      font-size: 22px;
      color: inherit; }
      @media only screen and (min-width: 768px) {
        .ec-footerTitle .ec-footerTitle__logo a {
          font-size: 24px; } }
    .ec-footerTitle .ec-footerTitle__logo:hover a {
      opacity: .8;
      text-decoration: none; }
  .ec-footerTitle .ec-footerTitle__copyright {
    font-size: 10px; }
    @media only screen and (min-width: 768px) {
      .ec-footerTitle .ec-footerTitle__copyright {
        font-size: 12px; } }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
トップページ

トップページ スライド部に関する Project コンポーネントを定義します。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/12.1.slider.pug
+ec-sliderRole

Styleguide 12.1
*/
.ec-sliderRole {
  margin: 0 auto;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 980px;
  margin-bottom: 24px; }
  .ec-sliderRole:after {
    content: " ";
    display: table; }
  .ec-sliderRole:after {
    clear: both; }
  .ec-sliderRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-sliderRole img {
    max-width: 100%; }
  .ec-sliderRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-sliderRole *,
  .ec-sliderRole *::before,
  .ec-sliderRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-sliderRole ul {
    padding: 0;
    list-style: none; }

.ec-sliderItemRole {
  margin: 0 auto;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 980px;
  margin-bottom: 24px; }
  .ec-sliderItemRole:after {
    content: " ";
    display: table; }
  .ec-sliderItemRole:after {
    clear: both; }
  .ec-sliderItemRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-sliderItemRole img {
    max-width: 100%; }
  .ec-sliderItemRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-sliderItemRole *,
  .ec-sliderItemRole *::before,
  .ec-sliderItemRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-sliderItemRole ul {
    padding: 0;
    list-style: none; }
  .ec-sliderItemRole .item_nav {
    display: none; }
    @media only screen and (min-width: 768px) {
      .ec-sliderItemRole .item_nav {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-bottom: 0; } }
  .ec-sliderItemRole .slideThumb {
    margin-bottom: 25px;
    width: 33%;
    opacity: .8;
    cursor: pointer; }
    .ec-sliderItemRole .slideThumb:focus {
      outline: none; }
    .ec-sliderItemRole .slideThumb:hover {
      opacity: 1; }
    .ec-sliderItemRole .slideThumb img {
      width: 80%; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
アイキャッチ

トップページ アイキャッチ部に関する Project コンポーネントを定義します。

ex [トップページスライダー直下 アイキャッチ部](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/12.2.eyecatch.pug
+ec-eyecatchRole

Styleguide 12.2
*/
.ec-eyecatchRole {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
  /* Deprecated https://github.com/EC-CUBE/ec-cube/pull/4220 */ }
  @media only screen and (min-width: 768px) {
    .ec-eyecatchRole {
      flex-wrap: nowrap; } }
  .ec-eyecatchRole .ec-eyecatchRole__image {
    display: block;
    margin-bottom: 40px;
    width: 100%;
    height: 100%; }
    @media only screen and (min-width: 768px) {
      .ec-eyecatchRole .ec-eyecatchRole__image {
        order: 2; } }
  .ec-eyecatchRole .ec-eyecatchRole__intro {
    color: black; }
    @media only screen and (min-width: 768px) {
      .ec-eyecatchRole .ec-eyecatchRole__intro {
        padding-right: 5%;
        order: 1; } }
  .ec-eyecatchRole .ec-eyecatchRole__introEnTitle {
    margin-bottom: .8em;
    font-size: 16px;
    font-weight: normal; }
    @media only screen and (min-width: 768px) {
      .ec-eyecatchRole .ec-eyecatchRole__introEnTitle {
        margin-top: 45px; } }
  .ec-eyecatchRole .ec-eyecatchRole__introTitle {
    margin-bottom: .8em;
    font-size: 24px;
    font-weight: bold; }
    @media only screen and (min-width: 768px) {
      .ec-eyecatchRole .ec-eyecatchRole__introTitle {
        margin-bottom: 1em;
        font-size: 26px; } }
  .ec-eyecatchRole .ec-eyecatchRole__introDescriptiron {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 2; }
    @media only screen and (min-width: 768px) {
      .ec-eyecatchRole .ec-eyecatchRole__introDescriptiron {
        margin-bottom: 30px; } }
  .ec-eyecatchRole .ec-eyecatchRole__introDescription {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 2; }
    @media only screen and (min-width: 768px) {
      .ec-eyecatchRole .ec-eyecatchRole__introDescription {
        margin-bottom: 30px; } }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
ボタン

トップページで使用されているボタンのスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.3
*/
/*
通常ボタン

インラインの要素としてボタンを定義出来ます。

Markup:
.ec-inlineBtn--top more

Styleguide 12.3.1
*/
.ec-inlineBtn--top {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  border-radius: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 16px;
  text-decoration: none;
  color: white;
  background-color: black;
  border-color: black; }
  .ec-inlineBtn--top:focus, .ec-inlineBtn--top.focus, .ec-inlineBtn--top:active:focus, .ec-inlineBtn--top:active.focus, .ec-inlineBtn--top.active:focus, .ec-inlineBtn--top.active.focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }
  .ec-inlineBtn--top:hover, .ec-inlineBtn--top:focus, .ec-inlineBtn--top.focus {
    color: #525263;
    text-decoration: none; }
  .ec-inlineBtn--top:active, .ec-inlineBtn--top.active {
    outline: 0;
    background-image: none;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .ec-inlineBtn--top.disabled, .ec-inlineBtn--top[disabled],
  fieldset[disabled] .ec-inlineBtn--top {
    cursor: not-allowed;
    filter: alpha(opacity=65);
    opacity: 0.65;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .ec-inlineBtn--top:focus, .ec-inlineBtn--top.focus {
    color: white;
    background-color: black;
    border-color: black; }
  .ec-inlineBtn--top:hover {
    color: white;
    background-color: black;
    border-color: black; }
  .ec-inlineBtn--top:active, .ec-inlineBtn--top.active,
  .open > .ec-inlineBtn--top.dropdown-toggle {
    color: white;
    background-color: black;
    background-image: none;
    border-color: black; }
    .ec-inlineBtn--top:active:hover, .ec-inlineBtn--top:active:focus, .ec-inlineBtn--top:active.focus, .ec-inlineBtn--top.active:hover, .ec-inlineBtn--top.active:focus, .ec-inlineBtn--top.active.focus,
    .open > .ec-inlineBtn--top.dropdown-toggle:hover,
    .open > .ec-inlineBtn--top.dropdown-toggle:focus,
    .open > .ec-inlineBtn--top.dropdown-toggle.focus {
      color: white;
      background-color: black;
      border-color: black; }
  .ec-inlineBtn--top.disabled:hover, .ec-inlineBtn--top.disabled:focus, .ec-inlineBtn--top.disabled.focus, .ec-inlineBtn--top[disabled]:hover, .ec-inlineBtn--top[disabled]:focus, .ec-inlineBtn--top[disabled].focus,
  fieldset[disabled] .ec-inlineBtn--top:hover,
  fieldset[disabled] .ec-inlineBtn--top:focus,
  fieldset[disabled] .ec-inlineBtn--top.focus {
    background-color: black;
    border-color: black; }
  .ec-inlineBtn--top .badge {
    color: black;
    background-color: white; }
  .ec-inlineBtn--top .ec-icon img {
    width: 1em;
    vertical-align: text-bottom; }

/*
ロングボタン（全幅）

ロングタイプのボタンです。

Markup:
.ec-blockBtn--top 商品一覧へ

Styleguide 2.1.2
*/
.ec-blockBtn--top {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  border-radius: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 16px;
  text-decoration: none;
  color: white;
  background-color: black;
  border-color: black;
  display: block;
  height: 56px;
  line-height: 56px;
  padding-top: 0;
  padding-bottom: 0; }
  .ec-blockBtn--top:focus, .ec-blockBtn--top.focus, .ec-blockBtn--top:active:focus, .ec-blockBtn--top:active.focus, .ec-blockBtn--top.active:focus, .ec-blockBtn--top.active.focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }
  .ec-blockBtn--top:hover, .ec-blockBtn--top:focus, .ec-blockBtn--top.focus {
    color: #525263;
    text-decoration: none; }
  .ec-blockBtn--top:active, .ec-blockBtn--top.active {
    outline: 0;
    background-image: none;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .ec-blockBtn--top.disabled, .ec-blockBtn--top[disabled],
  fieldset[disabled] .ec-blockBtn--top {
    cursor: not-allowed;
    filter: alpha(opacity=65);
    opacity: 0.65;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .ec-blockBtn--top:focus, .ec-blockBtn--top.focus {
    color: white;
    background-color: black;
    border-color: black; }
  .ec-blockBtn--top:hover {
    color: white;
    background-color: black;
    border-color: black; }
  .ec-blockBtn--top:active, .ec-blockBtn--top.active,
  .open > .ec-blockBtn--top.dropdown-toggle {
    color: white;
    background-color: black;
    background-image: none;
    border-color: black; }
    .ec-blockBtn--top:active:hover, .ec-blockBtn--top:active:focus, .ec-blockBtn--top:active.focus, .ec-blockBtn--top.active:hover, .ec-blockBtn--top.active:focus, .ec-blockBtn--top.active.focus,
    .open > .ec-blockBtn--top.dropdown-toggle:hover,
    .open > .ec-blockBtn--top.dropdown-toggle:focus,
    .open > .ec-blockBtn--top.dropdown-toggle.focus {
      color: white;
      background-color: black;
      border-color: black; }
  .ec-blockBtn--top.disabled:hover, .ec-blockBtn--top.disabled:focus, .ec-blockBtn--top.disabled.focus, .ec-blockBtn--top[disabled]:hover, .ec-blockBtn--top[disabled]:focus, .ec-blockBtn--top[disabled].focus,
  fieldset[disabled] .ec-blockBtn--top:hover,
  fieldset[disabled] .ec-blockBtn--top:focus,
  fieldset[disabled] .ec-blockBtn--top.focus {
    background-color: black;
    border-color: black; }
  .ec-blockBtn--top .badge {
    color: black;
    background-color: white; }
  .ec-blockBtn--top .ec-icon img {
    width: 1em;
    vertical-align: text-bottom; }
  @media only screen and (min-width: 768px) {
    .ec-blockBtn--top {
      max-width: 260px; } }

/*
見出し

トップページで使用されている見出しのスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.4
*/
/*
横並び見出し

横並びの見出しです。

Markup:
.ec-secHeading
  span.ec-secHeading__en TOPIC
  span.ec-secHeading__line |
  span.ec-secHeading__ja 特集

Styleguide 12.4.1
*/
.ec-secHeading {
  margin-bottom: 15px;
  color: black; }
  .ec-secHeading .ec-secHeading__en {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: .2em; }
  .ec-secHeading .ec-secHeading__line {
    display: inline-block;
    margin: 0 20px;
    width: 1px;
    height: 14px;
    background: black; }
  .ec-secHeading .ec-secHeading__ja {
    font-size: 12px;
    font-weight: normal;
    letter-spacing: .15em;
    vertical-align: 2px; }

/*
縦並び見出し

縦並びの見出しです。

Markup:
.ec-secHeading--tandem
  span.ec-secHeading__en TOPIC
  span.ec-secHeading__line |
  span.ec-secHeading__ja 特集

Styleguide 12.4.2
*/
.ec-secHeading--tandem {
  margin-bottom: 15px;
  color: black;
  text-align: center; }
  .ec-secHeading--tandem .ec-secHeading__en {
    display: block;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: .2em; }
  .ec-secHeading--tandem .ec-secHeading__line {
    display: block;
    margin: 13px auto;
    width: 20px;
    height: 1px;
    background: black; }
  .ec-secHeading--tandem .ec-secHeading__ja {
    display: block;
    margin-bottom: 30px;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: .15em;
    vertical-align: 2px; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
トピック（アイテム2列）

トップページで使用されているトピックのスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.5.1
*/
.ec-topicRole {
  padding: 40px 0;
  background: #F8F8F8; }
  @media only screen and (min-width: 768px) {
    .ec-topicRole {
      padding: 60px 0; } }
  .ec-topicRole .ec-topicRole__list {
    display: flex;
    flex-wrap: wrap; }
    @media only screen and (min-width: 768px) {
      .ec-topicRole .ec-topicRole__list {
        flex-wrap: nowrap; } }
  .ec-topicRole .ec-topicRole__listItem {
    margin-bottom: 20px;
    width: 100%;
    height: auto; }
    @media only screen and (min-width: 768px) {
      .ec-topicRole .ec-topicRole__listItem {
        width: calc(100% / 2); }
        .ec-topicRole .ec-topicRole__listItem:not(:last-of-type) {
          margin-right: 30px; } }
  .ec-topicRole .ec-topicRole__listItemTitle {
    margin-top: .5em;
    font-size: 14px;
    color: black; }
    @media only screen and (min-width: 768px) {
      .ec-topicRole .ec-topicRole__listItemTitle {
        margin-top: 1em; } }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
カテゴリ（アイテム4列 スマホの時は2列）

トップページで使用されているアイテムリストのスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.6.1
*/
.ec-newItemRole {
  padding: 40px 0; }
  @media only screen and (min-width: 768px) {
    .ec-newItemRole {
      padding: 60px 0; } }
  .ec-newItemRole .ec-newItemRole__list {
    display: flex;
    flex-wrap: wrap; }
    @media only screen and (min-width: 768px) {
      .ec-newItemRole .ec-newItemRole__list {
        flex-wrap: nowrap; } }
  .ec-newItemRole .ec-newItemRole__listItem {
    margin-bottom: 4%;
    width: 48%;
    height: auto; }
    .ec-newItemRole .ec-newItemRole__listItem:not(:first-child) a {
      color: black; }
    @media only screen and (min-width: 768px) {
      .ec-newItemRole .ec-newItemRole__listItem {
        margin-bottom: 15px;
        width: calc(100% / 4); }
        .ec-newItemRole .ec-newItemRole__listItem:not(:last-of-type) {
          margin-right: 30px; } }
    .ec-newItemRole .ec-newItemRole__listItem:nth-child(odd) {
      margin-right: 4%; }
      @media only screen and (min-width: 768px) {
        .ec-newItemRole .ec-newItemRole__listItem:nth-child(odd) {
          margin-right: 30px; } }
  .ec-newItemRole .ec-newItemRole__listItemHeading {
    margin-top: calc(45% - 20px); }
  .ec-newItemRole .ec-newItemRole__listItemTitle {
    margin: 8px 0;
    font-size: 14px;
    font-weight: bold; }
    @media only screen and (min-width: 768px) {
      .ec-newItemRole .ec-newItemRole__listItemTitle {
        margin: 20px 0 10px; } }
  .ec-newItemRole .ec-newItemRole__listItemPrice {
    font-size: 12px; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
カテゴリ（アイテム3列）

トップページで使用されているカテゴリのスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.7.1
*/
.ec-categoryRole {
  padding: 40px 0;
  color: black;
  background: #F8F8F8; }
  @media only screen and (min-width: 768px) {
    .ec-categoryRole {
      padding: 60px 0; } }
  .ec-categoryRole .ec-categoryRole__list {
    display: flex;
    flex-wrap: wrap; }
    @media only screen and (min-width: 768px) {
      .ec-categoryRole .ec-categoryRole__list {
        flex-wrap: nowrap; } }
  .ec-categoryRole .ec-categoryRole__listItem {
    margin-bottom: 20px;
    width: 100%;
    height: auto; }
    @media only screen and (min-width: 768px) {
      .ec-categoryRole .ec-categoryRole__listItem {
        width: calc(100% / 3); }
        .ec-categoryRole .ec-categoryRole__listItem:not(:last-of-type) {
          margin-right: 30px; } }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
見出し

トップページで使用されている新着情報のスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.8.1
*/
.ec-newsRole {
  padding: 40px 0 0; }
  @media only screen and (min-width: 768px) {
    .ec-newsRole {
      padding: 60px 0 0; } }
  .ec-newsRole .ec-newsRole__news {
    box-sizing: border-box; }
    @media only screen and (min-width: 768px) {
      .ec-newsRole .ec-newsRole__news {
        border: 16px solid #F8F8F8;
        padding: 20px 30px; } }
  .ec-newsRole .ec-newsRole__newsItem {
    width: 100%; }
    .ec-newsRole .ec-newsRole__newsItem:not(:last-of-type) {
      border-bottom: 1px solid #ccc; }
    .ec-newsRole .ec-newsRole__newsItem:last-of-type {
      margin-bottom: 20px; }
      @media only screen and (min-width: 768px) {
        .ec-newsRole .ec-newsRole__newsItem:last-of-type {
          margin-bottom: 0; } }
    @media only screen and (min-width: 768px) {
      .ec-newsRole .ec-newsRole__newsItem {
        padding: 20px 0; } }
  .ec-newsRole .ec-newsRole__newsHeading {
    cursor: pointer; }
    @media only screen and (min-width: 768px) {
      .ec-newsRole .ec-newsRole__newsHeading {
        display: flex; } }
  .ec-newsRole .ec-newsRole__newsDate {
    display: block;
    margin: 15px 0 5px;
    font-size: 12px;
    color: black; }
    @media only screen and (min-width: 768px) {
      .ec-newsRole .ec-newsRole__newsDate {
        display: inline-block;
        margin: 0;
        min-width: 120px;
        font-size: 14px; } }
  .ec-newsRole .ec-newsRole__newsColumn {
    display: flex; }
    @media only screen and (min-width: 768px) {
      .ec-newsRole .ec-newsRole__newsColumn {
        display: inline-flex;
        min-width: calc(100% - 120px); } }
  .ec-newsRole .ec-newsRole__newsTitle {
    display: inline-block;
    margin-bottom: 10px;
    width: 90%;
    font-size: 14px;
    font-weight: bold;
    color: #7D7D7D;
    line-height: 1.6; }
    @media only screen and (min-width: 768px) {
      .ec-newsRole .ec-newsRole__newsTitle {
        margin-bottom: 0;
        line-height: 1.8; } }
  .ec-newsRole .ec-newsRole__newsClose {
    display: inline-block;
    width: 10%;
    position: relative; }
  .ec-newsRole .ec-newsRole__newsCloseBtn {
    display: inline-block;
    margin-left: auto;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    color: white;
    text-align: center;
    background: black;
    cursor: pointer;
    position: absolute;
    right: 5px; }
  .ec-newsRole .ec-newsRole__newsDescription {
    display: none;
    margin: 0 0 10px;
    font-size: 14px;
    line-height: 1.4;
    overflow: hidden; }
    @media only screen and (min-width: 768px) {
      .ec-newsRole .ec-newsRole__newsDescription {
        margin: 20px 0 0;
        line-height: 1.8; } }
    .ec-newsRole .ec-newsRole__newsDescription a {
      color: #0092C4; }
  .ec-newsRole__newsItem.is_active .ec-newsRole__newsDescription {
    margin: 0 0 10px; }
    @media only screen and (min-width: 768px) {
      .ec-newsRole__newsItem.is_active .ec-newsRole__newsDescription {
        margin: 20px 0 0; } }
  .ec-newsRole__newsItem.is_active .ec-newsRole__newsCloseBtn i {
    display: inline-block;
    transform: rotateX(180deg) translateY(2px); }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
見出し

トップページで使用されているカレンダーのスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.9.1
*/
/* 背景や文字の色調整 */
.ec-calendar {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap; }
  @media screen and (min-width: 768px) {
    .ec-calendar {
      flex-direction: row;
      margin-left: -30px; } }
  .ec-calendar__month {
    border-collapse: collapse;
    margin-top: 30px; }
    @media screen and (min-width: 768px) {
      .ec-calendar__month {
        margin-top: 0;
        margin-left: 30px; } }
    .ec-calendar__month th, .ec-calendar__month td {
      border-top: 1px solid #f3f3f3;
      border-bottom: 1px solid #f3f3f3;
      padding: 8px;
      text-align: center;
      vertical-align: middle; }
  .ec-calendar .ec-calendar__title {
    border: 0; }
  .ec-calendar .ec-calendar__sun {
    background: #F8F8F8;
    color: #DE5D50; }
  .ec-calendar .ec-calendar__mon,
  .ec-calendar .ec-calendar__tue,
  .ec-calendar .ec-calendar__wed,
  .ec-calendar .ec-calendar__thu,
  .ec-calendar .ec-calendar__fri {
    background: #F8F8F8;
    color: #333; }
  .ec-calendar .ec-calendar__sat {
    background: #F8F8F8;
    color: #5CB1B1; }
  .ec-calendar .ec-calendar__day {
    background: #FFF;
    color: #333; }
  .ec-calendar .ec-calendar__holiday {
    background: #FFF;
    color: #DE5D50 !important; }
  .ec-calendar .ec-calendar__today {
    color: #333;
    position: relative;
    z-index: 1; }
    .ec-calendar .ec-calendar__today::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: #FFFDE7;
      transform: translate(-50%, -50%);
      z-index: -1; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
検索ラベル

商品一覧 ヘッダー部 に関する Project コンポーネントを定義します。

ex [商品一覧 ヘッダー部](http://demo3.ec-cube.net/products/list)

Markup:
include /assets/tmpl/elements/13.1.searchnav.pug
+ec-searchnavRole__topicpath
+ec-searchnavRole__info

Styleguide 13.1

*/
.ec-searchnavRole {
  margin-bottom: 0;
  padding: 0 20px; }
  @media only screen and (min-width: 768px) {
    .ec-searchnavRole {
      padding: 0;
      margin: 0 auto;
      box-sizing: border-box;
      font-size: 16px;
      line-height: 1.4;
      color: #525263;
      -webkit-text-size-adjust: 100%;
      width: 100%;
      max-width: 980px; }
      .ec-searchnavRole:after {
        content: " ";
        display: table; }
      .ec-searchnavRole:after {
        clear: both; }
      .ec-searchnavRole textarea {
        /* for chrome fontsize bug */
        font-family: sans-serif; }
      .ec-searchnavRole img {
        max-width: 100%; }
      .ec-searchnavRole html {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box; }
      .ec-searchnavRole *,
      .ec-searchnavRole *::before,
      .ec-searchnavRole *::after {
        -webkit-box-sizing: inherit;
        -moz-box-sizing: inherit;
        box-sizing: inherit; } }
  .ec-searchnavRole .ec-searchnavRole__infos {
    margin: 0 auto;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 1.4;
    color: #525263;
    -webkit-text-size-adjust: 100%;
    width: 100%;
    max-width: 980px;
    display: flex;
    border-top: 0;
    margin-bottom: 16px;
    padding-top: 5px;
    flex-direction: column; }
    .ec-searchnavRole .ec-searchnavRole__infos:after {
      content: " ";
      display: table; }
    .ec-searchnavRole .ec-searchnavRole__infos:after {
      clear: both; }
    .ec-searchnavRole .ec-searchnavRole__infos textarea {
      /* for chrome fontsize bug */
      font-family: sans-serif; }
    .ec-searchnavRole .ec-searchnavRole__infos img {
      max-width: 100%; }
    .ec-searchnavRole .ec-searchnavRole__infos html {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box; }
    .ec-searchnavRole .ec-searchnavRole__infos *,
    .ec-searchnavRole .ec-searchnavRole__infos *::before,
    .ec-searchnavRole .ec-searchnavRole__infos *::after {
      -webkit-box-sizing: inherit;
      -moz-box-sizing: inherit;
      box-sizing: inherit; }
    @media only screen and (min-width: 768px) {
      .ec-searchnavRole .ec-searchnavRole__infos {
        padding-left: 0;
        padding-right: 0;
        border-top: 1px solid #ccc;
        padding-top: 16px;
        flex-direction: row; } }
  .ec-searchnavRole .ec-searchnavRole__counter {
    margin-bottom: 16px;
    width: 100%; }
    @media only screen and (min-width: 768px) {
      .ec-searchnavRole .ec-searchnavRole__counter {
        margin-bottom: 0;
        width: 50%; } }
  .ec-searchnavRole .ec-searchnavRole__actions {
    text-align: right;
    width: 100%; }
    @media only screen and (min-width: 768px) {
      .ec-searchnavRole .ec-searchnavRole__actions {
        width: 50%; } }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
商品一覧

商品一覧 に関する Project コンポーネントを定義します。

SP版２列、PC版４列の特殊グリッドを構成します。

Markup:
include /assets/tmpl/elements/13.2.shelf.pug
+b.ec-shelfRole
  +ec-shelfGrid

Styleguide 13.2

*/
.ec-shelfRole {
  margin: 0 auto;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 980px; }
  .ec-shelfRole:after {
    content: " ";
    display: table; }
  .ec-shelfRole:after {
    clear: both; }
  .ec-shelfRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-shelfRole img {
    max-width: 100%; }
  .ec-shelfRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-shelfRole *,
  .ec-shelfRole *::before,
  .ec-shelfRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  @media only screen and (min-width: 768px) {
    .ec-shelfRole {
      padding: 0; } }

/*
商品一覧グリッド

商品一覧 で使用するグリッドコンポーネントです。

SP版２列、PC版４列の特殊グリッドを構成します。

Markup:
include /assets/tmpl/elements/13.2.shelf.pug
+b.ec-shelfRole
  +ec-shelfGrid

Styleguide 13.2.1

*/
.ec-shelfGrid {
  display: flex;
  margin-left: 0;
  margin-right: 0;
  flex-wrap: wrap;
  padding: 0;
  list-style: none; }
  .ec-shelfGrid a {
    color: inherit;
    text-decoration: none; }
  .ec-shelfGrid a:hover {
    text-decoration: none; }
  @media only screen and (min-width: 768px) {
    .ec-shelfGrid {
      margin-left: -16px;
      margin-right: -16px; } }
  .ec-shelfGrid .ec-shelfGrid__item {
    margin-bottom: 36px;
    width: 50%;
    display: flex;
    flex-direction: column; }
    .ec-shelfGrid .ec-shelfGrid__item-image {
      margin-bottom: 10px;
      text-align: center; }
    .ec-shelfGrid .ec-shelfGrid__item img {
      width: auto;
      max-height: 100%; }
    @media only screen and (min-width: 768px) {
      .ec-shelfGrid .ec-shelfGrid__item {
        padding: 0 16px;
        width: 25%; } }
    .ec-shelfGrid .ec-shelfGrid__item .ec-productRole__btn {
      margin-top: auto;
      margin-bottom: 15px; }
  .ec-shelfGrid .ec-shelfGrid__item:nth-child(odd) {
    padding-right: 8px; }
    @media only screen and (min-width: 768px) {
      .ec-shelfGrid .ec-shelfGrid__item:nth-child(odd) {
        padding: 0 16px; } }
  .ec-shelfGrid .ec-shelfGrid__item:nth-child(even) {
    padding-left: 8px; }
    @media only screen and (min-width: 768px) {
      .ec-shelfGrid .ec-shelfGrid__item:nth-child(even) {
        padding: 0 16px; } }
  .ec-shelfGrid .ec-shelfGrid__title {
    margin-bottom: 7px; }
  .ec-shelfGrid .ec-shelfGrid__plice {
    font-weight: bold; }

/*
13.2.2 商品一覧グリッド（中央寄せ）

商品一覧 で使用するグリッドコンポーネントです。

SP版２列、PC版４列の特殊グリッドを構成します。
商品のあまりはセンタリングされ、中央に表示されます。

Markup:
include /assets/tmpl/elements/13.2.shelf.pug
+b.ec-shelfRole
  +ec-shelfGridCenter

Styleguide 13.2.2

*/
.ec-shelfGridCenter {
  display: flex;
  margin-left: 0;
  margin-right: 0;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
  justify-content: center; }
  .ec-shelfGridCenter a {
    color: inherit;
    text-decoration: none; }
  .ec-shelfGridCenter a:hover {
    text-decoration: none; }
  @media only screen and (min-width: 768px) {
    .ec-shelfGridCenter {
      margin-left: -16px;
      margin-right: -16px; } }
  .ec-shelfGridCenter .ec-shelfGridCenter__item {
    margin-bottom: 36px;
    width: 50%; }
    .ec-shelfGridCenter .ec-shelfGridCenter__item-image {
      margin-bottom: 10px;
      text-align: center; }
    .ec-shelfGridCenter .ec-shelfGridCenter__item img {
      width: auto;
      max-height: 100%; }
    @media only screen and (min-width: 768px) {
      .ec-shelfGridCenter .ec-shelfGridCenter__item {
        padding: 0 16px;
        width: 25%; } }
    .ec-shelfGridCenter .ec-shelfGridCenter__item .ec-productRole__btn {
      margin-top: auto;
      padding-top: 1em; }
  .ec-shelfGridCenter .ec-shelfGridCenter__item:nth-child(odd) {
    padding-right: 8px; }
    @media only screen and (min-width: 768px) {
      .ec-shelfGridCenter .ec-shelfGridCenter__item:nth-child(odd) {
        padding: 0 16px; } }
  .ec-shelfGridCenter .ec-shelfGridCenter__item:nth-child(even) {
    padding-left: 8px; }
    @media only screen and (min-width: 768px) {
      .ec-shelfGridCenter .ec-shelfGridCenter__item:nth-child(even) {
        padding: 0 16px; } }
  .ec-shelfGridCenter .ec-shelfGridCenter__title {
    margin-bottom: 7px; }
  .ec-shelfGridCenter .ec-shelfGridCenter__plice {
    font-weight: bold; }

/*
商品一覧フッター

商品一覧 フッター に関する Project コンポーネントを定義します。

ex [商品一覧 ページャ部](http://demo3.ec-cube.net/products/list)

Markup:
include /assets/tmpl/elements/13.3.pager.pug
+ec-pagerRole

Styleguide 13.3

*/
/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
カート追加モーダル

カート追加モーダルに関する Project コンポーネントを定義します。

ex [商品一覧、商品詳細](http://demo3.ec-cube.net/products/list)

+ec-modal

Styleguide 13.4

*/
.ec-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 100%; }
  .ec-modal.small {
    width: 30%; }
  .ec-modal.full {
    width: 100%;
    height: 100%; }
  .ec-modal .ec-modal-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%; }
  .ec-modal .ec-modal-wrap {
    position: relative;
    border-radius: 2px;
    border: 1px solid #333;
    background-color: #fff;
    width: 90%;
    margin: 20px;
    padding: 40px 5px; }
    @media only screen and (min-width: 768px) {
      .ec-modal .ec-modal-wrap {
        padding: 40px 10px;
        width: 50%;
        margin: 20px auto; } }
  .ec-modal .ec-modal-close {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 10px;
    font-size: 20px;
    height: 30px;
    width: 20px; }
    .ec-modal .ec-modal-close:hover {
      color: #4b5361; }
  .ec-modal .ec-modal-box {
    text-align: center; }
  .ec-modal .ec-role {
    margin-top: 20px; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
商品詳細

商品詳細ページに関する Project コンポーネントを定義します。

ex [商品詳細ページ](http://demo3.ec-cube.net/products/detail/18)


Markup:
include /assets/tmpl/elements/14.1.product.pug
+ec-productSimpleRole

Styleguide 14.1
*/
.ec-productRole {
  margin: 0 auto;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 980px; }
  .ec-productRole:after {
    content: " ";
    display: table; }
  .ec-productRole:after {
    clear: both; }
  .ec-productRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-productRole img {
    max-width: 100%; }
  .ec-productRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-productRole *,
  .ec-productRole *::before,
  .ec-productRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-productRole .ec-productRole__img {
    margin-right: 0;
    margin-bottom: 20px; }
    @media only screen and (min-width: 768px) {
      .ec-productRole .ec-productRole__img {
        margin-right: 16px;
        margin-bottom: 0; } }
  .ec-productRole .ec-productRole__profile {
    margin-left: 0; }
    @media only screen and (min-width: 768px) {
      .ec-productRole .ec-productRole__profile {
        margin-left: 16px; } }
  .ec-productRole .ec-productRole__title .ec-headingTitle {
    font-size: 20px; }
    @media only screen and (min-width: 768px) {
      .ec-productRole .ec-productRole__title .ec-headingTitle {
        font-size: 32px; } }
  .ec-productRole .ec-productRole__tags {
    margin-top: 16px;
    padding: 0;
    padding-bottom: 16px;
    border-bottom: 1px dotted #ccc; }
  .ec-productRole .ec-productRole__tag {
    display: inline-block;
    padding: 2px 5px;
    list-style: none;
    font-size: 80%;
    color: #525263;
    border: solid 1px #D7DADD;
    border-radius: 3px;
    background-color: #F5F7F8; }
  .ec-productRole .ec-productRole__priceRegular {
    padding-top: 14px; }
  .ec-productRole .ec-productRole__priceRegularTax {
    margin-left: 5px;
    font-size: 10px; }
  .ec-productRole .ec-productRole__price {
    color: #DE5D50;
    font-size: 28px;
    padding: 0;
    border-bottom: 0; }
    @media only screen and (min-width: 768px) {
      .ec-productRole .ec-productRole__price {
        padding: 14px 0;
        border-bottom: 1px dotted #ccc; } }
  .ec-productRole .ec-productRole__code {
    padding: 14px 0;
    border-bottom: 1px dotted #ccc; }
  .ec-productRole .ec-productRole__category {
    padding: 14px 0;
    border-bottom: 1px dotted #ccc; }
    .ec-productRole .ec-productRole__category a {
      color: #33A8D0; }
    .ec-productRole .ec-productRole__category ul {
      list-style: none;
      padding: 0;
      margin: 0; }
  .ec-productRole .ec-productRole__actions {
    padding: 14px 0; }
    .ec-productRole .ec-productRole__actions .ec-select select {
      height: 40px;
      max-width: 100%; }
      @media only screen and (min-width: 768px) {
        .ec-productRole .ec-productRole__actions .ec-select select {
          min-width: auto;
          max-width: auto; } }
    .ec-productRole .ec-productRole__actions .detail_quantity {
      display: flex;
      align-items: center; }
      .ec-productRole .ec-productRole__actions .detail_quantity__text {
        margin-right: 0.5rem;
        word-break: keep-all;
        font-weight: bold;
        font-size: 1.4rem; }
  .ec-productRole .ec-productRole__btn {
    width: 100%;
    margin-bottom: 10px; }
    @media only screen and (min-width: 768px) {
      .ec-productRole .ec-productRole__btn {
        margin-bottom: 16px;
        min-width: 350px; } }
  .ec-productRole .ec-productRole__description {
    margin-bottom: 16px; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
カート

カート 注文詳細 に関する Project コンポーネントを定義します。

ex [カートページ](http://demo3.ec-cube.net/shopping)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
+ec-cartRole

Styleguide 15.1

*/
.ec-cartRole {
  margin: 0 auto;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 980px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end; }
  .ec-cartRole:after {
    content: " ";
    display: table; }
  .ec-cartRole:after {
    clear: both; }
  .ec-cartRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-cartRole img {
    max-width: 100%; }
  .ec-cartRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-cartRole *,
  .ec-cartRole *::before,
  .ec-cartRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-cartRole::before {
    display: none; }
  .ec-cartRole .ec-cartRole__progress {
    width: 100%;
    text-align: center; }
  .ec-cartRole .ec-cartRole__error {
    width: 100%;
    text-align: center; }
    .ec-cartRole .ec-cartRole__error .ec-alert-warning {
      max-width: 80%;
      display: inline-block; }
  .ec-cartRole .ec-cartRole__totalText {
    margin-bottom: 0;
    padding: 16px 0 6px;
    width: 100%;
    text-align: center;
    font-weight: normal; }
    @media only screen and (min-width: 768px) {
      .ec-cartRole .ec-cartRole__totalText {
        margin-bottom: 30px;
        padding: 0; } }
  .ec-cartRole .ec-cartRole__cart {
    margin: 0;
    width: 100%; }
  .ec-cartRole .ec-cartRole__actions {
    text-align: right;
    width: 100%; }
    @media only screen and (min-width: 768px) {
      .ec-cartRole .ec-cartRole__actions {
        width: 30%;
        margin-right: 10%; } }
  .ec-cartRole .ec-cartRole__actions_bottom {
    text-align: center;
    width: 100%;
    margin: auto; }
    @media only screen and (min-width: 768px) {
      .ec-cartRole .ec-cartRole__actions_bottom {
        width: 30%; } }
  .ec-cartRole .ec-cartRole__actions_text {
    font-size: 14px; }
    @media only screen and (min-width: 768px) {
      .ec-cartRole .ec-cartRole__actions_text__right {
        text-align: right; } }
    @media only screen and (min-width: 768px) {
      .ec-cartRole .ec-cartRole__actions_text__center {
        text-align: center; } }
    .ec-cartRole .ec-cartRole__actions_text p {
      margin-bottom: 20px; }
  .ec-cartRole .ec-cartRole__total {
    padding: 15px 0 30px;
    font-weight: bold;
    font-size: 16px; }
  .ec-cartRole .ec-cartRole__totalAmount {
    margin-left: 30px;
    color: #de5d50;
    font-size: 16px; }
    @media only screen and (min-width: 768px) {
      .ec-cartRole .ec-cartRole__totalAmount {
        font-size: 24px; } }
  .ec-cartRole .ec-blockBtn--action {
    margin-bottom: 10px; }

/*
カート商品表示枠（テーブルヘッダ）

カート内の商品をを表示するテーブル枠です。

ex [カートページ　テーブル部分(カート内に商品がある状態でアクセス)](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
+ec-cartTable

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>

Styleguide 15.1.2
*/
.ec-cartTable {
  display: table;
  border-top: 1px dotted #ccc;
  width: 100%; }
  @media only screen and (min-width: 768px) {
    .ec-cartTable {
      border-top: none; } }

/*
カート商品表示枠（テーブルヘッダ）

カート内の商品を表示するテーブルのヘッダです。
スマホでは非表示となります。

ex [カートページ　カートテーブルヘッダ部分(カート内に商品がある状態でアクセス)](http://demo3.ec-cube.net/cart)


Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartTable
  +ec-cartHeader

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>


Styleguide 15.1.3
*/
.ec-cartHeader {
  display: none;
  width: 100%;
  background: #F4F3F0; }
  @media only screen and (min-width: 768px) {
    .ec-cartHeader {
      display: table-row; } }
  .ec-cartHeader .ec-cartHeader__label {
    display: table-cell;
    padding: 16px;
    text-align: center;
    background: #F4F3F0;
    overflow-x: hidden;
    font-weight: bold;
    word-break: keep-all; }

.ec-cartCompleteRole {
  margin: 0 auto;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 980px; }
  .ec-cartCompleteRole:after {
    content: " ";
    display: table; }
  .ec-cartCompleteRole:after {
    clear: both; }
  .ec-cartCompleteRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-cartCompleteRole img {
    max-width: 100%; }
  .ec-cartCompleteRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-cartCompleteRole *,
  .ec-cartCompleteRole *::before,
  .ec-cartCompleteRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }

/*
カート内商品

カート内のアイテムを表示するテーブル行です。
スマホでは非表示となります。

ex [カートページ　テーブル部分](http://demo3.ec-cube.net/cart)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartTable
  +ec-cartRow

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>


Styleguide 15.1.4
*/
.ec-cartRow {
  display: table-row; }
  .ec-cartRow .ec-cartRow__delColumn {
    border-bottom: 1px dotted #ccc;
    text-align: center;
    display: table-cell;
    width: 14%;
    vertical-align: middle; }
    @media only screen and (min-width: 768px) {
      .ec-cartRow .ec-cartRow__delColumn {
        width: 8.3333333%; } }
    .ec-cartRow .ec-cartRow__delColumn .ec-icon img {
      width: 1.5em;
      height: 1.5em; }
      @media only screen and (min-width: 768px) {
        .ec-cartRow .ec-cartRow__delColumn .ec-icon img {
          width: 1em;
          height: 1em; } }
  .ec-cartRow .ec-cartRow__contentColumn {
    border-bottom: 1px dotted #ccc;
    padding: 10px 0;
    display: table; }
    @media only screen and (min-width: 768px) {
      .ec-cartRow .ec-cartRow__contentColumn {
        display: table-cell; } }
  .ec-cartRow .ec-cartRow__img {
    display: table-cell;
    width: 40%;
    vertical-align: middle;
    padding-right: 10px; }
    @media only screen and (min-width: 768px) {
      .ec-cartRow .ec-cartRow__img {
        display: inline-block;
        min-width: 80px;
        max-width: 100px;
        padding-right: 0; } }
  .ec-cartRow .ec-cartRow__summary {
    display: table-cell;
    margin-left: 5px;
    font-weight: bold;
    vertical-align: middle;
    width: 46%; }
    @media only screen and (min-width: 768px) {
      .ec-cartRow .ec-cartRow__summary {
        display: inline-block;
        width: calc(100% - 130px);
        margin-left: 20px;
        vertical-align: middle; } }
    .ec-cartRow .ec-cartRow__summary .ec-cartRow__name {
      margin-bottom: 5px; }
    .ec-cartRow .ec-cartRow__summary .ec-cartRow__sutbtotalSP {
      display: block;
      font-weight: normal; }
      @media only screen and (min-width: 768px) {
        .ec-cartRow .ec-cartRow__summary .ec-cartRow__sutbtotalSP {
          display: none; } }
  .ec-cartRow .ec-cartRow__amountColumn {
    display: table-cell;
    border-bottom: 1px dotted #ccc;
    vertical-align: middle;
    text-align: center;
    width: 20%; }
    @media only screen and (min-width: 768px) {
      .ec-cartRow .ec-cartRow__amountColumn {
        width: 16.66666667%; } }
    .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amount {
      margin-bottom: 10px; }
      @media only screen and (min-width: 768px) {
        .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amount {
          display: block; } }
    .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountSP {
      display: block;
      margin-bottom: 10px; }
      @media only screen and (min-width: 768px) {
        .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountSP {
          display: none; } }
    .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountUpDown {
      display: flex;
      justify-content: center; }
      @media only screen and (min-width: 768px) {
        .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountUpDown {
          display: block; } }
    .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountUpButton {
      margin: 0 2px;
      display: inline-block;
      border: 2px solid #c9c9c9;
      border-radius: 50%;
      width: 30px;
      min-width: 30px;
      max-width: 30px;
      height: 30px;
      cursor: pointer;
      line-height: 40px;
      vertical-align: middle;
      position: relative;
      text-align: center;
      background: #fff; }
      .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountUpButton .ec-cartRow__amountUpButton__icon img {
        display: block;
        margin-left: -0.4em;
        width: .8em;
        height: .8em;
        position: absolute;
        top: 28%;
        left: 50%; }
    .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButton, .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButtonDisabled {
      margin: 0 2px;
      display: inline-block;
      border: 2px solid #c9c9c9;
      border-radius: 50%;
      width: 30px;
      min-width: 30px;
      max-width: 30px;
      height: 30px;
      cursor: pointer;
      line-height: 40px;
      vertical-align: middle;
      position: relative;
      text-align: center;
      background: #fff; }
      .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButton .ec-cartRow__amountDownButton__icon img, .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButtonDisabled .ec-cartRow__amountDownButton__icon img {
        display: block;
        margin-left: -0.4em;
        width: .8em;
        height: .8em;
        position: absolute;
        top: 28%;
        left: 50%; }
    .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButtonDisabled {
      cursor: default; }
  .ec-cartRow .ec-cartRow__subtotalColumn {
    display: none;
    border-bottom: 1px dotted #ccc;
    text-align: right;
    width: 16.66666667%; }
    @media only screen and (min-width: 768px) {
      .ec-cartRow .ec-cartRow__subtotalColumn {
        display: table-cell; } }

/*
カート内商品(商品が１の場合)

商品が１の場合はカート商品を減らす「-」ボタンの無効化状態になります。

ex [カートページ　テーブル部分](http://demo3.ec-cube.net/cart)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartTable
  +ec-cartRowOnly

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>


Styleguide 15.1.5
*/
.ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButtonDisabled {
  cursor: default; }

/*
アラート

カート内の商品に問題があることを示す警告メッセージです。

ex [マイページ　カート](http://demo3.ec-cube.net/cart)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartRole
  .ec-cartRole__cart
    +ec-alert-warning

Styleguide 15.1.6
*/
.ec-alert-warning {
  width: 100%;
  padding: 10px;
  text-align: center;
  background: #F99;
  margin-bottom: 20px; }
  .ec-alert-warning .ec-alert-warning__icon {
    display: inline-block;
    margin-right: 1rem;
    width: 20px;
    height: 20px;
    color: #fff;
    fill: #fff;
    vertical-align: top; }
  .ec-alert-warning .ec-alert-warning__text {
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    position: relative; }

/*
アラート(空)

カートが空であることを示す警告メッセージです。

ex [マイページ　カート](http://demo3.ec-cube.net/cart)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-off3Grid
        .ec-off3Grid__cell
            +ec-alert-warningEnpty

Styleguide 15.1.7
*/
/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
注文内容確認

カート内 注文内容確認に関する Project コンポーネントを定義します。

ex [マイページ　注文詳細](http://demo3.ec-cube.net/shopping)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderRole

Styleguide 15.2
*/
.ec-orderRole {
  margin: 0 auto;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 980px;
  display: flex;
  flex-direction: column;
  margin-top: 0; }
  .ec-orderRole:after {
    content: " ";
    display: table; }
  .ec-orderRole:after {
    clear: both; }
  .ec-orderRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-orderRole img {
    max-width: 100%; }
  .ec-orderRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-orderRole *,
  .ec-orderRole *::before,
  .ec-orderRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  @media only screen and (min-width: 768px) {
    .ec-orderRole {
      margin-top: 20px;
      flex-direction: row; } }
  .ec-orderRole .ec-inlineBtn {
    font-weight: normal; }
  .ec-orderRole .ec-orderRole__detail {
    padding: 0;
    width: 100%; }
    @media only screen and (min-width: 768px) {
      .ec-orderRole .ec-orderRole__detail {
        padding: 0 16px;
        width: 66.66666%; } }
  .ec-orderRole .ec-orderRole__summary {
    width: 100%; }
    .ec-orderRole .ec-orderRole__summary .ec-inlineBtn {
      display: inline-block; }
    @media only screen and (min-width: 768px) {
      .ec-orderRole .ec-orderRole__summary {
        width: 33.33333%;
        padding: 0 16px; }
        .ec-orderRole .ec-orderRole__summary .ec-inlineBtn {
          display: none; } }
  .ec-orderRole .ec-borderedList {
    margin-bottom: 20px;
    border-top: 1px dotted #ccc; }
    @media only screen and (min-width: 768px) {
      .ec-orderRole .ec-borderedList {
        border-top: none; } }

/*
注文履歴詳細 オーダ情報

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　オーダ情報](http://demo3.ec-cube.net/mypage)
(要ログイン → 詳細を見るボタン押下)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderInfo

Styleguide 15.2.1
*/
.ec-orderOrder {
  margin-bottom: 30px; }
  .ec-orderOrder .ec-orderOrder__items {
    border-bottom: 1px dotted #ccc;
    border-top: 1px dotted #ccc; }

/*
注文履歴詳細 お客様情報

マイページ 注文詳細部に関する Project コンポーネントを定義します。

ex [マイページ　オーダ情報(要ログイン → 詳細を見るボタン押下)](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderAccount

Styleguide 15.2.2
*/
.ec-orderAccount {
  margin-bottom: 30px; }
  .ec-orderAccount p {
    margin-bottom: 0; }
  .ec-orderAccount:after {
    content: " ";
    display: table; }
  .ec-orderAccount:after {
    clear: both; }
  .ec-orderAccount .ec-orderAccount__change {
    display: inline-block;
    margin-left: 10px;
    float: right; }
  .ec-orderAccount .ec-orderAccount__account {
    margin-bottom: 16px; }

/*
注文詳細 配送情報

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　配送情報(要ログイン → 詳細を見るボタン押下)](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderDelivery

Styleguide 15.2.3
*/
.ec-orderDelivery .ec-orderDelivery__title {
  padding: 16px 0 17px;
  font-weight: bold;
  font-size: 18px;
  position: relative; }

.ec-orderDelivery .ec-orderDelivery__change {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0; }

.ec-orderDelivery .ec-orderDelivery__items {
  border-bottom: 1px dotted #ccc;
  border-top: 1px dotted #ccc; }

.ec-orderDelivery .ec-orderDelivery__address {
  margin: 10px 0 18px; }
  .ec-orderDelivery .ec-orderDelivery__address p {
    margin: 0; }

/*
注文履歴詳細 支払情報

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　支払情報(要ログイン → 詳細を見るボタン押下)](http://demo3.ec-cube.net/mypage)

Markup:
.ec-orderRole
  .ec-orderPayment
    .ec-rectHeading
      h2 お支払方法
    p 支払方法： 郵便振替

Styleguide 15.2.4
*/
/*
注文履歴詳細 お問い合わせ

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　お問い合わせ(要ログイン → 詳細を見るボタン押下)](http://demo3.ec-cube.net/mypage)

Markup:
.ec-orderRole
  .ec-orderConfirm
    .ec-rectHeading
      h2 お問い合わせ
    p 記載なし

Styleguide 15.2.5
*/
.ec-orderConfirm {
  margin-bottom: 20px; }
  @media only screen and (min-width: 768px) {
    .ec-orderConfirm {
      margin-bottom: 0; } }
  .ec-orderConfirm .ec-input textarea, .ec-orderConfirm .ec-halfInput textarea, .ec-orderConfirm .ec-numberInput textarea, .ec-orderConfirm .ec-zipInput textarea, .ec-orderConfirm .ec-telInput textarea, .ec-orderConfirm .ec-select textarea, .ec-orderConfirm .ec-birth textarea {
    height: 96px; }

/*
お届け先の複数指定

お届け先の複数指定に関するコンポーネントを定義します。

ex [マイページ　お届け先の複数指定](http://demo3.ec-cube.net/shopping/shipping_multiple)
(商品購入画面 → 「お届け先を追加する」を押下)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderAddAddress

Styleguide 15.2.6
*/
.ec-AddAddress {
  padding: 0 10px; }
  @media only screen and (min-width: 768px) {
    .ec-AddAddress {
      margin: 0 10%; } }
  .ec-AddAddress .ec-AddAddress__info {
    margin-bottom: 32px;
    text-align: center;
    font-size: 16px; }
  .ec-AddAddress .ec-AddAddress__add {
    border-top: 1px solid #f4f4f4;
    padding-top: 20px;
    margin-bottom: 20px; }
  .ec-AddAddress .ec-AddAddress__item {
    display: table;
    padding: 16px;
    background: #f4f4f4;
    margin-bottom: 16px; }
  .ec-AddAddress .ec-AddAddress__itemThumb {
    display: table-cell;
    min-width: 160px;
    width: 20%; }
    .ec-AddAddress .ec-AddAddress__itemThumb img {
      width: 100%; }
  .ec-AddAddress .ec-AddAddress__itemtContent {
    display: table-cell;
    vertical-align: middle;
    padding-left: 16px;
    font-size: 16px; }
  .ec-AddAddress .ec-AddAddress__itemtTitle {
    font-weight: bold;
    margin-bottom: 10px; }
  .ec-AddAddress .ec-AddAddress__itemtSize {
    margin-bottom: 10px; }
  .ec-AddAddress .ec-AddAddress__select {
    margin-bottom: 5px; }
  .ec-AddAddress .ec-AddAddress__selectAddress {
    display: inline-block; }
    .ec-AddAddress .ec-AddAddress__selectAddress label {
      font-size: 16px;
      font-weight: normal; }
    .ec-AddAddress .ec-AddAddress__selectAddress select {
      min-width: 100%; }
      @media only screen and (min-width: 768px) {
        .ec-AddAddress .ec-AddAddress__selectAddress select {
          min-width: 350px; } }
  .ec-AddAddress .ec-AddAddress__selectNumber {
    display: inline-block;
    margin-left: 30px; }
    .ec-AddAddress .ec-AddAddress__selectNumber label {
      font-size: 16px;
      font-weight: normal; }
    .ec-AddAddress .ec-AddAddress__selectNumber input {
      display: inline-block;
      margin-left: 10px;
      width: 80px; }
  .ec-AddAddress .ec-AddAddress__actions .ec-blockBtn--action {
    margin-bottom: 8px; }
  .ec-AddAddress .ec-AddAddress__new {
    margin-bottom: 20px; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
注文履歴一覧

マイページ 注文履歴部に関する Project コンポーネントを定義します。

ex [マイページ　注文履歴一覧](http://demo3.ec-cube.net/mypage)
(要ログイン)

Markup:
include /assets/tmpl/elements/16.1.history.pug
+ec-historyRole

Styleguide 16.1
*/
.ec-historyRole .ec-historyRole__contents {
  padding-top: 1em;
  padding-bottom: 16px;
  border-top: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  color: #525263; }
  @media only screen and (min-width: 768px) {
    .ec-historyRole .ec-historyRole__contents {
      flex-direction: row; } }

.ec-historyRole .ec-historyRole__header {
  width: 100%; }
  @media only screen and (min-width: 768px) {
    .ec-historyRole .ec-historyRole__header {
      width: 33.3333%; } }

.ec-historyRole .ec-historyRole__detail {
  border-top: 1px dotted #ccc;
  width: 100%; }
  .ec-historyRole .ec-historyRole__detail .ec-imageGrid:nth-of-type(1) {
    border-top: none; }
  .ec-historyRole .ec-historyRole__detail .ec-historyRole__detailTitle {
    margin-bottom: 8px;
    font-size: 1.6rem;
    font-weight: bold; }
  .ec-historyRole .ec-historyRole__detail .ec-historyRole__detailPrice {
    margin-bottom: 8px;
    font-size: 1.6rem;
    font-weight: bold; }
  @media only screen and (min-width: 768px) {
    .ec-historyRole .ec-historyRole__detail {
      width: 66.6666%;
      border-top: none; } }

/*
注文履歴一覧 規格

マイページ 注文履歴内アイテムの規格を定義します。

ex [マイページ　注文履歴一覧](http://demo3.ec-cube.net/mypage)
(要ログイン)

Markup:
include /assets/tmpl/elements/16.1.history.pug
+ec-historyRole-option

Styleguide 16.1.1
*/
.ec-historyRole .ec-historyRole__detail .ec-historyRole__detailOption {
  display: inline-block;
  margin-bottom: 8px;
  margin-right: .5rem;
  font-size: 1.6rem; }

.ec-historyRole .ec-historyRole__detail .ec-historyRole__detailOption::after {
  display: inline-block;
  padding-left: .5rem;
  content: "/";
  font-weight: bold; }

/*
注文履歴一覧ヘッダ

注文履歴一覧で使用するヘッダのコンポーネントを定義します。

ex [マイページ　注文履歴一覧ヘッダ](http://demo3.ec-cube.net/mypage)
(要ログイン)

Markup:
include /assets/tmpl/elements/16.1.history.pug
+ec-historyHeader
p hofe

Styleguide 16.1.2
*/
.ec-historyListHeader .ec-historyListHeader__date {
  font-weight: bold;
  font-size: 16px; }
  @media only screen and (min-width: 768px) {
    .ec-historyListHeader .ec-historyListHeader__date {
      font-weight: bold;
      font-size: 20px; } }

.ec-historyListHeader .ec-historyListHeader__action {
  margin: 16px 0; }
  .ec-historyListHeader .ec-historyListHeader__action a {
    font-size: 12px;
    font-weight: normal;
    text-decoration: none !important; }
    @media only screen and (min-width: 768px) {
      .ec-historyListHeader .ec-historyListHeader__action a {
        font-size: 14px; } }

/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
注文履歴詳細

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　注文詳細](http://demo3.ec-cube.net/mypage)
(要ログイン → 詳細を見るボタン押下)

Markup:
include /assets/tmpl/elements/16.2.historyDetail.pug
+ec-historyDetailRole

Styleguide 16.2
*/
/*
注文履歴詳細 メール履歴

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　メール履歴](http://demo3.ec-cube.net/mypage)
(要ログイン → 詳細を見るボタン押下)

Markup:
include /assets/tmpl/elements/16.2.historyDetail.pug
+ec-historyDetailMail

Styleguide 16.2.5
*/
.ec-orderMails .ec-orderMails__item {
  padding-bottom: 10px;
  border-bottom: 1px dotted #ccc; }

.ec-orderMails .ec-orderMails__time {
  margin: 0; }

.ec-orderMails .ec-orderMails__body {
  display: none; }

/*
注文履歴詳細 メール履歴個別

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　メール履歴個別](http://demo3.ec-cube.net/mypage)
(要ログイン → 詳細を見るボタン押下)

Markup:
include /assets/tmpl/elements/16.2.historyDetail.pug
+ec-historyDetailMailHistory

Styleguide 16.2.6
*/
.ec-orderMail {
  padding-bottom: 10px;
  border-bottom: 1px dotted #ccc;
  margin-bottom: 16px; }
  .ec-orderMail .ec-orderMail__time {
    margin: 0; }
  .ec-orderMail .ec-orderMail__body {
    display: none; }
  .ec-orderMail .ec-orderMail__time {
    margin-bottom: 4px; }
  .ec-orderMail .ec-orderMail__link {
    margin-bottom: 4px; }
    .ec-orderMail .ec-orderMail__link a {
      color: #0092C4;
      text-decoration: none;
      cursor: pointer; }
    .ec-orderMail .ec-orderMail__link a:hover {
      color: #33A8D0; }
  .ec-orderMail .ec-orderMail__close a {
    color: #0092C4;
    text-decoration: none;
    cursor: pointer; }
  .ec-orderMail .ec-orderMail__close a:hover {
    color: #33A8D0; }

/*
住所一覧

カート 注文詳細 に関する Project コンポーネントを定義します。

ex [マイページ内 お届け先編集](http://demo3.ec-cube.net/mypage/delivery)

Markup:
include /assets/tmpl/elements/17.1.address.pug
+ec-addressList
+ec-addressRole

sg-wrapper:
<div class="ec-addressRole">
  <sg-wrapper-content/>
</div>

Styleguide 17.1

*/
.ec-addressRole .ec-addressRole__item {
  border-top: 1px dotted #ccc; }

.ec-addressRole .ec-addressRole__actions {
  margin-top: 32px;
  padding-bottom: 20px;
  border-bottom: 1px dotted #ccc; }

.ec-addressList .ec-addressList__item {
  display: table;
  width: 100%;
  position: relative;
  border-bottom: 1px dotted #ccc; }

.ec-addressList .ec-addressList__remove {
  vertical-align: middle;
  padding: 16px;
  text-align: center; }
  .ec-addressList .ec-addressList__remove .ec-icon img {
    width: 1em;
    height: 1em; }

.ec-addressList .ec-addressList__address {
  display: table-cell;
  vertical-align: middle;
  padding: 16px;
  margin-right: 4em;
  width: 80%; }

.ec-addressList .ec-addressList__action {
  position: relative;
  vertical-align: middle;
  text-align: right;
  top: 27px;
  padding-right: 10px; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
パスワードリセット

カート 注文詳細 に関する Project コンポーネントを定義します。

ex [パスワードリセット画面](http://demo3.ec-cube.net/forgot)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/18.1.password.pug
+ec-passwordRole

Styleguide 18.1

*/
.ec-forgotRole {
  margin: 0 auto;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 980px; }
  .ec-forgotRole:after {
    content: " ";
    display: table; }
  .ec-forgotRole:after {
    clear: both; }
  .ec-forgotRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-forgotRole img {
    max-width: 100%; }
  .ec-forgotRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-forgotRole *,
  .ec-forgotRole *::before,
  .ec-forgotRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-forgotRole .ec-forgotRole__intro {
    font-size: 16px; }
  .ec-forgotRole .ec-forgotRole__form {
    margin-bottom: 16px; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
会員登録

新規会員登録 に関する Project コンポーネントを定義します。

ex [新規会員登録画面　会員登録](http://demo3.ec-cube.net/entry)

Markup:
include /assets/tmpl/elements/19.1.register.pug
+ec-registerRole

Styleguide 19.1

*/
.ec-registerRole {
  margin: 0 auto;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 980px; }
  .ec-registerRole:after {
    content: " ";
    display: table; }
  .ec-registerRole:after {
    clear: both; }
  .ec-registerRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-registerRole img {
    max-width: 100%; }
  .ec-registerRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-registerRole *,
  .ec-registerRole *::before,
  .ec-registerRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-registerRole .ec-registerRole__actions {
    padding-top: 20px;
    text-align: center; }
    @media only screen and (min-width: 768px) {
      .ec-registerRole .ec-registerRole__actions {
        text-align: left; } }
    .ec-registerRole .ec-registerRole__actions p {
      margin-bottom: 16px; }
  .ec-registerRole .ec-blockBtn--action {
    margin-bottom: 16px; }

.ec-registerCompleteRole {
  margin: 0 auto;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 980px; }
  .ec-registerCompleteRole:after {
    content: " ";
    display: table; }
  .ec-registerCompleteRole:after {
    clear: both; }
  .ec-registerCompleteRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-registerCompleteRole img {
    max-width: 100%; }
  .ec-registerCompleteRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-registerCompleteRole *,
  .ec-registerCompleteRole *::before,
  .ec-registerCompleteRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
お問い合わせ

お問い合わせ に関する Project コンポーネントを定義します。

ex [お問い合わせ](http://demo3.ec-cube.net/contact)

Markup:
include /assets/tmpl/elements/19.2.contact.pug
+ec-contactRole

Styleguide 19.2

*/
.ec-contactRole {
  margin: 0 auto;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 980px; }
  .ec-contactRole:after {
    content: " ";
    display: table; }
  .ec-contactRole:after {
    clear: both; }
  .ec-contactRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-contactRole img {
    max-width: 100%; }
  .ec-contactRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-contactRole *,
  .ec-contactRole *::before,
  .ec-contactRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-contactRole .ec-contactRole__actions {
    padding-top: 20px; }
  .ec-contactRole p {
    margin: 16px 0; }

.ec-contactConfirmRole {
  margin: 0 auto;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 980px; }
  .ec-contactConfirmRole:after {
    content: " ";
    display: table; }
  .ec-contactConfirmRole:after {
    clear: both; }
  .ec-contactConfirmRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-contactConfirmRole img {
    max-width: 100%; }
  .ec-contactConfirmRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-contactConfirmRole *,
  .ec-contactConfirmRole *::before,
  .ec-contactConfirmRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-contactConfirmRole .ec-contactConfirmRole__actions {
    padding-top: 20px; }
  .ec-contactConfirmRole .ec-blockBtn--action {
    margin-bottom: 16px; }

.ec-contactCompleteRole {
  margin: 0 auto;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 980px; }
  .ec-contactCompleteRole:after {
    content: " ";
    display: table; }
  .ec-contactCompleteRole:after {
    clear: both; }
  .ec-contactCompleteRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-contactCompleteRole img {
    max-width: 100%; }
  .ec-contactCompleteRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-contactCompleteRole *,
  .ec-contactCompleteRole *::before,
  .ec-contactCompleteRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }

.contact_top .contact_top__subtitel {
  font-size: 1.5em;
  font-weight: bold;
  line-height: 1.1; }

.contact_top .contact_top__teltext {
  margin-top: 10px; }

.contact_top .contact_top__tel {
  color: #D02428;
  font-size: 1.6em;
  font-weight: bold; }

@media only screen and (min-width: 768px) {
  .contact_top .contact_top__qa {
    padding-top: 45px; } }

.contact_top .topblk_contact_stepnum {
  color: #fff;
  background-color: #03a9f4;
  display: block;
  padding: 5px; }
  @media only screen and (min-width: 768px) {
    .contact_top .topblk_contact_stepnum {
      display: inline-block; } }

.contact_top .topblk_contact_steptext {
  padding: 5px;
  display: inline-block; }
  @media only screen and (min-width: 768px) {
    .contact_top .topblk_contact_steptext {
      padding: 0; } }

.contact_top .topblk_contact_step_now {
  background-color: #ffc107; }

.contact_top .topblk_contact_step {
  width: 160px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #333;
  border-radius: 3px; }

.contact_top .contact_top_flow {
  display: flex;
  align-items: center;
  margin-bottom: 10px; }

.contact_top .contact_top_ul {
  padding: 0; }
  @media only screen and (min-width: 768px) {
    .contact_top .contact_top_ul {
      padding-left: 30px; } }

.contact_top .contact_top_flex {
  margin-bottom: 10px; }
  @media only screen and (min-width: 768px) {
    .contact_top .contact_top_flex {
      display: flex; } }
  .contact_top .contact_top_flex .btn-push {
    display: inline-block; }
  .contact_top .contact_top_flex__right {
    text-align: center;
    margin-top: 10px; }
    @media only screen and (min-width: 768px) {
      .contact_top .contact_top_flex__right {
        padding-left: 1rem;
        margin-top: 0; } }
    .contact_top .contact_top_flex__right img {
      max-width: 50%; }
      @media only screen and (min-width: 768px) {
        .contact_top .contact_top_flex__right img {
          max-width: 100%; } }

@media only screen and (min-width: 768px) {
  .contact_top .contact_qa_flex {
    display: flex; } }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
お客様情報の入力

ログインせずゲストとして商品を購入する際の、お客様情報の入力 に関する Project コンポーネントを定義します。

ex [カートSTEP2 お客様情報の入力(ゲスト購入)](http://demo3.ec-cube.net/shopping/nonmember)

Markup:
include /assets/tmpl/elements/19.3.customer.pug
+ec-customerRole
hoge

Styleguide 19.3

*/
.ec-customerRole {
  margin: 0 auto;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 980px; }
  .ec-customerRole:after {
    content: " ";
    display: table; }
  .ec-customerRole:after {
    clear: both; }
  .ec-customerRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-customerRole img {
    max-width: 100%; }
  .ec-customerRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-customerRole *,
  .ec-customerRole *::before,
  .ec-customerRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-customerRole .ec-customerRole__actions {
    padding-top: 20px; }
  .ec-customerRole .ec-blockBtn--action {
    margin-bottom: 10px; }
    @media only screen and (min-width: 768px) {
      .ec-customerRole .ec-blockBtn--action {
        margin-bottom: 16px; } }

.ec-contactConfirmRole {
  margin: 0 auto;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 980px; }
  .ec-contactConfirmRole:after {
    content: " ";
    display: table; }
  .ec-contactConfirmRole:after {
    clear: both; }
  .ec-contactConfirmRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-contactConfirmRole img {
    max-width: 100%; }
  .ec-contactConfirmRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-contactConfirmRole *,
  .ec-contactConfirmRole *::before,
  .ec-contactConfirmRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-contactConfirmRole .ec-contactConfirmRole__actions {
    padding-top: 20px; }
  .ec-contactConfirmRole .ec-blockBtn--action {
    margin-bottom: 16px; }

.ec-contactCompleteRole {
  margin: 0 auto;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 980px; }
  .ec-contactCompleteRole:after {
    content: " ";
    display: table; }
  .ec-contactCompleteRole:after {
    clear: both; }
  .ec-contactCompleteRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-contactCompleteRole img {
    max-width: 100%; }
  .ec-contactCompleteRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-contactCompleteRole *,
  .ec-contactCompleteRole *::before,
  .ec-contactCompleteRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden; }
  100% {
    opacity: 1;
    visibility: visible; } }

@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible; }
  100% {
    opacity: 0;
    visibility: hidden; } }

.bg-load-overlay {
  background: rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  position: fixed;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-around;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2147483647;
  opacity: 1; }

/*
404ページ

404 エラー画面で使用するページコンポーネントです。

ex [404エラー画面](http://demo3.ec-cube.net/404)

Markup:
include /assets/tmpl/elements/20.1.404.pug
+ec-404Role

Styleguide 20.1

*/
.ec-404Role {
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  height: 100vh;
  background-color: #f2f2f2;
  text-align: center;
  box-sizing: border-box; }
  .ec-404Role textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-404Role img {
    max-width: 100%; }
  .ec-404Role html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-404Role *,
  .ec-404Role *::before,
  .ec-404Role *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-404Role .ec-404Role__icon img {
    width: 1em;
    height: 1em; }
  .ec-404Role .ec-404Role__title {
    font-weight: bold;
    font-size: 25px; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
退会手続き

退会手続きで使用するページコンポーネントです。

ex [退会手続き](http://demo3.ec-cube.net/mypage/withdraw)

Markup:
include /assets/tmpl/elements/21.1.withdraw.pug
+ec-withdrawRole

Styleguide 21.1

*/
.ec-withdrawRole {
  margin: 0 auto;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 980px;
  text-align: center;
  padding: 0 16px; }
  .ec-withdrawRole:after {
    content: " ";
    display: table; }
  .ec-withdrawRole:after {
    clear: both; }
  .ec-withdrawRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-withdrawRole img {
    max-width: 100%; }
  .ec-withdrawRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-withdrawRole *,
  .ec-withdrawRole *::before,
  .ec-withdrawRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-withdrawRole .ec-withdrawRole__title {
    margin-bottom: 16px;
    font-weight: bold;
    font-size: 23px; }
  .ec-withdrawRole .ec-withdrawRole__description {
    margin-bottom: 32px;
    font-size: 16px; }
  .ec-withdrawRole .ec-icon img {
    width: 100px;
    height: 100px; }

/*
退会手続き実行確認

退会手続き実行確認で使用するページコンポーネントです。

ex [退会手続き　退会手続きへボタン→押下](http://demo3.ec-cube.net/mypage/withdraw)

Markup:
include /assets/tmpl/elements/21.1.withdraw.pug
+ec-withdrawConfirm

Styleguide 21.1.2

*/
.ec-withdrawConfirmRole .ec-withdrawConfirmRole__cancel {
  margin-bottom: 20px; }

.ec-withdrawConfirmRole .ec-withdrawConfirmRole__title {
  margin-bottom: 16px;
  font-weight: bold;
  font-size: 24px; }

.ec-withdrawConfirmRole .ec-withdrawConfirmRole__description {
  margin-bottom: 32px;
  font-size: 16px; }

.ec-withdrawConfirmRole .ec-icon img {
  width: 100px;
  height: 100px; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
会員情報編集完了

会員情報編集完了で使用するページコンポーネントです。

ex [会員情報編集完了](http://demo3.ec-cube.net/mypage/change_complete)

Markup:
include /assets/tmpl/elements/22.1.editComplete.pug
+ec-userEditCompleteRole

Styleguide 22.1

*/
.ec-userEditCompleteRole {
  margin: 0 auto;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 980px;
  text-align: center;
  padding: 0 16px; }
  .ec-userEditCompleteRole:after {
    content: " ";
    display: table; }
  .ec-userEditCompleteRole:after {
    clear: both; }
  .ec-userEditCompleteRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-userEditCompleteRole img {
    max-width: 100%; }
  .ec-userEditCompleteRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-userEditCompleteRole *,
  .ec-userEditCompleteRole *::before,
  .ec-userEditCompleteRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-userEditCompleteRole .ec-userEditCompleteRole__title {
    margin-bottom: 16px;
    font-weight: bold;
    font-size: 24px; }
    @media only screen and (min-width: 768px) {
      .ec-userEditCompleteRole .ec-userEditCompleteRole__title {
        font-size: 32px; } }
  .ec-userEditCompleteRole .ec-userEditCompleteRole__description {
    margin-bottom: 32px;
    font-size: 16px; }

a:hover {
  text-decoration: unset; }

.developing,
.development {
  display: none; }

/************************************************
 印刷用
************************************************ */
@media print {
  body {
    zoom: 75%; } }

/************************************************
 共通設定
************************************************ */
html {
  font-size: unset; }

html,
body {
  height: 100%; }

.sp_none {
  display: none; }
  @media only screen and (min-width: 768px) {
    .sp_none {
      display: revert; } }

.pc_none {
  display: revert; }
  @media only screen and (min-width: 768px) {
    .pc_none {
      display: none; } }

#wrapper {
  position: relative;
  min-height: 820px;
  height: auto !important;
  height: 100%; }

#container {
  padding-bottom: 50px; }

#footer {
  bottom: 0;
  width: 100%; }

body {
  color: #333;
  font-family: "Hiragino Sans", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Meiryo", "BIZ UDPGothic", "MS Gothic", sans-serif;
  font-size: 75.5%;
  line-height: 1.2;
  letter-spacing: 0.1em;
  background-color: #fafafa;
  -webkit-text-size-adjust: 100%; }

img {
  -ms-interpolation-mode: bicubic; }

select {
  border: solid 1px #ccc; }

.input:-ms-input-placeholder {
  color: #A3A3A3; }

/*写真*/
.picture {
  border: 1px solid #ccc; }

.margintop {
  margin-top: 1rem; }

.small {
  float: left; }

.red {
  color: #ff0000;
  font-weight: bold; }

.url {
  font-weight: bold; }

.url a:hover {
  text-decoration: underline; }

.additional-banner {
  margin: 20px 0 10px !important; }

.boxshadow1 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }

.boxshadow2 {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); }

.boxshadow3 {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23); }

.boxshadow4 {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22); }

.boxshadow5 {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22); }

.list_disc li {
  list-style-type: disc;
  margin-left: 1.5rem; }

.swing {
  animation-name: fuwa_p;
  animation-duration: .3s;
  animation-iteration-count: 2;
  animation-timing-function: ease; }

#bank_check_area {
  margin-top: 15px; }

.pointer {
  display: none;
  float: left;
  position: relative;
  top: -35px;
  left: 550px;
  animation-name: fuwa_l;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease; }

.fa-fuwaru {
  margin-left: 0.5rem;
  animation-name: fuwa_l;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease; }

@keyframes fuwa_p {
  0% {
    transform: translate(0, 0); }
  50% {
    transform: translate(0, -10px); }
  100% {
    transform: translate(0, 0); } }

@keyframes fuwa_l {
  0% {
    transform: translate(0, 0); }
  50% {
    transform: translate(-10px, 0); }
  100% {
    transform: translate(0, 0); } }

/* FLOAT */
@-webkit-keyframes float {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  50% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes float {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  50% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

.faa-float {
  -webkit-animation: float 2s linear infinite;
  animation: float 2s linear infinite; }

.faa-float.faa-fast {
  -webkit-animation: float 1s linear infinite;
  animation: float 1s linear infinite; }

.faa-float.faa-slow {
  -webkit-animation: float 3s linear infinite;
  animation: float 3s linear infinite; }

.ibox_gray {
  background-color: #dddddd;
  display: inline-block;
  padding: 8px;
  border-radius: 4px; }

/* 緑の長いボタン */
.link_sp {
  width: 17em;
  max-width: 150px;
  height: auto;
  margin: 0 auto;
  padding: 10px;
  background: #4caf50;
  text-align: center;
  border-radius: 2px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25); }

.link_sp:hover {
  background: #439a46;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.35); }

.link_sp:active {
  background: #439a46;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.35); }

.link_sp_a {
  color: #fff !important;
  font-weight: bold;
  text-decoration: none !important;
  font-size: 1.5em;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4); }

/* オレンジ色の長いボタン */
.link_sp2 {
  width: 17em;
  max-width: 150px;
  height: auto;
  margin: 0 auto;
  padding: 10px;
  background: #FF9800;
  text-align: center;
  border-radius: 2px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25); }

.link_sp2:hover {
  background: #e08600; }

.link_sp2:active {
  background: #e08600;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.35); }

.link_sp2_a {
  color: #fff !important;
  font-weight: bold;
  text-decoration: none !important;
  font-size: 1.5em;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4); }

/* 白い長いボタン */
.link_sp3 {
  width: 19em;
  max-width: 50%;
  height: auto;
  margin: 0 auto;
  padding: 10px;
  background: #F6F6F6;
  text-align: center;
  border-radius: 2px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25); }

.link_sp3:hover {
  background: #E6E6E6; }

.link_sp3:active {
  background: #E6E6E6;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.35); }

.link_sp3_a {
  color: #130f30 !important;
  font-weight: bold;
  text-decoration: none !important;
  font-size: 1.5em; }

/* オレンジ色の細長いボタン */
.link_sp4 {
  width: 17em;
  max-width: 50%;
  height: auto;
  margin: 0 auto;
  padding: 10px;
  background: #FF9800;
  text-align: center;
  border-radius: 2px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25); }

.link_sp4:hover {
  background: #FF9E43; }

.link_sp4:active {
  background: #FF9E43;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.35); }

.link_sp4_a {
  color: #fff !important;
  text-decoration: none !important;
  font-size: 1.0em;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4); }

.sp_red {
  background-color: #f44336;
  color: white; }

.sp_red:hover {
  background-color: #ef5350; }

.sp_red:active {
  background: #ef5350;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.35); }

.sp_blue {
  background-color: #2196f3;
  color: white; }

.sp_blue:hover {
  background-color: #3ea4f5; }

.sp_blue:active {
  background: #3ea4f5;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.35); }

.sp_ora {
  background-color: #FF9800;
  color: white; }

.sp_ora:hover {
  background-color: #ffa41f; }

.sp_ora:active {
  background: #ffa41f;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.35); }

.orange {
  color: #FF8611; }

.fontsize12em {
  font-size: 1.2em; }

.summerholiday_table_scroll {
  width: 100%;
  overflow-x: auto; }
  .summerholiday_table_scroll table {
    width: 752px; }

.top-warning {
  display: block;
  background-color: #ffebee;
  text-align: center;
  font-size: 1.3em;
  border: 1px solid #f44336;
  padding: 5px;
  margin-bottom: 5px;
  border-radius: 3px;
  text-decoration: none;
  line-height: 1.3;
  margin-top: 15px; }

.top-warning:hover {
  cursor: pointer;
  opacity: 0.8; }

.box-text-notice {
  position: relative;
  background: #FFEBEE;
  border: 3px double #E53935;
  border-radius: 5px;
  margin: 20px 0;
  padding: 10px 5px;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.6; }

.btn-push {
  position: relative;
  top: 0px;
  font-family: "Font Awesome 5 Free", "Hiragino Sans", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Meiryo", "BIZ UDPGothic", "MS Gothic", sans-serif;
  text-decoration: none;
  font-size: 16px;
  background: #f7f7f7;
  padding: 10px 10px;
  margin: 0 10px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  color: #222;
  text-shadow: 1px 1px 1px #fff;
  transition: all .3s;
  text-decoration: none !important; }
  @media only screen and (min-width: 768px) {
    .btn-push {
      padding: 10px 35px; } }

/*==========  Active State  ==========*/
.btn-push:hover {
  opacity: 0.6;
  transition: all .3s; }

/*==========  Navy  ==========*/
.btn-push.navy {
  background: #1b486d;
  border: 1px solid #07121b;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 5px 0px #07121b;
  -moz-box-shadow: 0px 5px 0px #07121b;
  -ms-box-shadow: 0px 5px 0px #07121b;
  -o-box-shadow: 0px 5px 0px #07121b;
  box-shadow: 0px 5px 0px #07121b;
  color: #fff;
  text-shadow: 1px 1px 1px #07121b; }

/*==========  Green  ==========*/
.btn-push.green {
  background: #4caf50;
  color: #fff;
  border: 1px solid #439a46;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 5px 0px #46963f;
  -moz-box-shadow: 0px 5px 0px #46963f;
  -ms-box-shadow: 0px 5px 0px #46963f;
  -o-box-shadow: 0px 5px 0px #46963f;
  box-shadow: 0px 5px 0px #46963f;
  text-shadow: 1px 1px 1px #46963f;
  display: inline-block; }

/*==========  Red  ==========*/
.btn-push.red-b {
  background: #f44336;
  color: #fff;
  border: 1px solid #f22819;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 5px 0px #a01b1b;
  -moz-box-shadow: 0px 5px 0px #a01b1b;
  -ms-box-shadow: 0px 5px 0px #a01b1b;
  -o-box-shadow: 0px 5px 0px #a01b1b;
  box-shadow: 0px 5px 0px #a01b1b;
  text-shadow: 1px 1px 1px #a01b1b; }

/*==========  Blue  ==========*/
.btn-push.blue {
  background: #2196f3;
  color: #fff;
  border: 1px solid #0d87e9;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 5px 0px #177c8e;
  -moz-box-shadow: 0px 5px 0px #177c8e;
  -ms-box-shadow: 0px 5px 0px #177c8e;
  -o-box-shadow: 0px 5px 0px #177c8e;
  box-shadow: 0px 5px 0px #177c8e;
  text-shadow: 1px 1px 1px #177c8e;
  display: inline-block; }

/*==========  Orange  ==========*/
.btn-push.orange {
  background: #FF9800;
  color: #fff;
  border: 1px solid #e08600;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 5px 0px #bc4809;
  -moz-box-shadow: 0px 5px 0px #bc4809;
  -ms-box-shadow: 0px 5px 0px #bc4809;
  -o-box-shadow: 0px 5px 0px #bc4809;
  box-shadow: 0px 5px 0px #bc4809;
  text-shadow: 1px 1px 1px #bc4809; }

.btn-sm {
  padding: 10px 25px; }

.btn-ssm {
  padding: 10px 5px; }

.btn-sss {
  padding: 5px 2px;
  font-size: 1.2em;
  margin: 0px 5px;
  display: inline-block;
  color: #666666; }

.fav_button {
  display: table-cell;
  height: 54px;
  vertical-align: middle; }

.detail_button {
  display: inline-block;
  padding: 15px;
  font-size: 1.1rem;
  font-weight: bold;
  text-decoration: none !important;
  border-radius: 3px;
  background-color: #f5f5f5;
  transition: all 0.3s;
  border: none; }
  @media only screen and (max-width: 769px) {
    .detail_button {
      font-size: 0.9rem;
      padding: 15px 10px; } }

.s_btn_list {
  margin: 10px; }

.s_btn_list .detail_button {
  border: 4px solid #2196F3;
  background: #F5F5F5 !important;
  color: #333 !important;
  transition: all 0.3s; }

.s_btn_list .detail_button:hover {
  background: #EEEEEE !important; }

.cart_button {
  background-color: #4caf50;
  border: 0px;
  color: #fafafa !important; }

.contact_button {
  border: 1px solid #0D47A1;
  background: #E3F2FD;
  color: #0D47A1 !important; }

.contact_button:hover {
  background: #0D47A1;
  color: #fafafa !important; }

.contact_quick_button {
  border: 1px solid #E65100;
  background: #FFCDD2;
  color: #212121 !important; }

.contact_quick_button:hover {
  background: #F44336;
  color: #fafafa !important; }

.cart_x10_button {
  background-color: #43a047;
  color: #fafafa !important;
  width: 40%;
  text-align: center;
  margin: 10px 0px; }

.gray_ripple {
  display: inline; }
  .gray_ripple a {
    text-decoration: none !important; }

.gray_ripple .legitRipple-ripple {
  background: #ddd; }

.detail_etc_button {
  width: 45%;
  text-align: center;
  margin: 10px 0px;
  background-color: #f5f5f5;
  text-decoration: none !important; }
  @media only screen and (max-width: 769px) {
    .detail_etc_button {
      width: calc(50% - 5px); } }

.detail_etc_one_button {
  text-align: center;
  margin: 10px 0px;
  background-color: #f5f5f5; }

.sidebar_button {
  width: 100%;
  padding: 15px 10px;
  text-align: center;
  margin: 5px 0px;
  border: 0px;
  background-color: #f5f5f5; }

/* トップ画像の二列表示 */
.topimage-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: center;
  margin-bottom: 20px;
  width: 100%; }
  .topimage-container .topimage-cell {
    width: 50%; }

.topimage-container a img {
  padding: 3px;
  transition: 0.3s;
  border: 1px solid #ccc;
  width: 100%;
  height: auto; }

.topimage-container a:hover img {
  border: 1px solid #1E88E5;
  background: #1E88E5;
  opacity: 1.0;
  filter: alpha(opacity=100); }

/* ==============================================
 フレーム
=============================================== */
/* 全体を包括 */
/*.frame_outer {
    margin: 0 auto;
    width: 100%;
    text-align: center;
}*/
.frame_outer {
  width: 100%;
  position: relative;
  height: auto !important;
  height: 100%;
  min-height: 100%;
  text-align: center;
  margin: 0 auto; }

/* ラッパー */
#wrapper {
  margin: 0 auto;
  padding: 0; }

/* コンテンツ */
/* ==============================================
 カラム指定
=============================================== */
/* ヘッダーとフッターの上下
[注意]ブロック移動時はbloc.css内で調整が必要 */
#bottomcolumn,
#footerbottomcolumn {
  margin: 0 auto;
  /*padding: 15px;*/
  width: 950px;
  max-width: 100%;
  /*background: #fff;*/
  text-align: left; }

#topcolumn {
  margin: 10px auto 0;
  width: 980px;
  max-width: 100%;
  text-align: left; }
  #topcolumn img {
    max-width: 100%; }

/* 中央カラム */
.main_column {
  padding: 10px 0 20px; }

/* サイドカラム */
#leftcolumn {
  padding-right: 20px; }

#rightcolumn {
  float: right;
  width: 20%; }

/* 1カラム設定 */
#one_maincolumn {
  margin: 0 auto;
  width: 80%; }

/* 2カラム設定 (メイン部が左) */
#two_maincolumn_left {
  padding-left: 1.5%;
  float: left;
  width: 70%; }

/* 2カラム設定 (メイン部が右) */
#two_maincolumn_right {
  /*padding-right: 1.5%;
  width: 75.5%;
  float: right;*/
  padding-top: 0; }

/* 3カラム設定 */
#three_maincolumn {
  padding-left: 0.5%;
  width: 59%;
  float: left; }

/* 下層コンテンツ */
#undercolumn {
  width: 100%; }

/* ==============================================
 ユーティリティ
=============================================== */
/* フロート回り込み解除
----------------------------------------------- */
.clearfix {
  display: block;
  /* for IE8 */ }

.clearfix:after {
  display: block;
  clear: both;
  height: 0px;
  line-height: 0px;
  visibility: hidden;
  content: "."; }

.clear {
  clear: both; }

/* リンク指定
----------------------------------------------- */
a:link,
a:visited {
  color: #666666; }

a img:hover {
  opacity: 0.8;
  filter: alpha(opacity=80); }

h2 img:hover {
  opacity: 0.8;
  filter: alpha(opacity=80); }

/* フォント
----------------------------------------------- */
h1,
h2,
h3,
h4,
h5 {
  font-size: 100%;
  line-height: 150%; }

.sale_price {
  color: #f00; }

.normal_price {
  font-size: 90%; }

.point {
  color: #f00;
  font-weight: bold; }

.user_name {
  font-weight: bold; }

.recommend_level {
  color: #ecbd00; }

.attention {
  font-size: 1.2em;
  color: #f00; }

.attention2 {
  font-size: 1.2em;
  color: #f00; }

.attentionB {
  font-size: 1.2em;
  color: #2196f3; }

.attentionSt {
  color: #f00;
  font-weight: bold; }

.st,
.bold {
  font-weight: bold; }

.mini {
  font-size: 90%; }

/* 行揃え
----------------------------------------------- */
.alignC,
.alignC td {
  text-align: center; }

.alignR {
  text-align: right; }

.alignL {
  text-align: left; }

.pricetd em {
  font-weight: bold; }

/* フォーム
----------------------------------------------- */
.send-btn {
  margin: 0 auto;
  width: 27%; }

.send-btn input {
  padding: 10px; }

.box40 {
  width: 40px; }

.box60 {
  width: 60px; }

.box100 {
  width: 100px; }

.box120 {
  width: 120px; }

.box140 {
  width: 140px; }

.box140s {
  width: 140px;
  height: 20px;
  float: left; }

.box145 {
  width: 145px; }

.box150 {
  width: 150px; }

.box160 {
  width: 160px; }

.box240 {
  width: 240px; }

.box300 {
  width: 300px; }

.box320 {
  width: 320px; }

.box350 {
  width: 350px; }

.box380 {
  width: 380px; }

/* フォームが縦に重なり合う場合に併用する余白 */
.top {
  margin-bottom: 5px; }

.top5 {
  margin-top: 0.5rem; }

.ng_top {
  margin-bottom: 0 !important; }

.box300,
.box120,
.box380 {
  border: 1px solid #777 !important;
  padding: 5px;
  color: #333;
  background: #eee;
  border-radius: 5px; }

/* タイトル
----------------------------------------------- */
.h1_title {
  margin-bottom: 10px;
  color: #666;
  font-size: 16px;
  background: #fafafa !important;
  border: 1px solid #ccc !important;
  border-left: 15px solid #FF9800 !important;
  padding: 5px 10px !important;
  font-weight: bold; }

h2.title {
  margin-bottom: 10px;
  padding: 8px;
  border-top: solid 1px #ebeced;
  color: #f60;
  background: #fafafa !important;
  border: 1px solid #ccc !important;
  border-left: 15px solid #FF9800 !important;
  padding: 5px 10px !important;
  font-size: 170%; }

.h2_title {
  margin-bottom: 10px;
  color: #fff;
  font-size: 16px;
  background: #009688;
  border: 1px solid #ccc;
  border-left: 15px solid #FF9800;
  padding: 5px 10px;
  position: relative;
  margin-top: 10px; }
  .h2_title .rss_icon {
    position: absolute;
    top: 6px;
    right: 10px; }

h2.h2_title .category_h2text {
  font-size: 12px;
  text-align: right;
  float: right;
  margin-right: 10px; }

.h2_subtitle {
  margin-bottom: 10px;
  /*padding: 4px 0 5px 20px;*/
  padding: 5px 10px;
  color: #333;
  border-left: 16px solid #FF9800;
  background: none repeat scroll 0% 0% #FFEDA9;
  font-size: 14px;
  font-weight: bold;
  width: 100%; }

.h2_subtitle .category_h2text {
  font-size: 12px;
  text-align: right;
  float: right;
  margin-right: 10px; }

.h3_subtitle {
  border-bottom: solid 2px #1E88E5;
  display: inline-block;
  padding-right: 1rem;
  margin: 0.5rem !important; }

/*h2.h2_title span.page_title{
float:left;
}*/
#one_maincolumn .sub_area h3,
#two_maincolumn_right .sub_area h3,
#two_maincolumn_left .sub_area h3,
#three_maincolumn .sub_area h3,
#undercolumn_login .login_area h3,
#undercolumn_shopping h3,
#mypagecolumn h3,
#undercolumn_cart h3 {
  margin: 0 0 10px 0;
  padding: 5px 0 10px;
  color: #f60;
  background: url("../img/background/line_01.gif") repeat-x left bottom;
  font-size: 120%; }

div#undercolumn_login .login_area h4 {
  padding-left: 15px;
  background: url("../img/icon/ico_arrow_05.gif") no-repeat left; }

/* ==============================================
表示モード
=============================================== */
#displaymode {
  font-weight: bold;
  font-size: 18px;
  color: #FF9E3A; }

#displaymode a {
  color: #FF9E3A; }

#displaymode a:hover {
  color: #49B9E4; }

input#submit_button {
  width: 95%;
  height: 40px;
  font-size: 20px;
  font-weight: bold; }

/* ==============================================
 ヘッダー
=============================================== */
/* レイアウト
----------------------------------------------- */
#header_wrap {
  border-top: solid 3px #f90;
  min-height: 150px;
  background: url("../img/common/bg_header.gif") repeat-x bottom #fffaf0; }

#header {
  clear: both;
  max-width: 980px;
  margin: 0 auto; }
  @media only screen and (min-width: 768px) {
    #header {
      display: flex;
      justify-content: space-between; } }
  #header img {
    max-width: 100%;
    height: auto; }

#header #logo {
  max-width: 115px;
  margin-bottom: 20px; }
  @media only screen and (min-width: 768px) {
    #header #logo {
      max-width: 200px;
      position: relative;
      padding: 0 0 10px 0;
      margin-bottom: 0; } }
  #header #logo h1 {
    margin-top: 0; }
  #header #logo a {
    display: inline-block; }
    @media only screen and (min-width: 768px) {
      #header #logo a {
        width: 200px; } }
    @media only screen and (min-width: 768px) {
      #header #logo a img {
        width: 200px;
        height: auto; } }

@media only screen and (min-width: 768px) {
  #header_right {
    width: 770px; } }

#header_right h1 {
  font-weight: bold; }

#header p.description {
  margin: 0px;
  padding: 5px 0px 0px 0px;
  text-align: right;
  color: #333;
  font-family: "Hiragino Sans", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Meiryo", "BIZ UDPGothic", "MS Gothic", sans-serif;
  font-size: 11px;
  line-height: 16px; }

#header .contact {
  margin: 2px 0px 5px 0px;
  display: flex; }
  @media only screen and (max-width: 769px) {
    #header .contact {
      display: none; } }
  #header .contact__item {
    padding: 10px;
    margin: 14px 5px 8px 5px;
    border-radius: 3px;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.26);
    background-color: #f9f9f9;
    font-size: 99%; }
    #header .contact__item a {
      text-decoration: none;
      display: block;
      padding: 0 10px; }

#header ul#header_system {
  list-style-type: none;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  float: left;
  height: 33px; }

#header ul#header_system li {
  float: left;
  padding: 0px 0px 0px 10px; }

#header ul#header_link {
  float: left;
  padding-top: 8px;
  list-style-type: none;
  margin: 0px;
  height: 51px; }

#header ul#header_link li {
  float: left;
  padding: 0px 0px 0px 0px; }

#header ul#header_guide {
  margin: 0px;
  padding: 0px 0px 0px 10px;
  height: 17px;
  float: left;
  list-style-type: none; }

#header ul#header_guide li {
  /*background-image:url("../img/header/point01.jpg");*/
  color: #f90;
  background-repeat: no-repeat;
  background-position: left center;
  list-style-type: none;
  text-decoration: none;
  margin: 5px 6px 0px 0px;
  padding: 0px 0px 0px 17px;
  float: left;
  font-size: 12px;
  white-space: nowrap; }

#header div#menu_area {
  max-width: 770px;
  margin-top: 5px;
  display: flex;
  justify-content: flex-end; }
  @media only screen and (max-width: 769px) {
    #header div#menu_area {
      display: none; } }

#header div#list {
  width: 346px; }

ul#global_navi {
  clear: both;
  margin: 0px;
  padding: 5px 0px 0px 0px;
  list-style-type: none; }

ul#global_navi li {
  float: left; }

ul#global_navi li img {
  vertical-align: bottom; }

ul#navigation {
  clear: both;
  margin: 0px;
  padding: 0px 0px 0px 0px;
  list-style-type: none;
  width: 100%; }

ul#navigation li {
  float: left; }

ul#navigation li a:hover {
  border: none; }

ul#navigation li img {
  vertical-align: bottom; }

.head_nav2014 {
  font-size: 16px;
  font-weight: bold;
  font-family: "Hiragino Sans", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Meiryo", "BIZ UDPGothic", "MS Gothic", sans-serif; }

#logo_area {
  padding-left: 10px;
  float: left;
  width: 390px;
  text-align: left; }

#top_logo_area h1 {
  padding: 10px 10px 20px 10px;
  float: left; }

.header_text {
  float: right;
  padding-top: 20px; }

#header_utility {
  float: right;
  width: 580px; }

#errorHeader {
  color: #F00;
  font-weight: bold;
  font-size: 12px;
  background-color: #FEB;
  text-align: center;
  padding: 5px; }

#header_right2 .top_text {
  font-size: 100%;
  line-height: 150%;
  font-weight: bold;
  padding: 5px 63px 0px 98px;
  margin: 5px 0; }
  @media only screen and (min-width: 768px) {
    #header_right2 .top_text {
      padding: 0 0 0 5px; } }

/* 追尾型の電話番号表示
----------------------------------------------- */
@media print {
  #header-fixed {
    display: none; } }

#header-fixed {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100000;
  display: none; }
  @media only screen and (max-width: 769px) {
    #header-fixed {
      display: none !important; } }

/* ロゴ
----------------------------------------------- */
#site_description {
  font-size: 90%; }

#logo_area h1 {
  width: 378px;
  height: 33px; }

#logo_area h1 span {
  display: none;
  /* テキストロゴ非表示 */ }

/* ヘッダーナビ
----------------------------------------------- */
div#header_navi {
  float: right;
  width: 409px;
  height: 38px; }

div#header_navi ul li {
  display: block;
  float: left; }

div#header_navi ul li.mypage,
div#header_navi ul li.entry {
  margin-top: 6px; }

/* ==============================================
 フッター
=============================================== */
#footer_wrap {
  width: 100%;
  clear: both;
  /*background-image:url(../img/footer/f_bg.jpg);*/
  background-color: #7B6F5F;
  border-top: 2px solid #333; }

#footer {
  color: #fff;
  /*font-size:95%;
  width: 970px;
  margin: 0px auto;
  clear: both;*/ }

#footer ul {
  margin: 0px auto;
  padding: 10px;
  list-style-type: none; }
  @media only screen and (min-width: 768px) {
    #footer ul {
      display: flex;
      flex-wrap: wrap; } }

#footer ul li {
  padding-right: 10px; }

#footer ul li a {
  color: #fff;
  font-weight: bold; }

#footer ul li a:hover {
  color: #fff;
  font-weight: bold; }

.go_pagetop {
  clear: both;
  text-align: right;
  padding-right: 15px;
  margin-bottom: -8px; }

.link_pt {
  display: inline-block;
  width: 200px;
  height: 54px;
  text-align: center;
  text-decoration: none;
  line-height: 54px;
  outline: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all .3s;
  transition: all .3s;
  width: 12em;
  height: auto;
  margin: 0 auto;
  text-align: center;
  border-radius: 5px 5px 0px 0px;
  font-size: 12px; }

.link_pt::before,
.link_pt::after {
  position: absolute;
  z-index: -1;
  display: block;
  content: ''; }

.link_pt::before,
.link_pt::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all .3s;
  transition: all .3s; }

.link_pt a {
  color: #fff !important;
  font-weight: bold;
  text-decoration: none; }

.link_pt_a {
  color: #fff !important;
  font-weight: bold;
  text-decoration: none; }

.link_pt {
  position: relative;
  z-index: 2;
  background-color: #7B6F5F;
  border-left: 2px solid #333;
  border-right: 2px solid #333;
  border-top: 2px solid #333;
  color: #fff;
  line-height: 50px;
  overflow: hidden; }

.link_pt:hover {
  color: #fff; }

.link_pt::after {
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%; }

.link_pt:hover::after {
  left: 0;
  background-color: #333; }

.copyright {
  /*clear:both;*/
  margin: 0;
  padding: 0 0 15px 0;
  text-align: right; }

/* ==============================================
 パーツ
=============================================== */
/* ボタン
----------------------------------------------- */
.btn_area {
  margin-top: 10px;
  width: 100%;
  text-align: center; }
  .btn_area ul {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: none; }
    .btn_area ul li a {
      display: inline-block; }

.cart_btn_area {
  margin-top: 10px;
  width: 100%;
  text-align: center; }
  .cart_btn_area ul {
    margin: 0;
    padding: 0;
    list-style: none; }
    @media only screen and (min-width: 768px) {
      .cart_btn_area ul {
        display: flex;
        justify-content: center;
        flex-flow: row-reverse; } }
    .cart_btn_area ul li {
      margin: 0 0 10px; }
      @media only screen and (min-width: 768px) {
        .cart_btn_area ul li {
          margin: 0 10px; } }
      .cart_btn_area ul li a {
        display: inline-block; }

.btn_area_one {
  margin-top: 10px;
  width: 100%;
  text-align: center; }
  .btn_area_one li {
    display: inline-block; }

/* 完了メッセージ
----------------------------------------------- */
div#complete_area {
  margin-bottom: 20px; }

div#complete_area .message {
  margin-bottom: 20px;
  line-height: 150%;
  font-weight: bold;
  font-size: 120%; }

div#undercolumn_entry .message {
  margin-bottom: 20px;
  line-height: 150%;
  font-weight: bold;
  font-size: 120%; }

div#complete_area .shop_information {
  margin-top: 20px;
  padding: 20px 0 0 0;
  border-top: solid 1px #ccc; }

div#complete_area .shop_information .name {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 140%; }

/* Tipsy
----------------------------------------------- */
.tipsy {
  padding: 5px;
  font-size: 10px;
  position: absolute;
  z-index: 100000; }

.tipsy-inner {
  padding: 5px 8px 4px 8px;
  background-color: black;
  color: white;
  max-width: 200px;
  text-align: center;
  border-radius: 3px; }

.tipsy-arrow {
  position: absolute;
  background: url("../img/ajax/tipsy.gif") no-repeat top left;
  width: 9px;
  height: 5px; }

.tipsy-n .tipsy-arrow {
  top: 0;
  left: 50%;
  margin-left: -4px; }

.tipsy-nw .tipsy-arrow {
  top: 0;
  left: 10px; }

.tipsy-ne .tipsy-arrow {
  top: 0;
  right: 10px; }

.tipsy-s .tipsy-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -4px;
  background-position: bottom left; }

.tipsy-sw .tipsy-arrow {
  bottom: 0;
  left: 10px;
  background-position: bottom left; }

.tipsy-se .tipsy-arrow {
  bottom: 0;
  right: 10px;
  background-position: bottom left; }

.tipsy-e .tipsy-arrow {
  top: 50%;
  margin-top: -4px;
  right: 0;
  width: 5px;
  height: 9px;
  background-position: top right; }

.tipsy-w .tipsy-arrow {
  top: 50%;
  margin-top: -4px;
  left: 0;
  width: 5px;
  height: 9px; }

/* テーブル */
ul.ul_table {
  display: table;
  table-layout: fixed;
  width: 350px;
  padding: 3px; }

ul.ul_table li {
  display: table-cell;
  vertical-align: middle;
  text-align: left;
  font-weight: bold; }

ul.ul_table li:nth-of-type(odd) {
  width: 90px; }

ul.ul_table li:nth-of-type(even) {
  width: 150px; }

/*for IE6 */
* html ul.ul_table li,
*:first-child + html ul.ul_table li {
  display: inline;
  zoom: 1; }

/*for IE7 */
/* ログイン */
.login_head {
  padding: 2px 5px 5px;
  margin: 0 auto;
  text-align: center;
  background-color: #FF9800;
  color: #fff;
  font-family: "Hiragino Sans", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Meiryo", "BIZ UDPGothic", "MS Gothic", sans-serif;
  font-size: 14px; }

.login_b {
  width: 100px;
  margin: 0 auto;
  text-align: center;
  margin-top: 1px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #FF9800;
  color: #fff !important; }

.login_b a:visited,
.login_b a:hover {
  color: #fff !important; }

.ranking_num {
  background-color: #FF9800;
  color: #fff;
  vertical-align: middle;
  padding-left: 5px;
  vertical-align: middle;
  font-size: 25px;
  font-weight: bold;
  padding-right: 5px; }

.nav_bg {
  background-color: #FF9800;
  width: 158px;
  border: 1px solid #ccc; }

.nav_bg:hover {
  background-color: #FF9800;
  border: 1px solid #607d8b; }

.nav_bg1 {
  border-radius: 2px 0px 0px 2px; }

.nav_bgl {
  border-radius: 0px 2px 2px 0px; }

/* ドロップダウンメニュー */
#dropmenu {
  list-style-type: none;
  width: 970px;
  height: 40px;
  margin: 30px auto 0px;
  padding: 0;
  background: #9e9e9e;
  border-bottom: 5px solid #bdbdbd;
  border-radius: 3px 3px 0 0;
  clear: both; }

#dropmenu li {
  position: relative;
  width: 158px;
  float: left;
  margin: 0;
  padding: 0;
  text-align: center;
  border-right: 1px solid #bdbdbd; }

#dropmenu li:last-child {
  border-right: 0px solid #bdbdbd; }

#dropmenu li a {
  display: block;
  margin: 0;
  padding: 15px 0 11px;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  text-decoration: none; }

#dropmenu li ul {
  list-style: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 999;
  margin: 0;
  padding: 0;
  border-radius: 0 0 3px 3px; }

#dropmenu li:last-child ul {
  left: -100%;
  width: 100%; }

#dropmenu li ul.nav_br_r {
  float: right; }

#dropmenu li ul.nav_br_l {
  float: left;
  margin-left: 200px; }

#dropmenu li ul li {
  overflow: hidden;
  width: 220px;
  height: 0;
  color: #fff;
  -moz-transition: .2s;
  -webkit-transition: .2s;
  -o-transition: .2s;
  -ms-transition: .2s;
  transition: .2s; }

#dropmenu li ul.nav_br_r li,
#dropmenu li ul.nav_br_l li {
  width: 200px; }

#dropmenu li ul li a {
  padding: 13px 15px;
  background: #5E5A5A;
  text-align: left;
  font-size: 12px;
  font-weight: normal; }

#dropmenu li:hover > a {
  background: #fb8c00;
  color: #fff; }

#dropmenu li:hover ul li {
  overflow: visible;
  height: 38px;
  border-top: 1px solid #767474;
  border-bottom: 1px solid #767474; }

#dropmenu li:hover ul li:first-child {
  border-top: 0; }

#dropmenu li:hover ul li:last-child {
  border-bottom: 0; }

#dropmenu li:hover ul li:last-child a {
  border-radius: 0 0 3px 3px; }

#dropmenu li:hover ul.nav_br_r li:last-child a {
  border-radius: 0 0 0 3px; }

#dropmenu li:hover ul.nav_br_l li:last-child a {
  border-radius: 0 0 3px 0; }

.dropmenu_abt {
  background: #FF9800 !important;
  border-bottom: 5px solid #e08600 !important; }

.dropmenu_abt li {
  border-right: 1px solid #bdbdbd; }

.dropmenu_abt li:last-child {
  border-right: 0px solid #bdbdbd; }

.dropmenu_abt li a {
  color: #fff; }

.dropmenu_abt li ul li {
  color: #fff; }

.dropmenu_abt li ul li a {
  background: #5E5A5A; }

.dropmenu_abt li:hover > a {
  background: #ffa41f !important;
  color: #fff; }

.dropmenu_abt li:hover ul li {
  border-top: 1px solid #767474;
  border-bottom: 1px solid #767474; }

#srchBox {
  width: 200px;
  _width: 146px;
  margin-bottom: 10px;
  background-color: #FFFFFF;
  border-style: solid;
  border-width: 1px;
  border-color: #EEEEEE;
  color: #000000;
  text-align: center; }

#srchBox * {
  margin: 0;
  padding: 0;
  font-size: 13px;
  *font-size: small;
  *font: x-small; }

#srchBox a img {
  border: none; }

#srchBox #srch {
  padding: 6px; }

#srchBox #srchInput {
  width: 98%;
  _width: 130px; }

#srchBox #srchBtn {
  width: 115px; }

*:first-child + html #srchBox #srchBtn {
  padding-top: 2px; }

#srchBox ul {
  margin-top: 6px;
  margin-bottom: 6px;
  text-align: left; }

#srchBox li {
  list-style-type: none;
  display: block; }

#srchBox li input {
  zoom: 1;
  margin-right: 2px;
  _margin: -4px 0 -4px -4px;
  vertical-align: middle;
  border: 0; }

#srchBox #srchLogo {
  margin: 6px 6px 6px 0;
  text-align: center; }

#srchBox #srchLogo a {
  color: #666666;
  text-decoration: none;
  font-size: 85%; }

#srchBox #srchLogo a:hover {
  text-decoration: underline; }

.items_cartblock {
  margin: 0 auto;
  margin-bottom: 3px; }

.items_cartblock li:nth-child(odd) {
  width: 50px;
  display: inline-block;
  vertical-align: middle; }

.items_cartblock li:nth-child(even) {
  width: 130px;
  display: inline-block;
  vertical-align: middle; }

.items_cartblockin {
  border-bottom: 1px solid #9e9e9e; }

/* fort.js */
.top_fort {
  /*background: #009dff;*/
  background: linear-gradient(to right, #009dff 0, #00c8ff 100%);
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  height: 18px;
  transition: all 1s;
  width: 0;
  overflow: hidden; }

.colors {
  width: 100%;
  height: 4px; }

.prog_tr {
  position: fixed;
  top: 0px;
  right: 0px;
  color: #FAFAFA;
  z-index: 1032;
  display: none; }

.prog_tm1 {
  position: fixed;
  top: 0px;
  right: 75%;
  color: #FAFAFA;
  z-index: 1032;
  display: none; }

.prog_tm {
  position: fixed;
  top: 0px;
  right: 50%;
  color: #FAFAFA;
  z-index: 1032;
  display: none; }

.prog_tm2 {
  position: fixed;
  top: 0px;
  right: 25%;
  color: #FAFAFA;
  z-index: 1032;
  display: none; }

.prog_tl {
  position: fixed;
  top: 0px;
  left: 0px;
  color: #FAFAFA;
  z-index: 1032;
  display: none; }

#mauticform_input__submit {
  display: none; }

/************************************************
 ブロック用
************************************************ */
/*** 目次 ***

▼ブロック共通
リスト
タイトル
ヘッダー上、フッター下のブロックエリア

▼各機能ブロックの指定
-新着情報
-現在のカゴの中
-カテゴリー
-ガイドリンク
-ログイン（サイド用）
-検索
-カレンダー
-おすすめ商品
    *メインカラム用 1カラム時 [one_maincolumn]
    *メインカラム用 2カラム時 [two_maincolumn_left]
                              [two_maincolumn_right]
    *メインカラム用 3カラム時 [three_maincolumn]
    *サイドカラム用           [side_column]
    *商品詳細のオススメ商品   [whobought_area]
 */
/* ==============================================
ブロック共通
/* ============================================= */
#container .bloc_body {
  background-color: #fff; }

#container .login_body {
  background-color: #ccc; }

.side_column {
  /*overflow-x: hidden;*/
  /* IE6 表示乱れ防止 */ }

.ec-layoutRole__left {
  margin-top: 10px; }
  .ec-layoutRole__left .bloc_outer {
    margin: 10px 0 10px 0;
    clear: both; }
    .ec-layoutRole__left .bloc_outer img {
      max-width: 100%; }
    .ec-layoutRole__left .bloc_outer ul {
      list-style: none;
      margin: 0;
      padding: 0; }
  .ec-layoutRole__left .bloc_body {
    border: solid 1px #ccc;
    border-top: none; }
    .ec-layoutRole__left .bloc_body .box {
      border: solid 1px #ccc;
      width: 145px; }

.ec-layoutRole__mainWithColumn .bloc_body {
  border: solid 1px #ccc;
  border-top: none; }

.ec-layoutRole__mainWithColumn .bloc_outer {
  margin-bottom: 20px; }

/* リスト
----------------------------------------------- */
/* ログイン 検索条件 */
#container .bloc_outer .bloc_body dl.formlist {
  margin-bottom: 8px; }

#container .bloc_outer .bloc_body dl.formlist dd {
  margin-bottom: 5px; }

#container .bloc_outer .bloc_body dl.formlist dt {
  margin-bottom: 3px; }

#container .bloc_outer .bloc_body dl.formlist span {
  vertical-align: top; }

/* タイトル
----------------------------------------------- */
/* タイトルの背景 白 */
#login_area h2,
#search_area h2,
#calender_area h2,
#cart_area h2,
#cart h2 {
  padding: 5px 0 8px 10px;
  border-style: solid;
  /*border-color: $orange #ccc #ccc;
  border-width: 1px 1px 0;*/
  border-top: 1px solid #FF9800;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 2px solid #eee;
  /*background: url('../img/background/bg_tit_bloc_01.jpg') repeat-x left bottom;*/
  background-color: #f5f5f5;
  font-size: 14px; }

.cart_head {
  border: 2px solid #FF8D21;
  border-left: 15px solid #FF8D21;
  font-size: 16px;
  color: #FF8D21;
  padding: 5px; }

#category_area h2 {
  border-top: solid 1px #FF9800; }

/* ==============================================
▼ヘッダー上、フッター下のブロックエリア
=============================================== */
div#bottomcolumn {
  margin: 0px;
  clear: both; }

/* ***********************************************
▼各機能ブロックの指定
/*********************************************** */
/* ===============================================
▼新着情報
=============================================== */
#news_area .bloc_body {
  padding: 10px; }

#news_area .news_list {
  padding: 10px 10px 10px 10px;
  max-height: 12rem;
  height: auto;
  border: 2px solid #EEEEEE;
  border-radius: 0.5rem;
  font-size: 1rem;
  overflow: auto;
  overflow-y: scroll; }

#news_area dl.news {
  margin-bottom: 0; }
  #news_area dl.news dt {
    margin-bottom: 5px;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    font-weight: normal; }
  #news_area dl.news dd {
    margin-bottom: 10px;
    padding-bottom: 10px; }
  #news_area dl.news a {
    color: #1E88E5; }
    #news_area dl.news a:visited {
      color: #c58af9 !important; }
    #news_area dl.news a:hover {
      color: #1976D2; }

/* ===============================================
▼現在のカゴの中
=============================================== */
#cart_area .information,
#cart_area2 .information {
  padding: 10px; }

#cart_area .postage,
#cart_area2 .postage {
  margin-top: 10px;
  padding-top: 10px;
  /*background: url("../img/background/line_dot_01.gif") repeat-x top;*/ }

#cart_area .postage .point_announce,
#cart_area2 .postage .point_announce {
  padding: 2px 0 2px 20px;
  background: url("../img/icon/ico_price.gif") no-repeat left top; }

#cart_area .btn,
#cart_area2 .btn {
  /*padding: 10px 0;
    background: url("../img/background/line_dot_01.gif") repeat-x top #f7f7e6;
    height: 2.5em;*/
  text-align: center;
  background-color: #F6CD74;
  border-top: 2px dotted #eee;
  white-space: normal; }

/* ===============================================
▼カテゴリー
=============================================== */
#category_area .bloc_body {
  background-color: #fffaf0; }

#category_area li {
  padding-left: 5px; }

#category_area li.level1 {
  border-bottom: solid 1px #ccc; }

#category_area li.level1 p {
  padding-left: 20px;
  margin: 7px 3px;
  background: url("../img/icon/ico_arrow_01.gif") 2px 3px no-repeat; }

#category_area li.level1 li p {
  background: url("../img/icon/ico_level.gif") 7px 7px no-repeat; }

#category_area li a {
  display: block;
  padding: 0; }

a.onlink:link {
  color: #f00;
  text-decoration: underline; }

a.onlink:visited,
a.onlink:hover {
  color: #f00; }

#category_title {
  padding-top: 10px; }

#category_title ul#login_guide li {
  /*background-image:url("../img/header/point01.jpg");*/
  font-size: 12px;
  color: #FF9800;
  background-repeat: no-repeat;
  background-position: left center;
  list-style-type: none;
  text-decoration: underline;
  float: left; }

#category_title .categorytext {
  font-size: 12px;
  padding-left: 20px; }

#category_title .cate {
  /*background-image:url("../img/header/point01.jpg");
    background-repeat: no-repeat;
    background-position: left center;*/
  margin-left: 20px;
  padding-left: 15px;
  font-size: 14px;
  color: #FF9800; }

#category_title .cate:before {
  content: "\f144";
  font-family: "Font Awesome 5 Free"; }

#category_title .cate a {
  color: #FF9800;
  text-decoration: underline; }

#category_title .cate_li {
  margin: 5px 0 5px 5px;
  font-size: 13px; }

#category_title .cate_li a {
  font-family: "Hiragino Sans", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Meiryo", "BIZ UDPGothic", "MS Gothic", sans-serif;
  color: #333; }

#category_title .cate_li a:link {
  text-decoration: none; }

#category_title .cate_li a:hover {
  color: #FF9800; }

.forex_blk {
  position: relative;
  margin: 1em 0px 0.5em;
  width: 100%;
  min-height: 5.5em;
  overflow: hidden;
  padding: 0.5em;
  font-size: 1rem;
  color: #000;
  border-radius: 3px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.26);
  z-index: 1; }

.forex_blk span {
  display: block;
  position: absolute;
  bottom: 0.1em;
  right: 0;
  z-index: -1;
  font-size: 250%;
  color: #ffecb3; }

/* ===============================================
▼カテゴリ
=============================================== */
div#category_title .categorypadding {
  padding-top: 10px;
  padding-bottom: 10px; }
  div#category_title .categorypadding ul.sub {
    list-style: none;
    margin: 0;
    padding: 0; }

div#category_title .categorypadding p {
  padding-bottom: 10px; }

div#category_title .categorypadding input {
  padding-bottom: 0px; }

/* ===============================================
▼ガイドリンク
=============================================== */
#guide_area {
  margin-top: 1em;
  border: none; }

#guide_area li {
  margin-bottom: 5px;
  letter-spacing: -0.05em; }

ul.button_like li {
  margin: 0;
  padding: 0 0 1px 0;
  background: url("../img/background/bg_btn_list.jpg") bottom repeat-x; }

ul.button_like li a {
  margin: 0;
  padding: 10px 15px 10px 10px;
  border: 1px solid;
  border-bottom: none;
  border-color: #ccc;
  display: block;
  background: url("../img/icon/ico_arrow_02.gif") no-repeat right;
  text-decoration: none;
  outline: none; }

/* ===============================================
▼ログイン（サイド用）
※ヘッダー用はbloc_alpha.css内に記述
=============================================== */
.bloc_outer div#login_area .bloc_body {
  background-color: #FDFDBD;
  /*padding: 1px 1px 10px 1px;
    border: solid 1px $orange;*/ }

.bloc_outer div#login_area .bloc_body p {
  margin-bottom: 5px; }

.bloc_outer div#login_area .bloc_body .btn {
  text-align: center; }

.bloc_outer #login_area .text {
  padding: 10px 10px 0 10px; }

.bloc_outer .login_area dl.formlist {
  margin-bottom: 10px;
  margin: 0 auto;
  width: 500px; }

.bloc_outer .login_area dl.formlist dt {
  margin-bottom: 3px;
  padding-left: 15px;
  color: #333;
  background: url("../img/icon/ico_arrow_03.gif") no-repeat left;
  width: 120px;
  float: left;
  font-size: 90%; }

.bloc_outer .login_area dl.formlist dd {
  margin-bottom: 5px;
  float: right;
  width: 360px;
  vertical-align: bottom;
  text-align: left; }

.bloc_outer div#login_area .bloc_body .mini {
  margin-top: 5px;
  letter-spacing: -0.01em; }

.bloc_outer ul#login_guide {
  clear: both; }

.bloc_outer ul#login_guide li {
  /*background-image:url("../img/header/point01.jpg");
      background-repeat:no-repeat;
      background-position:left center;*/
  font-size: 12px;
  color: #FF9800;
  list-style-type: none;
  text-decoration: underline;
  float: left;
  clear: both;
  /* IE7用 */
  white-space: nowrap; }

.bloc_outerul #login_guide li a {
  padding-left: 5px;
  /* IE7用 */
  display: inline-block; }

.mb-40 {
  margin-bottom: 40px; }

/* ===============================================
▼検索
=============================================== */
div#search_area .bloc_body {
  padding: 10px; }

div#search_area .bloc_body .btn {
  text-align: center; }

/* ===============================================
▼お買い上げランキング
=============================================== */
#ranking {
  width: 740px;
  height: 155px;
  padding: 0 0 0 0;
  border-style: none; }

#ranking td {
  border-style: none; }

#ranking .rank {
  padding: 1px 0px 0px 1px;
  border-top: solid 1px #ccc;
  border-left: solid 1px #ccc;
  width: 30px; }

#ranking .name {
  font-weight: bold;
  border-top: solid 1px #ccc;
  border-right: solid 1px #ccc;
  overflow: hidden;
  padding: 5px;
  width: 95px; }

#ranking .space {
  width: 10px;
  padding: 0px; }

#ranking .rankimage {
  border-right: solid 1px #ccc;
  border-left: solid 1px #ccc;
  text-align: center;
  vertical-align: middle;
  overflow: hidden;
  padding: 5px;
  width: 125px; }

#ranking .rankimage img {
  width: 100px; }

#ranking .detail {
  border-bottom: solid 1px #ccc;
  border-right: solid 1px #ccc;
  border-left: solid 1px #ccc;
  text-align: center; }

#ranking .detail a {
  color: #FF9800;
  font-size: 14px; }

#ranking .detail a:before {
  content: "\f144";
  font-family: "Font Awesome 5 Free"; }

.product_rcheck_area .rcheck {
  display: flex; }
  .product_rcheck_area .rcheck .rcheck_box {
    width: 140px;
    margin: 0px 5px 10px 0px;
    padding: 0 0 0 0;
    transition: all 250ms;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
    .product_rcheck_area .rcheck .rcheck_box:hover {
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
      transform: translateY(-5px);
      cursor: pointer; }
    .product_rcheck_area .rcheck .rcheck_box .name {
      color: #fff;
      font-weight: bold;
      padding: 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center; }
      .product_rcheck_area .rcheck .rcheck_box .name a {
        color: #fff; }
    .product_rcheck_area .rcheck .rcheck_box .rankimage {
      text-align: center;
      vertical-align: middle;
      overflow: hidden;
      padding: 5px;
      width: 128px;
      min-height: 114px;
      margin: auto; }
      .product_rcheck_area .rcheck .rcheck_box .rankimage img {
        width: 100px;
        height: 100px; }
    .product_rcheck_area .rcheck .rcheck_box .detail {
      text-align: center; }
      .product_rcheck_area .rcheck .rcheck_box .detail a {
        color: #FF9800;
        font-size: 14px;
        text-decoration: underline; }
        .product_rcheck_area .rcheck .rcheck_box .detail a:before {
          font-family: "Font Awesome 5 Free";
          content: "\f144"; }

#product_rcome_area .rcome {
  display: flex; }
  #product_rcome_area .rcome .rcome_box {
    width: 140px;
    margin: 0px 5px 10px 0px;
    padding: 0 0 0 0;
    transition: all 250ms;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
    #product_rcome_area .rcome .rcome_box:hover {
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
      transform: translateY(-5px);
      cursor: pointer; }
    #product_rcome_area .rcome .rcome_box .name {
      color: #fff;
      font-weight: bold;
      padding: 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center; }
      #product_rcome_area .rcome .rcome_box .name a {
        color: #fff; }
    #product_rcome_area .rcome .rcome_box .rankimage {
      text-align: center;
      vertical-align: middle;
      overflow: hidden;
      padding: 5px;
      width: 128px;
      min-height: 114px;
      margin: auto; }
      #product_rcome_area .rcome .rcome_box .rankimage img {
        height: 100px; }
    #product_rcome_area .rcome .rcome_box .detail {
      text-align: center; }
      #product_rcome_area .rcome .rcome_box .detail a {
        color: #FF9800;
        font-size: 14px; }
        #product_rcome_area .rcome .rcome_box .detail a:before {
          content: "\f144";
          font-family: "Font Awesome 5 Free"; }

/* ===============================================
▼バナー
=============================================== */
.bana_area {
  text-align: center; }

.bana_area2 {
  text-align: center;
  padding-bottom: 10px; }

.bana_area2nd img {
  max-width: 200px;
  text-align: center;
  padding-bottom: 10px; }

.bana_area3 {
  text-align: center;
  border: solid 1px #ccc; }

.bana_area3 p {
  font-size: 12px;
  color: #666666;
  text-align: left;
  padding: 5px 5px 5px 5px;
  line-height: 105%; }

/* ===============================================
▼カレンダー
=============================================== */
#calender_area {
  background-color: transparent;
  border: none; }

#calender_area .bloc_body {
  padding: 10px 0;
  background-color: #f1f9fc; }

#calender_area table {
  background: #fff;
  border: none;
  width: 150px;
  margin: 0 auto 5px;
  font-size: 90%; }

#calender_area table td {
  padding: 1px 3px;
  border-top: 1px solid #ccc;
  border-right: none;
  text-align: center; }

#calender_area th {
  padding: 1px 3px;
  background: #fff;
  border: none;
  text-align: center; }

#calender_area table .month {
  margin-bottom: 5px;
  padding-left: 12px;
  background: url("../img/icon/ico_arrow_04.gif") no-repeat left;
  font-size: 120%; }

#calender_area .off {
  color: #f00; }

#calender_area .information {
  margin-left: 10px;
  font-size: 90%; }

/* ===============================================
▼カテゴリヘッダ
=============================================== */
.y_headerlist {
  clear: both;
  height: 190px; }

.y_headerlist a {
  float: left;
  margin: 10px 10px 0 0; }

.y_headerlist a img:hover {
  opacity: 0.8;
  filter: alpha(opacity=80); }

.y_headerlist2 {
  clear: both; }

.y_headerlist2 a {
  float: left;
  margin: 10px 10px 0 0; }

.y_headerlist2 a img:hover {
  opacity: 0.8;
  filter: alpha(opacity=80); }

.b_headerlist {
  clear: both;
  height: 120px; }

.b_headerlist a {
  float: left;
  margin: 8px 8px 0 0; }

.b_headerlist a img:hover {
  opacity: 0.8;
  filter: alpha(opacity=80); }

.t_headerlist {
  clear: both;
  height: 190px; }

.t_headerlist a {
  float: left;
  margin: 10px 12px 0 0; }

.t_headerlist a img:hover {
  opacity: 0.8;
  filter: alpha(opacity=80); }

.t_headerlist2 {
  clear: both; }

.t_headerlist2 a {
  float: left;
  margin: 10px 12px 0 0; }

.t_headerlist2 a img:hover {
  opacity: 0.8;
  filter: alpha(opacity=80); }

.i_headerlist {
  clear: both;
  height: 310px; }

.i_headerlist a {
  float: left;
  margin: 10px 12px 0 0; }

.i_headerlist a img:hover {
  opacity: 0.8;
  filter: alpha(opacity=80); }

.i_headerlist2 {
  clear: both; }

.i_headerlist2 a {
  float: left;
  margin: 10px 12px 0 0; }

.i_headerlist2 a img:hover {
  opacity: 0.8;
  filter: alpha(opacity=80); }

.f_headerlist {
  clear: both;
  height: 20px; }

.mr-5 {
  margin-right: 5px; }

/* ===============================================
▼おすすめ商品
=============================================== */
/*
   tplファイルのマークアップが同じ項目
   メインカラム用 2カラム時 [two_maincolumn_left]
                            [two_maincolumn_right]
   メインカラム用 3カラム時 [three_maincolumn]
   サイドカラム用           [side_column]
   商品詳細のオススメ商品   [whobought_area]
=============================================== */
/* 共通
----------------------------------------------- */
#recommend_area .bloc_body,
#whobought_area .whobought_bloc {
  margin-bottom: 10px;
  padding: 10px 0 10px;
  border: none;
  /*background: url("../img/background/line_dot_01.gif") repeat-x bottom;*/
  background: url(data:image/gif;base64,R0lGODlhBAABAIAAAP///8zMzCH5BAAHAP8ALAAAAAAEAAEAAAIDBBIFADs=) repeat-x bottom; }

#recommend_area .bloc_body p,
#whobought_area .whobought_bloc p {
  margin: 0 0 5px 0; }

#recommend_area .bloc_body img,
#whobought .whobought_bloc img {
  margin: 0 5px 0 0; }

#recommend_area .bloc_body h3,
#whobought_area .whobought_bloc h3 {
  font-size: 100%;
  font-weight: normal; }

/* サイドカラム用 */
.side_column #recommend_area .product_item {
  margin-bottom: 10px; }

/* 画像
----------------------------------------------- */
/* メインカラム用 */
.main_column #recommend_area .bloc_body .productImage,
#whobought_area .whobought_bloc .productImage {
  margin-bottom: 10px;
  float: left;
  width: 90px; }

/* サイドカラム用 */
.side_column #recommend_area .bloc_body .productImage {
  float: none;
  text-align: center;
  width: auto; }

/* 左右の振り分け
----------------------------------------------- */
.main_column #recommend_area .product_item,
#whobought_area .whobought_bloc .whobought_left {
  float: left;
  width: 47.5%; }

.product_outer {
  margin: 15px 0 20px 0; }

.product_outer #product_area .product_list {
  float: left;
  width: 19%;
  border: solid 1px #cccccc;
  margin: 10px 5px 0 0;
  height: 155px;
  overflow: hidden; }

.product_outer #product_area .product_list img {
  float: left; }

.product_outer #product_area .product_list .list a {
  /*background-image:url("../img/header/point01.jpg");
      background-repeat:no-repeat;
      background-position:left center;*/
  font-size: 10px;
  color: #FF9800;
  list-style-type: none;
  float: left;
  margin-left: 10px;
  padding: 5px 0 5px 15px; }

.product_outer #product_area .product_list .list a:before {
  content: "\f144";
  font-family: "Font Awesome 5 Free"; }

.product_outer #product_area .product_list .product_image {
  margin: 0px 10px 0px 20px; }

.product_outer #product_area .product_list .product_name {
  font-weight: bold;
  padding: 5px 10px 10px 35px;
  color: #FF9800; }

#whobought_area .whobought_bloc .whobought_right {
  float: right;
  width: 47.5%; }

/* 商品説明テキスト
----------------------------------------------- */
/* 前回購入*/
.bought_box {
  margin: 5px;
  padding: 10px;
  border: 1px solid #bbd3de;
  border-radius: 4px;
  background-color: #f6f6f6; }

.bought_box_icon {
  float: left;
  width: 30px;
  height: 30px; }

.bought_box_date {
  margin-left: 50px;
  font-size: 1.2em; }

/* メインカラム用 1カラム時*/
#one_maincolumn #recommend_area .bloc_body .productContents,
#two_maincolumn_right #recommend_area .bloc_body .productContents,
#two_maincolumn_left #recommend_area .bloc_body .productContents,
#two_maincolumn_right #whobought_area .whobought_bloc .productContents,
#two_maincolumn_left #whobought_area .whobought_bloc .productContents {
  float: right;
  width: 200px; }

/* メインカラム用 2カラム時*/
/* メインカラム用 3カラム時*/
#three_maincolumn #recommend_area .bloc_body .productContents,
#three_maincolumn #whobought_area .whobought_bloc .productContents {
  float: right;
  width: 67%; }

/* サイドカラム用 */
.side_column #recommend_area .bloc_body .productContents {
  clear: both; }

.mente {
  border: 4px solid #FF0000;
  padding: 10px;
  background-color: #FDFDBD; }

.mente_title {
  font-weight: bold;
  font-size: 16px; }

/* トップページ　お問い合わせ*/
.topimage_2 {
  padding-top: 10px;
  font-size: 1.4em;
  line-height: 1.3;
  color: #333; }
  .topimage_2 img {
    max-width: 100%; }

.contact_infop {
  border-left: 10px solid #FF9800;
  border-bottom: 2px solid #FF9800;
  padding: 5px;
  margin-bottom: 5px; }

.contact_infop strong {
  margin-left: 5px;
  font-size: 1.3em; }

/* 初めての方へ */
.newcomer {
  border: 1px solid #333;
  font-size: 1.5em;
  padding: 25px;
  -webkit-background-size: 30px 30px;
  -moz-background-size: 30px 30px;
  background-size: 30px 30px;
  background-color: #fff;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 158, 67, 0.5)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 158, 67, 0.5)), color-stop(0.75, rgba(255, 158, 67, 0.5)), color-stop(0.75, transparent), to(transparent)), -webkit-gradient(linear, 0 0, 100% 100%, color-stop(0.25, rgba(255, 158, 67, 0.5)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 158, 67, 0.5)), color-stop(0.75, rgba(255, 158, 67, 0.5)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 158, 67, 0.5) 25%, transparent 25%, transparent 50%, rgba(255, 158, 67, 0.5) 50%, rgba(255, 158, 67, 0.5) 75%, transparent 75%, transparent), -webkit-linear-gradient(-45deg, rgba(255, 158, 67, 0.5) 25%, transparent 25%, transparent 50%, rgba(255, 158, 67, 0.5) 50%, rgba(255, 158, 67, 0.5) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 158, 67, 0.5) 25%, transparent 25%, transparent 50%, rgba(255, 158, 67, 0.5) 50%, rgba(255, 158, 67, 0.5) 75%, transparent 75%, transparent), -moz-linear-gradient(-45deg, rgba(255, 158, 67, 0.5) 25%, transparent 25%, transparent 50%, rgba(255, 158, 67, 0.5) 50%, rgba(255, 158, 67, 0.5) 75%, transparent 75%, transparent);
  background-image: -ms-linear-gradient(45deg, rgba(255, 158, 67, 0.5) 25%, transparent 25%, transparent 50%, rgba(255, 158, 67, 0.5) 50%, rgba(255, 158, 67, 0.5) 75%, transparent 75%, transparent), -ms-linear-gradient(-45deg, rgba(255, 158, 67, 0.5) 25%, transparent 25%, transparent 50%, rgba(255, 158, 67, 0.5) 50%, rgba(255, 158, 67, 0.5) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 158, 67, 0.5) 25%, transparent 25%, transparent 50%, rgba(255, 158, 67, 0.5) 50%, rgba(255, 158, 67, 0.5) 75%, transparent 75%, transparent), -o-linear-gradient(-45deg, rgba(255, 158, 67, 0.5) 25%, transparent 25%, transparent 50%, rgba(255, 158, 67, 0.5) 50%, rgba(255, 158, 67, 0.5) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 158, 67, 0.5) 25%, transparent 25%, transparent 50%, rgba(255, 158, 67, 0.5) 50%, rgba(255, 158, 67, 0.5) 75%, transparent 75%, transparent), linear-gradient(-45deg, rgba(255, 158, 67, 0.5) 25%, transparent 25%, transparent 50%, rgba(255, 158, 67, 0.5) 50%, rgba(255, 158, 67, 0.5) 75%, transparent 75%, transparent); }

.newc_text {
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  border-radius: 10px; }

/* ヘッダーお問い合わせ */
.contact_n {
  margin-bottom: 5px;
  height: 20px; }

.header_mailc {
  height: 20px;
  float: left;
  padding: 10px;
  border: 1px solid #333;
  font-size: 14px;
  background-color: #FF9800; }

.header_mailc a {
  color: #fff;
  line-height: 20px; }

.header_phonec {
  height: 20px;
  width: 730px;
  padding: 10px;
  border: 1px solid #333;
  line-height: 20px; }

.phonec_text {
  font-size: 14px;
  float: left;
  margin-top: -7px;
  padding-left: 5px; }

/************************************************
 インヘッダーブロック
************************************************ */
#headerInternalColumn {
  margin-top: 5px;
  float: right;
  width: 520px;
  height: 35px; }

/* ログイン（ヘッダー用）
----------------------------------------------- */
#header_login_area {
  padding: 0 10px;
  border: solid 1px #ffc979;
  height: 30px;
  background: #fef3d3;
  letter-spacing: -0.075em; }

#header_login_area ul.formlist {
  margin-top: 5px; }

#header_login_area ul.formlist li {
  float: left; }

#header_login_area ul.formlist li.mail {
  padding-left: 28px;
  width: 155px;
  background: url("../img/common/ico_arrow_login.gif") no-repeat left;
  font-size: 90%; }

#header_login_area ul.formlist li.password {
  padding-right: 5px; }

#header_login_area ul.formlist li.login_memory {
  padding-right: 5px;
  font-size: 90%; }

#header_login_area ul.formlist li.forgot {
  margin-top: 3px;
  padding-right: 5px;
  font-size: 90%; }

#header_login_area ul.formlist li.btn {
  padding-right: 5px;
  width: 53px; }

#header_login_area p.btn {
  height: 20px;
  padding: 5px 0;
  vertical-align: middle; }

#header_login_area p.btn input[type=image] {
  vertical-align: middle; }

/* ***********************************************
追加ブロック
************************************************ */
/* 共通
----------------------------------------------- */
#container .bloc_outer #banner_area .bloc_body {
  border: none; }

/* 【メイン】バナーエリア_02
----------------------------------------------- */
.main_column .bloc_outer #banner_area .bloc_body ul {
  width: 100%; }

.main_column .bloc_outer #banner_area .bloc_body ul li {
  float: left; }

.main_column .bloc_outer #banner_area .bloc_body ul li.sub_01 {
  padding-right: 8px; }

/* 【サイド】バナーエリア_01
----------------------------------------------- */
/* 【サイド】バナーエリア_02
----------------------------------------------- */
#leftcolumn .bloc_outer #banner_area .bloc_body ul li,
#rightcolumn .bloc_outer #banner_area .bloc_body ul li {
  margin-bottom: 8px; }

/************************************************
 ポップアップウィンドウ
************************************************ */
/* 共通
----------------------------------------------- */
div#windowcolumn {
  border-top: solid 3px #FF9800;
  width: 560px;
  height: 100%;
  margin: 15px 15px 0 15px;
  background: #fff; }

div#windowcolumn h2 {
  margin-bottom: 10px;
  padding: 8px;
  border-top: solid 1px #ebeced;
  color: #f60;
  background: url("../img/background/bg_tit_sub_01.jpg") repeat-x left bottom;
  background-color: #fef3d8;
  font-size: 170%; }

div#window_area {
  margin: 15px auto 0 auto;
  padding-bottom: 20px;
  width: 540px;
  min-height: 300px;
  height: auto !important; }

div#window_area p.information {
  margin-bottom: 20px; }

div#window_area .message {
  margin-bottom: 20px;
  color: #f60;
  line-height: 150%;
  font-weight: bold;
  font-size: 140%; }

div#window_area table {
  width: 540px; }

div#window_area #forgot {
  margin: 0 auto;
  padding: 20px;
  width: 440px;
  border: 1px solid #ccc;
  text-align: left; }

div#window_area #forgot .mailaddres {
  margin-bottom: 10px; }

div#window_area #forgot p {
  text-align: center; }

div#bigimage,
div#cartimage {
  margin-top: 15px;
  background-color: #fff;
  text-align: center; }

div#bigimage img,
div#cartimage img {
  padding: 10px;
  background-color: #fff; }

div#zipsearchcolumn {
  margin: 15px auto 0 auto;
  border-top: 5px solid #ffa85c;
  border-bottom: 5px solid #ffa85c;
  width: 460px;
  background-color: #fff; }

div#zipsearchcolumn h2 {
  margin: 0 0 15px 0;
  width: 460px; }

div#zipsearch_area {
  margin: 15px auto 0 auto;
  width: 460px; }

div#zipsearchcolumn .btn {
  margin: 15px 0 30px 0;
  text-align: center; }

div#zipsearch_area #completebox p {
  padding: 60px 5px;
  text-align: center; }

/* お客様の声の書き込み、新しいお届け先の追加・変更
----------------------------------------------- */
/* 商品詳細拡大写真、カート拡大写真
----------------------------------------------- */
/* 郵便番号検索
----------------------------------------------- */
/************************************************
 各ページコンテンツ用
************************************************ */
/* ==============================================
▼TOP
=============================================== */
/* メインイメージ
----------------------------------------------- */
#main_image {
  margin-bottom: 10px;
  text-align: center; }

/* ==============================================
▼下層
=============================================== */
/* ==============================================
▼ガイド
=============================================== */
/* ◎◎について
----------------------------------------------- */
div#undercolumn_contact {
  margin: 0 auto;
  width: 100%; }

/* 特定商取引法
----------------------------------------------- */
/* お問い合わせ
----------------------------------------------- */
.zipimg img {
  vertical-align: middle; }

/* ==============================================
▼MYページ
=============================================== */
/* 共通設定
----------------------------------------------- */
div#mypagecolumn,
div#mynavi_area,
div#mycontents_area {
  width: 100%; }

div#mynavi_area .mynavi_list {
  margin-bottom: 20px;
  width: 100%; }

div#mynavi_area .mynavi_list li {
  margin: 0 15px 5px 0;
  padding-left: 15px;
  float: left;
  background: url("../img/icon/ico_arrow_01.gif") no-repeat left;
  font-size: 120%; }

div#mynavi_area div.point_announce {
  margin-bottom: 30px;
  padding: 10px;
  border: solid 1px #ffcc62;
  background-color: #fffaf0; }

div#mynavi_area div.point_announce p {
  padding-left: 20px;
  background: url("../img/icon/ico_point.gif") no-repeat left; }

div#mycontents_area p.inforamtion {
  margin-bottom: 20px; }

div#mypagecolumn h4 {
  margin: 10px auto;
  border-bottom: 1px solid #999;
  text-align: left;
  font-size: 120%; }

div#mypagecolumn .flow_title {
  margin: 20px 0px 20px 10px;
  width: 730px; }

div#mypagecolumn .flow_title .flow_img {
  float: left;
  width: 220px;
  padding-top: 10px; }

div#mypagecolumn .flow_title .flow_detail {
  float: left;
  width: 500px;
  line-height: 150%;
  font-weight: bold;
  font-size: 120%;
  margin: 10px 0px 5px 10px; }

div#mypagecolumn .flow_title .flow_detail .minnanokai {
  width: 450px;
  padding: 10px 5px 10px 5px;
  border: 1px dashed #cccccc; }

div#mypagecolumn .ticket_attention {
  width: 730px;
  background-color: #ffdd88;
  font-weight: bold;
  font-size: 110%;
  padding: 10px 10px 10px 10px; }

div#mycontents_area div.mycondition_area {
  margin: 0 auto 20px 0;
  padding: 10px;
  border: solid 1px #ccc;
  width: 97%;
  background: #f9f9f9; }

div#mycontents_area div.mycondition_area p {
  float: left; }

div#mycontents_area div.mycondition_area .btn {
  width: 160px;
  margin-top: 15px;
  float: right; }

/* 購入履歴一覧/詳細
----------------------------------------------- */
.add_address {
  margin-bottom: 20px; }

/* 会員登録内容変更/退会
----------------------------------------------- */
div#mycontents_area .message_area {
  margin: 30px auto;
  padding: 30px;
  border: 1px solid #ccc;
  text-align: center; }

div#mycontents_area .message_area p {
  margin-bottom: 20px; }

div#undercolumn_entry {
  width: 100%; }
  @media only screen and (max-width: 769px) {
    div#undercolumn_entry {
      padding: 0 16px; } }

div#undercolumn_entry .kiyaku_text {
  margin: 20px auto;
  padding: 10px;
  border: solid 1px #ccc;
  width: 94%;
  background: #fff; }

div#undercolumn_login {
  margin: 0 auto;
  width: 100%; }

div#undercolumn_login .login_area {
  margin-bottom: 30px; }

div#undercolumn_login .login_area .inputbox {
  margin: 15px auto 15px auto;
  padding: 15px 20px 10px 20px;
  background: #f0f0f0; }

div#undercolumn_login .login_area .inputbox .btn_area {
  margin-top: 0; }

div#undercolumn_error .message_area {
  margin: 15px auto;
  padding: 15px;
  border: 1px solid #ccc;
  text-align: center;
  background: rgba(255, 255, 255, 0.8) url("/info/wp-content/uploads/bag_bg.png") no-repeat left center;
  background-size: 80px auto;
  min-height: 185px;
  display: flex;
  flex-flow: column;
  justify-content: center; }
  @media only screen and (min-width: 768px) {
    div#undercolumn_error .message_area {
      margin: 30px auto;
      background: rgba(255, 255, 255, 0.8) url("/info/wp-content/uploads/bag_bg.png") no-repeat left center;
      background-size: 150px auto;
      padding: 60px 15px;
      min-height: 350px; } }
  div#undercolumn_error .message_area .error {
    padding: 10px 0;
    font-size: 14px;
    background-color: rgba(255, 255, 255, 0.8); }
    @media only screen and (min-width: 768px) {
      div#undercolumn_error .message_area .error {
        background-color: transparent; } }

div .topimage {
  clear: both;
  /*margin-bottom: 20px;*/
  padding-top: 10px;
  text-align: center; }

/* ==============================================
▼会員登録
=============================================== */
/* ==============================================
▼ログイン
=============================================== */
/* ==============================================
▼エラー
=============================================== */
/* ==============================================
▼トップ画面
=============================================== */
#spr_bana_top li {
  display: inline-block; }

#spr_bana_top a {
  width: 720px;
  height: 275px;
  float: left;
  overflow: hidden; }

#spr_bana_top a img {
  border: none;
  width: 720px; }

.sprite-z {
  margin-top: 0px; }

.sprite-s {
  margin-top: -325px; }

.sprite-t {
  margin-top: -650px; }

div .topimage_left {
  float: left;
  width: 55%;
  padding: 0px 10px 0px 0px; }

div .topimage_left .textarea {
  padding: 20px 0px 0px 10px; }

div .topimage_left .imagearea {
  float: left;
  margin-top: 20px; }

div .topimage_left .imagearea table {
  clear: both;
  border-style: none; }

div .topimage_left .imagearea table td {
  border-style: none;
  padding: 0 15px 0 0; }

div .topimage_right {
  float: left;
  width: 40%; }

div .topimage_left2 img {
  clear: both;
  float: left;
  padding: 0px 5px 0px 0; }

div .topimage_right2 img {
  float: left;
  padding: 0px 0 0px 0; }

/* ==============================================
▼商品一覧
=============================================== */
/* ページ送り
----------------------------------------------- */
.pagenumber_area {
  padding-bottom: 10px;
  /*background: url("../img/background/line_dot_01.gif") repeat-x bottom ;*/
  background: url(data:image/gif;base64,R0lGODlhBAABAIAAAP///8zMzCH5BAAHAP8ALAAAAAAEAAEAAAIDBBIFADs=) repeat-x bottom; }

.pagecond_area {
  margin-bottom: 20px;
  padding: 10px; }

.pagenumber_area {
  margin: 20px 0; }

.pagenumber_area li {
  float: left;
  padding-right: 10px; }

.pagecond_area {
  border: 1px solid #ccc; }

.pagenumber_area .navi {
  width: 100%;
  text-align: center; }

.pagenumber_area .navi a {
  color: #FF9800; }

.pagenumber_area .navi li {
  display: inline; }

.pagenumber_area .change {
  float: right;
  text-align: right;
  white-space: nowrap; }

/* レイアウト
----------------------------------------------- */
div.list_area {
  padding: 0 0 30px 0;
  width: 100%;
  overflow: auto; }

@media only screen and (min-width: 768px) {
  .product_list {
    display: flex;
    flex-wrap: wrap; } }

.product_list .list_wrap {
  margin-bottom: 10px; }
  @media only screen and (min-width: 768px) {
    .product_list .list_wrap {
      width: calc(50% - 5px);
      margin: 0 10px 10px 0; }
      .product_list .list_wrap--even {
        margin-right: 0; } }

.product_list .additional-banner {
  margin: 5px 0 15px !important; }

div.list_area_left,
div.list_area_right {
  padding: 0 0 30px 0;
  width: 100%;
  overflow: auto;
  float: left; }

div.listphoto {
  float: left; }

/* 商品一覧用ブロック */
#bloc_title {
  background-color: #FDDFA1; }

.list_products {
  background-color: #FDDFA1;
  border: solid 1px #E89B3F; }

td.bloc_t1 {
  text-align: center;
  width: 20px;
  border: solid 1px #E89B3F;
  padding: 0px; }

td.bloc_t2 {
  text-align: center;
  border: solid 1px #E89B3F;
  width: 80px;
  padding: 0px; }

td.bloc_t3 {
  text-align: center;
  border: solid 1px #E89B3F;
  padding: 0px;
  width: 80px; }

td.bloc_t4 {
  text-align: center;
  border: solid 1px #E89B3F;
  padding: 0px;
  width: 50px; }

td.bloc_t5 {
  text-align: center;
  border: solid 1px #E89B3F;
  padding: 0px;
  width: 480px; }

td.bloc_l1 {
  text-align: center;
  border: solid 1px #E89B3F;
  padding: 0px; }

td.bloc_l2 {
  text-align: center;
  word-wrap: break-word;
  border: solid 1px #E89B3F;
  width: 80px;
  padding: 0px;
  overflow: hidden; }

td.bloc_l2 a {
  color: #666666;
  font-weight: bold; }

td.bloc_l2 a:hover {
  text-decoration: underline; }

td.bloc_l2 .link_title {
  width: 80px; }

td.bloc_l3 {
  text-align: center;
  border: solid 1px #E89B3F;
  padding: 0px;
  word-wrap: break-word;
  overflow: hidden; }

td.bloc_l4 {
  text-align: center;
  border: solid 1px #E89B3F;
  word-wrap: break-word;
  padding: 0px;
  overflow: hidden; }

td.bloc_l5 {
  text-align: left;
  border: solid 1px #E89B3F;
  padding: 0px 0px 0px 1px;
  padding: 0 1px 1px 1px;
  overflow: hidden; }

td.bloc_l5 img {
  margin: 1px 1px 1px 0; }

.list_products .bloc_list1 {
  background-color: #FDFDBD;
  height: 82px; }

.list_products .bloc_list2 {
  background-color: #FDDFA1;
  height: 82px; }

/* お買い上げランキング */
#ranking_area {
  clear: both;
  border: solid 1px #cccccc; }

#ranking_area .bloc_body {
  clear: both;
  border: medium none; }

/* トップページアイコン */
@media only screen and (min-width: 768px) {
  .icontop_flex {
    display: flex;
    justify-content: space-between; } }

@media only screen and (min-width: 768px) {
  .icontop_flex__right, .icontop_flex__left {
    width: 49%; } }

.icontop_flex .h2_title {
  margin-bottom: 20px; }

.icontop_flex ul {
  list-style: none;
  margin: 0 10px;
  padding: 0; }
  @media only screen and (min-width: 768px) {
    .icontop_flex ul {
      margin: 0; } }
  .icontop_flex ul > li {
    margin-bottom: 20px; }

.icontop_flex .icontop_contents_flex {
  display: flex; }
  .icontop_flex .icontop_contents_flex__left {
    width: 59px; }
  .icontop_flex .icontop_contents_flex__right {
    padding-left: 5px;
    width: calc(100% - 59px); }
  .icontop_flex .icontop_contents_flex .title a {
    font-size: 16px;
    color: #FF9800;
    font-weight: bold; }

table#icontop {
  border-style: none;
  margin-top: 0; }

table#icontop td {
  padding: 0 0 10px 0;
  border-style: none; }

table#icontop td img {
  float: left; }

table#icontop .text {
  height: 50px;
  margin: 5px 8px 0 65px; }

table#icontop .text a {
  font-size: 12px;
  color: #333;
  line-height: 150%;
  font-weight: normal; }

table#icontop .title a {
  padding: 0px 10px 0px 0px;
  margin: 0 0 5px 5px;
  font-size: 16px;
  color: #FF9800;
  font-weight: bold; }

table#iconlist {
  border-style: none;
  padding: 0px; }

table#iconlist th {
  background-color: #ffffff;
  border-style: none;
  padding: 5px 5px 0 0; }

table#iconlist td {
  background-color: #ffffff;
  border-style: none;
  padding: 5px 5px 0px 0px; }

/* 商品一覧アイコン */
/* メインカラム用 1カラム時*/
#one_maincolumn div.listrightbloc {
  float: right;
  width: 40%; }

/* メインカラム用 2カラム時*/
#two_maincolumn_right div.listrightbloc,
#two_maincolumn_left div.listrightbloc {
  float: left;
  /*width: 45%;*/
  padding: 10px 0 0 0px; }

/* メインカラム用 3カラム時*/
#three_maincolumn div.listrightbloc {
  float: right;
  width: 74%; }

.text a,
.rank a {
  text-decoration: none; }

/* 商品情報 各種設定
----------------------------------------------- */
/* 商品ステータス */
div.listrightbloc ul.status_icon {
  margin-bottom: 10px;
  width: 100%; }

div.listrightbloc ul.status_icon li {
  margin-right: 5px;
  float: left; }

div.listrightbloc h3 {
  font-weight: bold;
  font-size: 120%; }

div.listrightbloc .listcomment {
  margin: 0 0 10px 0;
  text-align: left; }

div.listrightbloc .detail_btn {
  margin-bottom: 20px; }

div.listrightbloc .pricebox {
  margin: 0 0 10px 0; }

div.listrightbloc .cart_area {
  padding: 10px;
  border: 1px solid #cef0f4;
  background-color: #ecf5ff;
  width: 94%; }

/* 商品名 */
/* コメント */
/* 商品詳細を見る */
/* 価格 */
/* 買い物かご */
.order_number {
  margin: 0px 0 0 0px; }

.order_number .quantity {
  width: 380px; }

.order_number .quantity .set {
  margin: 10px 10px 10px 0px;
  float: left; }

.order_number .quantity .set2 {
  margin: 10px 10px 10px 0px;
  font-size: 1.2rem;
  font-weight: bold; }

.order_kind {
  margin: 10px 0 0 50px; }

.set_order {
  margin: 10px 0 0 0px;
  clear: both;
  text-align: center; }

.set_order2 {
  margin: 5px 0px 0px 50px;
  clear: both; }

.set_orderlist {
  margin: 0 0 10px 0; }

.set_orderlist li a {
  /*background-image:url("../img/header/point01.jpg");*/
  font-size: 12px;
  color: #FF9800;
  background-repeat: no-repeat;
  background-position: left center;
  list-style-type: none;
  text-decoration: underline;
  padding: 0px 0 0px 20px; }

.set_orderlist li a:before {
  content: "\f144";
  font-family: "Font Awesome 5 Free"; }

.set_present {
  margin: 5px 0 10px 10px; }

/* 規格 */
div.listrightbloc .classlist {
  margin-bottom: 10px;
  padding-bottom: 10px;
  background: url("../img/background/line_dot_02.gif") repeat-x bottom; }

div.listrightbloc dl {
  width: 100%; }

div.listrightbloc dt {
  display: inline-block;
  vertical-align: top; }

div.listrightbloc dd {
  padding-bottom: 10px;
  display: inline-block; }

div.listrightbloc dd p.attention {
  margin-top: 5px; }

div.listrightbloc .cartin {
  margin: 0;
  float: right; }

div.listrightbloc .cartin .quantity {
  padding: 3px 10px 0 0;
  width: 150px;
  float: left;
  text-align: right; }

div.listrightbloc .cartin .quantity .box {
  width: 70px; }

div.listrightbloc .cartin_btn {
  width: 160px;
  float: left; }

/* カゴに入れる */
/* ==============================================
▼商品詳細
=============================================== */
/* レイアウト

    tplファイルのマークアップが同じ項目
    *1カラム時 [one_maincolumn]
    *2カラム時 [two_maincolumn_left]
               [two_maincolumn_right]
    *3カラム時 [three_maincolumn]

----------------------------------------------- */
#detailarea,
.sub_area {
  margin-bottom: 20px;
  width: 100%; }

/* レイアウト
----------------------------------------------- */
/* 1カラム用 */
#one_maincolumn div#detailphotobloc {
  width: 37%;
  float: left; }

#one_maincolumn #detailrightbloc {
  width: 63%;
  float: right; }

#one_maincolumn div.subtext {
  margin-bottom: 20px;
  float: left;
  width: 69%; }

#one_maincolumn div.subphotoimg {
  float: right;
  width: 25%;
  text-align: right; }

#one_maincolumn p.subtext {
  margin-bottom: 20px; }

/* 2カラム用 */
#two_maincolumn_left div#detailphotobloc {
  width: 50%;
  float: left; }

#two_maincolumn_right div#detailphotobloc {
  width: 50%;
  float: left; }

#two_maincolumn_right div#detailphotobloc .detail_menu {
  /*height: 45px;*/
  text-align: center; }

.detail_photo {
  font-size: 12px; }
  .detail_photo .mainphoto {
    text-align: center; }
  .detail_photo .photolist {
    display: flex;
    flex-wrap: wrap; }
    .detail_photo .photolist img {
      padding: 0px 0px 0px 0px;
      margin: 10px 10px 0 0;
      border: 1px solid #cccccc; }

#two_maincolumn_left #detailrightbloc,
#two_maincolumn_right #detailrightbloc {
  float: right;
  width: 50%; }

#two_maincolumn_left div.subtext,
#two_maincolumn_right div.subtext {
  margin-bottom: 20px;
  float: left;
  width: 73%; }

#two_maincolumn_left p.subtext,
#two_maincolumn_right p.subtext {
  margin-bottom: 20px; }

#two_maincolumn_left div.subphotoimg,
#two_maincolumn_right div.subphotoimg {
  float: right;
  width: 25%;
  text-align: right; }

/* 3カラム用 */
#three_maincolumn div#detailphotobloc {
  float: left;
  width: 49%; }

#three_maincolumn #detailrightbloc {
  float: right;
  width: 50%; }

#three_maincolumn div.subtext {
  margin-bottom: 20px;
  float: left;
  width: 63%; }

#three_maincolumn p.subtext {
  margin-bottom: 20px; }

#three_maincolumn div.subphotoimg {
  float: right;
  width: 35%;
  text-align: right; }

/* 商品情報 各種設定
----------------------------------------------- */
.product_hint {
  margin-bottom: 5px;
  padding: 3px;
  background: #FFF176;
  display: inline-block;
  border-radius: 4px;
  font-size: 14px; }

#detailrightbloc h2 {
  margin: 0 0 10px 0;
  padding: 0 0 15px 0;
  color: #666;
  /*background: url("../img/background/line_dot_01.gif") repeat-x bottom ;*/
  background: url(data:image/gif;base64,R0lGODlhBAABAIAAAP///8zMzCH5BAAHAP8ALAAAAAAEAAEAAAIDBBIFADs=) repeat-x bottom;
  font-weight: bold;
  font-size: 160%; }

#detailrightbloc .point,
#detailrightbloc .relative_cat {
  margin: 0 0 10px 0;
  padding: 0 0 10px 0;
  /*background: url("../img/background/line_dot_01.gif") repeat-x bottom ;*/
  background: url(data:image/gif;base64,R0lGODlhBAABAIAAAP///8zMzCH5BAAHAP8ALAAAAAAEAAEAAAIDBBIFADs=) repeat-x bottom; }

#detailrightbloc .main_comment {
  margin: 10px 10px 10px 20px;
  width: 350px; }

#detailrightbloc .main_comment ul {
  list-style: disc inside none; }

#detailrightbloc .main_comment ul li {
  list-style: disc inside none; }

#detailrightbloc .main_comment a {
  color: #FF9800;
  /*&:not(.nobr):after {
        content: "\A";
        white-space: pre;
      }*/ }

#detailrightbloc .main_comment a:hover {
  text-decoration: underline; }

.iconlist {
  margin: 5px 0 10px;
  width: 100%;
  max-width: 100%;
  /*height: 180px;*/
  height: auto;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap; }
  .iconlist__item {
    margin-right: 10px;
    margin-bottom: 10px;
    width: calc(100% / 4 - 8px); }
    .iconlist__item:nth-of-type(4n) {
      margin-right: 0; }
  .iconlist a {
    text-decoration: none; }
  .iconlist img {
    width: 100%;
    height: auto; }

#other_area {
  clear: both;
  margin-top: 20px;
  margin-bottom: 90px; }

#order {
  float: left;
  padding: 10px 0 10px 0px; }

/* 商品コード */
#detailrightbloc .product_code dt,
#detailrightbloc .product_code dd {
  display: inline; }

#detailrightbloc ul.status_icon {
  margin-bottom: 10px;
  width: 100%; }

#detailrightbloc ul.status_icon li {
  margin-right: 5px;
  margin-bottom: 3px;
  float: left; }

#detailrightbloc .normal_price dt,
#detailrightbloc .normal_price dd {
  display: inline; }

#detailrightbloc .sale_price dt,
#detailrightbloc .sale_price dd {
  display: inline; }

#detailrightbloc .point dt,
#detailrightbloc .point dd {
  display: inline; }

#detailrightbloc div.classlist {
  margin-bottom: 10px;
  padding-bottom: 10px;
  width: 100%;
  background: url("../img/background/line_dot_02.gif") repeat-x bottom; }

#detailrightbloc .classlist {
  margin-bottom: 5px; }

#detailrightbloc ul {
  margin-bottom: 10px;
  width: 100%; }

#detailrightbloc ul li {
  vertical-align: top;
  float: left; }

#detailrightbloc .maker dt,
#detailrightbloc .maker dd {
  display: inline; }

#detailrightbloc .comment1 dt,
#detailrightbloc .comment1 dd {
  display: inline; }

#detailrightbloc .relative_cat dd {
  margin-left: 1em; }

#detailrightbloc .cart_area {
  padding: 10px;
  background-color: #ecf5ff;
  border: 1px solid #cef0f4; }

#detailrightbloc .quantity dt,
#detailrightbloc .quantity dd {
  display: inline; }

#detailrightbloc .cartin,
#detailrightbloc .cartin_btn {
  text-align: center; }

#detailrightbloc .favorite_btn {
  text-align: center;
  margin-top: 10px; }

/* 商品ステータス */
/* 通常価格 */
/* 販売価格 */
/* ポイント */
/* 規格 */
/* メーカー */
/* メーカーURL */
/* 関連カテゴリ */
/* 買い物かご */
/* 詳細ページ支払い方法など */
#footguide {
  margin: 0px auto 15px;
  width: 100%;
  text-align: left;
  font-size: 12px; }
  #footguide a {
    text-decoration: underline; }

@media only screen and (min-width: 768px) {
  .foot_flex {
    display: flex;
    justify-content: space-between; } }

@media only screen and (min-width: 768px) {
  .foot_flex__right, .foot_flex__left {
    width: 49%; } }

#footguide dt {
  font-size: 14px;
  border-left: 16px solid #FF9800;
  background: #FFEDA9;
  clear: both;
  font-weight: bold;
  padding: 5px 10px; }

#footguide dd {
  padding: 10px 10px 0px; }
  @media only screen and (min-width: 768px) {
    #footguide dd {
      padding: 3px 0px 0px 0px; } }

#footguide .kwsk {
  margin: 0px 0px 15px;
  padding: 3px 0px 0px 10px;
  text-align: right; }

#footguide .kwsk a {
  padding: 0px 0px 0px 15px; }

/* お客様の声
----------------------------------------------- */
/* 商品情報にそのまま流用したせいで面倒なことに */
div#itemdetail_area_wrap {
  margin-bottom: 10px;
  clear: both;
  font-size: 16px; }

div#itemdetail_area {
  clear: both;
  padding: 20px 5px 0px;
  /*h2 {
      margin-bottom: 20px;
      padding: 6px 0 8px 10px;
      border-top: solid 1px $orange;
      background: url('../img/background/bg_tit_sub_01.jpg') repeat-x left bottom;
    }*/ }

div#itemdetail_area .customer_left {
  width: 50%;
  float: left;
  margin-bottom: 20px; }

div#itemdetail_area ul {
  list-style-type: disc;
  padding-left: 15px; }

div#itemdetail_area ul li {
  list-style-type: disc; }

div#itemdetail_area .customer_right {
  width: 50%;
  float: left;
  /*padding-left: 15px;*/ }

div#itemdetail_area .customer_bottom {
  clear: both;
  height: 60px; }

div#itemdetail_area .customer_bottom img {
  float: left;
  padding-right: 5px; }

div#itemdetail_area .review_bloc {
  margin-bottom: 20px;
  padding: 10px;
  font-size: 1rem;
  background-color: #f6f6f6; }

div#itemdetail_area .review_bloc p {
  padding-top: 3px;
  margin-right: 10px; }

div#itemdetail_area review_bloc .review_btn {
  float: right;
  width: 160px; }

div#itemdetail_area .voicetitle {
  margin-bottom: 5px;
  color: #333;
  font-weight: bold; }

div#itemdetail_area .voicedate {
  margin-bottom: 10px; }

div#whobought_area {
  clear: both;
  /*padding: 35px 0 0 0;*/ }

div#whobought_area a:hover {
  text-decoration: underline; }

div#customervoice_area_wrap {
  margin-bottom: 10px;
  clear: both;
  font-size: 14px; }

div#customervoice_area {
  clear: both;
  padding: 20px 5px 0px;
  /*h2 {
      margin-bottom: 20px;
      padding: 6px 0 8px 10px;
      border-top: solid 1px $orange;
      background: url('../img/background/bg_tit_sub_01.jpg') repeat-x left bottom;
    }*/ }

div#customervoice_area .customer_left {
  width: 50%;
  float: left;
  margin-bottom: 20px; }

div#customervoice_area ul {
  list-style-type: disc; }

div#customervoice_area ul li {
  list-style-type: disc;
  margin: 10px 0px 10px 0px; }

div#customervoice_area .customer_right {
  width: 50%;
  float: left;
  /*padding-left: 15px;*/ }

div#customervoice_area .customer_bottom {
  clear: both;
  height: 60px; }

div#customervoice_area .customer_bottom img {
  float: left;
  padding-right: 5px; }

div#customervoice_area .review_bloc {
  /*margin-bottom: 20px;*/
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f6f6f6;
  font-size: 12px; }

div#customervoice_area .review_bloc p {
  padding-top: 3px;
  margin-right: 10px;
  float: left; }

div#customervoice_area review_bloc .review_btn {
  float: right;
  width: 160px; }

div#customervoice_area .voicetitle {
  margin-bottom: 5px;
  color: #333;
  font-weight: bold; }

div#customervoice_area .voicedate {
  margin-bottom: 10px; }

div#customervoice_area .voicecomment {
  position: relative;
  display: inline-block;
  margin-top: 18px;
  padding: 15px;
  width: auto;
  min-width: 115px;
  /*height: 50px;*/
  color: #333;
  line-height: 20px;
  text-align: left;
  background: #e0e0e0;
  border-radius: 20px;
  z-index: 0; }

div#customervoice_area .voicecomment:before {
  content: "";
  position: absolute;
  top: -17px;
  left: 15px;
  margin-right: 0;
  display: block;
  width: 15px;
  height: 15px;
  background: #e0e0e0;
  border-radius: 50%;
  z-index: -1; }

div#customervoice_area .voicecomment:after {
  content: "";
  position: absolute;
  top: -25px;
  left: 25px;
  margin-right: 0;
  display: block;
  width: 8px;
  height: 8px;
  background: #e0e0e0;
  border-radius: 50%;
  z-index: -1; }

div#whobought_area {
  clear: both;
  /*padding: 35px 0 0 0;*/ }

div#whobought_area a:hover {
  text-decoration: underline; }

div#customervoice_area #titulos_waku {
  border: #999 1px solid;
  border-left: #999 10px solid;
  padding: 5px;
  background-color: #F7F7F7;
  margin-bottom: 15px;
  min-height: 28px; }

div#customervoice_area #titulos_waku .titulos {
  float: left;
  padding: 5px; }

div#customervoice_area .titulos li {
  display: inline;
  background: none !important; }

div#customervoice_area .titulos a {
  padding: 5px;
  border: #999 1px solid;
  background-color: #FFF;
  cursor: pointer;
  color: #69C; }

div#customervoice_area .titulos a:hover {
  color: #F30; }

div#customervoice_area .titulos a.activo {
  background-color: #eee;
  color: #000; }

/* 関連商品（商品部分はbloc.cssのおすすめ商品と共通）
----------------------------------------------- */
/*div#whobought_area h2 {
    border-top: solid 1px $orange;
    background: url('../img/background/bg_tit_sub_01.jpg') repeat-x left bottom;
    padding: 5px 0 8px 10px;
    font-size: 14px;
}*/
.photo img {
  border: 2px solid #eee; }

#whobought_area .areabloc {
  float: left;
  width: 200px;
  height: 260px;
  margin: 0 auto;
  text-align: center;
  padding: 5px; }

/* ***********************************************
▼カートの中
/*********************************************** */
/* 現在のカゴの中
----------------------------------------------- */
div#undercolumn_cart .point_announce {
  padding: 20px;
  margin-bottom: 20px;
  border: solid 1px #ffcc62;
  background: #fffaf0;
  font-size: 120%;
  text-align: center;
  line-height: 140%; }

div#undercolumn_cart .totalmoney_area {
  margin-bottom: 20px; }

div#undercolumn_cart p {
  margin: 10px 5px; }

div#undercolumn ul#quantity_level li {
  padding: 3px;
  display: inline; }

div#undercolumn .empty {
  text-align: left; }

div.form_area {
  margin-bottom: 30px; }

/* お客様情報入力
----------------------------------------------- */
#undercolumn_shopping {
  font-size: 1rem; }

.white-block {
  width: 98%;
  padding: 1rem 0.8rem;
  margin: 1rem 0;
  border-radius: 0.8rem; }

.payment-block {
  width: 98%;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 0.8rem;
  padding: 0.5rem;
  margin-bottom: 1rem !important;
  box-sizing: border-box; }

.payment-block p {
  margin: 1rem;
  line-height: 1.5; }

.payment-block img {
  max-width: 95%; }

.payment_announce {
  background: #fafafa;
  border: 1px solid #ccc;
  border-radius: 0.4rem;
  margin: 1rem 0.2rem; }

.payment-alert {
  background: #FFEBEE; }

.confirm_after_pay {
  text-align: left;
  width: 30rem;
  margin: 0 auto; }

.flow_area {
  margin: 0 0 20px 0; }

div#undercolumn_customer th em {
  color: #000;
  font-weight: bold; }

div#undercolumn_shopping .pay_area02 .txtarea {
  margin: 5px 0 0 0;
  padding: 2px;
  border: 1px solid #ccc;
  border-radius: 0.4rem;
  width: 99%;
  height: 150px; }

div#after_pay {
  border: 4px solid #999999;
  background-color: #FDFDBD;
  padding: 10px;
  margin: 30px auto 30px auto; }

div#after_pay .title {
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  margin: 10px 0 20px 0; }

div#after_pay .accept_title {
  font-weight: bold;
  font-size: 22px;
  color: #ff0000; }

div#after_pay .accept_area {
  text-align: center; }

div#undercolumn_shopping .pay_area02 .select-msg {
  margin-bottom: 10px; }

div#undercolumn_shopping .point_area .point_announce {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 0.4rem; }

div#undercolumn_shopping .point_area p {
  margin-bottom: 20px; }

div#undercolumn_shopping .point_area .point_announce li {
  margin-bottom: 5px; }

#show-recept {
  display: none; }

#clk-recept:hover {
  cursor: pointer; }

/* 住所自動入力 */
.zipimg {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  padding: 5px; }

.zipimg a {
  text-decoration: none;
  color: #333; }

.zipimg:active {
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.35); }

.pay_area td,
.pay_area label {
  cursor: pointer; }

label,
input[type='checkbox'],
input[type='radio'] {
  cursor: pointer; }

.table_shop td {
  background: transparent; }

.table_shop tr,
.table_shop tr td {
  transition: all 0.3s; }

.table_shop tr:hover,
.table_shop tr:hover td {
  background-color: #E0F2F1; }

.card {
  position: relative; }

.card_link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: transparent; }

/* お支払い方法・お届け時間等の指定
----------------------------------------------- */
/* お届け先の指定
----------------------------------------------- */
#address_area {
  margin-bottom: 10px;
  width: 100%; }

#address_area .information {
  width: 65%;
  float: left; }

#address_area .information2 {
  width: 90%;
  float: left; }

#undercolumn_shopping .information {
  margin-bottom: 15px; }

#address_area .add_multiple {
  padding: 15px 10px;
  border: 1px solid #ffcc62;
  float: right;
  width: 30%;
  color: #555;
  background: #fffaf0;
  text-align: center;
  font-weight: bold; }

#address_area .add_multiple p {
  margin-bottom: 10px; }

#address_area p.addbtn {
  font-weight: bold;
  font-size: 10px; }

.otheraddr {
  display: none; }

#payblock_3 label:before,
#payblock_5 label:before,
#payblock_12 label:before,
#payblock_13 label:before,
#payblock_4 label:before,
#payblock_6 label:before,
#payblock_7 label:before,
#payblock_8 label:before,
#payblock_9 label:before,
#payblock_10 label:before,
#payblock_11 label:before {
  font-family: "Font Awesome 5 Free";
  font-size: 1.4rem;
  color: #2196F3;
  vertical-align: middle;
  display: inline-block;
  min-width: 2rem;
  text-align: center; }

/* クレジットカード */
#payblock_5 label:before {
  content: "\f283"; }

/* コンビニ */
#payblock_12 label:before {
  content: "\f0f7"; }

/* 銀行前払い */
#payblock_3 label:before {
  content: "\f19c"; }

/* 後払い */
#payblock_13 label:before {
  content: "\f0d1"; }

/* 代引き */
#payblock_4 label:before,
#payblock_6 label:before,
#payblock_7 label:before,
#payblock_8 label:before,
#payblock_9 label:before,
#payblock_10 label:before,
#payblock_11 label:before {
  content: "\f157"; }

/* ==============================================
▼検索結果
=============================================== */
p.condition_area {
  margin: 0 auto;
  padding: 5px;
  border: solid 1px #333;
  width: 566px; }

/************************************************
 tables
************************************************ */
/* デフォルトテーブル
----------------------------------------------- */
table {
  margin: 15px auto 20px auto;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  width: 100%;
  border-collapse: collapse;
  text-align: left; }

table th {
  padding: 8px;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  color: #333;
  background-color: #f0f0f0;
  font-weight: normal; }

table td {
  padding: 8px;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  background: #fff; }

table .alignC img {
  width: 110px; }

table .alignC .expansion img {
  max-width: 110px;
  max-height: 160px; }

.search_table {
  margin: 15px auto 20px auto;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  width: 100%;
  border-collapse: collapse;
  text-align: left; }
  @media only screen and (max-width: 769px) {
    .search_table {
      display: block; } }
  .search_table tr {
    clear: both; }
    @media only screen and (max-width: 769px) {
      .search_table tr {
        display: block; } }
  .search_table th {
    padding: 8px;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    background-color: #FDDFA1;
    border: solid 1px #FF9900; }
    @media only screen and (max-width: 769px) {
      .search_table th {
        display: block; } }
    @media only screen and (min-width: 768px) {
      .search_table th {
        min-width: 125px; } }
  .search_table td {
    border: solid 1px #EDD398; }

.tooltip_img {
  float: left;
  margin-right: 5px; }

.twoc_dl dt {
  float: left;
  width: 17rem; }

.threec_dl dt {
  float: left;
  width: 14rem; }

#search_form_lite .threec_dl label {
  display: block; }

#search_lite_button_area {
  margin-bottom: 10px; }

.btn_area-search > ul {
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 0; }

.btn_area-search a {
  display: inline-block; }

.tooltipster-icon {
  font-family: "Font Awesome 5 Free";
  font-size: 16px;
  vertical-align: middle; }

.list_wrap table.category_table {
  border-collapse: collapse;
  text-align: center;
  margin: 0 0 0 0;
  border: solid 1px #FF9900;
  width: 100%;
  /*overflow: visible;*/ }

.list_wrap table.category_table td div.category_list {
  width: 150px;
  /*height: 240px;
    overflow:hidden;*/
  padding: 0;
  margin: 0;
  text-align: center; }

.category_table td {
  padding: 0px 0px 0 0px;
  border: solid 1px #FF9900;
  position: relative;
  background: none;
  background-clip: padding-box; }

.category_table th {
  background-color: #FDDFA1;
  padding: 0 0 0 0px;
  border: solid 1px #FF9900;
  width: 19%; }

.category_table .cno_title {
  /*height: 25px;
    width: 70px;*/
  text-align: center;
  vertical-align: middle;
  padding-top: 5px;
  font-size: 1rem; }

.category_table .cno_text {
  /*height: 25px;
    width: 110px;
    overflow:hidden;*/
  text-align: center;
  font-weight: bold;
  vertical-align: middle;
  padding-top: 5px;
  font-size: 1rem; }

.category_table .h25_title {
  /*width: 70px;*/
  text-align: center;
  vertical-align: middle;
  padding-top: 5px;
  font-size: 1rem; }

.category_table .h25_text {
  /*width: 110px;*/
  overflow: hidden;
  text-align: center;
  font-weight: bold;
  vertical-align: middle;
  padding-top: 5px;
  word-wrap: break-word;
  font-size: 1rem;
  padding: 3px; }

.category_table .comment_text {
  /*width: 175px;
    height: 70px;*/
  padding: 5px 5px 0px 5px;
  overflow: hidden;
  font-size: 1rem;
  text-align: left; }

.category_table .s_btn {
  text-align: center;
  padding: 10px 0 10px 0; }
  .category_table .s_btn a {
    text-decoration: none; }

/* 商品詳細
----------------------------------------------- */
.detail_table {
  border-collapse: collapse;
  text-align: center;
  font-size: 1rem;
  margin: 20px 0 10px 0;
  border: solid 1px #FF9900;
  width: 355px;
  word-wrap: break-word;
  table-layout: fixed; }

.detail_table td {
  padding: 5px 0 0 10px;
  border: solid 1px #FF9900;
  text-align: center; }

.detail_table th {
  background-color: #FDDFA1;
  padding: 0 0 0 5px;
  border: solid 1px #FF9900;
  text-align: center;
  width: 100px; }

.detail_table2 {
  border-collapse: collapse;
  text-align: center;
  margin: 0px 0 0px 0;
  border: solid 1px #FF9900;
  width: 355px; }

.detail_table2 td {
  padding: 5px 0 0 5px;
  border: solid 1px #FF9900;
  text-align: center; }

.detail_table2 th {
  background-color: #FDDFA1;
  padding: 0 0 0 5px;
  border: solid 1px #FF9900;
  text-align: center;
  width: 100px; }

/* 見出し
----------------------------------------------- */
div#undercolumn_shopping table th[scope=col] {
  text-align: center; }

div#undercolumn_shopping table.delivname th {
  width: 155px; }

div#mycontents_area table th {
  text-align: left; }

div#mycontents_area table th.alignR {
  text-align: right; }

div#mycontents_area table th.alignL {
  text-align: left; }

div#mycontents_area table th.alignC {
  text-align: center; }

div#mycontents_area table th.resulttd {
  text-align: right; }

div#mycontents_area table caption {
  padding: 8px;
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  color: #000;
  background-color: #f0f0f0;
  text-align: left;
  font-weight: bold; }

/* MYページ */
/* その他
----------------------------------------------- */
table select {
  margin-bottom: 7px;
  border: solid 1px #ccc; }

table .change select {
  margin-bottom: 0px;
  border: solid 1px #ccc; }

.change_table {
  margin-top: 3px; }

.change_text {
  font-size: 14px; }

.change_bold {
  font-weight: bold;
  color: #ff0000; }

/* 買い物カゴ内送料表
----------------------------------------------- */
#pay-table {
  width: 60%;
  background: none repeat scroll 0 0 #F6D288;
  border: 1px solid #E89B3F;
  border-collapse: collapse;
  margin: 10px 18px 20px 0px; }

#pay-table th {
  border: 1px solid #E89B3F;
  background-color: #F6D288;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.7;
  padding: 7px;
  text-align: left; }

#pay-table td {
  background: none repeat scroll 0 0 #FFFFFF;
  border: 1px solid #E89B3F;
  font-size: 13px;
  line-height: 1.7;
  padding: 7px; }

/*! legitRipple.js v1.1.0: ripple.min.css by Matthias Vogt (ISC license) */
.legitRipple,
.legitRipple-custom {
  overflow: hidden; }

.legitRipple {
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 0; }

.legitRipple-ripple {
  position: absolute;
  z-index: -1;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  pointer-events: none;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.4);
  will-change: transform, width, opacity;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  width: 0;
  opacity: 1;
  -webkit-transition: width .15s linear, opacity .9s ease-out;
  transition: width .15s linear, opacity .9s ease-out; }

.legitRipple-ripple:before {
  content: "";
  padding-top: 100%;
  display: block; }

img ~ .legitRipple-ripple {
  z-index: auto; }

.legitRipple-template {
  display: none; }

.legitRipple-custom > * {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

/* 20190322 ヘッダーカテゴリ+サイト内検索 */
#dropmenu,
#srchBox_side {
  display: none !important; }

#serach-bar {
  background: #009688; }

/*サイト内検索ボックス*/
#serach-bar-searchform-block {
  max-width: 970px;
  width: 90%;
  margin: 0 auto;
  padding: 5px 0; }

#serach-bar-searchform-block-text {
  width: 100%; }
  @media only screen and (min-width: 768px) {
    #serach-bar-searchform-block-text {
      width: 80%;
      float: left; } }

#serach-bar-searchform-block-text:before {
  content: none; }

#serach-bar-searchform-block-icon {
  width: 10%;
  background: #EEEEEE;
  height: 35px;
  display: table-cell;
  vertical-align: middle;
  font-size: 1.1rem;
  padding: 2px 5px;
  border: 0px;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  text-align: center; }

#serach-bar-searchform-block-text input {
  width: calc(100% - 80px);
  height: 35px;
  float: left;
  font-size: 1.1rem;
  padding: 2px 5px;
  border: 0px;
  border-radius: 4px 0 0 4px;
  transition: .3s; }
  @media only screen and (min-width: 768px) {
    #serach-bar-searchform-block-text input {
      width: 85%; } }

#serach-bar-searchform-block-text input:hover {
  background: #E1F5FE;
  transition: .3s; }

#serach-bar-searchform-detail {
  display: none; }

#serach-bar-searchform-detail {
  position: fixed;
  z-index: 7777;
  display: none;
  width: 80%;
  max-width: 840px;
  margin: 0;
  padding: 60px 2vw 80px;
  border: 2px solid #aaa;
  text-align: center;
  background: #fff;
  box-sizing: border-box; }

#serach-bar-searchform-block-detail {
  color: #fff;
  text-decoration: underline;
  line-height: 1.5;
  cursor: pointer; }
  #serach-bar-searchform-block-detail #search-popup-trigger {
    display: none; }
    @media only screen and (min-width: 768px) {
      #serach-bar-searchform-block-detail #search-popup-trigger {
        display: inline-block;
        margin-left: 20px;
        text-decoration: underline; } }

.detail-modal-search {
  background: #009688;
  color: #fff !important;
  text-decoration: none;
  font-size: 1.2rem;
  padding: 10px 25px;
  border-radius: 4px; }

.detail-modal-close {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 62px;
  font-size: 46px;
  color: #000;
  line-height: 62px;
  text-align: center;
  background: #e6e6e6;
  border-radius: 0 0 0 20px;
  cursor: pointer; }

.detail-modal-close i {
  line-height: 62px;
  vertical-align: bottom; }

.modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 6666;
  display: none;
  width: 100%;
  height: 120%;
  background-color: rgba(0, 0, 0, 0.7); }

.youtube_area {
  max-width: 560px; }
  .youtube_area__inner {
    position: relative;
    width: 100%;
    padding-top: calc(315 / 560 * 100%); }
  .youtube_area iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

@media screen and (max-width: 769px) {
  /*  ウィンドウサイズ769px以下の時のスタイル  */
  #serach-bar-searchform-detail {
    padding: 50px 2vw 40px; }
  .detail-modal-close {
    width: 40px;
    line-height: 40px;
    font-size: 30px; }
  .detail-modal-close i {
    line-height: 44px; }
  #serach-bar-menu-dropdown {
    height: 55px; } }

#serach-bar-searchform-block-submit {
  width: 150px;
  float: left; }

#serach-bar-searchform-block-submit a {
  background: #fff;
  width: 150px;
  height: 35px;
  padding: 2px 5px;
  border-radius: 4px;
  display: table-cell;
  vertical-align: middle;
  font-size: 1.1rem;
  text-decoration: none;
  transition: .3s; }

#serach-bar-searchform-block-submit a:hover {
  background: #E8F5E9;
  transition: .3s; }

/* ドロップダウンメニュー */
.fa-color-bk {
  color: #000; }

.fa-color-wh {
  color: #fff; }

.fa-color-bl {
  color: #2196F3; }

.fa-color-gr {
  color: #8BC34A; }

.fa-color-ye {
  color: #FDD835; }

.fa-color-or {
  color: #FF7043; }

#serach-bar-menu {
  display: none; }
  @media only screen and (min-width: 768px) {
    #serach-bar-menu {
      display: block; } }
  #serach-bar-menu .header_button_cartitem {
    background-color: red;
    color: white;
    border-radius: 5px;
    padding: 3px; }

#serach-bar-menu-dropdown {
  list-style-type: none;
  width: 90%;
  max-width: 970px;
  height: 40px;
  margin: 0 auto;
  padding: 0;
  background: #009688;
  border-bottom: 5px solid #00897B;
  border-radius: 3px 3px 0 0;
  clear: both; }

#serach-bar-menu-dropdown li {
  position: relative;
  width: 15.5%;
  float: left;
  margin: 0;
  padding: 0;
  text-align: center;
  border-right: 1px solid #bdbdbd; }

#serach-bar-menu-dropdown li:last-child {
  border-right: 0px solid #bdbdbd; }

#serach-bar-menu-dropdown li a {
  display: block;
  margin: 0;
  padding: 15px 0 11px;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  text-decoration: none; }

#serach-bar-menu-dropdown li ul {
  list-style: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 999;
  margin: 0;
  padding: 0;
  border-radius: 0 0 3px 3px; }

#serach-bar-menu-dropdown li:last-child ul {
  left: -100%;
  width: 100%; }

#serach-bar-menu-dropdown li ul.nav_br_r {
  float: right; }

#serach-bar-menu-dropdown li ul.nav_br_l {
  float: left;
  margin-left: 200px; }

#serach-bar-menu-dropdown li ul li {
  overflow: hidden;
  width: 220px;
  height: 0;
  color: #fff;
  -moz-transition: .2s;
  -webkit-transition: .2s;
  -o-transition: .2s;
  -ms-transition: .2s;
  transition: .2s; }

#serach-bar-menu-dropdown li ul.nav_br_r li,
#serach-bar-menu-dropdown li ul.nav_br_l li {
  width: 200px; }

#serach-bar-menu-dropdown li ul li a {
  padding: 13px 15px;
  background: #5E5A5A;
  border-top: 2px solid #5E5A5A;
  border-bottom: 2px solid #5E5A5A;
  text-align: left;
  font-size: 12px;
  font-weight: normal; }

#serach-bar-menu-dropdown li:hover > a {
  background: #fb8c00;
  color: #fff; }

#serach-bar-menu-dropdown li:hover ul li {
  overflow: visible;
  height: 38px;
  border-top: 1px solid #767474;
  border-bottom: 1px solid #767474; }

#serach-bar-menu-dropdown li:hover ul li:first-child {
  border-top: 0; }

#serach-bar-menu-dropdown li:hover ul li:last-child {
  border-bottom: 0; }

#serach-bar-menu-dropdown li:hover ul li:last-child a {
  border-radius: 0 0 3px 3px; }

#serach-bar-menu-dropdown li:hover ul.nav_br_r li:last-child a {
  border-radius: 0 0 0 3px; }

#serach-bar-menu-dropdown li:hover ul.nav_br_l li:last-child a {
  border-radius: 0 0 3px 0; }

.serach-bar-menu-dropdown_abt {
  background: #FF9800 !important;
  border-bottom: 5px solid #e08600 !important; }

.serach-bar-menu-dropdown_abt li {
  border-right: 1px solid #bdbdbd; }

.serach-bar-menu-dropdown_abt li:last-child {
  border-right: 0px solid #bdbdbd; }

.serach-bar-menu-dropdown_abt li a {
  color: #fff; }

.serach-bar-menu-dropdown_abt li ul li {
  color: #fff; }

.serach-bar-menu-dropdown_abt li ul li a {
  background: #5E5A5A; }

.serach-bar-menu-dropdown_abt li:hover > a {
  background: #ffa41f !important;
  color: #fff; }

.serach-bar-menu-dropdown_abt li:hover ul li {
  border-top: 1px solid #767474;
  border-bottom: 1px solid #767474; }

/* パンくずリスト */
.breadcrumbs {
  margin: 10px 0; }

.breadcrumbs ol {
  display: table;
  font-size: 13px;
  list-style: none;
  margin: 0;
  padding: 0; }

.breadcrumbs ol li {
  margin: 0 5px 0 0;
  font-size: 13px;
  float: left; }

.disabled {
  pointer-events: none;
  text-decoration: none !important; }

.no-underline {
  text-decoration: none; }

/* 商品ページ ボタン 2019/06/14～ */
.cover-link {
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  display: block; }

.box-cta-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  list-style: none;
  padding: 0; }

.box-cta-item {
  border: 1px solid #2196F3;
  border-radius: 4px;
  box-sizing: border-box;
  color: #2196F3;
  display: flex;
  flex: 1 0 140px;
  flex-direction: column;
  justify-content: center;
  margin: 10px 5px;
  padding: 0.5rem;
  max-width: 100%;
  text-align: center;
  font-size: 1.0rem;
  font-weight: bold;
  transition: all .3s;
  position: relative; }

.box-cta-item:hover {
  background: #1565C0;
  color: #fff !important; }

.box-cta-item-link:hover {
  color: #fff !important; }

.box-cta-item .fa {
  margin-bottom: 10px; }

.box-cta-cart {
  border: 3px solid #388E3C;
  color: #fff;
  background: #43A047;
  font-size: 1.4rem;
  flex-basis: 95%; }

.box-cta-cart:hover {
  background: #388E3C;
  color: #fff !important; }

.box-cta-sample {
  border: 1px solid #0D47A1;
  background: #E3F2FD;
  color: #0D47A1; }

.box-cta-sample:hover {
  background: #0D47A1;
  color: #fff !important; }

.box-cta-kaematen {
  border: 1px solid #8BC34A;
  background: #F1F8E9;
  color: #689F38; }

.box-cta-kaematen:hover {
  background: #8BC34A;
  color: #fff !important; }

.box-cta-contact {
  border: 1px solid #0097A7;
  background: #E0F7FA;
  color: #0097A7; }

.box-cta-contact:hover {
  background: #0097A7;
  color: #fff !important; }

.box-cta-print {
  border: 1px solid #8D6E63;
  background: #EFEBE9;
  color: #8D6E63; }

.box-cta-print:hover {
  background: #8D6E63;
  color: #fff !important; }

.box-cta-quick {
  border: 1px solid #E65100;
  background: #FFCDD2;
  color: #212121; }

.box-cta-quick:hover {
  background: #F44336;
  color: #fafafa !important; }

.box-cta-item-link {
  color: #2196F3;
  padding: 20px 30px;
  text-decoration: none; }

.box-cta-block {
  background: #E3F2FD;
  padding: 20px; }

.box-present-10,
.box-present-20 {
  font-size: 1.1rem; }

.abtest-new {
  display: none; }

/* 20191028 検索バーをスクロールに追従 */
.show-on-scroll {
  display: none; }

@media screen and (min-width: 970px) {
  .is-fixed #serach-bar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    width: 100%; }
  .is-fixed .show-on-scroll {
    display: block; }
  .is-fixed #serach-bar-menu-dropdown {
    max-width: 1080px; }
  .is-fixed #serach-bar-menu-dropdown > li {
    width: 13.5%; } }

@keyframes show {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.is-fixed .side_banner {
  display: block;
  animation: show 0.3s linear 0s; }

@media screen and (max-width: 1250px) {
  .is-fixed .side_banner {
    display: none !important; } }

body.is-modal {
  overflow: hidden; }

.side_banner {
  display: none;
  position: fixed;
  top: 100px;
  z-index: 10001;
  background: url("/info/wp-content/uploads/sample-sidebanner2.jpg") no-repeat scroll 0 0;
  width: 100px;
  height: 600px;
  cursor: pointer; }

.side_banner_l {
  left: 0; }

/* レビューの★ */
.evaluation {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center; }

.evaluation input[type='radio'] {
  display: none; }

.evaluation label {
  position: relative;
  padding: 10px 10px 0;
  color: #757575;
  cursor: pointer;
  font-size: 50px; }

.evaluation label .text {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  text-align: center;
  font-size: 12px;
  color: #757575; }

.evaluation label:hover,
.evaluation label:hover ~ label {
  color: #FFD54F !important; }

.evaluation input[type='radio']:checked ~ label {
  color: #FFC107; }

.product-price-block {
  margin: 1rem 0; }

.product-price-detail {
  color: #e50000;
  font-weight: bold;
  font-size: 1.6em; }

.product-price-notice {
  color: #e50000;
  font-weight: bold;
  font-size: 1.3rem;
  text-decoration: underline; }

.product-price-list {
  font-size: 1.1rem; }

.product-price-list,
.product-price-list > dt,
.product-price-list > dd {
  box-sizing: border-box; }

.product-price-list > dt,
.product-price-list > dd {
  padding: 0.2rem;
  margin: 0.1rem 0; }

.product-price-list > dt {
  text-align: right;
  font-weight: normal; }

.product-price-list-del {
  text-decoration: line-through; }

.product-price-list-big {
  font-size: 1.6rem;
  color: #E53935;
  font-weight: bold; }

.product-price-list-little {
  font-size: 0.8rem; }

.product-price-list-point {
  font-size: 1.2rem;
  color: #F57C00;
  font-weight: bold; }

.product-price-list-promo {
  border-bottom: 4px double #e50000;
  line-height: 1.6;
  font-size: 1.5rem;
  font-weight: bold; }

.product-price-list-stock-ok {
  color: #008a00;
  font-weight: bold; }

.product-price-list-stock-pre {
  color: #008a00; }

.product-price-list-stock-soso {
  color: #9D0A0A; }

.product-price-list-stock-ng {
  color: #9D0A0A;
  font-size: 1.1rem; }

.product-price-delivery {
  margin-top: 0.5rem;
  font-size: 0.9rem; }

.product-price-delivery-remaining {
  font-weight: bold;
  font-size: 1.1rem;
  color: #008a00; }

.product-price-delivery-date {
  font-weight: bold;
  font-size: 1.1rem;
  color: #2196f3; }

.product-price-delivery-notice {
  font-size: 0.9rem; }

.product-price-per {
  padding: 0.3rem;
  font-size: 1rem; }

.product-free-shipping {
  font-size: 1.2rem;
  color: #fafafa;
  background: #F44336;
  display: inline-block;
  padding: 2px 5px;
  margin: 0 0.5rem;
  border-radius: 4px; }

.product-exclude-point {
  display: inline-block;
  padding: 5px;
  margin: 0.5rem;
  border-radius: 4px;
  font-size: 0.9rem;
  border: solid 2px #5C6BC0; }

.product-pointback {
  color: #e50000;
  font-weight: bold;
  font-size: 1.4em;
  margin-top: 0.5rem; }

@media screen and (max-width: 767px) {
  .product-price-list > dt,
  .product-price-list > dd {
    text-align: center; }
  .product-price-list > dd > ul {
    text-align: left; }
  .tradelaw_table tbody,
  .tradelaw_table td,
  .tradelaw_table th,
  .tradelaw_table tr,
  .tradelaw_table {
    display: block;
    width: 100%; } }

@media print, (min-width: 768px) {
  .product-price-list {
    display: flex;
    flex-wrap: wrap; }
  .product-price-list > dt,
  .product-price-list > dd {
    display: flex;
    flex-direction: column;
    justify-content: start; }
  .product-price-list-center {
    justify-content: center !important; }
  .product-price-list > dt {
    width: 35%; }
  .product-price-list > dd {
    width: 65%; } }

.confirm-mail-addr {
  font-size: 2.1rem;
  font-family: "UD Digi Kyokasho NP-R", "Hiragino Sans", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Meiryo", "MS Gothic", sans-serif;
  font-weight: bold;
  word-break: break-all;
  display: inline-block; }

/* サンプルフォームの選択肢 */
.form-desc {
  font-size: 1.1rem;
  margin: 1rem 0.5rem;
  line-height: 1.6; }

.form-button-lead {
  display: block;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  padding: 0.5rem;
  text-decoration: underline; }

.list-button-two {
  border: solid 2px #FF9800;
  border-radius: 8px;
  border-collapse: separate;
  overflow: hidden; }

.list-button-two td {
  vertical-align: top;
  padding: 0.2rem;
  width: 50%; }

.list-button-two tr,
.list-button-two td {
  background: transparent;
  border: none; }

.list-button-two tr td:nth-child(odd) {
  background: #F1F8E9; }

.list-button-two-l a,
.list-button-two-r a {
  display: block;
  padding: 1rem;
  margin: 0.5rem;
  border-radius: 8px;
  font-size: 1.2rem;
  font-weight: bold;
  transition: all .3s; }

.list-button-two span {
  font-size: 1.1rem;
  line-height: 1.5;
  padding: 0 1rem;
  display: block; }

.list-button-two-l,
.list-button-two-r {
  text-align: center !important; }

.list-button-two-l a {
  border: 1px solid #43A047;
  background: #4CAF50;
  color: #fafafa; }

.list-button-two-l a:hover {
  background: #2E7D32;
  color: #fafafa !important; }

.list-button-two-r a {
  border: 1px solid #0D47A1;
  background: #E3F2FD;
  color: #0D47A1; }

.list-button-two-r a:hover {
  background: #0D47A1;
  color: #fff !important; }

/* 支払い画面のポイント */
.payment-point {
  line-height: 1.5; }

.payment-point-balance {
  font-size: 1.2rem;
  font-weight: bold;
  color: #E53935; }

.point_check_use {
  margin-left: 1.5rem; }

#use_point {
  font-size: 1.2rem;
  padding: 0 5px; }

/* マイページのポイント表示 */
.mypage_point {
  width: 95%;
  max-width: 400px;
  line-height: 1.5; }

.mypage_point_balance {
  font-size: 1.2rem;
  font-weight: bold;
  color: #E53935; }

.is_used_point {
  background: #d7e9ff; }

.is_got_point {
  background: #ffe2e2; }

.is_used_point_status {
  color: #1E88E5; }

.is_got_point_status {
  color: #E53935; }

.mypage_point_num {
  font-size: 1.2rem;
  font-weight: bold; }

/* iframeフォーム埋め込み */
.iframe-pd {
  border: 2px solid #ccc;
  border-radius: 1rem;
  margin: 1.5rem 0;
  overflow: hidden; }

/* コラムランダム表示 */
@media only screen and (max-width: 769px) {
  .random-posts-banner {
    display: block; } }

.topimage-cell.random-posts {
  width: 45%;
  border-bottom: 2px solid #9E9E9E;
  padding: 0 1.5rem 1rem 0;
  margin: 10px 0; }
  @media only screen and (max-width: 769px) {
    .topimage-cell.random-posts {
      width: 100%;
      padding: 0 10px 1rem; } }

.random-posts a {
  display: inline-block;
  width: 90%; }
  @media only screen and (max-width: 769px) {
    .random-posts a {
      width: 100%; } }

.random-posts img {
  max-width: 95%;
  height: auto; }

.random-posts .ttl03 {
  border-left: 4px solid #388E3C;
  padding-left: 0.5rem;
  font-size: 1rem !important;
  display: inline-block; }

@media only screen and (max-width: 769px) {
  #other_area_n {
    margin: 0 10px; } }

.page-list p,
.page-list div {
  font-size: 16px;
  margin: 1rem 0; }

.sample-items td {
  text-align: center; }

/* サイドバー検索 */
.category_search_form h3 {
  clear: both;
  background: #E0E0E0;
  border-radius: 0.4rem;
  padding: 0.5rem;
  margin: 0.5rem 0 0.5rem;
  width: 100%; }

#search_form_lite .threec_dl {
  width: 100%; }

#search_form_lite dt {
  margin: 0.2rem;
  float: none;
  width: auto;
  font-weight: normal; }

.side_input {
  padding: 0.3rem !important;
  font-size: 1rem;
  border-radius: 0.2rem;
  width: 100%; }

.btn-search {
  text-align: center;
  width: 90%; }

.btn-search input {
  width: 80%;
  margin: 1.5rem 0;
  padding: 0.5rem;
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 0.4rem;
  border: 1px solid #ccc;
  background: #EEEEEE; }

.btn-search input:hover {
  cursor: pointer;
  opacity: 0.8; }

.search_lite_button {
  padding: 0.5rem;
  margin-top: 0.5rem;
  margin-right: 0.5rem;
  background: #e6f4fa;
  border: 1px solid #99cde7;
  color: #003b65;
  border-radius: 0.5rem;
  font-size: 1rem; }

.search_lite_button:after {
  content: "\f057";
  font-family: "Font Awesome 5 Free";
  vertical-align: middle;
  font-size: 1.2rem;
  margin-left: 0.2rem; }

.search_lite_button:hover {
  cursor: pointer;
  opacity: 0.8; }

.search_lite_button span {
  vertical-align: middle; }

.span_bl {
  color: #333; }

.product_brand_name {
  display: inline-block;
  background: #1E88E5;
  padding: 0.4rem;
  border-radius: 1rem;
  margin: 0.5rem 0;
  color: #fafafa; }

.product_brand_name span {
  vertical-align: middle; }

/* 右上メニュー */
.menu-box {
  max-width: 45rem;
  margin: 0 1vh;
  background: #fafafa;
  font-size: 1.1rem; }
  @media only screen and (max-width: 769px) {
    .menu-box {
      margin: 0;
      padding: 0 1vh; } }
  .menu-box .item-0 {
    text-align: center;
    padding: 0.5rem 1rem;
    font-size: 1.2rem;
    border-left: 2px solid #ccc;
    border-right: 2px solid #ccc; }
    .menu-box .item-0 a {
      text-decoration: underline; }
    @media only screen and (max-width: 769px) {
      .menu-box .item-0 {
        border-top: 2px solid #ccc;
        border-radius: 0.4rem 0.4rem 0 0;
        font-size: 1.0rem; } }
  .menu-box .menu-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 2vh;
    position: relative;
    border: 2px solid #ccc;
    border-radius: 0 0 0.4rem 0.4rem;
    list-style: none; }
    @media only screen and (max-width: 769px) {
      .menu-box .menu-container {
        align-items: center;
        flex-flow: row wrap;
        margin-bottom: 0.5rem;
        font-size: 1.0rem;
        padding: 0; } }
    .menu-box .menu-container li {
      border-right: 2px solid #ccc; }
      @media only screen and (min-width: 768px) {
        .menu-box .menu-container li:last-of-type {
          border-right: none; } }
      @media only screen and (max-width: 769px) {
        .menu-box .menu-container li {
          width: 50%; }
          .menu-box .menu-container li:nth-of-type(even) {
            border-right: none; }
          .menu-box .menu-container li:nth-of-type(-n+2) {
            border-bottom: 2px solid #ccc; } }
    .menu-box .menu-container .ec-cartNavi,
    .menu-box .menu-container li > a {
      padding: 0 1rem;
      margin: 0 0.2rem;
      text-decoration: none;
      transition: all 0.3s;
      display: block; }
      @media only screen and (max-width: 769px) {
        .menu-box .menu-container .ec-cartNavi,
        .menu-box .menu-container li > a {
          padding: 0.5rem; } }
      .menu-box .menu-container .ec-cartNavi:hover,
      .menu-box .menu-container li > a:hover {
        color: #1E88E5; }

.detail_table_area {
  width: 100%;
  margin-top: 10px; }

.detail_table {
  width: 100%; }

.callme_blk {
  position: relative;
  padding: 0.5em;
  font-size: 100%;
  font-weight: bolder;
  color: #000;
  border-radius: 3px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.26); }

.description_list_text {
  font-weight: bold;
  font-size: 19px; }

.detail_bottom_area {
  clear: both;
  padding-top: 10px;
  font-size: 12px; }
  .detail_bottom_area .common-table {
    width: 96%; }
    @media only screen and (min-width: 768px) {
      .detail_bottom_area .common-table {
        width: 100%; } }

.indent {
  text-indent: -1em;
  padding-left: 1em; }

@media only screen and (max-width: 769px) {
  table.entry_table {
    display: block;
    width: 100%; }
    table.entry_table tbody,
    table.entry_table tr,
    table.entry_table th,
    table.entry_table td {
      display: block;
      width: 100%; } }

table.estimate_table a img {
  max-width: 100px;
  display: block;
  margin: auto; }

table.estimate_table .download_image {
  max-width: 100px;
  display: block;
  margin: auto; }

@media only screen and (max-width: 769px) {
  table.estimate_table {
    display: block;
    width: 100%;
    border: none; }
    table.estimate_table tbody,
    table.estimate_table tr,
    table.estimate_table td {
      display: block;
      width: 100%; }
    table.estimate_table th {
      display: none; }
    table.estimate_table tbody > tr {
      margin-bottom: 20px;
      border: 1px solid #ccc; }
    table.estimate_table td {
      position: relative;
      text-align: right;
      word-break: break-all;
      padding-left: 8em; }
      table.estimate_table td a img {
        max-width: 150px; }
      table.estimate_table td:before {
        content: attr(aria-label);
        position: absolute;
        top: 0;
        left: 0;
        padding: 10px;
        text-align: left; }
      table.estimate_table td.estimate_table__td_btn {
        padding: 10px; } }

.ranking_ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0; }
  .ranking_ul li {
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    width: calc(100% / 2 - 10px);
    margin-right: 10px;
    margin-bottom: 10px; }
    .ranking_ul li:nth-of-type(2n) {
      margin-right: 0; }
    @media only screen and (min-width: 768px) {
      .ranking_ul li {
        width: calc(100% / 5 - 8px); }
        .ranking_ul li:nth-of-type(2n) {
          margin-right: 8px; }
        .ranking_ul li:nth-of-type(5n) {
          margin-right: 0; } }
    .ranking_ul li .ranking_ul_top {
      display: flex; }
      .ranking_ul li .ranking_ul_top__rank .ranking_num {
        display: inline-block;
        color: #fff;
        padding-left: 5px;
        padding-right: 5px;
        vertical-align: middle;
        font-size: 25px;
        font-weight: bold; }
      .ranking_ul li .ranking_ul_top__name {
        word-break: break-all;
        padding: 4px; }
    .ranking_ul li .ranking_ul_img {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1 auto;
      min-height: 0%;
      padding: 0 10px; }
      .ranking_ul li .ranking_ul_img img {
        width: 100%; }
    .ranking_ul li .ranking_ul_bottom {
      padding: 10px;
      text-align: center;
      max-height: 50px; }
      .ranking_ul li .ranking_ul_bottom a {
        display: inline-block;
        margin-top: auto;
        color: #FF9800;
        font-size: 14px; }
        .ranking_ul li .ranking_ul_bottom a:before {
          content: "\f144";
          font-family: 'Font Awesome 5 Free'; }
